import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import clsx from "clsx";
import { AppText } from "@/components/Text/AppText";
import { Tooltip } from "@/components/Tooltip/Tooltip";
import { TooltipContent } from "@/components/Tooltip/TooltipContent";
import { TooltipTrigger } from "@/components/Tooltip/TooltipTrigger";
import { resumeThemes } from "@/constants/resume";

interface ColourPickerProps {
    onChange: (colour: string) => void;
    activeColour: string;
}

const ColourBlock = ({
    colour,
    isActive,
    colourName,
}: {
    colour: string;
    isActive: boolean;
    colourName: string;
}) => (
    <Tooltip
        placement="bottom"
        openDelay={750}
    >
        <TooltipTrigger>
            <div
                className={clsx(
                    "h-4 w-4 rounded ",
                    colour === "#FFFFFF" && "border-2 border-neutral-900",
                    isActive && "ring-2 ring-primary-900 ring-offset-2",
                )}
                style={{
                    backgroundColor: colour,
                }}
            />
        </TooltipTrigger>
        <TooltipContent>
            <AppText variant="footnote">{colourName}</AppText>
        </TooltipContent>
    </Tooltip>
);

const ActiveItem = ({
    colour,
    colourName,
}: {
    colour: string;
    colourName: string;
}) => (
    <div className="flex h-9 w-9 items-center justify-center rounded-lg ring-2 ring-inset ring-neutral-300">
        <ColourBlock
            colour={colour}
            isActive={false}
            colourName={colourName}
        />
    </div>
);

export const ColourPicker = ({
    onChange,
    activeColour = "#FFFFFF",
}: ColourPickerProps) => {
    const themeOptions = resumeThemes.map(
        theme => theme.profileBackgroundColor,
    );
    const activeTheme = resumeThemes.find(
        theme => theme.profileBackgroundColor === activeColour,
    );

    return (
        <div className="flex justify-end">
            <Popover className="relative">
                <PopoverButton className="h-9 min-h-9 w-9">
                    <ActiveItem
                        colour={activeColour}
                        colourName={activeTheme?.name ?? ""}
                    />
                </PopoverButton>
                <PopoverPanel
                    portal
                    anchor={{
                        gap: 8,
                        to: "right start",
                    }}
                    className="z-50 rounded-md bg-white p-2 ring-2 ring-neutral-300"
                >
                    <div className="grid grid-cols-6 gap-3">
                        {themeOptions.map(colour => (
                            <span
                                key={colour}
                                onClick={() => onChange(colour)}
                                className="flex focus:outline-primary-900"
                            >
                                <ColourBlock
                                    colour={colour}
                                    isActive={colour === activeColour}
                                    colourName={
                                        resumeThemes.find(
                                            theme =>
                                                theme.profileBackgroundColor ===
                                                colour,
                                        )?.name ?? ""
                                    }
                                />
                            </span>
                        ))}
                    </div>
                </PopoverPanel>
            </Popover>
        </div>
    );
};
