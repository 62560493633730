import { InfoTooltip } from "@/components/InfoTooltip/InfoTooltip";
import { AppText } from "@/components/Text/AppText";

interface StatCardProps {
    title: string;
    value?: number | string;
    icon?: React.ReactNode;
    loading?: boolean;
    children?: React.ReactNode;
    className?: string;
    description?: string;
    tooltip?: string;
}

export const StatCard = ({
    title,
    value,
    icon,
    loading,
    children,
    className,
    description,
    tooltip,
}: StatCardProps) => (
    <div
        className={`flex flex-col gap-3 rounded-lg bg-white p-4 shadow-md ${className}`}
    >
        <div className="flex items-center gap-2">
            <div className="text-primary-900">{icon}</div>
            <AppText
                variant="contextheading"
                className="text-neutral-900"
            >
                {title}
            </AppText>
            {tooltip && <InfoTooltip content={tooltip} />}
        </div>
        {children ? (
            children
        ) : (
            <div className="flex h-12 items-center justify-center">
                <AppText
                    variant="headings"
                    className="text-primary-900"
                >
                    {loading ? (
                        <span className="inline-block w-24 animate-pulse rounded bg-gray-200 py-2">
                            &nbsp;
                        </span>
                    ) : (
                        value
                    )}
                </AppText>
            </div>
        )}
        {description && (
            <AppText
                variant="footnote"
                className="block !leading-tight text-neutral-500"
            >
                {description}
            </AppText>
        )}
    </div>
);
