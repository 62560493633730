import { CreditActionCode } from "@/types/creditAction";
import { apiRequest } from "./request";
import { ICredit, User } from "@/types/user";
import { setUserCredits } from "@/store/user/slice";
import { AppDispatch } from "@/store/store";
import { AiFeedbackRequest, AIModel } from "@/types/ai";

export interface AiResponse {
    output: string;
    userUpdates?: Partial<User>;
    modelUsed?: number;
}

export interface AiResumeSummaryRequest {
    content?: string;
    industries?: string[];
    jobRoles?: string[];
    experienceLevel?: string;
    overridePrompt?: string;
    temperature?: number;
    mockResponse?: boolean;
    adminId?: string;
    sectionType?: string;
    model?: AIModel;
}

const mockDelay = () => new Promise(resolve => setTimeout(resolve, 1000));

const postProcessAiResponse = (userCredits: ICredit | undefined, dispatch: AppDispatch) => {
    if (userCredits) {
        dispatch(setUserCredits(userCredits));
    }
};

const generateParagraphPro = async (params: AiResumeSummaryRequest, signal?: AbortSignal, dispatch?: AppDispatch) => {
    if (params.mockResponse) {
        await mockDelay();
        return Promise.resolve({
            output: "Results-driven software engineer with 5+ years of experience in full-stack development. Specialized in building scalable web applications using React, Node.js, and cloud technologies. Proven track record of delivering high-quality solutions and mentoring junior developers. Strong focus on clean code practices and performance optimization.",
        } as AiResponse);
    }
    const { userUpdates, ...rest } = await apiRequest<AiResponse>(`/ai/paragraph-pro`, "POST", params, undefined, undefined, signal);
    if (dispatch && userUpdates?.credits) {
        postProcessAiResponse(userUpdates.credits, dispatch);
    }
    return rest;
};

export interface AiBulletPointsRequest {
    content?: string;
    sectionName?: string;
    overridePrompt?: string;
    temperature?: number;
    mockResponse?: boolean;
    sectionType?: string;
    adminId?: string;
    industries?: string[];
    jobRoles?: string[];
    experienceLevel?: string;
    model?: AIModel;
}

const generateBulletBuilder = async (params: AiBulletPointsRequest, signal?: AbortSignal, dispatch?: AppDispatch) => {
    if (params.mockResponse) {
        await mockDelay();
        return Promise.resolve({
            output: "• Developed and maintained multiple high-traffic web applications using React and TypeScript\n• Implemented CI/CD pipelines reducing deployment time by 40%\n• Led a team of 5 developers in delivering a major platform upgrade\n• Optimized database queries resulting in 30% improved performance",
        } as AiResponse);
    }
    const { userUpdates, ...rest } = await apiRequest<AiResponse>(`/ai/generate-bullet-points`, "POST", params, undefined, undefined, signal);
    if (dispatch && userUpdates?.credits) {
        postProcessAiResponse(userUpdates.credits, dispatch);
    }
    return rest;
};


export interface AiQuickPromptRequest {
    content?: string;
    temperature?: number;
    mockResponse?: boolean;
    creditActionCode?: CreditActionCode;
    adminId?: string;
    sectionType?: string;
    model?: AIModel;
}

const generateQuickPrompt = async (params: AiQuickPromptRequest, signal?: AbortSignal, dispatch?: AppDispatch) => {
    if (params.mockResponse) {
        await mockDelay();
        return Promise.resolve({
            output: `Mock response for ${params.creditActionCode}`,
        } as AiResponse);
    }
    const { userUpdates, ...rest } = await apiRequest<AiResponse>(`/ai/write-prompt`, "POST", params, undefined, undefined, signal);
    if (dispatch && userUpdates?.credits) {
        postProcessAiResponse(userUpdates.credits, dispatch);
    }
    return rest;
};

export interface AiResumeFormatterRequest {
    resume: string;
    model: AIModel;
    temperature?: number;
}

const generateResumeFormatter = async (params: AiResumeFormatterRequest, signal?: AbortSignal, dispatch?: AppDispatch) => {
    const { userUpdates, ...rest } = await apiRequest<AiResponse>(`/ai/resume-formatter`, "POST", params, undefined, undefined, signal);
    if (dispatch && userUpdates?.credits) {
        postProcessAiResponse(userUpdates.credits, dispatch);
    }
    return rest;
};

export interface AiResumeRewriterRequest {
    resume: string;
    jobDescription: string;
    model: AIModel;
    temperature?: number;
}

const generateResumeRewriter = async (params: AiResumeRewriterRequest, signal?: AbortSignal, dispatch?: AppDispatch) => {
    const { userUpdates, ...rest } = await apiRequest<AiResponse>(`/ai/resume-rewriter`, "POST", params, undefined, undefined, signal);
    if (dispatch && userUpdates?.credits) {
        postProcessAiResponse(userUpdates.credits, dispatch);
    }
    return rest;
};


export interface AiResumeImporterRequest {
    resume: string;
    jobDescription: string;
    overrideRewriteModel: AIModel;
    overrideFormatModel: AIModel;
    rewriteTemperature: number;
    formatTemperature: number;
}

const generateResumeImporter = async (params: AiResumeImporterRequest, signal?: AbortSignal, dispatch?: AppDispatch) => {
    const { userUpdates, ...rest } = await apiRequest<AiResponse>(`/ai/resume-importer`, "POST", params, undefined, undefined, signal);
    if (dispatch && userUpdates?.credits) {
        postProcessAiResponse(userUpdates.credits, dispatch);
    }
    return rest;
};

export interface AiSmartMatchRequest {
    resume: string;
    jobDescription: string;
    model: AIModel;
    temperature?: number;
}

const generateSmartMatch = async (params: AiSmartMatchRequest, signal?: AbortSignal, dispatch?: AppDispatch) => {
    const { userUpdates, ...rest } = await apiRequest<AiResponse>(`/ai/smart-match`, "POST", params, undefined, undefined, signal);
    if (dispatch && userUpdates?.credits) {
        postProcessAiResponse(userUpdates.credits, dispatch);
    }
    return rest;
};

const createAiFeedback = async (params: AiFeedbackRequest) => {
    await apiRequest<AiResponse>(`/ai/feedback`, "POST", params);
};

export default {
    generateParagraphPro,
    generateBulletBuilder,
    generateQuickPrompt,
    createAiFeedback,
    generateResumeFormatter,
    generateSmartMatch,
    generateResumeRewriter,
    generateResumeImporter
};  
