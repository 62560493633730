import { useState } from "react";
import { JsonEditorComponent } from "@/pages/Admin/JsonEditorComponent/JsonEditorComponent";

interface AiResponsePreviewProps {
    aiResponse: Record<string, unknown>;
    model?: string;
    temperature?: number;
    tool?: string;
}

export const AiResponsePreview = ({
    aiResponse,
    model = "Unknown Model",
    temperature = 0,
    tool,
}: AiResponsePreviewProps) => {
    const previewOnly = tool === "ai-resume-rewriter";
    const [viewMode, setViewMode] = useState<"json" | "preview">("preview");
    const [copySuccess, setCopySuccess] = useState(false);

    const formatBulletPoints = (text: string): string[] => {
        // Match text that starts with •, -, *, or a number followed by . or )
        const bulletRegex = /^[•\-*]|^\d+[.)]|\n[•\-*]|\n\d+[.)]/;

        if (!bulletRegex.test(text)) {
            return [text];
        }

        // Split by bullet points while preserving the bullet point
        return text
            .split(/\n(?=[•\-*]|\d+[.)])/g)
            .map(line => line.trim())
            .filter(line => line.length > 0);
    };

    const renderValue = (value: unknown): JSX.Element => {
        // For previewOnly mode, just render the string value directly
        if (previewOnly) {
            return <div className="text-sm leading-snug">{String(value)}</div>;
        }

        if (Array.isArray(value)) {
            return (
                <div className="space-y-2">
                    {value.map((item, index) => (
                        <div
                            key={index}
                            className={`pb-2 leading-snug ${
                                index !== value.length - 1
                                    ? "border-b-2 border-primary-200"
                                    : ""
                            }`}
                        >
                            {renderValue(item)}
                        </div>
                    ))}
                </div>
            );
        }

        if (value && typeof value === "object") {
            return (
                <div className="space-y-1">
                    {Object.entries(value as Record<string, unknown>).map(
                        ([key, val]) => (
                            <div key={key}>
                                <div className="text-xs leading-snug text-gray-500">
                                    {key}
                                </div>
                                {renderValue(val)}
                            </div>
                        ),
                    )}
                </div>
            );
        }

        const stringValue = String(value);
        const isHtmlList = previewOnly
            ? false
            : stringValue.includes("<ul>") && stringValue.includes("</ul>");
        const lines = previewOnly
            ? stringValue.split("\n")
            : formatBulletPoints(stringValue);

        if (isHtmlList) {
            return (
                <div className="space-y-1 text-sm leading-snug ">
                    <div
                        dangerouslySetInnerHTML={{ __html: stringValue }}
                        className="[&_ul]:list-disc [&_ul]:pl-5"
                    />
                </div>
            );
        }

        if (lines.length > 1) {
            return (
                <div className="space-y-1 text-sm leading-snug">
                    {lines.map((line, index) => (
                        <div key={index}>{line}</div>
                    ))}
                </div>
            );
        }

        return <div className="text-sm leading-snug">{stringValue}</div>;
    };

    const renderPreview = (data: Record<string, unknown> | string) => {
        if (typeof data === "string" && !previewOnly) {
            return <div className="text-sm leading-snug">{data}</div>;
        }

        const previewLines = previewOnly
            ? typeof data === "string"
                ? data.split("\n")
                : []
            : [];

        if (previewOnly) {
            return (
                <div className="space-y-2 text-sm leading-snug">
                    {previewLines.map((line, index) => (
                        <div key={index}>{line}</div>
                    ))}
                </div>
            );
        }

        const orderedKeys = [
            "profile",
            "summary",
            "experience",
            "education",
            "skills",
            "accomplishments",
        ];

        // Get all keys that aren't in our ordered list
        const remainingKeys = Object.keys(data).filter(
            key => !orderedKeys.includes(key),
        );

        // Show all ordered keys (even if not in data) plus remaining keys that exist
        const allKeys = [...orderedKeys, ...remainingKeys];

        return (
            <div className="space-y-6">
                {allKeys.map(key => (
                    <div
                        key={key}
                        className="space-y-1 rounded-md border border-gray-300 p-2"
                    >
                        <h3 className="text-sm font-medium capitalize text-gray-500">
                            {key.replace(/([A-Z])/g, " $1").trim()}
                        </h3>
                        <div>
                            {key in data ? (
                                renderValue(data[key])
                            ) : (
                                <div className="text-sm italic text-gray-400">
                                    No value found
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const formatValue = (value: unknown, indent = ""): string => {
        if (Array.isArray(value)) {
            return value
                .map((item, index) => {
                    const formattedItem = formatValue(item, indent + "  ");
                    return `${indent}• ${formattedItem.trim()}`;
                })
                .join("\n");
        }

        if (value && typeof value === "object") {
            return Object.entries(value as Record<string, unknown>)
                .map(([k, v]) => {
                    const formattedValue = formatValue(v, indent + "  ");
                    return `${indent}${k}:\n${formattedValue}`;
                })
                .join("\n");
        }

        return `${indent}${String(value)}`;
    };

    const getFormattedText = (
        data: Record<string, unknown> | string,
    ): string => {
        if (typeof data === "string") {
            return data;
        }

        const orderedKeys = [
            "profile",
            "summary",
            "experience",
            "education",
            "skills",
            "accomplishments",
        ];

        const remainingKeys = Object.keys(data).filter(
            key => !orderedKeys.includes(key),
        );
        const allKeys = [...orderedKeys, ...remainingKeys];

        return allKeys
            .map(key => {
                if (!(key in data)) return "";

                const value = data[key];
                const formattedKey = key.replace(/([A-Z])/g, " $1").trim();
                const formattedValue = formatValue(value);

                return `${formattedKey.toUpperCase()}\n${formattedValue}\n`;
            })
            .filter(Boolean)
            .join("\n");
    };

    const getHtmlContent = (data: Record<string, unknown> | string): string => {
        const css = `
            body { 
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
                max-width: 800px;
                margin: 40px auto;
                padding: 0 20px;
                line-height: 1.5;
                color: #333;
            }
            .metadata {
                margin-bottom: 32px;
                padding: 12px 16px;
                background: #f7fafc;
                border-radius: 8px;
                font-size: 0.9em;
                color: #4a5568;
            }
            .metadata-item {
                margin: 4px 0;
            }
            .section {
                margin-bottom: 24px;
                padding: 16px;
                border: 1px solid #e2e8f0;
                border-radius: 8px;
            }
            .section-title {
                margin: 0 0 12px 0;
                color: #4a5568;
                font-size: 1.2em;
                font-weight: 600;
                text-transform: capitalize;
            }
            .content {
                margin: 0;
                white-space: pre-wrap;
            }
        `;

        const formattedContent = getFormattedText(data);
        const sections = formattedContent.split("\n\n").filter(Boolean);

        const metadata = `
            <div class="metadata">
                <div class="metadata-item"><strong>Model:</strong> ${model}</div>
                <div class="metadata-item"><strong>Temperature:</strong> ${temperature}</div>
            </div>
        `;

        const htmlSections = sections
            .map(section => {
                const [title, ...content] = section.split("\n");
                return `
                    <div class="section">
                        <h2 class="section-title">${title.toLowerCase()}</h2>
                        <pre class="content">${content.join("\n")}</pre>
                    </div>
                `;
            })
            .join("");

        return `
            <!DOCTYPE html>
            <html>
                <head>
                    <title>Resume Preview</title>
                    <style>${css}</style>
                </head>
                <body>
                    ${metadata}
                    ${htmlSections}
                </body>
            </html>
        `;
    };

    const openInNewWindow = () => {
        const htmlContent = getHtmlContent(aiResponse);
        const newWindow = window.open("", "_blank");
        if (newWindow) {
            newWindow.document.write(htmlContent);
            newWindow.document.close();
        }
    };

    const handleCopyContent = async () => {
        const textContent = previewOnly
            ? String(aiResponse)
            : getFormattedText(aiResponse);
        await navigator.clipboard.writeText(textContent);
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
    };

    return (
        <div className="h-full">
            <div className="mb-4 flex justify-between">
                <div className="flex space-x-2">
                    <button
                        onClick={handleCopyContent}
                        className="rounded bg-green-600 px-3 py-1 text-xs text-white hover:bg-green-700"
                    >
                        {copySuccess ? "Copied!" : "Copy Content"}
                    </button>
                    <button
                        onClick={openInNewWindow}
                        className="rounded bg-blue-600 px-3 py-1 text-xs text-white hover:bg-blue-700"
                    >
                        Open in New Window
                    </button>
                </div>

                <div className="flex space-x-2">
                    <button
                        onClick={() => setViewMode("preview")}
                        className={`rounded px-3 py-1 text-xs ${
                            viewMode === "preview"
                                ? "bg-primary-600 text-white"
                                : "bg-gray-100 text-gray-700"
                        }`}
                    >
                        Preview
                    </button>
                    <button
                        onClick={() => setViewMode("json")}
                        className={`rounded px-3 py-1 text-xs ${
                            viewMode === "json"
                                ? "bg-primary-600 text-white"
                                : "bg-gray-100 text-gray-700"
                        }`}
                    >
                        JSON
                    </button>
                </div>
            </div>

            {viewMode === "json" ? (
                <JsonEditorComponent
                    jsonData={aiResponse}
                    onUpdate={() => {}}
                    id="resume"
                    viewOnly={true}
                    className="h-[calc(100%-2rem)]"
                    rootFontSize="12px"
                    containerClassName="h-full"
                />
            ) : (
                <div className="h-[calc(100%-2rem)] overflow-auto">
                    {renderPreview(aiResponse)}
                </div>
            )}
        </div>
    );
};
