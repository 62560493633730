import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import resumeApi from "@/api/resume";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { queryKeys } from "@/hooks/useAppData";
import { resetResumeBuilderState } from "@/routes/utils/resetResumeBuilderState";
import {
    getActiveResume,
    getHasUnsavedChanges,
} from "@/store/resume/selectors";
import { setHasUnsavedChanges } from "@/store/resume/slice";
import { CLARITY_EVENT_NAMES } from "@/types/tracking";
import { trackClarityEvent, trackPostHogEvent } from "@/utils/tracking";

/**
 * Wrapper component to save the resume when the user navigates away from the resume builder page
 */
export const ResumeSaveWrapper = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const location = useLocation();
    const activeResume = useAppSelector(getActiveResume);
    const hasUnsavedChanges = useAppSelector(getHasUnsavedChanges);
    const dispatch = useAppDispatch();
    const [isSaving, setIsSaving] = useState(false);
    const queryClient = useQueryClient();

    const saveMutation = useMutation({
        mutationFn: async () => {
            if (!activeResume) return;
            return resumeApi.saveResume(activeResume.id, activeResume);
        },
        onSuccess: () => {
            dispatch(setHasUnsavedChanges(false));
            // Invalidate the resumes query to trigger a refresh
            queryClient.invalidateQueries({ queryKey: queryKeys.resumes });
            resetResumeBuilderState(dispatch);
        },
        onError: () => {
            trackClarityEvent(CLARITY_EVENT_NAMES.ERROR_RESUME_SAVE_WRAPPER);
            trackPostHogEvent(CLARITY_EVENT_NAMES.ERROR_RESUME_SAVE_WRAPPER);
        },
    });

    useEffect(() => {
        const saveResume = async () => {
            // If there is no active resume, or there are no unsaved changes, or the resume is already being saved, return
            if (!activeResume || !hasUnsavedChanges || isSaving) return;

            // Only save the resume if the user is leaving the resume page
            const isLeavingResumePage = !location.pathname.includes("/resume/");
            if (!isLeavingResumePage) return;

            setIsSaving(true);
            try {
                await saveMutation.mutateAsync();
            } finally {
                setIsSaving(false);
            }
        };

        saveResume();
    }, [
        location,
        activeResume,
        hasUnsavedChanges,
        isSaving,
        saveMutation,
        dispatch,
    ]);

    return <>{children}</>;
};
