import { ApiPaginationResponse, GenericObject, Resume, ResumeSections, User } from "@/types";
import { apiRequest } from "./request";
import { UploadImageResponse } from "@/hooks/types";
import { ColumnLayout, DocumentVisibility, ResumeStyles } from "@/types/resume";

const downloadResumeApi = (data: { id: string; }) =>
    apiRequest(
        "/resume/download",
        "POST",
        data,
        { "Content-Type": "application/json" },
        "arraybuffer",
    );

const getResume = (id: string) =>
    apiRequest<Resume>(`/resume/${id}`, "GET");

const deleteResume = (id: string) => apiRequest(`/resume/${id}`, "DELETE");

const getAllResume = (onlyTemplates: boolean) => apiRequest<ApiPaginationResponse<Resume[]>>(`/resume/`, 'GET', { onlyTemplates: !!onlyTemplates });

const getExamples = () => apiRequest<ApiPaginationResponse<Resume[]>>("/resume/examples", "GET");

const saveResume = (id: string, data: Partial<Resume>) =>
    apiRequest<Resume>(
        id ? `/resume/${id}` : "/resume",
        id ? "PATCH" : "POST",
        data,
    );

const createResume = (data: { sections: ResumeSections; name: string; styles: ResumeStyles; template: string; columnLayout: ColumnLayout; documentType: string; isTemplate: boolean; visibility: DocumentVisibility; exampleId?: string; }) =>
    apiRequest<Resume>(`/resume`, "POST", data);

const uploadResumeImage = (id: string, data: File) =>
    apiRequest<UploadImageResponse>(
        `/resume/${id}/upload-image`,
        "POST",
        { image: data },
        {
            "Content-Type": "multipart/form-data",
        },
    );

const deleteResumeImage = (id: string, image: string) => apiRequest(`/resume/${id}/delete-image`, "DELETE", { image });

const updateResumeSection = (resumeId: string, sectionId: string, data: GenericObject) =>
    apiRequest<Resume>(`/resume/${resumeId}/section/${sectionId}`, "POST", data);

const getSignedUrl = (id: string, src: string) =>
    apiRequest<{ signedUrl: string; }>(
        `/resume/${id}/get-signed-url`,
        "POST",
        { src },
    );

const importResume = (data: {
    resumeText: string;
    jobDescription: string;
    templateId: string;
    jobTitle: string;
}) =>
    apiRequest<{ resumeId: string; userUpdates: Partial<User>; }>(
        "/resume/import",
        "POST",
        data,
    );

const uploadResume = (data?: FormData, resumeText?: string) =>
    apiRequest<{ validatedText: string; showWarning?: boolean; }>(
        "/resume/upload",
        "POST",
        resumeText ? { resumeText } : data,
        resumeText ? { "Content-Type": "application/json" } : {
            "Content-Type": "multipart/form-data",
        },
    );

export default {
    downloadResumeApi,
    saveResume,
    getResume,
    getAllResume,
    deleteResume,
    uploadResumeImage,
    createResume,
    updateResumeSection,
    deleteResumeImage,
    getSignedUrl,
    getExamples,
    importResume,
    uploadResume,
};
