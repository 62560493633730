import { motion, AnimatePresence } from "framer-motion";
import { useState, useEffect } from "react";
import { AppText } from "@/components/Text/AppText";

type Step = {
    label: string;
};

export const LoadingSteps = ({
    currentStepIndex = 0,
    autoProgress = false,
    stepDuration = 4000,
    steps,
    loop = true,
    icon,
}: {
    currentStepIndex?: number;
    autoProgress?: boolean;
    stepDuration?: number;
    steps: Step[];
    loop?: boolean;
    icon?: React.ReactNode;
}) => {
    const [activeIndex, setActiveIndex] = useState(currentStepIndex);

    useEffect(() => {
        if (!autoProgress) return;

        const interval = setInterval(() => {
            setActiveIndex(prev => {
                // If we're at the last step and not looping, stay there
                if (prev === steps.length - 1 && !loop) {
                    return prev;
                }
                // Otherwise, move to the next step or loop back to the beginning
                return (prev + 1) % steps.length;
            });
        }, stepDuration);

        return () => clearInterval(interval);
    }, [autoProgress, steps.length, stepDuration, loop]);

    useEffect(() => {
        setActiveIndex(currentStepIndex);
    }, [currentStepIndex]);

    return (
        <div className="flex flex-col items-center justify-center">
            <div className="relative flex h-20 w-20 items-center justify-center">
                <div className="absolute flex h-16 w-16 items-center justify-center overflow-hidden rounded-full bg-gradient-primary text-white shadow-lg">
                    {icon}
                </div>
            </div>

            <AnimatePresence mode="wait">
                <motion.div
                    key={activeIndex}
                    initial={{ opacity: 0, y: 5 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -5 }}
                    transition={{ duration: 0.3 }}
                    className="mt-2 text-center"
                >
                    <AppText
                        variant="regular"
                        className="!font-medium text-neutral-700"
                    >
                        {steps[activeIndex]?.label}
                    </AppText>
                </motion.div>
            </AnimatePresence>

            <div className="mt-3 flex space-x-1">
                {steps.map((_, index) => (
                    <motion.div
                        key={index}
                        className="h-1.5 w-1.5 rounded-full"
                        animate={{
                            backgroundColor:
                                index === activeIndex ? "#3B82F6" : "#CBD5E1",
                            scale: index === activeIndex ? [1, 1.2, 1] : 1,
                        }}
                        transition={{
                            duration: index === activeIndex ? 1.5 : 0.3,
                            repeat: index === activeIndex ? Infinity : 0,
                            ease: "easeInOut",
                        }}
                    />
                ))}
            </div>
        </div>
    );
};
