import { View } from "@react-pdf/renderer";
import parse, { domToReact, HTMLReactParserOptions } from "html-react-parser";
import { FieldIcon } from "@/components/FieldIcon/FieldIcon";
import { PdfImage } from "@/components/PdfDocument/PdfImage";
import { PdfListRenderer } from "@/components/PdfDocument/PdfListRenderer";
import { PdfTextRenderer } from "@/components/PdfDocument/PdfTextRenderer";
import { TextAlignType } from "@/components/PdfDocument/types/pdfTypes";
import { parseStyles } from "@/components/PdfDocument/utils/parseStyles";
import { ResumeStyles } from "@/types/resume";
import { generateUniqueId } from "@/utils/string";

const renderElement = ({
    node,
    key,
    documentStyles,
    isStaticHeader,
}: {
    node: Element;
    key: string | number;
    documentStyles: ResumeStyles;
    isStaticHeader?: boolean;
}) => {
    if (node.type === "tag") {
        const textAlign: TextAlignType | undefined = node.attribs?.style?.match(
            /text-align:\s*([^;]*)/,
        )?.[1];

        switch (node.name) {
            case "ul":
                return (
                    <PdfListRenderer
                        node={node}
                        nodeKey={key}
                        documentStyles={documentStyles}
                        textAlign={textAlign}
                        variant="ul"
                    />
                );
            case "ol":
                return (
                    <PdfListRenderer
                        node={node}
                        nodeKey={key}
                        documentStyles={documentStyles}
                        textAlign={textAlign}
                        variant="ol"
                    />
                );
            case "h1":
                return (
                    <PdfTextRenderer
                        isStaticHeader={isStaticHeader}
                        textAlign={textAlign}
                        variant="h1"
                        documentStyles={documentStyles}
                        nodeKey={key}
                        node={node}
                    />
                );
            case "h2":
                return (
                    <PdfTextRenderer
                        isStaticHeader={isStaticHeader}
                        nodeKey={key}
                        textAlign={textAlign}
                        variant="h2"
                        documentStyles={documentStyles}
                        node={node}
                    />
                );
            case "h3":
                return (
                    <PdfTextRenderer
                        isStaticHeader={isStaticHeader}
                        nodeKey={key}
                        textAlign={textAlign}
                        variant="h3"
                        documentStyles={documentStyles}
                        node={node}
                    />
                );
            case "h4":
                return (
                    <PdfTextRenderer
                        isStaticHeader={isStaticHeader}
                        nodeKey={key}
                        textAlign={textAlign}
                        variant="h4"
                        documentStyles={documentStyles}
                        node={node}
                    />
                );
            case "h5":
                return (
                    <PdfTextRenderer
                        isStaticHeader={isStaticHeader}
                        nodeKey={key}
                        textAlign={textAlign}
                        variant="h5"
                        documentStyles={documentStyles}
                        node={node}
                    />
                );
            case "h6":
                return (
                    <PdfTextRenderer
                        isStaticHeader={isStaticHeader}
                        nodeKey={key}
                        textAlign={textAlign}
                        variant="h6"
                        documentStyles={documentStyles}
                        node={node}
                    />
                );
            case "p":
            case "span":
                return (
                    <PdfTextRenderer
                        isStaticHeader={isStaticHeader}
                        textAlign={textAlign}
                        variant="p"
                        documentStyles={documentStyles}
                        nodeKey={key}
                        node={node}
                    />
                );
            case "div": {
                const style = parseStyles(node.attribs.style ?? "") ?? {};
                const hasIcon = !!node.attribs["data-icon"];
                const id = node.attribs?.id;
                const isDetailsPosition = id === "detailsPosition";

                // Ensure icons and text are all displayed on same line in PDF
                if (hasIcon) {
                    style.display = "flex";
                    style.flexDirection = "row";
                    style.justifyContent = "flex-start";
                    style.flexWrap = "wrap";
                }
                // Ensure content doesn't wrap incorrectly when set to column layout
                if (isDetailsPosition && style.flexDirection === "column") {
                    style.flexWrap = "nowrap";
                }

                return (
                    <View
                        key={key}
                        style={{
                            ...style,
                        }}
                    >
                        {node.children.map((child: any, index: number) =>
                            renderElement({
                                node: child,
                                key: `${key}-${index}`,
                                documentStyles,
                                isStaticHeader,
                            }),
                        )}
                    </View>
                );
            }
            case "img":
                return (
                    <PdfImage
                        node={node}
                        documentStyles={documentStyles}
                    />
                );
            case "i":
                return (
                    <FieldIcon
                        name={node.attribs?.id}
                        stylesConfig={documentStyles}
                        isForPdf
                        isStaticHeader={!!isStaticHeader}
                    />
                );
            default:
                domToReact(node.children, {
                    trim: true,
                    replace: domNode =>
                        renderElement({
                            node: domNode as Element,
                            key: generateUniqueId(),
                            documentStyles,
                            isStaticHeader,
                        }),
                });
        }
    }
    return node.data;
};

interface HtmlToPdfProps {
    html: string;
    documentStyles: ResumeStyles;
    isStaticHeader?: boolean;
}

export const HtmlToPdf = ({
    html,
    documentStyles,
    isStaticHeader,
}: HtmlToPdfProps) => {
    if (!html) return null;

    const options: HTMLReactParserOptions = {
        trim: true,
        replace: domNode => {
            if (domNode.type === "tag") {
                return renderElement({
                    node: domNode as Element,
                    key: generateUniqueId(),
                    documentStyles,
                    isStaticHeader,
                });
            }
        },
    };

    return parse(html, options);
};
