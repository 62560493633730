import { PlusSquare } from "lucide-react";
import { useCallback } from "react";
import { PageColumn } from "@/components/Grid/PageColumn";
import { getPageHorizontalMarginStyle } from "@/components/PdfDocument/utils/getStyles";
import { AppText } from "@/components/Text/AppText";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { openModal } from "@/store/modal/slice";
import {
    getActiveResumeGlobalStyles,
    getActiveResumeSections,
    getIsTwoColumnLayout,
} from "@/store/resume/selectors";
import { Page } from "@/types";
import { ModalTypes } from "@/types/modal";

interface PageGridProps {
    page: Page;
}

export const PageGrid = ({ page }: PageGridProps) => {
    const isTwoColumnLayout = useAppSelector(getIsTwoColumnLayout);
    const activeResumeStyle = useAppSelector(getActiveResumeGlobalStyles);
    const pageHorizontalMargins = getPageHorizontalMarginStyle(
        activeResumeStyle.pageMarginSize,
    );
    const activeResumeSections = useAppSelector(getActiveResumeSections);
    const dispatch = useAppDispatch();
    const showAddSection =
        activeResumeSections &&
        Object.keys(activeResumeSections).length === 1 &&
        page.pageNumber === 0;

    const onAddSection = useCallback(() => {
        dispatch(
            openModal({
                modalType: ModalTypes.ADD_NEW_SECTION,
            }),
        );
    }, [dispatch]);

    return (
        <div
            className="grid h-full grid-cols-2"
            style={{
                ...pageHorizontalMargins,
                gridTemplateColumns: !isTwoColumnLayout ? "1fr" : "1fr 40%",
            }}
        >
            {showAddSection && (
                <button
                    onClick={onAddSection}
                    className="flex h-[200px] w-full items-center justify-center gap-2 rounded-lg bg-neutral-100 text-neutral-900"
                >
                    <PlusSquare className="h-6 w-6" />
                    <AppText variant="labelsbuttons">Add Section</AppText>
                </button>
            )}
            <PageColumn
                pageNumber={page.pageNumber}
                numberOfColumns={isTwoColumnLayout ? 2 : 1}
                columnIndex={0}
                className={isTwoColumnLayout ? "col-span-1" : "col-span-1"}
            />
            {isTwoColumnLayout && (
                <PageColumn
                    pageNumber={page.pageNumber}
                    numberOfColumns={2}
                    columnIndex={1}
                    className="col-span-1"
                />
            )}
        </div>
    );
};
