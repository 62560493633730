import { string } from "yup";

const emailValidation = string().email("Invalid email").required("Email is required");

const passwordValidation = string().required("Password is required");

const firstNameValidation = string().required("First name is required");

const lastNameValidation = string().required("Last name is required");

const getNewPasswordSchemaField = (requireMessage: string = "Password is required") =>
    string()
        .required(requireMessage)
        .min(8, "Must be 8 characters or more")
        .matches(/[a-z]+/, "Password must contain an lowercase character")
        .matches(/[A-Z]+/, "Password must contain an uppercase character")
        .matches(/\d+/, "Password must contain a number");

export {
    emailValidation,
    passwordValidation,
    firstNameValidation,
    lastNameValidation,
    getNewPasswordSchemaField
};