import { JsonEditor } from "json-edit-react"; //https://carlosnz.github.io/json-edit-react/
import "./styles.css";
import { GenericObject } from "@/types";

interface JsonEditorProps {
    jsonData: GenericObject;
    onUpdate: (newData: GenericObject) => void;
    id: string;
    viewOnly?: boolean;
    className?: string;
    rootFontSize?: string;
    containerClassName?: string;
}

export const JsonEditorComponent = ({
    jsonData,
    onUpdate,
    id,
    viewOnly = false,
    className = "h-72",
    rootFontSize = "16px",
    containerClassName = "h-80 w-full",
}: JsonEditorProps) => (
    <div className={containerClassName}>
        <JsonEditor
            data={jsonData}
            onUpdate={newData => onUpdate(newData.newData)}
            className={className}
            defaultValue=""
            rootName={id}
            restrictAdd={viewOnly}
            restrictDelete={viewOnly}
            restrictEdit={viewOnly}
            restrictDrag={viewOnly}
            restrictTypeSelection={viewOnly}
            rootFontSize={rootFontSize}
        />
    </div>
);
