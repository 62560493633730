import { ReactNode, Component, ErrorInfo } from "react";
import { ErrorPage } from "@/pages/Error/ErrorPage";
import { ErrorTypes } from "@/types";
import { GA_EVENT_CATEGORIES, GA_EVENT_ACTIONS } from "@/types/tracking";
import {
    trackClarityError,
    trackGAEvent,
    trackPostHogEvent,
} from "@/utils/tracking";

function logErrorNicely(error: unknown, errorInfo: unknown): void {
    console.error(
        "%c[ERROR]",
        "display: inline-block; background-color: #E30B5C; color: #ffffff; font-weight: bold; padding: 3px 7px 3px 7px; border-radius: 3px 3px 3px 3px;",
        error,
        errorInfo,
    );
}

interface Props {
    children: ReactNode;
}

export default class ErrorBoundary extends Component<Props> {
    state: { hasError: boolean; errorMessage: unknown; errorInfo: unknown };

    constructor(props: Props) {
        super(props);
        this.state = { hasError: false, errorMessage: "", errorInfo: "" };
    }

    static getDerivedStateFromError(error: unknown) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can also log the error to an error reporting service
        const errorMessage = this.formatErrorMessage(error, errorInfo);
        logErrorNicely(error, errorInfo);
        this.setState({ errorMessage: error, errorInfo });
        trackGAEvent(
            GA_EVENT_CATEGORIES.ERROR,
            GA_EVENT_ACTIONS.ERROR,
            errorMessage,
        );
        trackClarityError("ErrorBoundary");
        trackPostHogEvent("ErrorBoundary", {
            errorMessage,
            errorInfo,
            rawMessage: error.message,
            stack: error.stack,
        });
    }

    formatErrorMessage(error: Error, errorInfo: ErrorInfo): string {
        const componentStack = errorInfo.componentStack
            ?.split("\n")
            .filter(line => line.trim() !== "")
            .map(line => line.trim())
            .join(" -> ");

        return `${error.name}: ${error.message}\nComponent Stack: ${componentStack}`;
    }

    render() {
        if (this.state.hasError) {
            // You may render any custom fallback UI
            return (
                <ErrorPage
                    error={ErrorTypes.internalServerError}
                    additionalInfo={{
                        message: this.state.errorMessage,
                        info: this.state.errorInfo,
                    }}
                />
            );
        }

        return this.props.children;
    }
}
