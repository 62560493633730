import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";

export const getAppState = (state: RootState) => state.app;

export const getNotifications = createSelector(
    getAppState,
    app => app.notifications,
);

export const getSideBarState = createSelector(
    getAppState,
    app => app.sideBar,
);

export const getShowSideBar = createSelector(
    getAppState,
    app => app.showSideBar,
);

export const getSaveTemplateState = createSelector(
    getAppState,
    app => app.saveTemplateState,
);

export const getTemplateLoadingState = createSelector(
    getAppState,
    app => app.templateLoadingState,
);

export const getShowPlansModal = createSelector(
    getAppState,
    app => app.showPlansModal,
);

export const getShowRearrangeModal = createSelector(
    getAppState,
    app => app.showRearrangeModal,
);

export const getDatePickerId = createSelector(
    getAppState,
    app => app.datePickerId,
);

export const getDebugBuilder = createSelector(
    getAppState,
    app => app.debugBuilder,
);

export const getDebugPageMargins = createSelector(
    getAppState,
    app => app.debugPageMargins,
);

export const getDebugSectionSpacing = createSelector(
    getAppState,
    app => app.debugSectionSpacing,
);

export const getShowFeedbackSurvey = createSelector(
    getAppState,
    app => app.showFeedbackSurvey,
);

export const getAiToolboxState = (state: RootState) => state.app.aiToolbox;

export const getAiToolboxSelection = createSelector(
    getAiToolboxState,
    aiToolbox => aiToolbox.selection,
);

export const getAiToolboxPromptActionCode = createSelector(
    getAiToolboxState,
    aiToolbox => aiToolbox.promptActionCode,
);

export const getAiToolboxInitialPrompt = createSelector(
    getAiToolboxState,
    aiToolbox => aiToolbox.promptInput,
);

export const getAiToolboxIsVisible = createSelector(
    getAiToolboxState,
    aiToolbox => aiToolbox.isVisible,
);

export const getAiToolboxStage = createSelector(
    getAiToolboxState,
    aiToolbox => aiToolbox.stage,
);

export const getAiToolboxPromptOutput = createSelector(
    getAiToolboxState,
    aiToolbox => aiToolbox.promptOutput,
);

export const getShowBonusNavbarButton = createSelector(
    getAppState,
    app => app.showBonusNavbarButton,
);

export const getShowImportFeedback = createSelector(
    getAppState,
    app => app.showImportFeedback,
);
