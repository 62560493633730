import { getResumes } from "@/store/resume/selectors";
import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";
import { getIsAdminRole } from "@/utils/getIsRole";
import { USER_FLAGS } from "@/types/user";
import { formatUserName } from "@/utils/string";
import { CreditActionCode } from "@/types/creditAction";

export const getUserState = (state: RootState) => state.user;

export const getUserDetails = createSelector(
    getUserState,
    user => user.userDetails,
);

export const getUserId = createSelector(getUserDetails, user => user?.id);

export const getUserRole = createSelector(getUserDetails, user => user?.role);
export const getIsAdminUser = createSelector(getUserRole, role => getIsAdminRole(role));

export const getAccessToken = createSelector(
    getUserState,
    user => user.accessToken,
);

export const getRefreshToken = createSelector(
    getUserState,
    user => user.refreshToken,
);

export const getUserFullName = createSelector(
    getUserDetails,
    user => formatUserName(user),
);

export const getUserInitials = createSelector(
    getUserDetails,
    user => {
        if (!user) return "";
        const firstInitial = user.firstName ? user.firstName[0] : "";
        const lastInitial = user.lastName ? user.lastName[0] : "";
        return `${firstInitial}${lastInitial}`.toUpperCase();
    },
);

export const getStripeCustomerId = createSelector(
    getUserDetails,
    user => user?.stripeCustomerId,
);

export const getUserMarketingOptIn = createSelector(
    getUserDetails,
    user => user?.marketingOptIns,
);

export const getUserCreditBalance = createSelector(
    getUserDetails,
    user => user?.credits?.balance ?? 0,
);

export const getUserDocumentLimit = createSelector(
    getUserDetails,
    user => user?.documentLimit,
);

export const getUserReachedResumeLimit = createSelector(
    getUserDocumentLimit,
    getResumes,
    (documentLimit, resumes) => {
        return resumes.length >= (documentLimit ?? 2);
    }
);

export const getUserPreferences = createSelector(
    getUserDetails,
    user => user?.preferences,
);

export const getUserFlags = createSelector(
    getUserDetails,
    user => user?.flags,
);

export const getUserHasCompletedOnboarding = createSelector(
    getUserFlags,
    flags => flags?.[USER_FLAGS.HAS_SEEN_RESUME_BUILDER_TOUR],
);

export const getUserCanRefer = createSelector(
    getUserDetails,
    user => user && user?.referrals?.length <= 5,
);

export const getUserReferrals = createSelector(
    getUserDetails,
    user => user?.referrals,
);

export const getUserFeatures = createSelector(
    getUserState,
    user => user?.features,
);

export const getUserFeatureByActionCode = createSelector(
    [getUserFeatures, (_: RootState, actionCode: CreditActionCode) => actionCode],
    (features, actionCode) => {
        if (!features) return null;
        return features[actionCode];
    }
);
