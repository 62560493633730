import clsx from "clsx";
import { EditIcon, SettingsIcon, XIcon } from "lucide-react";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import Chip from "@/components/Chip/Chip";
import { AppText } from "@/components/Text/AppText";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { openModal } from "@/store/modal/slice";
import { getIsAdminUser } from "@/store/user/selectors";
import { Resume } from "@/types";
import { ModalTypes } from "@/types/modal";
import { DocumentVisibility } from "@/types/resume";

interface AdminResumeDetailsProps {
    resume: Resume;
}

export const AdminResumeDetails = ({ resume }: AdminResumeDetailsProps) => {
    const isAdmin = useAppSelector(getIsAdminUser);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(true);

    const handleEditTemplateSettings = useCallback(() => {
        dispatch(
            openModal({
                modalType: ModalTypes.RESUME_ADMIN,
                props: { resume },
            }),
        );
    }, [dispatch, resume]);

    const goToResume = useCallback(() => {
        navigate(`/resume/${resume.id}`);
    }, [navigate, resume]);

    const handleClose = useCallback(() => {
        setIsVisible(false);
    }, []);

    if (!resume.isTemplate || !isAdmin || !isVisible) return null;

    return (
        <div className="absolute bottom-32 left-2 z-[1] flex flex-col items-end gap-2">
            <div className="flex flex-col gap-2 rounded-lg bg-white p-3 shadow-md ring-2 ring-primary-900">
                <div className="flex items-center justify-between gap-2">
                    <EditIcon
                        size={20}
                        onClick={goToResume}
                        className="cursor-pointer rounded-sm hover:text-primary-900"
                    />
                    <SettingsIcon
                        size={20}
                        onClick={handleEditTemplateSettings}
                        className="cursor-pointer rounded-sm hover:text-primary-900"
                    />
                    <XIcon
                        size={20}
                        onClick={handleClose}
                        className="cursor-pointer rounded-sm hover:text-red-500"
                    />
                </div>
                <div className="flex flex-col items-start gap-2">
                    <Chip
                        type="primary"
                        className={clsx(
                            "font-semibold",
                            resume.visibility === DocumentVisibility.PUBLIC
                                ? "!bg-primary-500"
                                : resume.visibility ===
                                    DocumentVisibility.PRIVATE
                                  ? "!bg-red-500"
                                  : "!bg-neutral-500",
                        )}
                    >
                        <AppText
                            variant="footnote"
                            className="!font-medium"
                        >
                            {resume.visibility.toUpperCase()}
                        </AppText>
                    </Chip>
                    <Chip type="secondary">
                        <AppText
                            variant="footnote"
                            className="!font-medium text-neutral-50"
                        >
                            COUNT: {resume.templateUsageCount ?? 0}
                        </AppText>
                    </Chip>
                </div>
            </div>
        </div>
    );
};
