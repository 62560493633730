import clsx from "clsx";
import { useCallback } from "react";
import { Button, ButtonSize } from "@/components/Button/Button";
import { AppText } from "@/components/Text/AppText";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useTransaction } from "@/hooks/useTransaction";
import { openModal } from "@/store/modal/slice";
import { getUserCreditBalance } from "@/store/user/selectors";
import { CreditActionCode, ICreditAction } from "@/types/creditAction";
import { ModalTypes } from "@/types/modal";
import { formatCreditAmount, pluralise } from "@/utils/string";

interface CreditSpendIndicatorProps {
    creditAction?: ICreditAction;
    creditActionCode?: CreditActionCode;
    children?: React.ReactNode;
    className?: string;
    buttonSize?: ButtonSize;
    buttonClassName?: string;
    textClassName?: string;
}

export const CreditSpendIndicator = ({
    creditAction,
    creditActionCode,
    children,
    className,
    buttonSize,
    buttonClassName,
    textClassName,
}: CreditSpendIndicatorProps) => {
    const userBalance = useAppSelector(getUserCreditBalance);
    const { getCreditActionByCode, getCanAffordCreditAction } =
        useTransaction();
    const dispatch = useAppDispatch();
    const activeAction =
        creditAction ?? getCreditActionByCode(creditActionCode ?? "");
    const canAffordAction = getCanAffordCreditAction(activeAction?.code ?? "");
    const onTopUp = useCallback(() => {
        dispatch(
            openModal({
                modalType: ModalTypes.INSUFFICIENT_FUNDS,
                props: {
                    isTopUp: true,
                    actionCode: activeAction?.code ?? "",
                },
            }),
        );
    }, [dispatch, activeAction?.code]);

    const creditsNeeded = Math.abs(activeAction?.credits ?? 0) - userBalance;

    return (
        <div className={clsx("flex items-center gap-3", className)}>
            {canAffordAction ? (
                <AppText
                    variant="labelsbuttons"
                    applyGradient
                    className={textClassName}
                >
                    Use {Math.abs(creditAction?.credits ?? 0)} of{" "}
                    {formatCreditAmount(userBalance)}
                </AppText>
            ) : (
                <AppText
                    variant="labelsbuttons"
                    applyGradient
                    className={textClassName}
                >
                    {creditsNeeded} more{" "}
                    {pluralise(creditsNeeded, "credit", "credits")} needed
                </AppText>
            )}
            {canAffordAction ? (
                children
            ) : (
                <Button
                    onClick={onTopUp}
                    size={buttonSize}
                    className={buttonClassName}
                >
                    Top Up
                </Button>
            )}
        </div>
    );
};
