import { ArrowRight, FileText, ImportIcon } from "lucide-react";
import { Button } from "@/components/Button/Button";
import { ResumeOptionCard } from "@/components/Cards/ResumeOptionCard";
import { AppText } from "@/components/Text/AppText";

interface ImportChoiceProps {
    onOptionSelect: (importResume: boolean) => void;
    onBlankResumeSelect?: () => void;
}

export const ImportChoice = ({
    onOptionSelect,
    onBlankResumeSelect,
}: ImportChoiceProps) => {
    return (
        <div className="flex flex-col gap-8">
            <AppText
                variant="regular"
                className="text-neutral-600"
            >
                Get started in seconds—import your resume or choose a
                professional template.
            </AppText>
            <div className="flex flex-1 flex-col justify-center gap-4 sm:items-center">
                <div className="flex w-full max-w-[97%] flex-col items-center justify-center gap-4 lg:grid lg:grid-cols-2 lg:gap-6">
                    <ResumeOptionCard
                        icon={ImportIcon}
                        title="Import Resume"
                        features={[
                            {
                                text: (
                                    <>
                                        Transform your resume with{" "}
                                        <AppText
                                            variant="regular"
                                            applyGradient
                                            className="inline whitespace-nowrap !font-semibold"
                                        >
                                            Smart Scribe
                                        </AppText>
                                    </>
                                ),
                            },
                            {
                                text: (
                                    <>
                                        Tailored to your roles with{" "}
                                        <AppText
                                            variant="regular"
                                            applyGradient
                                            className="inline whitespace-nowrap !font-semibold"
                                        >
                                            Smart Match
                                        </AppText>
                                    </>
                                ),
                            },
                            {
                                text: "Get a polished, ATS-friendly resume in minutes",
                            },
                        ]}
                        ctaText="Import & Enhance"
                        onClick={() => onOptionSelect(true)}
                        iconBgColor="bg-primary-600"
                        gradientFrom="from-primary-50"
                        className="border-primary-100 bg-primary-50/10 hover:border-primary-200 hover:bg-primary-50/20"
                    />

                    <ResumeOptionCard
                        icon={FileText}
                        title="Use a Template"
                        features={[
                            {
                                text: "Start with proven, recruiter-approved designs",
                            },
                            {
                                text: (
                                    <>
                                        Create impactful content with{" "}
                                        <AppText
                                            variant="regular"
                                            applyGradient
                                            className="inline whitespace-nowrap !font-semibold"
                                        >
                                            Smart Scribe
                                        </AppText>
                                    </>
                                ),
                            },
                            {
                                text: "Dynamic layouts for standout resumes",
                            },
                        ]}
                        ctaText="Choose Template"
                        onClick={() => onOptionSelect(false)}
                        iconBgColor="bg-primary-600"
                        gradientFrom="from-primary-50"
                        className="border-primary-100 bg-primary-50/10 hover:border-primary-200 hover:bg-primary-50/20"
                    />
                </div>

                <div className="my-4 text-center">
                    <Button
                        variant="text"
                        color="grey"
                        size="small"
                        onClick={onBlankResumeSelect}
                        rightIcon={<ArrowRight size={16} />}
                    >
                        Start with a blank resume instead
                    </Button>
                </div>
            </div>
        </div>
    );
};
