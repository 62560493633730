import { FileText, Trash2 } from "lucide-react";
import { useRef, useCallback, useState, useEffect } from "react";
import { Alert } from "@/components/Alert/Alert";
import { Button } from "@/components/Button/Button";
import { ALLOWED_RESUME_FILE_TYPES } from "@/components/Modals/NewResumeModal/constants";
import { AppText } from "@/components/Text/AppText";
import { Input } from "@/components/form/Input";
import { TextArea } from "@/components/form/Textarea";

interface ImportDetailsProps {
    inputType: "file" | "text";
    setInputType: (type: "file" | "text") => void;
    selectedFile: File | null;
    resumeText: string;
    jobTitle: string;
    onFileChange: (event?: React.ChangeEvent<HTMLInputElement>) => void;
    onTextChange: (text: string) => void;
    onJobTitleChange: (title: string) => void;
    resumeTextError: string;
    fileError: string;
    showResumeInputWarning: boolean;
    onWarningBypass: () => void;
    canBypassWarning: boolean;
}

export const ImportDetails = ({
    inputType,
    setInputType,
    selectedFile,
    resumeText,
    jobTitle,
    onFileChange,
    onTextChange,
    onJobTitleChange,
    resumeTextError,
    fileError,
    showResumeInputWarning,
    onWarningBypass,
    canBypassWarning,
}: ImportDetailsProps) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const dropzoneRef = useRef<HTMLDivElement>(null);
    const [isDragging, setIsDragging] = useState(false);

    // Track drag counter to handle nested elements
    const dragCounter = useRef(0);

    const handleButtonClick = () => {
        fileInputRef.current?.click();
    };

    // Handle click on the dropzone
    const handleDropzoneClick = () => {
        if (!selectedFile) {
            fileInputRef.current?.click();
        }
    };

    // Improved drag event handlers
    const handleDragIn = useCallback((e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter.current++;

        if (e.dataTransfer?.items && e.dataTransfer.items.length > 0) {
            setIsDragging(true);
        }
    }, []);

    const handleDragOut = useCallback((e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter.current--;

        if (dragCounter.current === 0) {
            setIsDragging(false);
        }
    }, []);

    const handleDragOver = useCallback((e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleDrop = useCallback(
        (e: DragEvent) => {
            e.preventDefault();
            e.stopPropagation();
            setIsDragging(false);
            dragCounter.current = 0;

            if (e.dataTransfer?.files && e.dataTransfer.files.length > 0) {
                const syntheticEvent = {
                    target: {
                        files: e.dataTransfer.files,
                    },
                } as unknown as React.ChangeEvent<HTMLInputElement>;
                onFileChange(syntheticEvent);
            }
        },
        [onFileChange],
    );

    // Set up global event listeners for drag and drop
    useEffect(() => {
        const div = dropzoneRef.current;
        if (div) {
            div.addEventListener("dragenter", handleDragIn);
            div.addEventListener("dragleave", handleDragOut);
            div.addEventListener("dragover", handleDragOver);
            div.addEventListener("drop", handleDrop);

            return () => {
                div.removeEventListener("dragenter", handleDragIn);
                div.removeEventListener("dragleave", handleDragOut);
                div.removeEventListener("dragover", handleDragOver);
                div.removeEventListener("drop", handleDrop);
            };
        }
    }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

    return (
        <div className="flex-1">
            <AppText
                variant="regular"
                className="mb-6 text-neutral-900"
            >
                Give your resume a competitive edge! Enter a job title and
                resume.{" "}
                <AppText
                    variant="labelsbuttons"
                    applyGradient
                    className="inline-block"
                >
                    Smart Scribe
                </AppText>{" "}
                will refine, structure, and enhance it to ensure clarity,
                professionalism, and readability.
            </AppText>
            <div className="flex flex-col gap-6">
                <div>
                    <AppText
                        variant="labelsbuttons"
                        className="mb-2 block text-neutral-700"
                    >
                        What is your desired job title?
                    </AppText>
                    <Input
                        type="text"
                        placeholder="Example: Software Engineer"
                        value={jobTitle}
                        onChange={(_, value) => onJobTitleChange(value)}
                        maxLength={50}
                        className="!font-normal"
                        showCounter={jobTitle?.length >= 40}
                    />
                </div>
                {fileError && (
                    <Alert
                        type="warning"
                        message={fileError}
                        className="items-center"
                    />
                )}
                {showResumeInputWarning && (
                    <Alert
                        type="warning"
                        message={
                            canBypassWarning
                                ? "We couldn’t identify your input as a resume. Please review your content and try again, or proceed with the resume you’ve provided."
                                : "We couldn’t identify your input as a resume. Please review your content and try again."
                        }
                        onAction={onWarningBypass}
                        actionLabel={canBypassWarning ? "Continue" : undefined}
                        className="!flex-row !items-center gap-4"
                    />
                )}
                <div className="flex flex-col gap-2">
                    {inputType === "file" ? (
                        <div className="relative">
                            <input
                                ref={fileInputRef}
                                type="file"
                                id="resumeUpload"
                                className="hidden"
                                accept={ALLOWED_RESUME_FILE_TYPES.join(",")}
                                onChange={onFileChange}
                            />

                            <div
                                ref={dropzoneRef}
                                onClick={handleDropzoneClick}
                                className={`flex h-[175px] cursor-pointer items-center justify-center gap-2 rounded-md border-2 border-dashed p-6 text-center transition-colors ${
                                    isDragging
                                        ? "bg-primary-50/2 border-primary-700"
                                        : "border-neutral-300 hover:border-primary-700"
                                }`}
                            >
                                {selectedFile ? (
                                    <div className="flex h-full flex-col items-center justify-center gap-3">
                                        <FileText className="h-9 w-9 text-neutral-600" />
                                        <div className="flex items-center gap-2 rounded-full bg-neutral-100 px-3 py-1.5">
                                            <AppText
                                                variant="labelsbuttons"
                                                className="max-w-[200px] truncate text-neutral-600"
                                            >
                                                {selectedFile.name}
                                            </AppText>
                                            <Trash2
                                                className="h-4 w-4 cursor-pointer text-neutral-500 transition-colors hover:text-error-600"
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    onFileChange();
                                                }}
                                            />
                                        </div>

                                        <Button
                                            variant="text"
                                            color="primary"
                                            onClick={handleButtonClick}
                                        >
                                            Change File
                                        </Button>
                                    </div>
                                ) : (
                                    <div className="flex flex-col items-center gap-2">
                                        <FileText className="h-9 w-9 text-neutral-600" />
                                        <AppText
                                            variant="labelsbuttons"
                                            className="text-neutral-600"
                                        >
                                            {isDragging
                                                ? "Drop your resume file here..."
                                                : "Drag and drop file, or click to choose"}
                                        </AppText>
                                        <AppText
                                            variant="footnote"
                                            className="text-neutral-600"
                                        >
                                            Supported formats: PDF, DOCX
                                        </AppText>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-col">
                            <AppText
                                variant="labelsbuttons"
                                className="mb-2 block text-neutral-700"
                            >
                                Resume Details
                            </AppText>
                            <TextArea
                                name="resumeText"
                                id="resumeText"
                                placeholder="Paste your resume here..."
                                value={resumeText}
                                onChange={e => onTextChange(e.target.value)}
                                className="h-[150px] bg-neutral-100 !font-normal text-neutral-600 !ring-neutral-100"
                                showCounter={resumeText?.length >= 9000}
                                maxLength={10000}
                            />
                            {resumeTextError && (
                                <AppText
                                    variant="footnote"
                                    className="mt-2 text-red-500"
                                >
                                    {resumeTextError}
                                </AppText>
                            )}
                        </div>
                    )}
                    <div className="mb-4 flex justify-center gap-4">
                        <Button
                            variant="text"
                            onClick={() =>
                                setInputType(
                                    inputType === "file" ? "text" : "file",
                                )
                            }
                        >
                            {inputType === "file"
                                ? "Or paste resume as text"
                                : "Or attach resume as a file"}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
