import clsx from "clsx";
import { AppText } from "@/components/Text/AppText";
import { Referral } from "@/types/user";
import { pluralise } from "@/utils/string";

interface ReferralLineItemProps {
    referral: Referral;
}

const LineItemDetails = ({ referral }: ReferralLineItemProps) => {
    return (
        <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
                <AppText
                    variant="contextheading"
                    className="capitalize text-neutral-900"
                >
                    {referral.referredUserName.firstName}{" "}
                    {referral.referredUserName.lastName ?? ""}
                </AppText>
            </div>
            <div className="flex items-center gap-2">
                {referral.completedDate ? (
                    <AppText
                        variant="labelsbuttons"
                        className="text-neutral-600"
                    >
                        Completed on{" "}
                        {new Date(referral.completedDate).toLocaleString(
                            "en-GB",
                            {
                                month: "long",
                                day: "numeric",
                                year: "numeric",
                            },
                        )}
                    </AppText>
                ) : (
                    <AppText
                        variant="labelsbuttons"
                        className="text-neutral-600"
                    >
                        Joined{" "}
                        {new Date(referral.signupDate).toLocaleString("en-GB", {
                            month: "long",
                            day: "numeric",
                            year: "numeric",
                        })}
                    </AppText>
                )}
            </div>
        </div>
    );
};

const LineItemStatus = ({ referral }: { referral: Referral }) => {
    const isComplete = referral.status === "successful";
    return (
        <div
            className={clsx(
                isComplete
                    ? "bg-gradient-primary text-neutral-50"
                    : "bg-primary-50 text-primary-700",
                "flex w-full items-center justify-center gap-2 rounded-lg px-3 py-1",
            )}
        >
            {isComplete ? (
                <AppText
                    variant="regular"
                    className="!font-semibold"
                >
                    Earned {Math.abs(referral.creditsEarned)}{" "}
                    {pluralise(referral.creditsEarned, "Credit", "Credits")}
                </AppText>
            ) : (
                <AppText
                    variant="regular"
                    className="!font-semibold"
                >
                    Signed Up
                </AppText>
            )}
        </div>
    );
};

export const ReferralLineItem = ({ referral }: ReferralLineItemProps) => {
    return (
        <div className="flex  flex-row items-center justify-between gap-2 rounded-xl bg-neutral-100 p-4 sm:gap-4">
            <LineItemDetails referral={referral} />
            <div className="flex w-[100px] flex-col items-center gap-1 sm:w-[200px]">
                <LineItemStatus referral={referral} />
            </div>
        </div>
    );
};
