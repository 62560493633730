import { Eye } from "lucide-react";
import { useCallback } from "react";
import { IconButton } from "@/components/Button/IconButton";
import { BuilderTourStep } from "@/components/Onboarding/builder";
import { AppText } from "@/components/Text/AppText";
import { Tooltip } from "@/components/Tooltip/Tooltip";
import { TooltipContent } from "@/components/Tooltip/TooltipContent";
import { TooltipTrigger } from "@/components/Tooltip/TooltipTrigger";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getTemplateLoadingState } from "@/store/app/selectors";
import { resetAiToolbox } from "@/store/app/slice";
import { openModal } from "@/store/modal/slice";
import { TemplateLoadingState } from "@/types/app";
import { ModalTypes } from "@/types/modal";
import { CLARITY_EVENT_NAMES } from "@/types/tracking";
import { trackClarityEvent, trackPostHogEvent } from "@/utils/tracking";

export const PreviewResumeButton = () => {
    const dispatch = useAppDispatch();
    const templateLoadingState = useAppSelector(getTemplateLoadingState);

    const onOpenPreviewResumeModal = useCallback(() => {
        dispatch(openModal({ modalType: ModalTypes.RESUME_PREVIEW }));
        trackClarityEvent(CLARITY_EVENT_NAMES.PREVIEW_RESUME_BUTTON_CLICKED);
        trackPostHogEvent(CLARITY_EVENT_NAMES.PREVIEW_RESUME_BUTTON_CLICKED);
        dispatch(resetAiToolbox());
    }, [dispatch]);

    return (
        <Tooltip
            placement="bottom"
            openDelay={200}
        >
            <TooltipTrigger>
                <IconButton
                    variant={"outline"}
                    color={"primary"}
                    onClick={onOpenPreviewResumeModal}
                    disabled={
                        templateLoadingState !== TemplateLoadingState.READY
                    }
                    className={BuilderTourStep.PREVIEW_RESUME}
                >
                    <Eye size={20} />
                </IconButton>
            </TooltipTrigger>
            <TooltipContent>
                <AppText variant="footnote">Preview</AppText>
            </TooltipContent>
        </Tooltip>
    );
};
