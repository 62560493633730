import clsx from "clsx";
import { useCallback, useEffect, useMemo } from "react";
import { Alert } from "@/components/Alert/Alert";
import { Button } from "@/components/Button/Button";
import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner";
import { AppText } from "@/components/Text/AppText";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useStripe } from "@/hooks/useStripe";
import { useTransaction } from "@/hooks/useTransaction";
import {
    getProducts,
    getProductsError,
    getProductsLoading,
} from "@/store/billing/selectors";
import { openModal } from "@/store/modal/slice";
import { StripeItem } from "@/types/billing";
import { ICreditAction } from "@/types/creditAction";
import { ModalTypes } from "@/types/modal";
import { CLARITY_EVENT_NAMES, META_EVENT_ACTIONS } from "@/types/tracking";
import { pluralise } from "@/utils/string";
import {
    trackClarityEvent,
    trackMetaEvent,
    trackPostHogEvent,
} from "@/utils/tracking";

interface CreditPackButtonsProps {
    title?: string;
    buttonPrefix?: string;
    className?: string;
    buttonsOnly?: boolean;
}

export const CreditPackButtons = ({
    title,
    buttonPrefix,
    className,
    buttonsOnly = false,
}: CreditPackButtonsProps) => {
    const allStripeItems = useAppSelector(getProducts);
    const loadingProducts = useAppSelector(getProductsLoading);
    const errorProducts = useAppSelector(getProductsError);
    const { getCreditActions } = useTransaction();
    const purchaseCreditActions = useMemo(
        () => getCreditActions({ type: "PURCHASE" }),
        [getCreditActions],
    );
    const { getStripeProducts } = useStripe();
    const dispatch = useAppDispatch();
    const stripeProducts = useMemo(
        () => allStripeItems?.filter(product => !product.isSubscription),
        [allStripeItems],
    );

    const onPackSelect = useCallback(
        (creditAction: ICreditAction, productDetails: StripeItem) => {
            trackClarityEvent(CLARITY_EVENT_NAMES.INITIATE_CHECKOUT);
            trackPostHogEvent(CLARITY_EVENT_NAMES.INITIATE_CHECKOUT, {
                feature_name: "checkout",
                product_id: productDetails.id,
                product_name: productDetails.name,
                product_price: productDetails.price,
            });
            trackMetaEvent(META_EVENT_ACTIONS.INITIATE_CHECKOUT, {
                content_category: "Pack selection",
                content_name: "Select pack",
                num_items: 1,
                content_type: "product",
                content_ids: [productDetails.id],
            });

            dispatch(
                openModal({
                    modalType: ModalTypes.BUY_CREDITS,
                    props: { productDetails, creditAction },
                }),
            );
        },
        [dispatch],
    );

    useEffect(() => {
        if (allStripeItems === null && !loadingProducts && !errorProducts)
            getStripeProducts();
    }, [getStripeProducts, allStripeItems, loadingProducts, errorProducts]);

    if (errorProducts) {
        return (
            <div className="flex h-[230px] items-center justify-center">
                <Alert
                    message="Something went wrong, please reload and try again."
                    type="error"
                />
            </div>
        );
    }

    return (
        <div className={clsx("flex w-full flex-col gap-2")}>
            {title && (
                <AppText
                    variant="labelsbuttons"
                    className="text-left text-neutral-900"
                >
                    {title}
                </AppText>
            )}
            <div
                className={clsx(
                    "no-scrollbar flex gap-3",
                    buttonsOnly
                        ? "flex-wrap justify-center"
                        : "mx-auto flex-wrap justify-center lg:flex-nowrap lg:justify-start",
                    loadingProducts ? "flex-col" : "flex-row ",
                    className,
                )}
            >
                {loadingProducts ? (
                    <div className="flex min-h-[66px] items-center justify-center">
                        <LoadingSpinner />
                    </div>
                ) : (
                    stripeProducts &&
                    stripeProducts.map(product => {
                        const productDetails = product;
                        const creditAction = purchaseCreditActions.find(
                            action =>
                                action.metadata?.stripePriceId ===
                                productDetails.priceId,
                        );

                        if (!creditAction) {
                            return null;
                        }
                        const credits = creditAction.credits ?? 0;
                        const nameWithoutPack = productDetails.name.replace(
                            "Pack",
                            "",
                        );

                        const buttonText = buttonPrefix
                            ? `${buttonPrefix} ${credits} ${pluralise(
                                  credits,
                                  "Credit",
                                  "Credits",
                              )}`
                            : `${credits} ${pluralise(
                                  credits,
                                  "Credit",
                                  "Credits",
                              )}`;

                        if (buttonsOnly) {
                            return (
                                <Button
                                    key={productDetails.id}
                                    onClick={() =>
                                        onPackSelect(
                                            creditAction,
                                            productDetails,
                                        )
                                    }
                                    size="small"
                                    className="w-40"
                                >
                                    {buttonText}
                                </Button>
                            );
                        }

                        return (
                            <div
                                key={productDetails.id}
                                className="flex min-w-32 cursor-pointer flex-col gap-[6px] rounded-lg bg-neutral-50 p-2 ring-2 ring-inset ring-primary-200 transition-colors duration-300 hover:bg-primary-200"
                                onClick={() =>
                                    onPackSelect(creditAction, productDetails)
                                }
                            >
                                <AppText
                                    variant="labelsbuttons"
                                    className=" text-neutral-900"
                                >
                                    {nameWithoutPack}
                                </AppText>
                                <AppText
                                    variant="contextheading"
                                    className="block text-neutral-900"
                                >
                                    {productDetails.price > 0 &&
                                        productDetails.currencySymbol}
                                    {productDetails.price}
                                </AppText>
                                <Button
                                    onClick={() =>
                                        onPackSelect(
                                            creditAction,
                                            productDetails,
                                        )
                                    }
                                    size="small"
                                >
                                    {buttonText}
                                </Button>
                            </div>
                        );
                    })
                )}
            </div>
        </div>
    );
};
