import clsx from "clsx";
import { ThumbsDownIcon, ThumbsUpIcon } from "lucide-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import aiApi from "@/api/ai";
import { getAiFeaturePlaceholder } from "@/components/AiToolbox/helper";
import { Alert } from "@/components/Alert/Alert";
import { AppText } from "@/components/Text/AppText";
import { useAppSelector } from "@/hooks/types";
import { useTransaction } from "@/hooks/useTransaction";
import {
    getAiToolboxPromptActionCode,
    getAiToolboxPromptOutput,
    getAiToolboxStage,
} from "@/store/app/selectors";
import { CreditActionCode } from "@/types/creditAction";
import { CLARITY_EVENT_NAMES } from "@/types/tracking";
import { trackClarityEvent, trackPostHogEvent } from "@/utils/tracking";

const textAreaClasses =
    "w-full rounded-lg border-none bg-neutral-100/50 text-sm leading-tight focus:outline-none focus:ring-0 focus:ring-primary-700 py-2 px-3";

interface AiInputOutputProps {
    maxLength?: number;
    showCounter?: boolean;
    inputValue: string;
    animateOutput?: boolean;
    onInputChange: (value: string) => void;
    className?: string;
    creditActionCode?: CreditActionCode;
    sectionType?: string;
    hasGenerateError?: boolean;
    modelUsed?: number | null;
}

export const AiInputOutput = ({
    maxLength = 1000,
    inputValue,
    animateOutput = false,
    onInputChange,
    className,
    creditActionCode,
    sectionType,
    hasGenerateError,
    modelUsed,
}: AiInputOutputProps) => {
    const [displayedOutput, setDisplayedOutput] = useState("");
    const [isAnimating, setIsAnimating] = useState(false);
    const [loadingDots, setLoadingDots] = useState("");
    const [disabledThumbs, setDisabledThumbs] = useState(false);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [selectedThumb, setSelectedThumb] = useState<"up" | "down" | null>(
        null,
    );
    const placeholder = useMemo(
        () =>
            getAiFeaturePlaceholder(
                creditActionCode,
                sectionType?.toLowerCase(),
            ),
        [creditActionCode, sectionType],
    );
    const stage = useAppSelector(getAiToolboxStage);
    const promptOutput = useAppSelector(getAiToolboxPromptOutput);
    const [localOutput, setLocalOutput] = useState(promptOutput);
    const lastAnimatedOutput = useRef<string>(promptOutput);
    const activeCreditActionCode = useAppSelector(getAiToolboxPromptActionCode);
    const initialOutput = useRef<string>(promptOutput);
    const { getCanAffordCreditAction } = useTransaction();
    const canAffordAiWritingPrompt = getCanAffordCreditAction(
        activeCreditActionCode ?? "",
    );

    useEffect(() => {
        setLocalOutput(promptOutput);
        if (!initialOutput.current) {
            initialOutput.current = promptOutput;
        }
    }, [promptOutput]);

    const adjustTextAreaHeight = () => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    };
    const isLoading = useMemo(
        () => stage === "output" && !localOutput && !hasGenerateError,
        [stage, localOutput, hasGenerateError],
    );

    useEffect(() => {
        if (isLoading) {
            setSelectedThumb(null);
            const interval = setInterval(() => {
                setLoadingDots(prev => {
                    if (prev === "...") return "";
                    return prev + ".";
                });
            }, 200);

            return () => clearInterval(interval);
        } else {
            setLoadingDots("");
        }
    }, [isLoading]);

    useEffect(() => {
        if (selectedThumb) {
            const timeout = setTimeout(() => {
                setDisabledThumbs(true);
            }, 1000);
            return () => clearTimeout(timeout);
        }
    }, [selectedThumb]);

    useEffect(() => {
        if (
            localOutput &&
            animateOutput &&
            localOutput === promptOutput &&
            lastAnimatedOutput.current !== localOutput
        ) {
            setIsAnimating(true);
            let index = 0;
            const interval = setInterval(() => {
                if (index <= localOutput.length) {
                    setDisplayedOutput(localOutput.slice(0, index));
                    index++;
                } else {
                    clearInterval(interval);
                    setIsAnimating(false);
                    lastAnimatedOutput.current = localOutput;
                }
            }, 10);

            return () => {
                clearInterval(interval);
                setIsAnimating(false);
            };
        } else if (localOutput !== promptOutput) {
            lastAnimatedOutput.current = "";
            setDisplayedOutput(localOutput);
            setIsAnimating(false);
        } else {
            setDisplayedOutput(localOutput);
            setIsAnimating(false);
        }
    }, [localOutput, animateOutput, promptOutput]);

    useEffect(() => {
        if (localOutput) {
            adjustTextAreaHeight();
            setSelectedThumb(null);
            setDisabledThumbs(false);
        }
    }, [displayedOutput, localOutput]);

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        if (value.length <= maxLength) {
            onInputChange(value);
        }
    };

    const handleOutputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setLocalOutput(e.target.value);
        adjustTextAreaHeight();
    };

    const handleThumb = useCallback(
        async (type: "up" | "down") => {
            setSelectedThumb(type);
            trackClarityEvent(
                type === "up"
                    ? CLARITY_EVENT_NAMES.AI_TOOLBOX_THUMB
                    : CLARITY_EVENT_NAMES.AI_TOOLBOX_THUMB_DOWN,
            );
            trackPostHogEvent("ai_feedback", {
                feature_name: "smart_scribe",
                action_taken: type === "up" ? "thumbs_up" : "thumbs_down",
            });
            if (inputValue) {
                await aiApi.createAiFeedback({
                    creditActionCode: activeCreditActionCode!,
                    userInput: inputValue,
                    aiResponse: initialOutput.current,
                    feedback: type === "up" ? "thumbs_up" : "thumbs_down",
                    action: "thumb",
                    userEditedResponse: localOutput,
                    modelUsed: modelUsed ?? undefined,
                });
            }
        },
        [activeCreditActionCode, inputValue, localOutput, modelUsed],
    );

    const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
        if (stage !== "input") return;
        e.preventDefault();
        // Replace multiple line breaks with single line break and trim
        const text = e.clipboardData
            .getData("text/plain")
            .replace(/[\r\n]+/g, "\n")
            .trim();
        const target = e.target as HTMLTextAreaElement;
        const start = target.selectionStart;
        const end = target.selectionEnd;

        // Prevent double spaces when pasting in the middle of text
        const beforeText = inputValue.substring(0, start).trimEnd();
        const afterText = inputValue.substring(end).trimStart();
        const spaceBefore = beforeText && " ";
        const spaceAfter = afterText && " ";

        const newValue =
            beforeText + spaceBefore + text + spaceAfter + afterText;
        const valueThatFits = newValue.slice(0, maxLength);
        onInputChange(valueThatFits);
    };

    if (hasGenerateError) {
        return (
            <div className="mx-auto w-2/3 p-2">
                <Alert
                    type="warning"
                    message={
                        <div className="flex w-full flex-col items-center justify-center">
                            <AppText
                                variant="labelsbuttons"
                                className="text-center text-alert-800"
                            >
                                Something went wrong! Please try again later.
                            </AppText>
                            <AppText
                                variant="labelsbuttons"
                                className="text-alert-800"
                            >
                                You were not charged any Credits
                            </AppText>
                        </div>
                    }
                />
            </div>
        );
    }

    return (
        <div className="relative">
            {stage === "output" ? (
                <textarea
                    ref={textareaRef}
                    value={
                        isLoading
                            ? loadingDots
                            : isAnimating
                              ? displayedOutput
                              : localOutput
                    }
                    onChange={handleOutputChange}
                    disabled={isAnimating || isLoading}
                    className={clsx(
                        textAreaClasses,
                        "min-h-[85px] resize-none overflow-hidden",
                        isLoading &&
                            "cursor-wait bg-neutral-100 text-neutral-600",
                        !isLoading && "bg-neutral-100 ring-primary-200",
                        (isAnimating || isLoading) && "cursor-wait",
                        className,
                    )}
                    onPaste={handlePaste}
                />
            ) : (
                <textarea
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    disabled={isLoading || !canAffordAiWritingPrompt}
                    className={clsx(
                        textAreaClasses,
                        "min-h-[85px] disabled:opacity-20",
                        isLoading && "cursor-wait",
                        className,
                    )}
                    onPaste={handlePaste}
                />
            )}
            {localOutput && (
                <div className="flex items-center justify-between gap-2 px-3 pb-1 text-neutral-500">
                    <AppText
                        variant="labelsbuttons"
                        className="text-primary-700"
                    >
                        Generated with AI
                    </AppText>
                    <div className="flex items-center gap-3">
                        <ThumbsUpIcon
                            size={16}
                            className={clsx(
                                "text-neutral-900 transition-all duration-300 hover:rotate-12 hover:scale-110 hover:cursor-pointer active:rotate-[-12deg] active:scale-95",
                                disabledThumbs &&
                                    "pointer-events-none cursor-not-allowed opacity-30",
                                selectedThumb === "down" && "!opacity-10",
                            )}
                            onClick={() => {
                                if (!disabledThumbs) {
                                    handleThumb("up");
                                }
                            }}
                        />
                        <ThumbsDownIcon
                            size={16}
                            className={clsx(
                                "text-neutral-900 transition-all duration-300 hover:rotate-[-12deg] hover:scale-110 hover:cursor-pointer active:rotate-12 active:scale-95",
                                disabledThumbs &&
                                    "pointer-events-none cursor-not-allowed opacity-30",
                                selectedThumb === "up" && "!opacity-10",
                            )}
                            onClick={() => {
                                if (!disabledThumbs) {
                                    handleThumb("down");
                                }
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
