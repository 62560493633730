import { Section } from "@react-email/components";
import { EmailButton } from "@/email/components/EmailButton";
import { EmailHeading } from "@/email/components/EmailHeading";
import { EmailText } from "@/email/components/EmailText";
import { EmailWrapper } from "@/email/components/EmailWrapper";

const ReferralSuccessEmail = ({
    inviteLink = "https://app.1template.io/profile?tab=invite",
    referredUserName = "Referred User",
    creditsEarned = 10,
    name = "User",
    referralLimit = 5,
}: {
    inviteLink: string;
    referredUserName: string;
    creditsEarned: number;
    name: string;
    referralLimit: number;
}) => {
    return (
        <EmailWrapper
            preview={`You've earned ${creditsEarned} credits for referring ${referredUserName} to 1Template`}
        >
            <Section>
                <EmailHeading>
                    You&apos;ve Earned {creditsEarned} Credits!
                </EmailHeading>
                <Section>
                    <EmailText>Hi {name},</EmailText>
                    <EmailText>
                        {`Great news! You've just earned ${creditsEarned} credits for referring ${referredUserName} to 1Template.
                        Your credits are now available in your account.`}
                    </EmailText>
                    <Section
                        style={{
                            marginTop: "16px",
                            padding: "16px",
                            backgroundColor: "#f8f9fa",
                            borderRadius: "8px",
                            border: "1px solid #e9ecef",
                        }}
                    >
                        <EmailText
                            additionalStyle={{
                                fontWeight: "bold",
                                fontSize: "18px",
                                color: "#303330",
                            }}
                        >
                            Want to earn even more?
                        </EmailText>
                        <EmailText
                            additionalStyle={{
                                color: "#303330",
                                marginBottom: "16px",
                                fontSize: "16px",
                            }}
                        >
                            You can refer up to {referralLimit} users and
                            collect up to {5 * creditsEarned} credits!
                        </EmailText>
                        <EmailButton
                            noMargin
                            href={inviteLink}
                        >
                            Earn More Credits
                        </EmailButton>
                    </Section>
                    <Section style={{ marginTop: "32px" }}>
                        <EmailText>
                            Thanks for helping us grow the 1Template community!
                        </EmailText>
                        <EmailText>The 1Template Team</EmailText>
                    </Section>
                </Section>
            </Section>
        </EmailWrapper>
    );
};

export default ReferralSuccessEmail;
