import { AppText } from "@/components/Text/AppText";

interface StatsContainerProps {
    children: React.ReactNode;
    title: string;
    description?: string;
}

export const StatsContainer = ({
    children,
    title,
    description,
}: StatsContainerProps) => {
    return (
        <div className="mb-2 flex flex-col gap-4 rounded-lg bg-white p-4 shadow-md">
            <div className="flex items-center gap-6 border-b pb-3">
                <h2 className="flex-shrink-0 text-2xl font-bold text-gray-800">
                    {title}
                </h2>
                {description && (
                    <AppText variant="footnote">{description}</AppText>
                )}
            </div>
            {children}
        </div>
    );
};
