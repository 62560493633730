import { useMemo } from "react";
import { AppText } from "@/components/Text/AppText";
import { useTransaction } from "@/hooks/useTransaction";
import { CreditActionCode } from "@/types/creditAction";
import { pluralise } from "@/utils/string";

interface HowItWorksProps {
    title?: string;
    className?: string;
    showNote?: boolean;
}

const howItWorksSteps = [
    {
        icon: (
            <div className="relative flex h-6 w-6 items-center justify-center rounded-full bg-primary-700 text-neutral-50">
                <AppText
                    variant="contextheading"
                    className="absolute left-1/2 top-[52%] -translate-x-1/2 -translate-y-1/2 text-neutral-50"
                >
                    1
                </AppText>
            </div>
        ),
        title: "Invite your friends",
        description: "Copy your unique referral link and share it with friends",
    },
    {
        icon: (
            <div className="relative flex h-6 w-6 items-center justify-center rounded-full bg-primary-700 text-neutral-50">
                <AppText
                    variant="contextheading"
                    className="absolute left-1/2 top-[52%] -translate-x-1/2 -translate-y-1/2 text-neutral-50"
                >
                    2
                </AppText>
            </div>
        ),
        title: "Friends join 1Template for free",
        description:
            "When someone clicks your link and signs up, they're added to your referral list",
    },
    {
        icon: (
            <div className="relative flex h-6 w-6 items-center justify-center rounded-full bg-primary-700 text-neutral-50">
                <AppText
                    variant="contextheading"
                    className="absolute left-1/2 top-[52%] -translate-x-1/2 -translate-y-1/2 text-neutral-50"
                >
                    3
                </AppText>
            </div>
        ),
        title: "They create and download a resume",
        description:
            "The referral is complete once your friend downloads their first resume on 1Template",
    },
];

export const ReferralSteps = ({
    title,
    className = "",
    showNote = true,
}: HowItWorksProps) => {
    const { getCreditActionByCode } = useTransaction();
    const referralAction = useMemo(
        () => getCreditActionByCode(CreditActionCode.USER_REFERRAL),
        [getCreditActionByCode],
    );

    return (
        <div className={className}>
            {title && (
                <AppText
                    variant="labelsbuttons"
                    className="mb-4 block text-center text-neutral-900 lg:text-left"
                >
                    {title}
                </AppText>
            )}
            <div className="flex flex-col">
                {howItWorksSteps.map(step => (
                    <div
                        key={step.title}
                        className="relative flex items-start gap-4"
                    >
                        <div className="relative flex flex-col items-center pt-0.5">
                            <div className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full border-2 border-primary-700 bg-white">
                                {step.icon}
                            </div>
                        </div>
                        <div className="flex flex-col pb-6">
                            <AppText
                                variant="labelsbuttons"
                                className="text-neutral-900"
                            >
                                {step.title}
                            </AppText>
                            <AppText
                                variant="regular"
                                className="text-neutral-700"
                            >
                                {step.description}
                            </AppText>
                        </div>
                    </div>
                ))}
                <div className="relative flex items-start gap-4">
                    <div className="relative flex flex-col items-center pt-0.5">
                        <div className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full border-2 border-primary-700 bg-white">
                            <div className="relative flex h-6 w-6 items-center justify-center rounded-full bg-primary-700 text-neutral-50">
                                <AppText
                                    variant="contextheading"
                                    className="absolute left-[47%] top-[52%] -translate-x-1/2 -translate-y-1/2 text-neutral-50"
                                >
                                    4
                                </AppText>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col pb-6">
                        <AppText
                            variant="regular"
                            className="!font-medium text-neutral-900"
                        >
                            You earn rewards
                        </AppText>
                        <AppText
                            variant="regular"
                            className="text-neutral-700"
                        >
                            For each successful referral, you get{" "}
                            <AppText
                                variant="regular"
                                applyGradient
                                className="inline !font-semibold"
                            >
                                {referralAction?.credits}{" "}
                                {pluralise(
                                    referralAction?.credits ?? 0,
                                    "credit",
                                    "credits",
                                )}
                            </AppText>
                        </AppText>
                    </div>
                </div>
            </div>
            {showNote && (
                <div className="mt-4 w-full max-w-2xl">
                    <AppText
                        variant="footnote"
                        className="block text-center text-neutral-700 lg:text-left"
                    >
                        Note: You can refer up to 5 friends. Make the most of
                        your referrals to maximize your rewards!
                    </AppText>
                </div>
            )}
        </div>
    );
};
