import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PostHogClient from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Notifications } from "./components/Notifications/Notifications";
import "./index.css";
import { store } from "./store/store";
import CookieConsent from "@/components/CookieConsent/CookieConsent";
import ErrorBoundary from "@/components/ErrorBoundary/ErrorBoundary";
import { CreditsInfoModal } from "@/components/Modals";
import { TrackingManager } from "@/components/Tracking/TrackingManager";
import { DeviceProvider } from "@/context/DeviceProvider";
import { TransactionProvider } from "@/context/TransactionProvider";
import { detectUserCountry } from "@/i18n";
import { router } from "@/routes";

const options = {
    api_host: import.meta.env.VITE_POSTHOG_HOST,
    disable_surveys: true,
    disable_session_recording: true,
    property_blacklist: ["$ip", "$email", "$name"],
    mask_all_text: true,
    mask_all_element_attributes: true,
    disable_persistence: true,
    disable_web_experiments: true,
    disable_external_dependency_loading: true,
    ip: false,
    autocapture: false,
    capture_pageview: true,
    capture_pageleave: false,
};

const posthogClient = import.meta.env.VITE_POSTHOG_API_KEY
    ? (() => {
          const client = PostHogClient.init(
              import.meta.env.VITE_POSTHOG_API_KEY,
              options,
          );
          client.identify(uuidv4());
          return client;
      })()
    : null;

const rootElement = document.getElementById("root");

if (rootElement === null)
    throw new Error("Root container missing in index.html");

const root = createRoot(rootElement);

detectUserCountry();

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60 * 5, // Data is considered fresh for 5 minutes
            gcTime: 1000 * 60 * 30, // Cache is kept for 30 minutes
        },
    },
});

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <DeviceProvider>
                    <TransactionProvider>
                        <ErrorBoundary>
                            {posthogClient ? (
                                <PostHogProvider client={posthogClient}>
                                    <RouterProvider router={router} />
                                    <Notifications />
                                </PostHogProvider>
                            ) : (
                                <>
                                    <RouterProvider router={router} />
                                    <Notifications />
                                </>
                            )}
                        </ErrorBoundary>
                        <CookieConsent />
                        <TrackingManager />
                        <CreditsInfoModal />
                    </TransactionProvider>
                </DeviceProvider>
            </QueryClientProvider>
        </Provider>
    </React.StrictMode>,
);
