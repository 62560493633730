import {
    CredentialResponse,
    GoogleLogin,
    useGoogleLogin,
} from "@react-oauth/google";
import { useSearchParams } from "react-router-dom";
import userApi from "@/api/user";
import { Google } from "@/components/Icons/Icons";
import { AppText } from "@/components/Text/AppText";
import { User } from "@/types";
import { Tokens } from "@/types/token";
import { ReferralSource } from "@/types/user";

export type SSOLogin = {
    setError: (error: string) => void;
    onSuccess: (user: User, tokens: Tokens) => void;
};

export const SSOLogin = ({ setError, onSuccess }: SSOLogin) => {
    const [searchParams] = useSearchParams();
    const referralSource = searchParams.get("utm_source") as ReferralSource;
    const signUpReferralCode = searchParams.get("referral_code");
    const onOneTapSuccess = async (credentialResponse: CredentialResponse) => {
        const { tokens, user } = await userApi.ssoLoginApi({
            type: "credential",
            value: credentialResponse.credential ?? "",
            method: "google",
            referralSource,
            signUpReferralCode: signUpReferralCode ?? undefined,
        });
        onSuccess(user, tokens);
    };

    const onOneTapError = () => {
        setError("Failed to login with Google");
    };

    const loginWithGoogle = useGoogleLogin({
        flow: "auth-code",
        onSuccess: async ({ code }) => {
            const { tokens, user } = await userApi.ssoLoginApi({
                type: "code",
                value: code,
                method: "google",
                referralSource,
                signUpReferralCode: signUpReferralCode ?? undefined,
            });
            onSuccess(user, tokens);
        },
        onError: e => {
            if (e?.error !== "access_denied") {
                setError("Failed to login with Google");
            }
        },
    });

    return (
        <div>
            <div>
                <button
                    onClick={() => {
                        loginWithGoogle();
                    }}
                    className="mx-auto flex h-10 items-center justify-center rounded-lg bg-neutral-50 pl-[2px] pr-3 ring-2 ring-[#35475a33] hover:bg-[#F2F2F2]"
                >
                    <Google className="h-10 w-10" />
                    <AppText variant="labelsbuttons">
                        Continue with Google
                    </AppText>
                </button>
            </div>
            <div className="hidden">
                <GoogleLogin
                    useOneTap
                    onSuccess={onOneTapSuccess}
                    onError={onOneTapError}
                    cancel_on_tap_outside={false}
                />
            </div>
        </div>
    );
};
