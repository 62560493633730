export enum AppRoutes {
    Admin = "admin",
    Ai = "ai",
    Stats = "stats",
    Assistant = "assistant",
    AssistantItem = "assistant/:assistantItemId",
    CreditActions = "credit-actions",
    CreditAction = "credit-actions/:creditActionId",
    Dashboard = "/",
    DocumentType = "document-types/:documentTypeId",
    DocumentTypes = "document-types",
    Examples = "examples",
    ForgotPassword = "forgot-password",
    Login = "login",
    Logout = "logout",
    Preferences = "preferences",
    Preview = "preview/:id",
    Profile = "profile",
    ResetPassword = "reset-password",
    Resume = "resume/:id",
    Section = "sections/:sectionId",
    Sections = "sections",
    Signup = "signup",
    Styles = "styles",
    Template = "templates/:templateId",
    Templates = "templates",
    User = "users/:userId",
    Users = "users",
    Verify = "verify"
}

export enum SiteRoutes {
    Landing = "https://1template.io",
    PrivacyPolicy = "https://1template.io/privacy-policy",
    Terms = "https://1template.io/terms-of-service",
    CookiePolicy = "https://1template.io/privacy-policy#using-our-platform-and-cookies",
    About = "https://1template.io/about",
    Blog = "https://1template.io/blog",
    Pricing = "https://1template.io/pricing",
}

export enum SocialMediaRoutes {
    Instagram = "https://www.instagram.com/1template.io/",
    LinkedIn = "https://www.linkedin.com/company/1template/about",
}
