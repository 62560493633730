import resumeApi from "@/api/resume";
import { openModal } from "@/store/modal/slice";
import { addResume, setActiveResume } from "@/store/resume/slice";
import { AppDispatch, store } from "@/store/store";
import { getUserReachedResumeLimit } from "@/store/user/selectors";
import { ModalTypes } from "@/types/modal";
import { DocumentVisibility, Resume } from "@/types/resume";
import { GA_EVENT_ACTIONS, GA_EVENT_CATEGORIES } from "@/types/tracking";
import { User } from "@/types/user";
import { trackGAEvent } from "@/utils/tracking";
import { NavigateFunction } from "react-router-dom";

export const duplicateResume = ({
    navigate,
    resume,
    isExampleResume,
    resumeName,
    isDesktop,
    userDetails,
}: {
    navigate?: NavigateFunction;
    resume: Resume;
    isExampleResume?: boolean;
    resumeName?: string;
    isDesktop: boolean;
    userDetails?: User;
}): Promise<Resume | null> => {
    const dispatch = store.dispatch as AppDispatch;
    const state = store.getState();
    const userReachedResumeLimit = getUserReachedResumeLimit(state);

    if (userReachedResumeLimit) {
        dispatch(
            openModal({
                modalType: ModalTypes.DOCUMENT_LIMIT,
            }),
        );
        return Promise.resolve(null);
    }

    const newSections = JSON.parse(JSON.stringify(resume.sections));
    const profileSectionId = Object.keys(newSections).find(
        key => newSections[key].sectionName?.toLowerCase() === "profile",
    );

    // Set users account details in profile section
    if (profileSectionId && userDetails) {
        const userProfileSection = newSections[profileSectionId];
        userProfileSection.header = {
            ...userProfileSection.header,
            name: `<h1><strong>${userDetails.firstName}${userDetails.lastName ? ` ${userDetails.lastName}` : ""}</strong></h1>`,
            email: `<p>${userDetails.email}</p>`,
        };
    }

    return resumeApi
        .createResume({
            sections: newSections,
            name: resumeName || "New Resume",
            styles: { ...resume.styles },
            template: resume.template,
            documentType: resume.documentType,
            columnLayout: resume.columnLayout,
            isTemplate: false,
            visibility: DocumentVisibility.PRIVATE,
            exampleId: isExampleResume ? resume.id : undefined,
        })
        .then(newResume => {
            dispatch(setActiveResume(newResume));
            dispatch(addResume(newResume));
            if (navigate) {
                if (isDesktop) {
                    navigate(`/resume/${newResume.id}`);
                } else {
                    navigate(`/preview/${newResume.id}`);
                }
            }
            if (isExampleResume) {
                trackGAEvent(GA_EVENT_CATEGORIES.BUTTON, GA_EVENT_ACTIONS.CLICK, "Example resume selected");
            }
            return newResume;
        });
};