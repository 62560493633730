import { motion } from "framer-motion";

export const AnimatedUploadAnalysis = ({
    className,
    color = "#CED4E5",
    magnifyingGlassColor = "#F2F4F9",
}: {
    className?: string;
    color?: string;
    magnifyingGlassColor?: string;
}) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            {/* Document */}
            <rect
                x="5"
                y="4"
                width="14"
                height="16"
                rx="2"
                stroke={color}
                strokeWidth="1.5"
            />

            {/* Static document lines */}
            <path
                d="M8 8H16"
                stroke="rgba(255, 255, 255, 0.8)"
                strokeWidth="1.5"
                strokeLinecap="round"
            />

            <path
                d="M8 12H16"
                stroke="rgba(255, 255, 255, 0.8)"
                strokeWidth="1.5"
                strokeLinecap="round"
            />

            <path
                d="M8 16H13"
                stroke="rgba(255, 255, 255, 0.8)"
                strokeWidth="1.5"
                strokeLinecap="round"
            />

            {/* Magnifying glass */}
            <motion.g
                animate={{
                    x: [10, 8, 14, 10, 10],
                    y: [10, 8, 14, 16, 10],
                }}
                transition={{
                    duration: 8,
                    repeat: Infinity,
                    ease: "easeInOut",
                }}
            >
                {/* Glass lens */}
                <circle
                    cx="0"
                    cy="0"
                    r="4.5"
                    stroke={magnifyingGlassColor}
                    strokeWidth="2"
                    fill="rgba(168, 186, 255, 0.15)"
                />

                {/* Handle */}
                <path
                    d="M4 4L7 7"
                    stroke={magnifyingGlassColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                />
            </motion.g>
        </svg>
    );
};
