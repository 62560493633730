import { motion } from "framer-motion";

export const AnimatedSmartScribe = ({
    className,
    starColor = "white",
}: {
    className?: string;
    starColor?: string;
}) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g clipPath="url(#clip0_animated_scribe)">
                {/* Main pen path */}
                <path
                    d="M5.20145 15.478L16.3231 4.35383C16.7637 3.91335 17.3612 3.66594 17.9842 3.66602C18.6073 3.66609 19.2047 3.91366 19.6452 4.35425C20.0857 4.79484 20.3331 5.39237 20.333 6.01538C20.3329 6.63839 20.0854 7.23585 19.6448 7.67633L8.52312 18.7997M5.20145 15.478C5.00797 15.6709 4.86488 15.9084 4.78478 16.1697L3.68395 19.7963C3.66241 19.8684 3.66079 19.945 3.67924 20.0179C3.6977 20.0908 3.73555 20.1574 3.78878 20.2105C3.84201 20.2636 3.90863 20.3014 3.98158 20.3197C4.05453 20.3381 4.13108 20.3363 4.20312 20.3147L7.83062 19.2147C8.09159 19.1353 8.32909 18.9931 8.52228 18.8005L8.52312 18.7997M5.20145 15.478L8.52312 18.7997"
                    stroke="white"
                    strokeWidth="1.83333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />

                {/* Animated stars */}
                <motion.path
                    d="M13.8482 16.6858L14.5437 16.9423C15.1069 17.1499 15.5508 17.5938 15.7584 18.1569L16.0149 18.8525C16.0246 18.8786 16.0495 18.896 16.0774 18.896C16.1052 18.896 16.1302 18.8786 16.1398 18.8525L16.3964 18.1569C16.604 17.5938 17.0479 17.1499 17.611 16.9423L18.3066 16.6858C18.3327 16.6761 18.3501 16.6512 18.3501 16.6233C18.3501 16.5955 18.3327 16.5705 18.3066 16.5609L17.611 16.3043C17.0479 16.0966 16.604 15.6528 16.3964 15.0897L16.1398 14.3941C16.1302 14.3679 16.1052 14.3505 16.0774 14.3505C16.0495 14.3505 16.0246 14.3679 16.0149 14.3941L15.7584 15.0897C15.5507 15.6528 15.1069 16.0967 14.5438 16.3043L13.8482 16.5609C13.822 16.5705 13.8046 16.5955 13.8046 16.6233C13.8046 16.6512 13.822 16.6761 13.8482 16.6858Z"
                    fill={starColor}
                    animate={{
                        scale: [1, 1.2, 1],
                        opacity: [0.7, 1, 0.7],
                    }}
                    transition={{
                        duration: 2,
                        repeat: Infinity,
                        ease: "easeInOut",
                        delay: 0.5,
                    }}
                />

                <motion.path
                    d="M3.20367 9.78978L3.89921 10.0463C4.46233 10.254 4.90622 10.6978 5.11384 11.2609L5.37039 11.9565C5.38003 11.9826 5.40497 12 5.43284 12C5.46071 12 5.48565 11.9826 5.49529 11.9565L5.75184 11.2609C5.95949 10.6978 6.40339 10.254 6.96647 10.0463L7.66205 9.78978C7.6882 9.78014 7.70557 9.75521 7.70557 9.72733C7.70557 9.69946 7.6882 9.67452 7.66205 9.66488L6.96643 9.40829C6.40335 9.20063 5.9595 8.75677 5.75184 8.19369L5.49529 7.49806C5.48565 7.47191 5.46071 7.45455 5.43284 7.45455C5.40497 7.45455 5.38003 7.47191 5.37039 7.49806L5.11384 8.19369C4.90618 8.75677 4.46232 9.20067 3.89925 9.40829L3.20363 9.66488C3.17748 9.67452 3.16011 9.69946 3.16011 9.72733C3.16011 9.7552 3.17752 9.78014 3.20367 9.78978Z"
                    fill={starColor}
                    animate={{
                        scale: [1, 1.3, 1],
                        opacity: [0.7, 1, 0.7],
                    }}
                    transition={{
                        duration: 2.5,
                        repeat: Infinity,
                        ease: "easeInOut",
                    }}
                />

                <motion.path
                    d="M9.08469 5.5641L9.41855 5.68722C9.68885 5.7869 9.90192 5.99995 10.0016 6.27023L10.1247 6.60411C10.1293 6.61666 10.1413 6.625 10.1547 6.625C10.1681 6.625 10.18 6.61666 10.1847 6.60411L10.3078 6.27025C10.4075 5.99995 10.6206 5.7869 10.8908 5.68722L11.2247 5.5641C11.2373 5.55947 11.2456 5.5475 11.2456 5.53412C11.2456 5.52074 11.2373 5.50877 11.2247 5.50414L10.8908 5.38098C10.6205 5.2813 10.4075 5.06825 10.3078 4.79797L10.1847 4.46407C10.18 4.45152 10.1681 4.44318 10.1547 4.44318C10.1413 4.44318 10.1293 4.45152 10.1247 4.46407L10.0016 4.79797C9.9019 5.06825 9.68885 5.28132 9.41857 5.38098L9.08467 5.50414C9.07212 5.50877 9.06379 5.52074 9.06379 5.53412C9.06379 5.5475 9.07214 5.55947 9.08469 5.5641Z"
                    fill={starColor}
                    animate={{
                        scale: [1, 1.4, 1],
                        opacity: [0.7, 1, 0.7],
                    }}
                    transition={{
                        duration: 3,
                        repeat: Infinity,
                        ease: "easeInOut",
                        delay: 0.8,
                    }}
                />
            </g>
            <defs>
                <clipPath id="clip0_animated_scribe">
                    <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(2 2)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
