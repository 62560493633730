import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import {
    AtSign,
    Diamond,
    DollarSign,
    FileIcon,
    HandCoins,
    PieChartIcon,
    PiggyBank,
    RefreshCwIcon,
    ThumbsDown,
    ThumbsUp,
    UserCog,
    UserPlus,
    Users,
} from "lucide-react";
import { useMemo, useState } from "react";
import {
    aiFeedbackColumns,
    creditActionCodeColumns,
    creditBalanceColumns,
    documentLimitColumns,
    experienceLevelColumns,
    feedbackActionBreakdownColumns,
    industryColumns,
    jobRoleColumns,
    jobTypeColumns,
    resumeLayoutColumns,
    resumesPerUserColumns,
} from "./tableDefinitions";
import statsApi, { StatsRequest } from "@/api/stats";
import { Button } from "@/components/Button/Button";
import { AppText } from "@/components/Text/AppText";
import { useAppSelector } from "@/hooks/types";
import { StatCard } from "@/pages/Admin/Stats/StatCard";
import { StatTable } from "@/pages/Admin/Stats/StatTable";
import { StatsContainer } from "@/pages/Admin/Stats/StatsContainer";
import {
    calculateCreditBalanceStats,
    calculatePreferenceStats,
    calculateResumeLayoutStats,
    formatCreditSpendUserProfile,
} from "@/pages/Admin/Stats/helper";
import { CreditSpendUserProfile } from "@/pages/Admin/Stats/types";
import {
    jobRoles,
    industries,
    experienceLevels,
    jobTypes,
} from "@/pages/Preferences/constants";
import { getUserDetails } from "@/store/user/selectors";
import { Resume, User } from "@/types";
import { ICreditTransaction } from "@/types/creditTransaction";
type CreditStat = {
    count: number;
    amount: number;
    codeBreakdown: Record<string, { count: number; amount: number }>;
};
interface CreditTransactionAnalytics {
    purchase: CreditStat;
    spend: CreditStat;
    earn: CreditStat;
    refund: CreditStat;
    signUp: CreditStat;
    admin: CreditStat;
}

const defaultCreditSpendUserProfile = {
    happyUsers: 0,
    powerUsers: 0,
    casualUsers: 0,
    featureExplorers: 0,
    dormantUsers: 0,
    newUsers: 0,
    creditHoarders: 0,
    highValueUsers: 0,
    earners: 0,
};

const defaultCreditTransactionAnalytics: CreditTransactionAnalytics = {
    purchase: { count: 0, amount: 0, codeBreakdown: {} },
    spend: { count: 0, amount: 0, codeBreakdown: {} },
    earn: { count: 0, amount: 0, codeBreakdown: {} },
    refund: { count: 0, amount: 0, codeBreakdown: {} },
    signUp: { count: 0, amount: 0, codeBreakdown: {} },
    admin: { count: 0, amount: 0, codeBreakdown: {} },
};

interface AiFeedbackStats {
    thumbsUp: number;
    thumbsDown: number;
    actions: Record<string, number>;
    creditActions: Record<string, { positive: number; negative: number }>;
}

const defaultAiFeedbackStats: AiFeedbackStats = {
    thumbsUp: 0,
    thumbsDown: 0,
    actions: {},
    creditActions: {},
};

const defaultResumesPerUser: Record<string, number> = {};
const defaultDocumentLimitStats: Record<string, number> = {};

interface QuickDateRange {
    label: string;
    getDates: () => { startDate: string; endDate: string };
}

const quickDateRanges: QuickDateRange[] = [
    {
        label: "1 Day",
        getDates: () => {
            const end = new Date();
            const start = new Date();
            start.setHours(end.getHours() - 24);
            return {
                startDate: start.toISOString().split("T")[0],
                endDate: end.toISOString().split("T")[0],
            };
        },
    },
    {
        label: "3 days",
        getDates: () => {
            const end = new Date();
            const start = new Date();
            start.setDate(end.getDate() - 3);
            return {
                startDate: start.toISOString().split("T")[0],
                endDate: end.toISOString().split("T")[0],
            };
        },
    },
    {
        label: "7 Days",
        getDates: () => {
            const end = new Date();
            const start = new Date();
            start.setDate(end.getDate() - 7);
            return {
                startDate: start.toISOString().split("T")[0],
                endDate: end.toISOString().split("T")[0],
            };
        },
    },
    {
        label: "30 Days",
        getDates: () => {
            const end = new Date();
            const start = new Date();
            start.setMonth(end.getMonth() - 1);
            return {
                startDate: start.toISOString().split("T")[0],
                endDate: end.toISOString().split("T")[0],
            };
        },
    },
    {
        label: "All Time",
        getDates: () => ({
            startDate: "2020-01-01", // Adjust this date as needed
            endDate: new Date().toISOString().split("T")[0],
        }),
    },
    {
        label: "Since v2 Launch",
        getDates: () => {
            const end = new Date();
            const start = new Date("2025-02-07");
            return {
                startDate: start.toISOString().split("T")[0],
                endDate: end.toISOString().split("T")[0],
            };
        },
    },
];

const defaultQueryParams = {
    refetchOnMount: false,
    staleTime: 0,
    gcTime: 0,
};

export const StatsPage = () => {
    const userDetails = useAppSelector(getUserDetails);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [activeTab, setActiveTab] = useState<string>("totals");

    // Update submitted dates type
    const [submittedDates, setSubmittedDates] = useState<{
        startDate: string | null;
        endDate: string | null;
    }>({
        startDate: null,
        endDate: null,
    });

    const params: StatsRequest = useMemo(
        () => ({
            startDate: submittedDates.startDate,
            endDate: submittedDates.endDate,
            userId: userDetails?.id ?? "",
        }),
        [submittedDates, userDetails?.id],
    );

    const {
        data: allResumes = [],
        refetch: refetchResumes,
        isLoading: isResumesLoading,
    } = useQuery<Resume[]>({
        queryKey: ["allResumes"],
        queryFn: () => statsApi.getAllResumes(params),
        ...defaultQueryParams,
        enabled: activeTab === "resumes" || activeTab === "totals",
    });

    const {
        data: allUsers = [],
        refetch: refetchUsers,
        isLoading: isUsersLoading,
    } = useQuery<User[]>({
        queryKey: ["allUsers"],
        queryFn: () => statsApi.getAllUsers(params),
        ...defaultQueryParams,
        enabled: activeTab === "users" || activeTab === "totals",
    });

    const {
        data: creditsAnalytics = defaultCreditTransactionAnalytics,
        refetch: refetchCreditTransactionAnalytics,
        isLoading: isTransactionsLoading,
    } = useQuery<CreditTransactionAnalytics>({
        queryKey: ["creditsAnalytics"],
        queryFn: async () => {
            const result = await statsApi.getCreditTransactionAnalytics(params);
            return result as CreditTransactionAnalytics;
        },
        ...defaultQueryParams,
        enabled: activeTab === "transactions",
    });

    const {
        data: creditSpendUserProfile = defaultCreditSpendUserProfile,
        refetch: refetchCreditSpendUserProfile,
    } = useQuery<CreditSpendUserProfile>({
        queryKey: ["creditSpendUserProfile"],
        queryFn: async () => {
            const result =
                await statsApi.getCreditSpendUserProfileStats(params);
            return formatCreditSpendUserProfile(
                result as ICreditTransaction[],
            ) as CreditSpendUserProfile;
        },
        ...defaultQueryParams,
        enabled: activeTab === "users",
    });

    const {
        data: aiFeedbackStats = defaultAiFeedbackStats,
        refetch: refetchAiFeedbackStats,
    } = useQuery<AiFeedbackStats>({
        queryKey: ["aiFeedbackStats"],
        queryFn: async () => {
            const result = await statsApi.getAiFeedbackStats(params);
            return result as AiFeedbackStats;
        },
        ...defaultQueryParams,
        enabled: activeTab === "ai",
    });

    const {
        data: resumesPerUser = defaultResumesPerUser,
        refetch: refetchResumesPerUser,
    } = useQuery<Record<string, number>>({
        queryKey: ["resumesPerUser"],
        queryFn: () =>
            statsApi.getResumesPerUser(params) as Promise<
                Record<string, number>
            >,
        ...defaultQueryParams,
        enabled: activeTab === "resumes" || activeTab === "totals",
    });

    const {
        data: documentLimitStats = defaultDocumentLimitStats,
        refetch: refetchDocumentLimitStats,
    } = useQuery<Record<string, number>>({
        queryKey: ["documentLimitStats"],
        queryFn: () =>
            statsApi.getDocumentLimitStats(params) as Promise<
                Record<string, number>
            >,
        ...defaultQueryParams,
        enabled: activeTab === "resumes" || activeTab === "totals",
    });

    const isLoading =
        isResumesLoading || isUsersLoading || isTransactionsLoading;

    const handleRefresh = () => {
        if (activeTab === "totals") {
            refetchResumes();
            refetchUsers();
        } else if (activeTab === "transactions") {
            refetchCreditTransactionAnalytics();
        } else if (activeTab === "users") {
            refetchUsers();
            refetchResumesPerUser();
            refetchCreditSpendUserProfile();
        } else if (activeTab === "resumes") {
            refetchResumes();
            refetchDocumentLimitStats();
        } else if (activeTab === "ai") {
            refetchAiFeedbackStats();
        }
    };

    const handleSubmit = () => {
        setSubmittedDates({ startDate, endDate });
        setTimeout(() => {
            handleRefresh(); // Trigger the refresh after updating dates
        }, 300);
    };

    const handleQuickDateSelect = (range: QuickDateRange) => {
        const { startDate: newStartDate, endDate: newEndDate } =
            range.getDates();
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        setSubmittedDates({ startDate: newStartDate, endDate: newEndDate });
        setTimeout(handleRefresh, 300);
    };

    const industryStats = useMemo(
        () => calculatePreferenceStats(allUsers, industries, "industries"),
        [allUsers],
    );

    const jobRoleStats = useMemo(
        () => calculatePreferenceStats(allUsers, jobRoles, "jobRoles"),
        [allUsers],
    );

    const experienceLevelStats = useMemo(
        () =>
            calculatePreferenceStats(
                allUsers,
                experienceLevels,
                "experienceLevel",
            ),
        [allUsers],
    );

    const jobTypeStats = useMemo(
        () => calculatePreferenceStats(allUsers, jobTypes, "jobTypes"),
        [allUsers],
    );

    const creditBalanceStats = useMemo(
        () => calculateCreditBalanceStats(allUsers),
        [allUsers],
    );

    const resumeLayoutStats = useMemo(
        () => calculateResumeLayoutStats(allResumes),
        [allResumes],
    );

    return (
        <div className="h-full rounded-lg bg-white px-6 pt-2">
            <div className="mb-4 flex flex-wrap items-center justify-between gap-4">
                <div className="flex flex-wrap items-center gap-4">
                    <div className="flex items-center gap-2">
                        <label
                            htmlFor="start-date"
                            className="text-sm font-medium text-gray-700"
                        >
                            Start Date
                        </label>
                        <input
                            type="date"
                            id="start-date"
                            value={startDate ?? ""}
                            onChange={e => setStartDate(e.target.value || null)}
                            className="rounded-md border-gray-300 px-2 py-1 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className="flex items-center gap-2">
                        <label
                            htmlFor="end-date"
                            className="text-sm font-medium text-gray-700"
                        >
                            End Date
                        </label>
                        <input
                            type="date"
                            id="end-date"
                            value={endDate ?? ""}
                            onChange={e => setEndDate(e.target.value || null)}
                            className="rounded-md border-gray-300 px-2 py-1 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                    </div>
                    <Button
                        onClick={handleSubmit}
                        disabled={isLoading || !startDate || !endDate}
                        loading={isLoading}
                    >
                        Apply
                    </Button>
                    <div className="flex flex-wrap gap-2">
                        {quickDateRanges.map(range => (
                            <Button
                                key={range.label}
                                variant={"outline"}
                                color={"grey"}
                                onClick={() => handleQuickDateSelect(range)}
                                disabled={isLoading}
                                className="!py-1 !text-sm"
                                size="small"
                            >
                                {range.label}
                            </Button>
                        ))}
                    </div>
                </div>
                <button
                    onClick={handleRefresh}
                    disabled={isLoading}
                    className="inline-flex items-center gap-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50"
                >
                    <RefreshCwIcon
                        className={`h-4 w-4 ${isLoading ? "animate-spin" : ""}`}
                    />
                    {isLoading ? "Refreshing..." : "Refresh"}
                </button>
            </div>

            <TabGroup
                selectedIndex={[
                    "totals",
                    "transactions",
                    "ai",
                    "users",
                    "resumes",
                ].indexOf(activeTab)}
                onChange={index =>
                    setActiveTab(
                        ["totals", "transactions", "ai", "users", "resumes"][
                            index
                        ],
                    )
                }
            >
                <TabList className="flex space-x-1 rounded-xl bg-gray-100 p-1">
                    <Tab
                        className={({ selected }) =>
                            `w-full rounded-lg py-2.5 text-sm font-medium leading-5
                        ${
                            selected
                                ? "bg-white text-gray-900 shadow"
                                : "text-gray-500 hover:bg-white/[0.12] hover:text-gray-900"
                        }`
                        }
                    >
                        Totals
                    </Tab>
                    <Tab
                        className={({ selected }) =>
                            `w-full rounded-lg py-2.5 text-sm font-medium leading-5
                        ${
                            selected
                                ? "bg-white text-gray-900 shadow"
                                : "text-gray-500 hover:bg-white/[0.12] hover:text-gray-900"
                        }`
                        }
                    >
                        Transactions
                    </Tab>
                    <Tab
                        className={({ selected }) =>
                            `w-full rounded-lg py-2.5 text-sm font-medium leading-5
                        ${
                            selected
                                ? "bg-white text-gray-900 shadow"
                                : "text-gray-500 hover:bg-white/[0.12] hover:text-gray-900"
                        }`
                        }
                    >
                        AI
                    </Tab>
                    <Tab
                        className={({ selected }) =>
                            `w-full rounded-lg py-2.5 text-sm font-medium leading-5
                        ${
                            selected
                                ? "bg-white text-gray-900 shadow"
                                : "text-gray-500 hover:bg-white/[0.12] hover:text-gray-900"
                        }`
                        }
                    >
                        Users
                    </Tab>
                    <Tab
                        className={({ selected }) =>
                            `w-full rounded-lg py-2.5 text-sm font-medium leading-5
                        ${
                            selected
                                ? "bg-white text-gray-900 shadow"
                                : "text-gray-500 hover:bg-white/[0.12] hover:text-gray-900"
                        }`
                        }
                    >
                        Resumes
                    </Tab>
                </TabList>

                <TabPanels className="mt-4">
                    <TabPanel>
                        <StatsContainer title="Totals">
                            <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-5">
                                <StatCard
                                    title="Resume Created"
                                    value={allResumes.length}
                                    icon={<FileIcon size={16} />}
                                    loading={isResumesLoading}
                                />
                                <StatCard
                                    title="Users Created"
                                    value={allUsers.length}
                                    icon={<Users size={16} />}
                                    loading={isUsersLoading}
                                />
                                <StatCard
                                    title="Email Users"
                                    value={
                                        allUsers.filter(
                                            user =>
                                                user.loginMethod === "email",
                                        ).length
                                    }
                                    icon={<Users size={16} />}
                                    loading={isUsersLoading}
                                />
                                <StatCard
                                    title="SSO Users"
                                    value={
                                        allUsers.filter(
                                            user =>
                                                user.loginMethod !== "email",
                                        ).length
                                    }
                                    icon={<Users size={16} />}
                                    loading={isUsersLoading}
                                />
                                <StatCard
                                    title="Opt-In Emails"
                                    value={
                                        allUsers.filter(user =>
                                            user.marketingOptIns.some(
                                                optIn =>
                                                    optIn.channel === "email",
                                            ),
                                        ).length
                                    }
                                    icon={<AtSign size={16} />}
                                    loading={isUsersLoading}
                                />
                            </div>
                            <div className="flex flex-wrap gap-6">
                                <StatCard
                                    title="Document Limit"
                                    icon={<FileIcon size={16} />}
                                    loading={isResumesLoading}
                                    className="col-span-2"
                                >
                                    <StatTable
                                        columns={documentLimitColumns}
                                        data={Object.entries(
                                            documentLimitStats,
                                        ).map(([documentLimit, count]) => ({
                                            documentLimit,
                                            count,
                                            percentage:
                                                (count / allUsers.length) * 100,
                                        }))}
                                    />
                                </StatCard>
                            </div>
                        </StatsContainer>
                    </TabPanel>

                    <TabPanel>
                        <StatsContainer title="Transaction Event Type Counts">
                            <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-6">
                                <StatCard
                                    title="Purchase Count"
                                    value={`${creditsAnalytics.purchase.count}`}
                                    icon={<PiggyBank size={16} />}
                                />
                                <StatCard
                                    title="Spend Count"
                                    value={`${creditsAnalytics.spend.count}`}
                                    icon={<DollarSign size={16} />}
                                />
                                <StatCard
                                    title="Refunded Count"
                                    value={`${creditsAnalytics.refund.count}`}
                                    icon={<HandCoins size={16} />}
                                />
                                <StatCard
                                    title="Earned Count"
                                    value={`${creditsAnalytics.earn.count}`}
                                    icon={<Diamond size={16} />}
                                />
                                <StatCard
                                    title="Sign Up Count"
                                    value={`${creditsAnalytics.signUp.count}`}
                                    icon={<UserPlus size={16} />}
                                />
                                <StatCard
                                    title="Admin Count"
                                    value={`${creditsAnalytics.admin.count}`}
                                    icon={<UserCog size={16} />}
                                />
                                <StatCard
                                    title="Earn V Spend"
                                    icon={<PieChartIcon size={16} />}
                                >
                                    <div className="relative h-40 w-40">
                                        {(() => {
                                            const totalEarned =
                                                creditsAnalytics.earn.amount +
                                                creditsAnalytics.signUp.amount;
                                            const totalSpent = Math.abs(
                                                creditsAnalytics.spend.amount,
                                            );
                                            const spentPercentage = totalEarned
                                                ? (totalSpent / totalEarned) *
                                                  100
                                                : 0;

                                            // Convert percentage to radians for SVG arc
                                            const spentRadians =
                                                (spentPercentage / 100) *
                                                2 *
                                                Math.PI;
                                            const radius = 70;
                                            const center = 80;

                                            // Calculate arc endpoints
                                            const endX =
                                                center +
                                                radius *
                                                    Math.cos(
                                                        spentRadians -
                                                            Math.PI / 2,
                                                    );
                                            const endY =
                                                center +
                                                radius *
                                                    Math.sin(
                                                        spentRadians -
                                                            Math.PI / 2,
                                                    );

                                            // Create large arc flag based on percentage
                                            const largeArcFlag =
                                                spentPercentage > 50
                                                    ? "1"
                                                    : "0";

                                            return (
                                                <>
                                                    <svg className="h-full w-full -rotate-90 transform">
                                                        {/* Background circle (Earned) */}
                                                        <circle
                                                            cx={center}
                                                            cy={center}
                                                            r={radius}
                                                            fill="none"
                                                            stroke="#4ade80"
                                                            strokeWidth="20"
                                                        />

                                                        {/* Spent arc */}
                                                        {totalSpent > 0 && (
                                                            <path
                                                                d={`M ${center} ${center - radius}
                                                                    A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}`}
                                                                fill="none"
                                                                stroke="#ef4444"
                                                                strokeWidth="20"
                                                            />
                                                        )}
                                                    </svg>
                                                    <div className="absolute inset-0 flex flex-col items-center justify-center text-center">
                                                        <div className="text-sm font-semibold text-gray-600">
                                                            {spentPercentage.toFixed(
                                                                1,
                                                            )}
                                                            % Used
                                                        </div>
                                                        <div className="text-xs text-gray-500">
                                                            {totalSpent} /{" "}
                                                            {totalEarned}
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        })()}
                                    </div>
                                </StatCard>
                            </div>
                        </StatsContainer>
                        <div className="flex flex-wrap gap-6">
                            <StatCard title="Credit Action Breakdown: PURCHASE">
                                <StatTable
                                    columns={creditActionCodeColumns}
                                    data={Object.entries(
                                        creditsAnalytics.purchase
                                            .codeBreakdown || {},
                                    ).map(([code, { count, amount }]) => ({
                                        code,
                                        count,
                                        amount,
                                        percentage:
                                            (count /
                                                creditsAnalytics.purchase
                                                    .count) *
                                            100,
                                    }))}
                                />
                            </StatCard>
                            <StatCard title="Credit Action Breakdown: SPEND">
                                <StatTable
                                    columns={creditActionCodeColumns}
                                    data={Object.entries(
                                        creditsAnalytics.spend.codeBreakdown ||
                                            {},
                                    ).map(([code, { count, amount }]) => ({
                                        code,
                                        count,
                                        amount,
                                        percentage:
                                            (count /
                                                creditsAnalytics.spend.count) *
                                            100,
                                    }))}
                                />
                            </StatCard>
                            <StatCard title="Credit Action Breakdown: EARN">
                                <StatTable
                                    columns={creditActionCodeColumns}
                                    data={Object.entries(
                                        creditsAnalytics.earn.codeBreakdown ||
                                            {},
                                    ).map(([code, { count, amount }]) => ({
                                        code,
                                        count,
                                        amount,
                                        percentage:
                                            (count /
                                                creditsAnalytics.earn.count) *
                                            100,
                                    }))}
                                />
                            </StatCard>
                            <StatCard title="Credit Action Breakdown: REFUND">
                                <StatTable
                                    columns={creditActionCodeColumns}
                                    data={Object.entries(
                                        creditsAnalytics.refund.codeBreakdown ||
                                            {},
                                    ).map(([code, { count, amount }]) => ({
                                        code,
                                        count,
                                        amount,
                                        percentage:
                                            (count /
                                                creditsAnalytics.refund.count) *
                                            100,
                                    }))}
                                />
                            </StatCard>
                            <StatCard title="Credit Action Breakdown: SIGN UP">
                                <StatTable
                                    columns={creditActionCodeColumns}
                                    data={Object.entries(
                                        creditsAnalytics.signUp.codeBreakdown ||
                                            {},
                                    ).map(([code, { count, amount }]) => ({
                                        code,
                                        count,
                                        amount,
                                        percentage:
                                            (count /
                                                creditsAnalytics.signUp.count) *
                                            100,
                                    }))}
                                />
                            </StatCard>
                            <StatCard title="Credit Action Breakdown: ADMIN">
                                <StatTable
                                    columns={creditActionCodeColumns}
                                    data={Object.entries(
                                        creditsAnalytics.admin.codeBreakdown ||
                                            {},
                                    ).map(([code, { count, amount }]) => ({
                                        code,
                                        count,
                                        amount,
                                        percentage:
                                            (count /
                                                creditsAnalytics.admin.count) *
                                            100,
                                    }))}
                                />
                            </StatCard>
                        </div>
                    </TabPanel>

                    <TabPanel>
                        <StatsContainer title="AI Feedback">
                            <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-6">
                                <StatCard
                                    title="Positive"
                                    value={`${aiFeedbackStats.thumbsUp}`}
                                    icon={<ThumbsUp size={16} />}
                                />
                                <StatCard
                                    title="Negative"
                                    value={`${aiFeedbackStats.thumbsDown}`}
                                    icon={<ThumbsDown size={16} />}
                                />
                            </div>
                            <div className="flex flex-wrap gap-6">
                                <StatCard title="Feedback Actions">
                                    <StatTable
                                        columns={aiFeedbackColumns}
                                        data={Object.entries(
                                            aiFeedbackStats.actions,
                                        ).map(([action, count]) => ({
                                            action,
                                            count,
                                        }))}
                                    />
                                </StatCard>
                                <StatCard title="Credit Action Breakdown">
                                    <StatTable
                                        columns={feedbackActionBreakdownColumns}
                                        data={Object.entries(
                                            aiFeedbackStats.creditActions ?? {},
                                        ).map(
                                            ([
                                                code,
                                                { positive, negative },
                                            ]) => ({
                                                code,
                                                positive,
                                                negative,
                                                count: positive + negative,
                                            }),
                                        )}
                                    />
                                </StatCard>
                            </div>
                        </StatsContainer>
                    </TabPanel>
                    <TabPanel>
                        <StatsContainer
                            title="User Profiles"
                            description="This section provides a breakdown of user activity based on their credit usage patterns. Each category highlights different user behaviors — from active spenders to those yet to explore key features. Understanding these groups can help you identify your most engaged users, spot potential growth opportunities, and tailor strategies to improve retention and conversion. Setting the dates will find users created within that time frame."
                        >
                            <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-5">
                                <StatCard
                                    title="Power Users"
                                    value={creditSpendUserProfile["Power User"]}
                                    icon={<Emoji emoji="💪" />}
                                    loading={isUsersLoading}
                                    description="Highly engaged users who actively spend most of their credits and explore multiple features"
                                    tooltip="Users who have spent 8 or more credits and engaged with 2 or more features. ➡️ Indicates heavy usage and strong feature engagement."
                                />
                                <StatCard
                                    title="High Value Users"
                                    value={
                                        creditSpendUserProfile[
                                            "High Value User"
                                        ]
                                    }
                                    icon={<Emoji emoji="💰" />}
                                    loading={isUsersLoading}
                                    description="Users who purchase credits and actively engage across features"
                                    tooltip="Users who have purchased 10 or more credits and spent at least 5 credits. ➡️ Indicates a buyer with notable spending activity."
                                />
                                <StatCard
                                    title="Happy Users"
                                    value={creditSpendUserProfile["Happy User"]}
                                    icon={<Emoji emoji="😊" />}
                                    loading={isUsersLoading}
                                    description="Consistent users who spend credits steadily across key features"
                                    tooltip="Users who have spent between 5 and 7 credits and engaged with at least 1 feature ➡️ Shows consistent credit spending and steady engagement."
                                />
                                <StatCard
                                    title="Casual Users"
                                    value={
                                        creditSpendUserProfile["Casual User"]
                                    }
                                    icon={<Emoji emoji="👀" />}
                                    loading={isUsersLoading}
                                    description="Occasional users who engage with minimal credit spending"
                                    tooltip="Users who have spent between 1 and 4 credits and engaged with 1 feature. ➡️ Indicates occasional engagement with minimal credit usage."
                                />
                                <StatCard
                                    title="New Users"
                                    value={creditSpendUserProfile["New User"]}
                                    icon={<Emoji emoji="👶" />}
                                    loading={isUsersLoading}
                                    description="Recently joined users still discovering the platform"
                                    tooltip="Users who joined in the last 7 days and have spent 2 or fewer credits. ➡️ Represents early-stage users still exploring the platform."
                                />
                                <StatCard
                                    title="Feature Explorers"
                                    value={
                                        creditSpendUserProfile[
                                            "Feature Explorer"
                                        ]
                                    }
                                    icon={<Emoji emoji="🔍" />}
                                    loading={isUsersLoading}
                                    description="Users who try multiple features but haven’t spent heavily yet."
                                    tooltip="Users who have engaged with 2 or more features and spent 4 or fewer credits. ➡️ Exploring features but not yet committed to spending credits."
                                />
                                <StatCard
                                    title="Inactive Users"
                                    value={
                                        creditSpendUserProfile["Inactive User"]
                                    }
                                    icon={<Emoji emoji="👎" />}
                                    loading={isUsersLoading}
                                    description="Users who haven’t spent credits in a while"
                                    tooltip="Users who haven’t spent credits in the last 10 days. ➡️ Indicates users who are inactive but could be re-engaged."
                                />
                                <StatCard
                                    title="Dormant Users"
                                    value={
                                        creditSpendUserProfile["Dormant User"]
                                    }
                                    icon={<Emoji emoji="💤" />}
                                    loading={isUsersLoading}
                                    description="Previously active users who haven’t spent credits in a while"
                                    tooltip="Users who previously spent credits but have had no spending activity in the last 10 days. ➡️ Indicates past engagement but recent inactivity."
                                />

                                <StatCard
                                    title="Credit Hoarders"
                                    value={
                                        creditSpendUserProfile["Credit Hoarder"]
                                    }
                                    icon={<Emoji emoji="🏦" />}
                                    loading={isUsersLoading}
                                    description="Users who accumulate credits but rarely spend them"
                                    tooltip="Users with 8 or more credits remaining and who have spent fewer than 2 credits. ➡️ These users are accumulating credits without spending them."
                                />

                                <StatCard
                                    title="Earners"
                                    value={creditSpendUserProfile["Earner"]}
                                    icon={<Emoji emoji="💸" />}
                                    loading={isUsersLoading}
                                    description="Users who frequently earn credits through referrals, surveys, or promotions"
                                    tooltip="Users who have earned 5 or more credits and spent fewer than 5 credits. ➡️ Shows engagement through earning methods rather than spending."
                                />
                                <StatCard
                                    title="Other"
                                    value={creditSpendUserProfile["Other"]}
                                    icon={<Emoji emoji="👥" />}
                                    loading={isUsersLoading}
                                    description="Users who don't fit into any of the other categories"
                                />
                            </div>
                        </StatsContainer>
                        <StatsContainer title="Credit Balance">
                            <StatTable
                                columns={creditBalanceColumns}
                                data={creditBalanceStats}
                            />
                        </StatsContainer>
                        <StatsContainer title="User Preferences">
                            <div className="flex flex-wrap gap-6">
                                <StatCard title="Industries">
                                    <StatTable
                                        columns={industryColumns}
                                        data={industryStats}
                                    />
                                </StatCard>
                                <StatCard title="Job Roles">
                                    <StatTable
                                        columns={jobRoleColumns}
                                        data={jobRoleStats}
                                    />
                                </StatCard>
                                <StatCard title="Experience Levels">
                                    <StatTable
                                        columns={experienceLevelColumns}
                                        data={experienceLevelStats}
                                    />
                                </StatCard>
                                <StatCard title="Job Types">
                                    <StatTable
                                        columns={jobTypeColumns}
                                        data={jobTypeStats}
                                    />
                                </StatCard>
                            </div>
                        </StatsContainer>
                    </TabPanel>

                    <TabPanel>
                        <StatsContainer title="Resumes">
                            <div className="flex flex-wrap gap-6">
                                <StatCard title="Column Layout">
                                    <StatTable
                                        columns={resumeLayoutColumns}
                                        data={resumeLayoutStats}
                                    />
                                </StatCard>
                                <StatCard
                                    title="Average Resumes per User"
                                    icon={<FileIcon size={16} />}
                                    loading={isResumesLoading}
                                    className="col-span-2"
                                >
                                    <StatTable
                                        columns={resumesPerUserColumns}
                                        data={Object.entries(
                                            resumesPerUser,
                                        ).map(([numResumes, count]) => ({
                                            numResumes,
                                            count,
                                            percentage:
                                                (count / allUsers.length) * 100,
                                        }))}
                                    />
                                </StatCard>
                            </div>
                        </StatsContainer>
                    </TabPanel>
                </TabPanels>
            </TabGroup>
        </div>
    );
};

const Emoji = ({ emoji }: { emoji: string }) => (
    <span className="text-2xl">{emoji}</span>
);
