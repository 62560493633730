import clsx from "clsx";
import { CircleCheck, Eye } from "lucide-react";
import { useCallback } from "react";
import { AdminResumeDetails } from "@/components/Admin/AdminResumeDetails";
import { Button } from "@/components/Button/Button";
import { Page } from "@/components/Page/Page";
import { AppText } from "@/components/Text/AppText";
import { useAppDispatch } from "@/hooks/types";
import { ReadOnlyResumeView } from "@/pages/Dashboard/ReadOnlyResumeView/ReadOnlyResumeView";
import { TemplateDetails } from "@/pages/Dashboard/TemplateDetails";
import { resumeToPages } from "@/pages/Examples/utils";
import { openModal } from "@/store/modal/slice";
import { Resume } from "@/types";
import { ModalTypes } from "@/types/modal";
import { GA_EVENT_ACTIONS, GA_EVENT_CATEGORIES } from "@/types/tracking";
import { getIsExamplesPage } from "@/utils/routes";
import { trackGAEvent } from "@/utils/tracking";

interface ResumeExampleProps {
    resume: Resume;
    onSelect?: () => void;
    isSelected?: boolean;
}

export const ResumeExample = ({
    resume,
    onSelect,
    isSelected,
}: ResumeExampleProps) => {
    const dispatch = useAppDispatch();
    const resumePages = resumeToPages(resume);
    const isExamplePage = getIsExamplesPage();
    const openResumePreview = useCallback(() => {
        dispatch(
            openModal({
                modalType: ModalTypes.RESUME_PREVIEW,
                props: { resume, resumePages, isResumeExample: true },
            }),
        );
        trackGAEvent(
            GA_EVENT_CATEGORIES.BUTTON,
            GA_EVENT_ACTIONS.CLICK,
            "Example resume previewed",
        );
    }, [dispatch, resume, resumePages]);

    return (
        <div className="flex flex-col rounded-lg bg-white">
            <li
                className={clsx(
                    "group relative cursor-pointer rounded-lg ring-2",
                    onSelect ? "resume-container-small" : "resume-container",
                    isSelected ? "ring-primary-700" : "ring-neutral-400",
                )}
            >
                <div
                    className={clsx(
                        "absolute inset-0 flex items-center justify-center opacity-0 transition-opacity duration-100 group-hover:opacity-100",
                        isSelected && "opacity-100",
                    )}
                >
                    {onSelect && isSelected && (
                        <Button
                            className="z-10 block"
                            onClick={onSelect}
                        >
                            <AppText variant="labelsbuttons">Selected</AppText>
                        </Button>
                    )}
                    {!onSelect && (
                        <Button
                            className="z-10 hidden lg:block"
                            onClick={openResumePreview}
                            leftIcon={<Eye size={16} />}
                        >
                            <AppText variant="labelsbuttons">View</AppText>
                        </Button>
                    )}
                </div>
                {isExamplePage && <AdminResumeDetails resume={resume} />}
                <div
                    className={clsx(
                        "group block overflow-hidden rounded-lg ring-2 ring-neutral-400",
                        onSelect ? "resume-item-small" : "resume-item",
                    )}
                    onClick={onSelect ? onSelect : openResumePreview}
                >
                    <Page
                        pageNumber={1}
                        overrideResumeStyles={resume.styles}
                    >
                        <ReadOnlyResumeView resume={resume} />
                    </Page>
                    <div
                        className={clsx(
                            "absolute inset-0 opacity-0 transition-opacity duration-300 group-hover:opacity-100",
                            isSelected && "opacity-100",
                            onSelect ? "bg-black/10" : "bg-black/40",
                        )}
                    />
                </div>
                <div
                    className={clsx(
                        "absolute right-2 flex items-center gap-1 rounded-lg bg-primary-100 px-2 py-1 text-primary-700",
                        onSelect ? "bottom-[90px]" : "bottom-[106px]",
                    )}
                >
                    <CircleCheck size={16} />
                    <AppText variant="labelsbuttons">ATS</AppText>
                </div>
                <div
                    className={clsx(
                        "absolute bottom-0 left-0 right-0 flex items-center justify-between gap-4 border-t-2 border-neutral-400 bg-white ",
                        onSelect ? "p-3" : "p-4",
                    )}
                >
                    <div className="min-w-0 flex-1 overflow-hidden">
                        <TemplateDetails
                            name={resume.name}
                            tags={resume.tags}
                            categories={resume.categories}
                            size={onSelect ? "small" : "default"}
                        />
                    </div>
                </div>
            </li>
        </div>
    );
};
