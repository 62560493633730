import { CSSProperties } from "react";
import { SectionBody } from "@/components/Section/SectionBody";
import { SectionHeader } from "@/components/Section/SectionHeader";
import { GLOBAL_STYLE_KEY, resumeThemes } from "@/constants/resume";
import { useAppSelector } from "@/hooks/types";
import { SectionContainer } from "@/pages/ResumeBuilder/SectionContainer";
import { getPages } from "@/store/pages/selectors";
import {
    getActiveResumeGlobalStyles,
    getActiveResumeSectionById,
} from "@/store/resume/selectors";
import { getSectionConfigById } from "@/store/sections/selectors";
import {
    ColumnLayout,
    PageMarginSize,
    ResumeStyles,
    SectionDetails,
    SectionSpacingSize,
} from "@/types/resume";

interface ReadOnlySectionProps {
    sectionId: string;
    shouldMeasure?: boolean;
    overrideSectionStyles?: CSSProperties;
    overrideSectionDetails?: SectionDetails;
    resumeId?: string;
    overrideResumeStyles?: ResumeStyles;
    previewWidth?: string;
    overrideColumnLayout?: ColumnLayout;
    pageNumber: number;
    isDefaultMeasurement?: boolean;
}

export const ReadOnlySection = ({
    sectionId,
    shouldMeasure,
    overrideSectionStyles,
    resumeId,
    overrideSectionDetails,
    overrideResumeStyles,
    previewWidth,
    pageNumber,
    isDefaultMeasurement,
}: ReadOnlySectionProps) => {
    const activeSectionDetails = useAppSelector(state =>
        getActiveResumeSectionById(state, sectionId),
    );
    const sectionConfig = useAppSelector(state =>
        getSectionConfigById(
            state,
            overrideSectionDetails?.sectionConfigId ||
                activeSectionDetails?.sectionConfigId,
        ),
    );
    const pages = useAppSelector(getPages);
    const activeResumeStyleConfig = useAppSelector(getActiveResumeGlobalStyles);
    const pageConfig = pages.find(page => page.pageNumber === pageNumber);
    const pageSectionConfig = pageConfig?.sections.find(
        pageSection => pageSection.sectionId === sectionId,
    );
    const pageItems = pageConfig?.items;
    const itemsHasSectionHeaderMeasurement = pageItems?.find(
        item => item.type === "header" && item.section === sectionId,
    );
    // Only show the header if this section is the start of the items as well
    const showSectionHeader = pageSectionConfig
        ? itemsHasSectionHeaderMeasurement
        : true; // True because we are building the pages for the first time

    const themeColour =
        overrideResumeStyles?.[GLOBAL_STYLE_KEY]?.themeColor ||
        activeResumeStyleConfig.themeColor;

    const originalBgColour = sectionConfig?.isStaticHeader
        ? overrideSectionStyles?.backgroundColor ||
          activeResumeStyleConfig.backgroundColor
        : undefined;

    const themeBackgroundColour = sectionConfig?.isStaticHeader
        ? resumeThemes.find(
              theme => theme.profileBackgroundColor === themeColour,
          )?.profileBackgroundColor
        : undefined;

    return (
        <div
            className="relative"
            style={previewWidth ? { gridColumn: previewWidth } : undefined}
        >
            <SectionContainer
                isStaticHeader={!!sectionConfig?.isStaticHeader}
                activeItemId={null}
                sectionId={sectionId}
                backgroundColour={themeBackgroundColour || originalBgColour}
                sectionSpacingSize={
                    overrideResumeStyles?.[GLOBAL_STYLE_KEY]
                        ?.sectionSpacingSize ||
                    activeResumeStyleConfig.sectionSpacingSize ||
                    SectionSpacingSize.MD
                }
                pageMarginSize={
                    overrideResumeStyles?.[GLOBAL_STYLE_KEY]?.pageMarginSize ||
                    activeResumeStyleConfig.pageMarginSize ||
                    PageMarginSize.MD
                }
                pageNumber={pageNumber}
            >
                {showSectionHeader && (
                    <SectionHeader
                        sectionId={sectionId}
                        isEditable={false}
                        resumeId={resumeId}
                        overrideSectionDetails={overrideSectionDetails}
                        overrideResumeStyles={overrideResumeStyles}
                        shouldMeasure={shouldMeasure}
                        isDefaultMeasurement={isDefaultMeasurement}
                    />
                )}
                <div className="break-words">
                    <SectionBody
                        isEditable={false}
                        sectionId={sectionId}
                        resumeId={resumeId}
                        overrideSectionDetails={overrideSectionDetails}
                        overrideResumeStyles={overrideResumeStyles}
                        shouldMeasure={shouldMeasure}
                        pageNumber={pageNumber}
                        isDefaultMeasurement={isDefaultMeasurement}
                    />
                </div>
            </SectionContainer>
        </div>
    );
};
