import { PlusIcon } from "lucide-react";
import Chip from "@/components/Chip/Chip";
import { AppText } from "@/components/Text/AppText";

export const CreateResumeButton = ({
    onClick,
    reachedResumeLimit,
}: {
    onClick: () => void;
    reachedResumeLimit: boolean;
}) => (
    <div className="flex flex-col">
        <li
            className="resume-container relative flex cursor-pointer flex-row items-center justify-center gap-1 rounded-lg bg-white ring-2 ring-inset ring-neutral-400 duration-100 ease-in hover:ring-primary-700"
            onClick={() => onClick()}
        >
            {reachedResumeLimit ? (
                <Chip type="gradient">
                    <PlusIcon
                        size={16}
                        className="text-primary-700"
                    />
                    <AppText
                        variant="labelsbuttons"
                        className="py-1"
                        applyGradient
                    >
                        Increase Resume Limit
                    </AppText>
                </Chip>
            ) : (
                <div className="flex items-center justify-center gap-2 text-primary-700">
                    <PlusIcon size={16} />
                    <AppText
                        variant="labelsbuttons"
                        className="pt-[1px]"
                    >
                        New Resume
                    </AppText>
                </div>
            )}
        </li>
    </div>
);
