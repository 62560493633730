import { useState, useEffect } from "react";
import aiApi from "@/api/ai";
import { AIModelConfig } from "@/pages/Admin/AI/AIPreferencesConfig";
import { ModelConfigSettings } from "@/pages/Admin/AI/ModelConfigSettings";
import { AIToolHeader } from "@/pages/Admin/AI/components/AIToolHeader";
import { AiResponsePreview } from "@/pages/Admin/AI/components/AiResponsePreview";
import { AiHistoryEntry } from "@/types/ai";
import { getIsProductionEnv } from "@/utils/env";

interface TextAreaStats {
    text: string;
    wordCount: number;
}

type AiResponse = Record<string, any>;

interface AdminResumeRewriterProps {
    modelConfig: AIModelConfig;
    setModelConfig: (modelConfig: AIModelConfig) => void;
    addToHistory: (entry: AiHistoryEntry) => void;
}

export const AdminResumeRewriter = ({
    modelConfig,
    setModelConfig,
    addToHistory,
}: AdminResumeRewriterProps) => {
    const isProdEnv = getIsProductionEnv();
    const [resumeInput, setResumeInput] = useState<TextAreaStats>({
        text: "",
        wordCount: 0,
    });
    const [jobDescription, setJobDescription] = useState<TextAreaStats>({
        text: "",
        wordCount: 0,
    });
    const [aiResponse, setAiResponse] = useState<AiResponse | null>(null);
    const [isGenerating, setIsGenerating] = useState(false);
    const [isResumeExpanded, setIsResumeExpanded] = useState(false);
    const [isJobDescriptionExpanded, setIsJobDescriptionExpanded] =
        useState(false);
    const [error, setError] = useState<string | null>(null);
    const [timer, setTimer] = useState<number>(0);
    const [timerActive, setTimerActive] = useState(false);

    const calculateWordCount = (text: string): number => {
        return text
            .trim()
            .split(/\s+/)
            .filter(word => word.length > 0).length;
    };

    const handleTextChange = (
        text: string,
        setter: (stats: TextAreaStats) => void,
    ) => {
        setter({
            text,
            wordCount: calculateWordCount(text),
        });
    };

    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (timerActive) {
            interval = setInterval(() => {
                setTimer(prev => prev + 0.1);
            }, 100);
        }
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [timerActive]);

    const handleClear = () => {
        setResumeInput({ text: "", wordCount: 0 });
        setJobDescription({ text: "", wordCount: 0 });
        setAiResponse(null);
        setError(null);
        setTimer(0);
        setTimerActive(false);
    };

    const handleGenerate = async () => {
        if (isProdEnv) {
            setError(
                "This feature is not available for testing in production. Test on staging",
            );
            return;
        }
        setIsGenerating(true);
        setError(null);
        setTimer(0);
        setTimerActive(true);
        try {
            const response = await aiApi.generateResumeRewriter({
                resume: resumeInput.text,
                jobDescription: jobDescription.text,
                model: modelConfig.model,
                temperature: modelConfig.temperature,
            });
            setAiResponse(response.output);
            addToHistory({
                id: Date.now().toString(),
                timestamp: new Date().toISOString(),
                tool: "ai-resume-rewriter",
                input: {
                    resume: resumeInput.text,
                    jobDescription: jobDescription.text,
                    temperature: modelConfig.temperature,
                },
                output: response.output,
                modelUsed: modelConfig.model,
                duration: timer,
            });
        } catch (error) {
            console.error("Error generating response:", error);
            setAiResponse(null);
            setError(
                error instanceof Error
                    ? error.message
                    : "An unknown error occurred",
            );
        } finally {
            setIsGenerating(false);
            setTimerActive(false);
        }
    };

    const getExpandButtonText = (isExpanded: boolean) =>
        isExpanded ? "Collapse" : "Expand";

    return (
        <>
            <AIToolHeader activeTab="ai-resume-rewriter" />
            <div className="grid grid-cols-12 gap-8 px-8">
                {/* Left Column - Inputs */}
                <div className="col-span-5 space-y-6">
                    <ModelConfigSettings
                        modelConfig={modelConfig}
                        setModelConfig={setModelConfig}
                    />
                    <div className="space-y-2">
                        <div className="flex items-center justify-between">
                            <label className="block text-sm font-medium text-gray-700">
                                Resume Text *
                            </label>
                            <button
                                onClick={() =>
                                    setIsResumeExpanded(!isResumeExpanded)
                                }
                                className="text-xs text-primary-600 hover:text-primary-700"
                            >
                                {getExpandButtonText(isResumeExpanded)}
                            </button>
                        </div>
                        <div className="relative">
                            <textarea
                                className={`w-full rounded-lg border border-gray-300 p-4 text-xs leading-tight transition-all duration-200 ${
                                    isResumeExpanded
                                        ? "min-h-[500px]"
                                        : "min-h-[200px]"
                                }`}
                                placeholder="Paste resume text here..."
                                value={resumeInput.text}
                                onChange={e =>
                                    handleTextChange(
                                        e.target.value,
                                        setResumeInput,
                                    )
                                }
                            />
                            <div className="absolute bottom-2 right-2 text-xs text-gray-500">
                                Words: {resumeInput.wordCount}
                            </div>
                        </div>
                    </div>

                    <div className="space-y-2">
                        <div className="flex items-center justify-between">
                            <label className="block text-sm font-medium text-gray-700">
                                Job Description (Optional)
                            </label>
                            <button
                                onClick={() =>
                                    setIsJobDescriptionExpanded(
                                        !isJobDescriptionExpanded,
                                    )
                                }
                                className="text-xs text-primary-600 hover:text-primary-700"
                            >
                                {getExpandButtonText(isJobDescriptionExpanded)}
                            </button>
                        </div>
                        <div className="relative">
                            <textarea
                                className={`w-full rounded-lg border border-gray-300 p-4 text-xs leading-tight transition-all duration-200 ${
                                    isJobDescriptionExpanded
                                        ? "min-h-[500px]"
                                        : "min-h-[200px]"
                                }`}
                                placeholder="Paste job description here..."
                                value={jobDescription.text}
                                onChange={e =>
                                    handleTextChange(
                                        e.target.value,
                                        setJobDescription,
                                    )
                                }
                            />
                            <div className="absolute bottom-2 right-2 text-xs text-gray-500">
                                Words: {jobDescription.wordCount}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Right Column - AI Response */}
                <div className="col-span-7 rounded-lg border p-4">
                    <div className="mb-4 flex items-center justify-between space-x-4">
                        <h2 className="text-xl font-semibold">AI Output</h2>
                        <div className="flex items-center space-x-2">
                            <button
                                onClick={handleClear}
                                className="rounded-md bg-gray-100 px-4 py-2 text-sm font-medium text-gray-600 hover:bg-gray-200"
                            >
                                Clear
                            </button>
                            <button
                                onClick={handleGenerate}
                                disabled={!resumeInput.text || isGenerating}
                                className={`rounded-md px-4 py-2 text-sm font-medium text-white ${
                                    !resumeInput.text || isGenerating
                                        ? "cursor-not-allowed bg-primary-400"
                                        : "bg-primary-600 hover:bg-primary-700"
                                }`}
                            >
                                {isGenerating ? "Generating..." : "Generate"}
                            </button>
                            <span className="text-xs text-gray-500">
                                {timer > 0 && `${timer.toFixed(1)}s`}
                            </span>
                        </div>
                    </div>

                    <div className="min-h-[450px] overflow-auto rounded-lg border border-gray-300 bg-white p-4">
                        {isGenerating ? (
                            <div className="flex h-full items-center justify-center">
                                <p className="text-sm text-gray-500">
                                    Generating response...
                                </p>
                            </div>
                        ) : aiResponse ? (
                            <AiResponsePreview
                                aiResponse={aiResponse}
                                tool="ai-resume-rewriter"
                            />
                        ) : (
                            <div className="flex h-full items-center justify-center">
                                <p className="text-sm text-gray-500">
                                    AI response will appear here
                                </p>
                            </div>
                        )}
                        {error && (
                            <div className="mt-4 rounded-lg bg-red-50 p-4">
                                <p className="text-sm text-red-500">{error}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
