import Chip from "@/components/Chip/Chip";
import { AppText } from "@/components/Text/AppText";
import { Tooltip } from "@/components/Tooltip/Tooltip";
import { TooltipContent } from "@/components/Tooltip/TooltipContent";
import { TooltipTrigger } from "@/components/Tooltip/TooltipTrigger";
import { useAppSelector } from "@/hooks/types";
import { getIsModalOpen } from "@/store/modal/selectors";
import { ModalTypes } from "@/types/modal";
import { getIsExamplesPage } from "@/utils/routes";

interface TemplateDetailsProps {
    name: string;
    updatedAt?: Date;
    tags?: string[];
    categories?: string[];
    size?: "small" | "default";
    noChips?: boolean;
}

export const TemplateDetails = ({
    name,
    updatedAt,
    tags,
    categories,
    size = "default",
    noChips = false,
}: TemplateDetailsProps) => {
    const allChips = noChips ? [] : [...(tags || []), ...(categories || [])];
    const isExamplePage = getIsExamplesPage();
    const isNewResumeModalOpen = useAppSelector(
        getIsModalOpen(ModalTypes.NEW_RESUME),
    );
    return (
        <>
            <Tooltip placement="top">
                <TooltipTrigger className="w-full text-left">
                    <div className="group relative w-full overflow-hidden">
                        <AppText
                            variant={
                                size === "small"
                                    ? "contextheading"
                                    : "subheadings"
                            }
                            className="block cursor-default truncate"
                        >
                            {name}
                        </AppText>
                    </div>
                </TooltipTrigger>
                <TooltipContent style={{ maxWidth: "300px" }}>
                    <AppText variant="footnote">{name}</AppText>
                </TooltipContent>
            </Tooltip>
            {updatedAt && (
                <AppText
                    variant="labelsbuttons"
                    className="truncate"
                >
                    Last edited: {updatedAt.toLocaleDateString()}
                </AppText>
            )}
            {!noChips && (isExamplePage || isNewResumeModalOpen) && (
                <div className="flex min-h-[26px] flex-wrap gap-1">
                    {allChips.map(chip => (
                        <Chip
                            key={chip}
                            type="secondary"
                            className="!px-2"
                        >
                            <AppText
                                variant="footnote"
                                className="!font-medium"
                            >
                                {chip}
                            </AppText>
                        </Chip>
                    ))}
                </div>
            )}
        </>
    );
};
