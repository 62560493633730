import { ChevronDown, ChevronUp, ArrowLeft } from "lucide-react";
import { useState } from "react";
import { AiResponsePreview } from "./AiResponsePreview";
import { AiHistoryEntry } from "@/types/ai";

interface AiHistoryProps {
    history: AiHistoryEntry[];
    onSelectEntry: (entry: AiHistoryEntry) => void;
    onClearHistory: () => void;
}

export const AiHistory = ({
    history,
    onSelectEntry,
    onClearHistory,
}: AiHistoryProps) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [selectedEntry, setSelectedEntry] = useState<AiHistoryEntry | null>(
        null,
    );

    const handleEntryClick = (entry: AiHistoryEntry) => {
        setSelectedEntry(entry);
        onSelectEntry(entry);
    };

    const handleBackToList = () => {
        setSelectedEntry(null);
    };

    const renderHistoryList = () => (
        <div className="max-h-[600px] overflow-y-auto px-8 pb-4">
            {history.map(entry => (
                <button
                    key={entry.id}
                    onClick={() => handleEntryClick(entry)}
                    className="w-full border-b border-gray-200 p-4 text-left hover:bg-gray-50"
                >
                    <div className="flex items-center justify-between">
                        <span className="text-sm font-medium">
                            {entry.tool}
                        </span>
                        <div className="mt-1 flex gap-2 text-xs text-gray-500">
                            {entry.modelUsed && (
                                <span>Model: {entry.modelUsed}</span>
                            )}
                            {!!entry.duration && (
                                <span>
                                    Generated in {entry.duration.toFixed(2)}s
                                </span>
                            )}
                            {entry.input.temperature && (
                                <span>Temp: {entry.input.temperature}</span>
                            )}
                        </div>
                        <span className="text-xs text-gray-500">
                            {new Date(entry.timestamp).toLocaleString("en-GB", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                            })}
                        </span>
                    </div>
                    {!!entry.input.context && (
                        <div className="mt-2 line-clamp-2 text-xs text-gray-600">
                            {entry.input.context}
                        </div>
                    )}
                    {!!entry.input.resume && (
                        <div className="mt-2 line-clamp-2 text-xs text-gray-600">
                            {entry.input.resume}
                        </div>
                    )}
                    {!!entry.input.jobDescription && (
                        <div className="mt-2 line-clamp-2 text-xs text-gray-600">
                            {entry.input.jobDescription}
                        </div>
                    )}
                </button>
            ))}
        </div>
    );

    const renderEntryDetail = (entry: AiHistoryEntry) => (
        <div className="px-8 pb-4">
            <div className="flex justify-between">
                <button
                    onClick={handleBackToList}
                    className="mb-4 flex items-center gap-2 text-sm text-gray-600 hover:text-gray-900"
                >
                    <ArrowLeft className="h-4 w-4" />
                    Back to history
                </button>
                <div className="flex items-center gap-4 text-xs text-gray-500">
                    <span>Generated in {entry.duration?.toFixed(2)}s</span>
                    {entry.input.temperature && (
                        <span>Temperature: {entry.input.temperature}</span>
                    )}
                    {entry.modelUsed && <span>Model: {entry.modelUsed}</span>}
                </div>
            </div>

            <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <h3 className="text-sm font-medium text-gray-900">
                            Input
                        </h3>
                        {entry.input.context && (
                            <div className="mt-2 whitespace-pre-wrap rounded-md border p-3 text-xs text-gray-600">
                                {entry.input.context}
                            </div>
                        )}
                        {entry.input.resume && (
                            <div className="mt-2 max-h-[300px] overflow-y-auto whitespace-pre-wrap rounded-md border p-3 text-xs text-gray-600">
                                {entry.input.resume}
                            </div>
                        )}
                        {entry.input.jobDescription && (
                            <div className="mt-2 max-h-[300px] overflow-y-auto whitespace-pre-wrap rounded-md border p-3 text-xs text-gray-600">
                                {entry.input.jobDescription}
                            </div>
                        )}
                        {entry.input.jobRoles &&
                            entry.input.jobRoles.length > 0 && (
                                <div className="mt-2 text-xs text-gray-600">
                                    <span className="font-medium">
                                        Job Roles:
                                    </span>{" "}
                                    {entry.input.jobRoles.join(", ")}
                                </div>
                            )}
                        {entry.input.industries &&
                            entry.input.industries.length > 0 && (
                                <div className="mt-2 text-xs text-gray-600">
                                    <span className="font-medium">
                                        Industries:
                                    </span>{" "}
                                    {entry.input.industries.join(", ")}
                                </div>
                            )}
                        {entry.input.experience && (
                            <div className="mt-2 text-xs text-gray-600">
                                <span className="font-medium">Experience:</span>{" "}
                                {entry.input.experience}
                            </div>
                        )}
                    </div>

                    <div>
                        <h3 className="text-sm font-medium text-gray-900">
                            Output
                        </h3>
                        <div className="mt-2 overflow-y-auto whitespace-pre-wrap rounded-md border p-3 text-xs text-gray-600">
                            <AiResponsePreview
                                aiResponse={entry.output}
                                temperature={entry.input.temperature}
                                model={entry.modelUsed}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className="fixed bottom-0 left-0 right-0 z-50">
            <div className="mx-auto max-w-[1400px]">
                <div className="relative rounded-t-lg border bg-white shadow-lg">
                    <div className="flex w-full items-center justify-between px-8 py-4 hover:bg-gray-50">
                        <div className="flex items-center gap-4">
                            <h2 className="text-lg font-semibold">
                                Generation History
                                {selectedEntry && " • Details"}
                            </h2>
                            {!selectedEntry && history.length > 0 && (
                                <button
                                    onClick={e => {
                                        e.stopPropagation();
                                        if (
                                            window.confirm(
                                                "Are you sure you want to clear the history?",
                                            )
                                        ) {
                                            onClearHistory();
                                        }
                                    }}
                                    className="rounded-md bg-red-50 px-2 py-1 text-xs text-red-600 hover:bg-red-100"
                                >
                                    Clear History
                                </button>
                            )}
                        </div>
                        <button
                            onClick={() => setIsExpanded(!isExpanded)}
                            className="p-1"
                        >
                            {isExpanded ? (
                                <ChevronDown className="h-5 w-5" />
                            ) : (
                                <ChevronUp className="h-5 w-5" />
                            )}
                        </button>
                    </div>

                    <div
                        className={`transition-all duration-300 ${
                            isExpanded
                                ? "max-h-[700px] overflow-y-scroll"
                                : "max-h-0 overflow-y-hidden"
                        }`}
                    >
                        {selectedEntry
                            ? renderEntryDetail(selectedEntry)
                            : renderHistoryList()}
                    </div>
                </div>
            </div>
        </div>
    );
};
