import { useState } from "react";
import { Dropdown } from "@/components/Dropdown/Dropdown";
import {
    getJobDescriptionExample,
    jobDescriptionExampleOptions,
} from "@/components/Modals/NewResumeModal/constants";
import { AppText } from "@/components/Text/AppText";
import { TextArea } from "@/components/form/Textarea";
import { translate } from "@/helper/translate";
import { trackPostHogEvent } from "@/utils/tracking";

interface ImportJobDetailsProps {
    jobDescription: string;
    onJobDescriptionChange: (description: string) => void;
    onNext: () => void;
    jobDescriptionError: string;
}

export const ImportJobDetails = ({
    jobDescription,
    onJobDescriptionChange,
    jobDescriptionError,
}: ImportJobDetailsProps) => {
    const [activeJobDescription, setActiveJobDescription] = useState<string>(
        "select-job-description",
    );

    const onJobDescriptionExampleChange = (id: string) => {
        setActiveJobDescription(id);
        onJobDescriptionChange(getJobDescriptionExample(id));
        trackPostHogEvent("job_description_example_selected", {
            feature_name: "new_resume",
            example_selected: id,
        });
    };

    const handleManualJobDescriptionChange = (value: string) => {
        setActiveJobDescription("select-job-description");
        onJobDescriptionChange(value);
    };

    return (
        <div className="flex-1">
            <AppText
                variant="regular"
                className="mb-6 text-neutral-900"
            >
                Want a job-winning resume? Paste the job description below, and{" "}
                <AppText
                    variant="regular"
                    applyGradient
                    className="inline-block !font-semibold"
                >
                    Smart Match
                </AppText>{" "}
                will tailor your resume to highlight the most relevant skills
                and experience—helping you stand out to recruiters and applicant
                tracking systems (ATS).
            </AppText>
            <div className="flex flex-col gap-4">
                <div>
                    <div className="mb-2 flex flex-col gap-2 sm:mb-4 sm:flex-row sm:items-center sm:justify-between">
                        <AppText
                            variant="labelsbuttons"
                            className="block text-neutral-700"
                        >
                            Job Description (Optional but highly recommended for
                            best results)
                        </AppText>
                        <Dropdown
                            items={jobDescriptionExampleOptions}
                            handleChange={onJobDescriptionExampleChange}
                            activeId={activeJobDescription}
                            className="w-full sm:w-64"
                            title={
                                jobDescriptionExampleOptions.find(
                                    option =>
                                        option.id === activeJobDescription,
                                )?.title ?? "Example Job Description"
                            }
                        />
                    </div>
                    <TextArea
                        className="h-64 w-full bg-neutral-100 !font-normal text-neutral-600 !ring-neutral-100"
                        placeholder={`Paste the job description here to ${translate("optimise", "lower")} your resume...`}
                        value={jobDescription}
                        onChange={e => {
                            handleManualJobDescriptionChange(e.target.value);
                        }}
                        name="jobDescription"
                        id="jobDescription"
                        showCounter={jobDescription.length >= 9000}
                        maxLength={10000}
                    />
                    {jobDescriptionError && (
                        <AppText
                            variant="footnote"
                            className="mt-2 text-red-500"
                        >
                            {jobDescriptionError}
                        </AppText>
                    )}
                </div>
            </div>
        </div>
    );
};
