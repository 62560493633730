import { InfoTooltip } from "@/components/InfoTooltip/InfoTooltip";
import { AIModelConfig } from "@/pages/Admin/AI/AIPreferencesConfig";
import { AIModel } from "@/types/ai";

export const ModelConfigSettings = ({
    modelConfig,
    setModelConfig,
    includeRewriterModel = false,
    includeFormatterModel = false,
}: {
    modelConfig: AIModelConfig;
    setModelConfig: (modelConfig: AIModelConfig) => void;
    includeRewriterModel?: boolean;
    includeFormatterModel?: boolean;
}) => {
    const onModelSelect = (modelKey: keyof AIModelConfig, model: AIModel) => {
        setModelConfig({
            ...modelConfig,
            [modelKey]: model,
        });
    };

    return (
        <div className="grid grid-cols-4 gap-4">
            {includeRewriterModel && (
                <ModelSelector
                    value={modelConfig.overrideRewriteModel}
                    label="Rewrite Model"
                    onModelSelect={model =>
                        onModelSelect("overrideRewriteModel", model)
                    }
                />
            )}
            {includeFormatterModel && (
                <ModelSelector
                    value={modelConfig.overrideFormatModel}
                    label="Format Model"
                    onModelSelect={model =>
                        onModelSelect("overrideFormatModel", model)
                    }
                />
            )}
            {!includeRewriterModel && !includeFormatterModel && (
                <ModelSelector
                    value={modelConfig.model}
                    label="Model"
                    onModelSelect={model => onModelSelect("model", model)}
                />
            )}
            {!includeRewriterModel && !includeFormatterModel && (
                <TemperatureInput
                    label="Temp"
                    value={modelConfig.temperature}
                    onChange={value =>
                        setModelConfig({ ...modelConfig, temperature: value })
                    }
                />
            )}
            {includeRewriterModel && (
                <TemperatureInput
                    label="Rewrite Temp"
                    value={modelConfig.rewriteTemperature}
                    onChange={value =>
                        setModelConfig({
                            ...modelConfig,
                            rewriteTemperature: value,
                        })
                    }
                />
            )}
            {includeFormatterModel && (
                <TemperatureInput
                    label="Format Temp"
                    value={modelConfig.formatTemperature}
                    onChange={value =>
                        setModelConfig({
                            ...modelConfig,
                            formatTemperature: value,
                        })
                    }
                />
            )}
        </div>
    );
};

const ModelSelector = ({
    onModelSelect,
    label = "Model",
    value,
}: {
    onModelSelect: (model: AIModel) => void;
    label?: string;
    value: string;
}) => (
    <div className="col-span-2 space-y-2">
        <label className="block text-sm font-medium text-gray-700">
            {label}
        </label>
        <select
            value={value}
            onChange={e => onModelSelect(e.target.value as AIModel)}
            className="w-full rounded-lg border border-gray-300 p-2 text-sm"
        >
            <option value="gpt-4o-mini">GPT-4o Mini</option>
            <option value="gemini-2.0-flash-lite">Gemini 2.0 Flash Lite</option>
            <option value="gemini-2.0-flash">Gemini 2.0 Flash</option>
            <option value="gemini-1.5-flash-8b">Gemini 1.5 Flash 8B</option>
            {/* <option value="mistral-small-25.01">Mistral Small 25.01</option> */}
        </select>
    </div>
);

const TemperatureInput = ({
    value,
    onChange,
    label = "Temp",
}: {
    value: number;
    onChange: (value: number) => void;
    label?: string;
}) => (
    <div className="space-y-2">
        <div className="flex items-center space-x-1 text-sm">
            <span className="block leading-tight">{label}</span>
            <InfoTooltip
                content="Controls randomness in the output. Lower values (0.0) make the output more focused, while higher values (1.0) make it more creative. Normal ChatGPT is 0.7."
                position="bottom"
            />
        </div>
        <input
            type="number"
            value={value}
            onChange={e => onChange(parseFloat(e.target.value))}
            step="0.1"
            min="0"
            max="1"
            className="w-full rounded-lg border border-gray-300 p-2 text-sm"
        />
    </div>
);
