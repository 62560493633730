import { pdf } from "@react-pdf/renderer";
import React, { useEffect, useRef, useState } from "react";
import {
    pdfjs,
    Document as ReactPdfDocumentFile,
    Page as ReactPdfPageCanvas,
} from "react-pdf";
import { Alert } from "@/components/Alert/Alert";
import { loadFonts } from "@/components/PdfDocument/utils/loadFonts";
import { GLOBAL_STYLE_KEY } from "@/constants/resume";
import { Resume } from "@/types";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/build/pdf.worker.min.mjs",
    import.meta.url,
).toString();

interface PdfDocumentPreviewProps {
    children: React.ReactNode;
    onLoadSuccess: () => void;
    scale: number;
    loadingComponent?: React.ReactNode;
    numberOfPages: number;
    resume: Resume;
}

/**
 * This component acts as the generator for the PDF document as well
 * as the preview for the PDF document.
 * The children prop is expected to be a React PDF renderer Document component.
 * The pages should match the number of pages in the document.
 */
export const PdfDocumentPreview = ({
    children,
    onLoadSuccess,
    scale,
    loadingComponent,
    numberOfPages,
    resume,
}: PdfDocumentPreviewProps) => {
    const [pdfUrl, setPdfUrl] = useState<string | null>(null);
    const hasLoaded = useRef(false);
    const [error, setError] = useState<Error | null>(null);
    const [fontsLoaded, setFontsLoaded] = useState(false);
    const [documentLoaded, setDocumentLoaded] = useState(false);

    useEffect(() => {
        if (resume?.styles[GLOBAL_STYLE_KEY]?.fontFamily) {
            loadFonts(resume?.styles[GLOBAL_STYLE_KEY].fontFamily)
                .then(() => {
                    setFontsLoaded(true);
                })
                .catch(err => {
                    setError(err);
                    setFontsLoaded(true);
                });
        }
    }, [fontsLoaded, resume?.styles]);

    useEffect(() => {
        if (documentLoaded && fontsLoaded) {
            onLoadSuccess();
        }
    }, [documentLoaded, fontsLoaded, onLoadSuccess]);

    useEffect(() => {
        if (hasLoaded.current || !fontsLoaded) return;
        const child = React.Children.only(children);

        pdf(child as React.ReactElement)
            .toBlob()
            .then(blob => {
                setPdfUrl(URL.createObjectURL(blob));
                hasLoaded.current = true;
            })
            .catch(err => {
                console.error(JSON.stringify(err));
                setError(err);
            });

        return () => {
            if (pdfUrl) {
                URL.revokeObjectURL(pdfUrl);
            }
        };
    }, [children, fontsLoaded, pdfUrl]);

    if (error) {
        return (
            <Alert
                className="mt-10"
                message="Something went wrong. Please refresh and try again."
                type="error"
            />
        );
    }

    return (
        <ReactPdfDocumentFile
            file={pdfUrl}
            onLoadSuccess={() => {
                setDocumentLoaded(true);
            }}
            loading={loadingComponent}
            noData={loadingComponent}
        >
            {Array.from({ length: numberOfPages }, (_, index) => (
                <div
                    key={index}
                    style={{
                        marginBottom:
                            index === numberOfPages - 1 ? "0" : "20px",
                    }}
                >
                    <ReactPdfPageCanvas
                        renderMode="canvas"
                        pageNumber={index + 1}
                        scale={scale}
                        renderAnnotationLayer={false}
                        renderForms={false}
                        renderTextLayer={false}
                        loading={loadingComponent}
                    />
                </div>
            ))}
        </ReactPdfDocumentFile>
    );
};
