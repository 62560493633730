import clsx from "clsx";
import { ChangeEvent, useState, useEffect } from "react";
import { AppText } from "@/components/Text/AppText";

interface TextAreaProps
    extends Omit<
        React.TextareaHTMLAttributes<HTMLTextAreaElement>,
        "onChange"
    > {
    label?: string;
    name: string;
    id: string;
    defaultValue?: string;
    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    className?: string;
    showCounter?: boolean;
    maxLength?: number;
}

export const TextArea = ({
    label,
    name,
    id,
    defaultValue,
    onChange,
    className,
    showCounter,
    maxLength,
    ...props
}: TextAreaProps) => {
    const [charCount, setCharCount] = useState(defaultValue?.length || 0);

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setCharCount(e.target.value.length);
        onChange(e);
    };

    useEffect(() => {
        setCharCount(defaultValue?.length || 0);
    }, [defaultValue]);

    return (
        <div className="relative">
            {label && (
                <label
                    htmlFor="comment"
                    className="block text-sm font-medium leading-6 text-neutral-900"
                >
                    {label}
                </label>
            )}
            <div className={clsx(label && "mt-2", "relative")}>
                <textarea
                    rows={4}
                    name={name}
                    id={id}
                    className={clsx(
                        "block w-full rounded-lg border-0 py-1.5 text-app-labelsbuttons-4-mobile text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-600 focus:ring-2 focus:ring-inset focus:ring-primary-700 md:text-app-labelsbuttons-4-desktop",
                        className,
                        showCounter && "pb-6",
                    )}
                    defaultValue={defaultValue}
                    onChange={handleChange}
                    maxLength={maxLength}
                    {...props}
                />
                {showCounter && (
                    <div className="absolute bottom-1 right-1 rounded px-1">
                        <AppText
                            variant="labelsbuttons"
                            className="text-neutral-700"
                        >
                            {charCount.toLocaleString()}/
                            {(maxLength || 0).toLocaleString()}
                        </AppText>
                    </div>
                )}
            </div>
        </div>
    );
};
