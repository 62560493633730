import clsx from "clsx";
import { Eye, EyeOff } from "lucide-react";
import {
    ChangeEvent,
    ReactElement,
    cloneElement,
    useState,
    useEffect,
} from "react";
import { InputError } from "./InputError";
import { AppText } from "@/components/Text/AppText";

const defaultClass =
    "block w-full rounded-lg border-0 py-1.5 text-neutral-900 shadow-sm ring-2 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-primary-700 focus:ring-2 focus:ring-inset text-app-labelsbuttons-4-mobile md:text-app-labelsbuttons-4-desktop";

interface InputProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
    label?: string;
    name?: string;
    className?: string;
    error?: string;
    onChange?: (name: string, value: string) => void;
    leadingIcon?: ReactElement;
    trailingIcon?: ReactElement;
    formikChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    containerClassName?: string;
    showCounter?: boolean;
    maxLength?: number;
}

export const Input = ({
    name,
    label,
    className = defaultClass,
    autoComplete,
    type = "text",
    onChange,
    error,
    id,
    onBlur,
    placeholder,
    autoFocus,
    defaultValue,
    disabled,
    leadingIcon,
    trailingIcon,
    formikChange,
    containerClassName,
    showCounter,
    maxLength,
    ...props
}: InputProps) => {
    const classNames = clsx(className, defaultClass);
    const [showPassword, setShowPassword] = useState(false);
    const [passwordType, setPasswordType] = useState(type);
    const [charCount, setCharCount] = useState(
        defaultValue?.toString().length || 0,
    );

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCharCount(e.target.value.length);
        if (formikChange) {
            formikChange(e);
        } else {
            onChange?.(name || "", e.target.value);
        }
    };

    useEffect(() => {
        setCharCount(defaultValue?.toString().length || 0);
    }, [defaultValue]);

    return (
        <div className={containerClassName}>
            {label && (
                <label
                    htmlFor={name}
                    className="block  text-app-contextheadings-3-mobile text-neutral-900 md:text-app-contextheadings-3-desktop"
                >
                    {label}
                </label>
            )}
            <div className={clsx(label && "mt-2", "relative")}>
                {leadingIcon && (
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        {cloneElement(leadingIcon, {
                            className: "h-5 w-5 text-neutral-500",
                        })}
                    </div>
                )}
                <input
                    id={id}
                    name={name}
                    autoComplete={autoComplete}
                    className={clsx(
                        classNames,
                        leadingIcon && "pl-10",
                        trailingIcon && "pr-10",
                        error && "bg-error-50 ring-1 !ring-error-500",
                        showCounter && "pr-20",
                    )}
                    type={type === "password" ? passwordType : type}
                    onChange={handleChange}
                    onBlur={onBlur}
                    placeholder={placeholder}
                    autoFocus={autoFocus}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    maxLength={maxLength}
                    {...props}
                />
                {type === "password" && (
                    <div
                        className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3"
                        onClick={() => {
                            setShowPassword(!showPassword);
                            setPasswordType(showPassword ? "password" : "text");
                        }}
                    >
                        {showPassword ? (
                            <EyeOff
                                size={20}
                                className="h-5 w-5 text-neutral-500"
                            />
                        ) : (
                            <Eye
                                size={20}
                                className="h-5 w-5 text-neutral-500"
                            />
                        )}
                    </div>
                )}
                {trailingIcon && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        {cloneElement(trailingIcon, {
                            className: "h-5 w-5 text-neutral-500",
                        })}
                    </div>
                )}
                {showCounter && (
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                        <AppText
                            variant="labelsbuttons"
                            className="text-neutral-700"
                        >
                            {charCount.toLocaleString()}/
                            {(maxLength || 0).toLocaleString()}
                        </AppText>
                    </div>
                )}
            </div>
            <InputError error={error} />
        </div>
    );
};
