import clsx from "clsx";
import { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { BaseAiFeature } from "@/components/AiToolbox/features/BaseAiFeature";
import {
    getAiFeatureTitle,
    handleAiToolboxGenerate,
} from "@/components/AiToolbox/helper";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useContentEditor } from "@/hooks/useContentEditor";
import { useOutsideClick } from "@/hooks/useOutsideClick";
import { useTransaction } from "@/hooks/useTransaction";
import {
    getAiToolboxPromptActionCode,
    getAiToolboxSelection,
    getAiToolboxState,
    getShowSideBar,
} from "@/store/app/selectors";
import { updateAiToolbox } from "@/store/app/slice";
import { getActiveSectionDetails } from "@/store/resume/selectors";
import { getSectionConfigById } from "@/store/sections/selectors";
import { getUserPreferences } from "@/store/user/selectors";
import { CLARITY_EVENT_NAMES } from "@/types/tracking";
import { trackClarityEvent, trackPostHogEvent } from "@/utils/tracking";

export const AiToolbox = () => {
    const { isVisible, stage, source } = useAppSelector(getAiToolboxState);
    const { activeEditor } = useContentEditor();
    const { validateCreditAction } = useTransaction();
    const activeSection = useAppSelector(getActiveSectionDetails);
    const activeSectionConfig = useAppSelector(state =>
        getSectionConfigById(state, activeSection?.sectionConfigId ?? ""),
    );
    const creditActionCode = useAppSelector(getAiToolboxPromptActionCode);
    const featureTitle = useMemo(
        () => getAiFeatureTitle(creditActionCode),
        [creditActionCode],
    );
    const sideBarOpen = useAppSelector(getShowSideBar);
    const [position, setPosition] = useState({ top: 140, left: 0 });
    const [hasGenerateError, setHasGenerateError] = useState(false);
    const [modelUsed, setModelUsed] = useState<number | null>(null);
    const abortControllerRef = useRef<AbortController | null>(null);
    const toolboxRef = useRef<HTMLDivElement>(null);
    const [isMounted, setIsMounted] = useState(false);

    const selection = useAppSelector(getAiToolboxSelection);
    const dispatch = useAppDispatch();
    const userPreferences = useAppSelector(getUserPreferences);

    const updatePosition = useCallback(
        (source?: string) => {
            if (!activeEditor?.state || source === "" || source === "navbar") {
                setPosition({ top: 140, left: 0 });
                return;
            }

            const { from } = activeEditor.state.selection;
            const editorPos = activeEditor.view.coordsAtPos(from);
            const editorRect = activeEditor.view.dom.getBoundingClientRect();
            const gap = 30;
            const headerOffset = 140;
            const viewportHeight = window.innerHeight;
            const bottomPadding = 30;

            if (editorPos && editorRect && toolboxRef.current) {
                const toolboxHeight = toolboxRef.current.offsetHeight;
                let newTop = Math.max(editorPos.top + gap, headerOffset);

                // Check if toolbox would go below viewport
                if (newTop + toolboxHeight > viewportHeight - bottomPadding) {
                    newTop = Math.max(
                        viewportHeight - bottomPadding - toolboxHeight,
                        headerOffset,
                    );
                }

                const newLeft = editorRect.left + editorRect.width / 2;

                setPosition(prevPosition => {
                    if (
                        prevPosition.top === newTop &&
                        prevPosition.left === newLeft
                    ) {
                        return prevPosition;
                    }
                    return { top: newTop, left: newLeft };
                });
            }
        },
        [activeEditor],
    );

    useEffect(() => {
        if (isVisible) {
            updatePosition(source);
            if (source) {
                switch (source) {
                    case "navbar":
                        trackClarityEvent(
                            CLARITY_EVENT_NAMES.AI_TOOLBOX_OPENED_NAVBAR,
                        );
                        trackPostHogEvent(
                            CLARITY_EVENT_NAMES.AI_TOOLBOX_OPENED_NAVBAR,
                        );
                        break;
                    case "actions-toolbar":
                        trackClarityEvent(
                            CLARITY_EVENT_NAMES.AI_TOOLBOX_OPENED_ACTIONS_TOOLBAR,
                        );
                        trackPostHogEvent(
                            CLARITY_EVENT_NAMES.AI_TOOLBOX_OPENED_ACTIONS_TOOLBAR,
                        );
                        break;
                    case "bubble-menu":
                        trackClarityEvent(
                            CLARITY_EVENT_NAMES.AI_TOOLBOX_OPENED_BUBBLE_MENU,
                        );
                        trackPostHogEvent(
                            CLARITY_EVENT_NAMES.AI_TOOLBOX_OPENED_BUBBLE_MENU,
                        );
                        break;
                    default:
                        break;
                }
            }
        }

        const handleSelectionUpdate = () => {
            if (isVisible) {
                updatePosition();
            }
        };

        activeEditor?.on("selectionUpdate", handleSelectionUpdate);
        return () => {
            activeEditor?.off("selectionUpdate", handleSelectionUpdate);
        };
    }, [activeEditor, isVisible, source, updatePosition]);

    const handleGenerate = useCallback(
        async (input: string) => {
            try {
                const { hasInsufficientFunds, creditActionError } =
                    validateCreditAction({
                        actionCode: creditActionCode!,
                    });
                if (hasInsufficientFunds || creditActionError) {
                    return;
                }
                abortControllerRef.current = new AbortController();
                const response = await handleAiToolboxGenerate({
                    creditActionCode: creditActionCode!,
                    input,
                    dispatch,
                    sectionType: activeSectionConfig?.sectionType,
                    userPreferences: userPreferences,
                    signal: abortControllerRef.current.signal,
                });
                setModelUsed(response.modelUsed ?? null);
                return response.output;
            } catch (error) {
                // Only set error if it's not an abort error
                if (
                    !(
                        error instanceof DOMException &&
                        error.name === "AbortError"
                    )
                ) {
                    setHasGenerateError(true);
                }
                return undefined;
            }
        },
        [
            validateCreditAction,
            creditActionCode,
            dispatch,
            activeSectionConfig?.sectionType,
            userPreferences,
        ],
    );

    useEffect(() => {
        if (stage === "input" && hasGenerateError) {
            setHasGenerateError(false);
        }
    }, [stage, hasGenerateError]);

    const handleCancel = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
            abortControllerRef.current = null;
            dispatch(updateAiToolbox({ stage: "input" }));
            dispatch(updateAiToolbox({ promptOutput: "" }));
        }
    }, [dispatch]);

    // Add new effect to handle cleanup when toolbox closes
    useEffect(() => {
        if (!isVisible) {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
                abortControllerRef.current = null;
            }
            dispatch(
                updateAiToolbox({
                    stage: "input",
                    promptOutput: "",
                    promptInput: "",
                }),
            );
        }
    }, [isVisible, dispatch]);

    const handleOutsideClick = useCallback(() => {
        dispatch(
            updateAiToolbox({
                isVisible: false,
                stage: "input",
                promptOutput: "",
                promptInput: "",
            }),
        );
    }, [dispatch]);

    useOutsideClick(toolboxRef, handleOutsideClick, [
        "history-button",
        "headlessui-portal-root", // This is the modal root
        "smart-scribe-nav-button",
        "actions-toolbar-smart-scribe-button",
    ]);

    // Add this effect to watch for toolbox height changes
    useEffect(() => {
        if (!toolboxRef.current) return;

        const resizeObserver = new ResizeObserver(() => {
            if (isVisible) {
                updatePosition(source);
            }
        });

        resizeObserver.observe(toolboxRef.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, [isVisible, source, updatePosition]);

    // Add effect to handle delayed mount
    useEffect(() => {
        if (isVisible) {
            // Small delay to ensure position is calculated
            const timer = setTimeout(() => {
                setIsMounted(true);
            }, 50);
            return () => clearTimeout(timer);
        } else {
            setIsMounted(false);
        }
    }, [isVisible]);

    if (!isVisible) return null;

    return (
        <div
            ref={toolboxRef}
            className={clsx(
                "fixed left-1/2 z-[10] w-[700px] transition-transform delay-100 duration-300",
                sideBarOpen
                    ? "-translate-x-[150px] max-xl:-translate-x-0"
                    : "-translate-x-[320px]",
                !isMounted && "opacity-0",
            )}
            style={{
                top: position.top,
            }}
        >
            <BaseAiFeature
                title={featureTitle}
                creditActionCode={creditActionCode!}
                onGenerate={handleGenerate}
                selection={selection}
                sectionType={activeSectionConfig?.sectionType}
                hasGenerateError={hasGenerateError}
                handleCancel={handleCancel}
                modelUsed={modelUsed}
            />
        </div>
    );
};
