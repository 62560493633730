import { useEffect, useState } from "react";
import { Alert } from "@/components/Alert/Alert";
import { AnimatedSmartScribe } from "@/components/Icons/AnimatedSmartScribe";
import { LoadingSteps } from "@/components/LoadingSpinner/LoadingSteps";
import { AppText } from "@/components/Text/AppText";

export const ImportLoading = ({ importError }: { importError: string }) => {
    const [showDelayMessage, setShowDelayMessage] = useState(false);

    const loadingSteps = [
        {
            label: "Creating your resume...",
        },
        {
            label: "Polishing your achievements...",
        },
        {
            label: "Crafting for the job market...",
        },
        {
            label: "Highlighting your expertise...",
        },
        {
            label: "Crafting compelling descriptions...",
        },
        {
            label: "Enhancing your professional story...",
        },
        {
            label: "Structuring your experience...",
        },
        {
            label: "Making your skills stand out...",
        },
        {
            label: "Completing your winning resume...",
        },
    ];

    useEffect(() => {
        const delayTimer = setTimeout(() => {
            setShowDelayMessage(true);
        }, 15000);

        return () => clearTimeout(delayTimer);
    }, []);

    return (
        <div className="flex min-h-[200px] flex-1 flex-col items-center justify-center gap-2">
            {importError ? (
                <Alert
                    type="warning"
                    message={importError}
                />
            ) : (
                <>
                    <div className="mb-2 text-center">
                        <AppText
                            variant="contextheading"
                            className="mt-2 text-neutral-900"
                        >
                            Applying the final touches...
                        </AppText>
                    </div>

                    <div className="mb-4 text-center">
                        <AppText
                            variant="regular"
                            className="text-neutral-700"
                        >
                            1Template&apos;s{" "}
                            <AppText
                                variant="regular"
                                applyGradient
                                className="inline-block !font-medium"
                            >
                                Smart Scribe
                            </AppText>{" "}
                            &{" "}
                            <AppText
                                variant="regular"
                                applyGradient
                                className="inline-block !font-medium"
                            >
                                Smart Match
                            </AppText>{" "}
                            AI are enhancing your resume to help you stand out.
                        </AppText>
                    </div>

                    <LoadingSteps
                        steps={loadingSteps}
                        autoProgress={true}
                        stepDuration={4000}
                        loop={false}
                        icon={<AnimatedSmartScribe className="h-9 w-9" />}
                    />

                    <div className="mt-3 text-center">
                        <AppText
                            variant="footnote"
                            className="text-neutral-600"
                        >
                            {showDelayMessage
                                ? "We're still processing your resume—this is taking longer than usual, but we're on it!"
                                : "It should be ready shortly!"}
                        </AppText>
                    </div>
                </>
            )}
        </div>
    );
};
