import { NotificationDetails } from "@/types";
import { SaveTemplateState, SideBarState, TemplateLoadingState } from "@/types/app";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AiToolboxState } from "@/types/aiToolbox";
import { CreditActionCode } from "@/types/creditAction";

interface AppState {
    notifications: NotificationDetails[];
    sideBar: SideBarState;
    showSideBar: boolean;
    saveTemplateState: SaveTemplateState;
    templateLoadingState: TemplateLoadingState;
    showPlansModal: "upgrade" | "download" | "preview" | "";
    showRearrangeModal: boolean;
    datePickerId: string;
    debugBuilder: boolean;
    debugPageMargins: boolean;
    debugSectionSpacing: boolean;
    showFeedbackSurvey: boolean;
    aiToolbox: AiToolboxState;
    showBonusNavbarButton: boolean;
    showImportFeedback: boolean;
}

const initialState: AppState = {
    notifications: [],
    sideBar: SideBarState.CLOSED,
    showSideBar: false,
    saveTemplateState: SaveTemplateState.SAVED,
    templateLoadingState: TemplateLoadingState.INITIAL,
    showPlansModal: "", // the value is the source
    showRearrangeModal: false,
    datePickerId: "",
    debugBuilder: false,
    debugPageMargins: false,
    debugSectionSpacing: false,
    showFeedbackSurvey: false,
    aiToolbox: {
        isVisible: false,
        promptInput: "",
        promptActionCode: CreditActionCode.AI_GENERATE_SUMMARY,
        stage: "input",
        promptOutput: "",
        source: "",
    },
    showBonusNavbarButton: false,
    showImportFeedback: false,
};

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        addNotification: (
            state,
            { payload }: PayloadAction<NotificationDetails>,
        ) => {
            const isAlreadyDisplayingNotification = state.notifications.some(
                n => n.type === payload.type,
            );

            if (!isAlreadyDisplayingNotification) {
                return {
                    ...state,
                    notifications: [...state.notifications, payload],
                };
            }
            return state;
        },
        removeNotification: (
            state,
            { payload }: PayloadAction<NotificationDetails>,
        ) => {
            return {
                ...state,
                notifications: state.notifications.filter(
                    notification => notification.type !== payload.type,
                ),
            };
        },
        setSideBarState: (state, { payload }: PayloadAction<SideBarState>) => {
            return {
                ...state,
                sideBar: payload,
            };
        },
        setShowSideBar: (state, { payload }: PayloadAction<boolean>) => {
            return {
                ...state,
                showSideBar: payload,
            };
        },
        setSaveTemplateState: (state, { payload }: PayloadAction<SaveTemplateState>) => {
            return {
                ...state,
                saveTemplateState: payload,
            };
        },
        setTemplateLoadingState: (state, { payload }: PayloadAction<TemplateLoadingState>) => {
            return {
                ...state,
                templateLoadingState: payload,
            };
        },
        setShowPlansModal: (state, { payload }: PayloadAction<"upgrade" | "download" | "preview" | "">) => {
            return {
                ...state,
                showPlansModal: payload,
            };
        },
        setShowRearrangeModal: (state, { payload }: PayloadAction<boolean>) => {
            return {
                ...state,
                showRearrangeModal: payload,
            };
        },
        setDatePickerId: (state, { payload }: PayloadAction<string>) => {
            return {
                ...state,
                datePickerId: payload,
            };
        },
        setDebugBuilder: (state, { payload }: PayloadAction<boolean>) => {
            return {
                ...state,
                debugBuilder: payload,
            };
        },
        setDebugPageMargins: (state, { payload }: PayloadAction<boolean>) => {
            return {
                ...state,
                debugPageMargins: payload,
            };
        },
        setDebugSectionSpacing: (state, { payload }: PayloadAction<boolean>) => {
            return {
                ...state,
                debugSectionSpacing: payload,
            };
        },
        setShowFeedbackSurvey: (state, { payload }: PayloadAction<boolean>) => {
            return {
                ...state,
                showFeedbackSurvey: payload,
            };
        },
        resetAiToolbox: (state) => {
            state.aiToolbox = initialState.aiToolbox;
        },
        updateAiToolbox: (state, { payload }: PayloadAction<Partial<AiToolboxState>>) => {
            const newState = {
                ...state.aiToolbox,
                ...payload,
            };

            if (payload.stage === "input") {
                newState.promptOutput = ""; // clear output state is set to input
            }

            state.aiToolbox = newState;
        },
        setShowBonusNavbarButton: (state, { payload }: PayloadAction<boolean>) => {
            return {
                ...state,
                showBonusNavbarButton: payload,
            };
        },
        setShowImportFeedback: (state, { payload }: PayloadAction<boolean>) => {
            return {
                ...state,
                showImportFeedback: payload,
            };
        },
    },
});

export const { addNotification, removeNotification, setSideBarState, setShowSideBar, setSaveTemplateState, setTemplateLoadingState, setShowPlansModal, setShowRearrangeModal, setDatePickerId, setDebugBuilder, setDebugPageMargins, setDebugSectionSpacing, setShowFeedbackSurvey, resetAiToolbox, updateAiToolbox, setShowBonusNavbarButton, setShowImportFeedback } = appSlice.actions;
export const { reducer: appReducer } = appSlice;
