import clsx from "clsx";
import { ArrowRight, ChevronLeft } from "lucide-react";
import { useState, useMemo, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { filterXSS } from "xss";
import { ImportChoice } from "./Steps/ImportChoice";
import { ImportDetails } from "./Steps/ImportDetails";
import { ImportJobDetails } from "./Steps/ImportJobDetails";
import { ImportLoading } from "./Steps/ImportLoading";
import { ImportTemplate } from "./Steps/ImportTemplate";
import { ImportValidateDetailsLoading } from "./Steps/ImportValidateDetailsLoading";
import resumeApi from "@/api/resume";
import { Button } from "@/components/Button/Button";
import { IconButton } from "@/components/Button/IconButton";
import { CreditSpendIndicator } from "@/components/Credits/CreditSpendIndicator";
import { Modal } from "@/components/Modal/Modal";
import {
    ALLOWED_RESUME_FILE_TYPES,
    MAX_RESUME_FILE_SIZE,
    MIN_JD_CHARS,
    MIN_RESUME_TEXT_CHARS,
} from "@/components/Modals/NewResumeModal/constants";
import { createNewResume } from "@/helper/createNewResume";
import { duplicateResume } from "@/helper/duplicateResume";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useDevice } from "@/hooks/useDevice";
import { useTransaction } from "@/hooks/useTransaction";
import { setShowImportFeedback } from "@/store/app/slice";
import { getIsModalOpen, getModalProps } from "@/store/modal/selectors";
import { closeModal } from "@/store/modal/slice";
import { getResumeExamples } from "@/store/resume/selectors";
import { getUserDetails } from "@/store/user/selectors";
import { setUserCredits } from "@/store/user/slice";
import { CreditActionCode } from "@/types/creditAction";
import { ModalTypes } from "@/types/modal";
import { trackPostHogEvent } from "@/utils/tracking";

export enum ResumeStep {
    CHOICE = 1,
    RESUME_DETAILS = 2,
    DETAILS_LOADING = 3,
    JOB_DETAILS = 4,
    TEMPLATE = 5,
    IMPORT_LOADING = 6,
}

const NewResumeModal = () => {
    const isOpen = useAppSelector(getIsModalOpen(ModalTypes.NEW_RESUME));
    const modalProps = useAppSelector(getModalProps(ModalTypes.NEW_RESUME));
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [importError, setImportError] = useState<string>("");
    const [inputType, setInputType] = useState<"file" | "text">("file");
    const [resumeInputText, setResumeInputText] = useState("");
    const [jobDescription, setJobDescription] = useState("");
    const resumeExamples = useAppSelector(getResumeExamples);
    const [selectedExample, setSelectedExample] = useState<string>("");
    const [currentStep, setCurrentStep] = useState<ResumeStep>(
        modalProps?.startStep || ResumeStep.CHOICE,
    );
    const [jobTitle, setJobTitle] = useState("");
    const [isFileImport, setIsFileImport] = useState<boolean | null>(null);
    const { getCreditActionByCode, validateCreditAction } = useTransaction();
    const { isDesktop } = useDevice();
    const userDetails = useAppSelector(getUserDetails);
    const [jobDescriptionError, setJobDescriptionError] = useState<string>("");
    const [resumeTextError, setResumeInputTextError] = useState<string>("");
    const [fileError, setFileError] = useState<string>("");
    const [showResumeInputWarning, setShowResumeInputWarning] =
        useState<boolean>(false);
    const [canBypassResumeInputWarning, setCanBypassResumeInputWarning] =
        useState<boolean>(false);
    const importResumeCreditAction = useMemo(
        () => getCreditActionByCode(CreditActionCode.IMPORT_RESUME),
        [getCreditActionByCode],
    );
    const [fileUploadedResumeText, setFileUploadedResumeText] =
        useState<string>("");

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (modalProps?.startStep) {
            setCurrentStep(modalProps.startStep);
        }
    }, [modalProps?.startStep]);

    const handleFileChange = (event?: React.ChangeEvent<HTMLInputElement>) => {
        setShowResumeInputWarning(false);
        setFileError("");
        setResumeInputTextError("");
        setFileUploadedResumeText("");

        if (!event) {
            setSelectedFile(null);
            return;
        }
        const file = event.target.files?.[0] || null;
        setFileError("");

        if (!file) {
            setSelectedFile(null);
            return;
        }

        if (!ALLOWED_RESUME_FILE_TYPES.includes(file.type)) {
            setFileError("Invalid file type. Please upload a PDF or DOCX file");
            return;
        }

        if (file.size > MAX_RESUME_FILE_SIZE) {
            setFileError("File size must be less than 10MB");
            return;
        }

        setSelectedFile(file);
    };

    const handleExampleChange = useCallback((exampleId: string | null) => {
        if (exampleId) {
            setSelectedExample(exampleId);
        } else {
            setSelectedExample("");
        }
    }, []);

    const handleBlankResumeSelect = useCallback(() => {
        setIsFileImport(false);
        dispatch(closeModal(ModalTypes.NEW_RESUME));
        trackPostHogEvent("new_resume_option_selected", {
            feature_name: "new_resume",
            option_selected: "create_from_blank",
        });
        createNewResume(navigate, {
            userDetails,
            isDesktop,
        });
    }, [dispatch, navigate, userDetails, isDesktop]);

    const handleCreateFromTemplate = () => {
        setFileError("");
        setResumeInputTextError("");
        setJobDescriptionError("");
        setImportError("");
        setShowResumeInputWarning(false);
        const resumeExampleData = resumeExamples?.find(
            example => example.id === selectedExample,
        );
        if (resumeExampleData) {
            dispatch(closeModal(ModalTypes.NEW_RESUME));
            duplicateResume({
                navigate,
                resume: resumeExampleData,
                isExampleResume: true,
                isDesktop,
                userDetails,
            });
        }
    };

    const handleOptionSelect = useCallback((importResume: boolean) => {
        setIsFileImport(importResume);
        setCurrentStep(
            importResume ? ResumeStep.RESUME_DETAILS : ResumeStep.TEMPLATE,
        );
        trackPostHogEvent("new_resume_option_selected", {
            feature_name: "new_resume",
            option_selected: importResume
                ? "import_resume"
                : "create_from_template",
        });
    }, []);

    const handleNextStep = () => {
        if (currentStep === ResumeStep.RESUME_DETAILS) {
            if (inputType === "file" && selectedFile) {
                setCurrentStep(ResumeStep.DETAILS_LOADING);
                handleUploadFile();
            } else if (inputType === "text" && resumeInputText?.trim()) {
                // If text input, go directly to JOB_DETAILS
                if (resumeInputText.length < MIN_RESUME_TEXT_CHARS) {
                    setResumeInputTextError(
                        `Resume must be at least ${MIN_RESUME_TEXT_CHARS} characters`,
                    );
                    return;
                }
                setCurrentStep(ResumeStep.DETAILS_LOADING);
                handleUploadFile();
            }
        } else if (currentStep === ResumeStep.JOB_DETAILS) {
            if (jobDescription && jobDescription.length < MIN_JD_CHARS) {
                setJobDescriptionError(
                    `Job description must be at least ${MIN_JD_CHARS} characters`,
                );
                return;
            }
            setCurrentStep(ResumeStep.TEMPLATE);
        } else if (currentStep === ResumeStep.TEMPLATE) {
            if (importResumeCreditAction) {
                const { hasInsufficientFunds } = validateCreditAction({
                    actionCode: importResumeCreditAction.code,
                    amount: importResumeCreditAction.credits ?? 0,
                });
                if (hasInsufficientFunds) {
                    return;
                }
            }
            if (resumeInputText || fileUploadedResumeText) {
                handleImport();
            } else {
                handleCreateFromTemplate();
            }
        } else {
            setCurrentStep(prev => (prev + 1) as ResumeStep);
        }
    };

    const handleUploadFile = async () => {
        const hasWarningVisible = showResumeInputWarning;
        setFileError("");
        setResumeInputTextError("");
        setShowResumeInputWarning(false);

        let formData;
        if (selectedFile) {
            formData = new FormData();
            formData.append("file", selectedFile);

            trackPostHogEvent("resume_file_upload_started", {
                feature_name: "new_resume",
                file_size: selectedFile.size,
            });
        }

        try {
            // If they are submitting the resume text whilst the warning is displayed, just let them go through.
            if (
                hasWarningVisible &&
                (resumeInputText || fileUploadedResumeText)
            ) {
                setCurrentStep(ResumeStep.JOB_DETAILS);
                return;
            }

            const substringResumeText = resumeInputText?.substring(0, 600);
            const isFileUpload = inputType === "file";
            const response = await resumeApi.uploadResume(
                formData,
                isFileUpload ? undefined : substringResumeText,
            );
            if (response.validatedText) {
                setFileUploadedResumeText(response.validatedText);
            }

            if (response.showWarning) {
                setShowResumeInputWarning(true);
                setCanBypassResumeInputWarning(true);
                setCurrentStep(ResumeStep.RESUME_DETAILS);
            } else {
                setCurrentStep(ResumeStep.JOB_DETAILS);
            }
        } catch (err) {
            if (
                err instanceof Error &&
                err.message ===
                    "This doesn't appear to be a resume. Please try again."
            ) {
                setShowResumeInputWarning(true);
                setCanBypassResumeInputWarning(false);
            } else {
                setFileError("Failed to upload resume. Please try again.");
            }
            setCurrentStep(ResumeStep.RESUME_DETAILS);
        }
    };

    const handleImport = async () => {
        setFileError("");
        setResumeInputTextError("");
        setJobDescriptionError("");
        setImportError("");
        setCurrentStep(ResumeStep.IMPORT_LOADING);

        const resumeTextToUse =
            inputType === "file" ? fileUploadedResumeText : resumeInputText;

        try {
            // Sanitize resume text to prevent XSS attacks
            const sanitizedResumeText = filterXSS(resumeTextToUse, {
                whiteList: {},
                stripIgnoreTag: true,
                stripIgnoreTagBody: ["script", "style"],
                css: false,
            });

            const sanitizedJobDescription = filterXSS(jobDescription, {
                whiteList: {},
                stripIgnoreTag: true,
                stripIgnoreTagBody: ["script", "style"],
                css: false,
            });

            const sanitizedJobTitle = filterXSS(jobTitle, {
                whiteList: {},
                stripIgnoreTag: true,
                stripIgnoreTagBody: ["script", "style"],
                css: false,
            });

            const response = await resumeApi.importResume({
                resumeText: sanitizedResumeText,
                jobDescription: sanitizedJobDescription,
                templateId: selectedExample,
                jobTitle: sanitizedJobTitle,
            });
            const { resumeId, userUpdates } = response;

            if (resumeId) {
                dispatch(closeModal(ModalTypes.NEW_RESUME));
                dispatch(setShowImportFeedback(true));
                if (userUpdates.credits) {
                    dispatch(setUserCredits(userUpdates.credits));
                }

                trackPostHogEvent("resume_import_success", {
                    feature_name: "new_resume",
                    isDesktop,
                });

                const nextRoute = isDesktop
                    ? `/resume/${resumeId}`
                    : `/preview/${resumeId}`;
                navigate(nextRoute);
            }
        } catch (err) {
            setImportError("Failed to create resume. Please try again.");
            trackPostHogEvent("resume_import_failed", {
                feature_name: "new_resume",
                error: err instanceof Error ? err.message : "Unknown error",
            });
        }
    };

    const handleResetModal = () => {
        // Only reset the modal if the user has actually closed the modal. If the modal is still present in the modals list, it means its just hidden for
        if (!isOpen) {
            setResumeInputText("");
            setJobDescription("");
            setJobTitle("");
            setSelectedFile(null);
            setSelectedExample("");
            setCurrentStep(ResumeStep.CHOICE);
        }
    };

    const handleBackStep = () => {
        if (currentStep > ResumeStep.CHOICE) {
            // If going back from DETAILS_LOADING, go to IMPORT
            if (currentStep === ResumeStep.DETAILS_LOADING) {
                setCurrentStep(ResumeStep.RESUME_DETAILS);
            }
            // If going back from JOB_DETAILS and we came from import flow
            else if (currentStep === ResumeStep.JOB_DETAILS && isFileImport) {
                setCurrentStep(ResumeStep.RESUME_DETAILS);
            } else if (
                currentStep === ResumeStep.JOB_DETAILS &&
                !isFileImport
            ) {
                setCurrentStep(ResumeStep.CHOICE);
            } else if (currentStep === ResumeStep.TEMPLATE) {
                if (resumeInputText || fileUploadedResumeText) {
                    setCurrentStep(ResumeStep.JOB_DETAILS);
                } else {
                    setCurrentStep(ResumeStep.CHOICE);
                }
            } else {
                setCurrentStep(prev => (prev - 1) as ResumeStep);
            }
        }
    };

    const getStepTitle = useCallback((step: ResumeStep) => {
        switch (step) {
            case ResumeStep.CHOICE:
                return "Create Your Resume";
            case ResumeStep.RESUME_DETAILS:
                return "Add Your Resume";
            case ResumeStep.JOB_DETAILS:
                return "Enter Job Details";
            case ResumeStep.TEMPLATE:
                return "Select a Template";
            case ResumeStep.DETAILS_LOADING:
            case ResumeStep.IMPORT_LOADING:
                return "";
        }
    }, []);

    const renderCurrentStep = () => {
        const stepContent = (() => {
            switch (currentStep) {
                case ResumeStep.CHOICE:
                    return (
                        <ImportChoice
                            onOptionSelect={handleOptionSelect}
                            onBlankResumeSelect={handleBlankResumeSelect}
                        />
                    );
                case ResumeStep.RESUME_DETAILS:
                    return (
                        <ImportDetails
                            inputType={inputType}
                            setInputType={(type: "file" | "text") => {
                                trackPostHogEvent(
                                    "resume_details_input_type_changed",
                                    {
                                        feature_name: "new_resume",
                                        input_type: type,
                                    },
                                );
                                setInputType(type);
                                setFileError("");
                                setResumeInputTextError("");
                            }}
                            selectedFile={selectedFile}
                            resumeText={resumeInputText}
                            jobTitle={jobTitle}
                            onFileChange={(
                                e?: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                                handleFileChange(e);
                            }}
                            onTextChange={(text: string) => {
                                setResumeInputText(text);
                                setResumeInputTextError("");
                                setShowResumeInputWarning(false);
                                setFileError("");
                            }}
                            onJobTitleChange={(title: string) => {
                                setJobTitle(title);
                                setResumeInputTextError("");
                                setFileError("");
                            }}
                            resumeTextError={resumeTextError}
                            fileError={fileError}
                            showResumeInputWarning={showResumeInputWarning}
                            onWarningBypass={() => {
                                trackPostHogEvent("new_resume_warning_bypass", {
                                    feature_name: "new_resume",
                                });
                                setShowResumeInputWarning(false);
                                handleNextStep();
                            }}
                            canBypassWarning={
                                showResumeInputWarning &&
                                canBypassResumeInputWarning &&
                                (resumeInputText.length >
                                    MIN_RESUME_TEXT_CHARS ||
                                    fileUploadedResumeText.length >
                                        MIN_RESUME_TEXT_CHARS)
                            }
                        />
                    );
                case ResumeStep.DETAILS_LOADING:
                    return <ImportValidateDetailsLoading />;
                case ResumeStep.JOB_DETAILS:
                    return (
                        <ImportJobDetails
                            jobDescription={jobDescription}
                            onJobDescriptionChange={(description: string) => {
                                setJobDescription(description);
                                setJobDescriptionError("");
                            }}
                            onNext={handleNextStep}
                            jobDescriptionError={jobDescriptionError}
                        />
                    );
                case ResumeStep.TEMPLATE:
                    return (
                        <ImportTemplate
                            selectedExample={selectedExample}
                            resumeExamples={resumeExamples}
                            onExampleChange={handleExampleChange}
                            onNext={handleNextStep}
                            hasJobDescription={!!jobDescription || !!jobTitle}
                        />
                    );
                case ResumeStep.IMPORT_LOADING:
                    return <ImportLoading importError={importError} />;
                default:
                    return null;
            }
        })();

        return (
            <div
                className={clsx(
                    "flex flex-col",
                    currentStep === ResumeStep.DETAILS_LOADING && "h-full",
                    currentStep === ResumeStep.IMPORT_LOADING && "h-full",
                )}
            >
                {stepContent}
            </div>
        );
    };

    const renderFooter = () => {
        switch (currentStep) {
            case ResumeStep.CHOICE:
            case ResumeStep.DETAILS_LOADING:
                return null;
            case ResumeStep.IMPORT_LOADING:
                return importError ? (
                    <div className="flex justify-between">
                        <Button
                            variant="solid"
                            color="grey"
                            onClick={handleBackStep}
                            leftIcon={<ChevronLeft size={16} />}
                        >
                            Back
                        </Button>
                        <Button
                            variant="solid"
                            color="primary"
                            onClick={() => {
                                setImportError("");
                                handleImport();
                            }}
                        >
                            Retry
                        </Button>
                    </div>
                ) : null;
            case ResumeStep.RESUME_DETAILS:
                return (
                    <div className="flex justify-between">
                        <Button
                            variant="solid"
                            color="grey"
                            onClick={handleBackStep}
                            leftIcon={<ChevronLeft size={16} />}
                        >
                            Back
                        </Button>
                        <Button
                            variant="solid"
                            color="primary"
                            onClick={handleNextStep}
                            disabled={
                                (!resumeInputText?.trim() && !selectedFile) ||
                                !!resumeTextError ||
                                !!fileError ||
                                showResumeInputWarning
                            }
                        >
                            Next
                        </Button>
                    </div>
                );
            case ResumeStep.JOB_DETAILS:
                return (
                    <div className="flex justify-between">
                        <Button
                            variant="solid"
                            color="grey"
                            onClick={handleBackStep}
                            leftIcon={<ChevronLeft size={16} />}
                        >
                            Back
                        </Button>
                        <Button
                            variant="solid"
                            color="primary"
                            onClick={() => {
                                trackPostHogEvent(
                                    "new_resume_job_details_step",
                                    {
                                        feature_name: "new_resume",
                                        hasJobDescription: !!jobDescription,
                                    },
                                );
                                handleNextStep();
                            }}
                            disabled={!!jobDescriptionError}
                        >
                            Next - Choose Template
                        </Button>
                    </div>
                );
            case ResumeStep.TEMPLATE:
                return (
                    <div className="flex justify-between gap-3 pt-2">
                        <Button
                            variant="solid"
                            color="grey"
                            className="hidden lg:flex"
                            onClick={handleBackStep}
                            leftIcon={<ChevronLeft size={16} />}
                        >
                            Back
                        </Button>
                        <IconButton
                            variant="solid"
                            color="grey"
                            className="flex lg:hidden"
                            onClick={handleBackStep}
                        >
                            <ChevronLeft size={16} />
                        </IconButton>
                        <div className="flex items-center gap-3">
                            {resumeInputText || fileUploadedResumeText ? (
                                <CreditSpendIndicator
                                    creditAction={importResumeCreditAction}
                                    textClassName="text-right"
                                >
                                    <Button
                                        variant="solid"
                                        color="primary"
                                        onClick={handleNextStep}
                                        rightIcon={<ArrowRight size={16} />}
                                        disabled={!selectedExample}
                                    >
                                        Create Resume
                                    </Button>
                                </CreditSpendIndicator>
                            ) : (
                                <Button
                                    variant="solid"
                                    color="primary"
                                    onClick={handleNextStep}
                                    rightIcon={<ArrowRight size={16} />}
                                    disabled={!selectedExample}
                                >
                                    Create Resume
                                </Button>
                            )}
                        </div>
                    </div>
                );
        }
    };

    return (
        <Modal
            open={isOpen}
            modalType={ModalTypes.NEW_RESUME}
            width="4xl"
            title={getStepTitle(currentStep)}
            footer={renderFooter()}
            closeOnOverlayClick={false}
            mobileFullScreen={true}
            gradientBackground={
                currentStep === ResumeStep.DETAILS_LOADING ||
                currentStep === ResumeStep.IMPORT_LOADING
            }
            noHeader={
                currentStep === ResumeStep.DETAILS_LOADING ||
                currentStep === ResumeStep.IMPORT_LOADING
            }
            disableScroll
            afterClose={handleResetModal}
        >
            {renderCurrentStep()}
        </Modal>
    );
};

export default NewResumeModal;
