import {
    AddNewSectionModal,
    HelpGuideModal,
    DocumentLimitModal,
    PreviewResumeModal,
    RearrangeModal,
    RenameResumeModal,
    ResumeAdminModal,
    JobRoleModal,
    PreferencesModal,
    DeleteResumeModal,
    PaymentSuccessModal,
    InsufficientFundsModal,
    DownloadResumeModal,
    BuyCreditsModal,
    CreditsSuccessModal,
    AddLinkModal,
    DesktopOnlyModal,
    PromptHistoryModal,
    CreditsWelcomeModal,
    ReferralModal,
    NewResumeModal,
} from "@/components/Modals";

const ModalManager = () => {
    return (
        <>
            <RearrangeModal />
            <HelpGuideModal />
            <RenameResumeModal />
            <AddNewSectionModal />
            <DocumentLimitModal />
            <ResumeAdminModal />
            <PreviewResumeModal />
            <JobRoleModal />
            <PreferencesModal />
            <DeleteResumeModal />
            <PaymentSuccessModal />
            <InsufficientFundsModal />
            <DownloadResumeModal />
            <BuyCreditsModal />
            <CreditsSuccessModal />
            <AddLinkModal />
            <DesktopOnlyModal />
            <PromptHistoryModal />
            <CreditsWelcomeModal />
            <ReferralModal />
            <NewResumeModal />
        </>
    );
};

export default ModalManager;
