import { DropdownItemProps } from "@/components/Dropdown/Dropdown";
import { translate } from "@/helper/translate";

export const MIN_JD_CHARS = 80;
export const MIN_RESUME_TEXT_CHARS = 80;
export const MAX_RESUME_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes
export const ALLOWED_RESUME_FILE_TYPES = [
	"application/pdf",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
	// "application/msword", // .doc
];

export const jobDescriptionExampleOptions: DropdownItemProps[] = [
	{
		title: "Software Engineer",
		id: "software-engineer",
	},
	{
		title: "Data Scientist",
		id: "data-scientist",
	},
	{
		title: "Designer",
		id: "designer",
	},
	{
		title: "Financial Analyst",
		id: "financial-analyst",
	},
	{
		title: "Project Management",
		id: "project-management",
	},
	{
		title: "Sales",
		id: "sales",
	},
	{
		title: "Teacher",
		id: "teacher",
	},
];

const softwareEngineerJobDescription = () => `Job Title: Software Engineer

Job Summary:
We are seeking a skilled Software Engineer to design, develop, and maintain high-quality software solutions. The ideal candidate will have strong programming skills, experience with modern development frameworks, and a passion for building scalable and efficient applications.

Responsibilities:
	•	Develop, test, and maintain software applications following best practices.
	•	Write clean, efficient, and maintainable code in programming languages such as Java, Python, JavaScript, or C#.
	•	Collaborate with cross-functional teams to define requirements and deliver high-quality solutions.
	•	Troubleshoot and debug software issues to ensure optimal performance.
	•	Participate in code reviews and provide constructive feedback to improve code quality.
	•	Implement and maintain APIs and database systems.
	•	Stay updated on emerging technologies and industry trends to improve development practices.

Requirements:
	•	Bachelor’s degree in Computer Science, Software Engineering, or a related field.
	•	Proficiency in at least one major programming language (e.g., Java, Python, JavaScript).
	•	Experience with front-end and/or back-end development frameworks (e.g., React, Node.js, Spring Boot, Django).
	•	Knowledge of database management systems (SQL, NoSQL).
	•	Familiarity with cloud platforms (AWS, GCP, or Azure) is a plus.
	•	Strong problem-solving skills and the ability to work independently or as part of a team.
`;

const dataScientistJobDescription = () => `Job Title: Data Scientist

Job Summary:
We are looking for a highly analytical and results-driven Data Scientist to help transform data into actionable insights. The ideal candidate will have expertise in statistical analysis, machine learning, and data ${translate("visualisation", "lower")}, with the ability to solve complex business problems using data-driven approaches.

Responsibilities:
	•	Collect, clean, and ${translate("analyse", "lower")} large datasets to extract meaningful insights.
	•	Develop and implement machine learning models to support business objectives.
	•	Apply statistical techniques to identify trends, patterns, and correlations.
	•	Build and maintain dashboards and ${translate("visualizations")} to communicate findings.
	•	Collaborate with cross-functional teams to ${translate("optimise")} data-driven decision-making.
	•	Work with data engineers to improve data pipelines and infrastructure.
	•	Stay up to date with advancements in AI, machine learning, and data science best practices.

Requirements:
	•	Bachelor’s or Master’s degree in Data Science, Computer Science, Mathematics, or a related field.
	•	Strong programming skills in Python or R, with experience in SQL.
	•	Knowledge of machine learning frameworks such as TensorFlow, PyTorch, or Scikit-Learn.
	•	Experience with data visualization tools like Tableau, Power BI, or Matplotlib.
	•	Understanding of cloud computing platforms (AWS, GCP, or Azure) is a plus.
	•	Excellent problem-solving and communication skills.
`;

const designerJobDescription = () => `Job Title: Graphic Designer

Job Summary:
We are looking for a creative and detail-oriented Designer to develop visually appealing and user-friendly designs across digital and print media. The ideal candidate will have a strong portfolio showcasing expertise in graphic design, branding, and/or UI/UX design principles.

Responsibilities:
	•	Create compelling visual designs for websites, apps, marketing materials, and branding assets.
	•	Develop wireframes, prototypes, and high-fidelity UI/UX designs for digital products.
	•	Collaborate with product managers, developers, and marketing teams to bring ideas to life.
	•	Ensure consistency in design elements, typography, and color schemes across all platforms.
	•	${translate("Optimise", "title")} user experiences by conducting usability testing and gathering feedback.
	•	Stay up to date with design trends, tools, and best practices.

Requirements:
	•	Bachelor’s degree in Graphic Design, UI/UX Design, or a related field (or equivalent experience).
	•	Proficiency in Adobe Creative Suite (Photoshop, Illustrator, XD, InDesign), Figma, or Sketch.
	•	Experience in UI/UX design with knowledge of wireframing and prototyping tools.
	•	Strong understanding of typography, color theory, and layout design.
	•	Ability to work in a fast-paced environment and manage multiple projects.
	•	Experience with HTML, CSS, and front-end design principles is a plus.
`;

const financialAnalystJobDescription = () => `Job Title: Financial Analyst

Job Summary:
We are looking for a detail-oriented Financial Analyst to support business decision-making through financial modeling, data analysis, and forecasting. The ideal candidate will have strong analytical skills, financial acumen, and the ability to communicate insights effectively.

Responsibilities:
	•	${translate("Analyse", "title")} financial data and trends to support strategic decision-making.
	•	Develop and maintain financial models for forecasting and budgeting.
	•	Prepare reports and presentations for senior management and stakeholders.
	•	Assess financial risks and opportunities, providing actionable insights.
	•	Conduct variance analysis to compare actual vs. projected performance.
	•	Collaborate with teams across departments to optimize financial planning.

Requirements:
	•	Bachelor’s degree in Finance, Accounting, Economics, or a related field.
	•	Proficiency in Excel, financial modeling, and data visualization tools (Power BI, Tableau).
	•	Strong understanding of financial statements, ratios, and valuation methods.
	•	Experience with ERP systems (SAP, Oracle) is a plus.
	•	Excellent analytical and communication skills.
`;

const projectManagementJobDescription = () => `Job Title: Project Manager

Job Summary:
We are looking for a highly organized Project Manager to lead and coordinate projects from initiation to completion. The ideal candidate will have experience in project planning, resource management, and stakeholder communication.

Responsibilities:
	•	Define project scope, objectives, and deliverables.
	•	Develop project timelines, budgets, and resource allocation plans.
	•	Monitor progress, identify risks, and implement mitigation strategies.
	•	Collaborate with cross-functional teams to ensure smooth execution.
	•	Communicate project status to stakeholders and leadership.
	•	Ensure projects meet quality standards and business goals.

Requirements:
	•	Bachelor’s degree in Business, Project Management, or a related field.
	•	Project Management Certification (PMP, PRINCE2) is a plus.
	•	Experience with project management tools (Jira, Trello, Asana, Monday.com).
	•	Strong leadership, organizational, and problem-solving skills.
	•	Ability to manage multiple projects simultaneously in a fast-paced environment.
`;

const salesJobDescription = () => `Job Title: Sales Representative

Job Summary:
We are seeking a results-driven Sales Representative to identify leads, build relationships, and drive revenue growth. The ideal candidate will have strong interpersonal skills and a passion for delivering value to customers.

Responsibilities:
	•	Identify and engage potential customers through prospecting and networking.
	•	Conduct sales presentations and product demonstrations.
	•	Build and maintain long-term relationships with clients.
	•	Negotiate contracts and close deals to meet sales targets.
	•	Track sales performance and provide regular reports.
	•	Stay up to date on market trends and competitor offerings.

Requirements:
	•	Bachelor’s degree in Business, Marketing, or a related field (preferred).
	•	Proven experience in sales, business development, or account management.
	•	Strong communication and negotiation skills.
	•	Ability to work independently and manage multiple leads.
	•	Experience with CRM software (Salesforce, HubSpot, or similar).
`;

const teacherJobDescription = () => `Job Title: Elementary School Teacher

Job Summary:
We are seeking a passionate and dedicated Elementary School Teacher to create a dynamic and engaging learning environment for students. The ideal candidate will have a strong background in education, excellent classroom management skills, and a commitment to fostering academic and personal growth in students.

Responsibilities:
	•	Develop and implement lesson plans that align with curriculum standards.
	•	Create an engaging and inclusive classroom environment that supports student learning.
	•	Assess student progress through tests, assignments, and class participation.
	•	Adapt teaching methods to accommodate diverse learning styles and needs.
	•	Maintain classroom discipline and promote positive student behavior.
	•	Communicate effectively with parents, guardians, and school staff regarding student progress.
	•	Participate in professional development and collaborate with fellow educators.

Requirements:
	•	Bachelor’s degree in Education or a related field.
	•	Teaching certification or licensure (as required by state or country).
	•	Experience in classroom teaching, preferably at the elementary level.
	•	Strong communication, organizational, and problem-solving skills.
	•	Ability to use educational technology and digital learning tools effectively.
`;

export const getJobDescriptionExample = (id: string) => {
	switch (id) {
		case "software-engineer":
			return softwareEngineerJobDescription();
		case "data-scientist":
			return dataScientistJobDescription();
		case "designer":
			return designerJobDescription();
		case "financial-analyst":
			return financialAnalystJobDescription();
		case "project-management":
			return projectManagementJobDescription();
		case "sales":
			return salesJobDescription();
		case "teacher":
			return teacherJobDescription();
		default:
			return "";
	}
};