import { Transition } from "@headlessui/react";
import { ThumbsDown, ThumbsUp, SmileIcon, X } from "lucide-react";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import aiApi from "@/api/ai";
import { Button } from "@/components/Button/Button";
import { AppText } from "@/components/Text/AppText";
import { TextArea } from "@/components/form/Textarea";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useDevice } from "@/hooks/useDevice";
import {
    getShowImportFeedback,
    getTemplateLoadingState,
} from "@/store/app/selectors";
import { setShowImportFeedback } from "@/store/app/slice";
import { getActiveResumeId } from "@/store/resume/selectors";
import { TemplateLoadingState } from "@/types/app";
import { CreditActionCode } from "@/types/creditAction";
import { DeviceContextType } from "@/types/device";
import { CLARITY_EVENT_NAMES } from "@/types/tracking";
import { trackClarityEvent, trackPostHogEvent } from "@/utils/tracking";

export const ImportFeedback = () => {
    const showImportFeedback = useAppSelector(getShowImportFeedback);
    const dispatch = useAppDispatch();
    const [rating, setRating] = useState<"positive" | "negative" | null>(null);
    const [comments, setComments] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const activeResumeId = useAppSelector(getActiveResumeId);
    const { isMobile } = useDevice() as DeviceContextType;
    const [showDelayed, setShowDelayed] = useState(false);
    const templateLoadingState = useAppSelector(getTemplateLoadingState);

    // Add delay before showing the feedback component
    useEffect(() => {
        let timeoutId: ReturnType<typeof setTimeout>;

        // Only start the timer when both conditions are met:
        // 1. showImportFeedback is true
        // 2. templateLoadingState is READY
        if (
            showImportFeedback &&
            templateLoadingState === TemplateLoadingState.READY
        ) {
            timeoutId = setTimeout(() => {
                setShowDelayed(true);
            }, 2000); // 2 second delay after template is ready
        } else {
            setShowDelayed(false);
        }

        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [showImportFeedback, templateLoadingState]);

    const handleClose = useCallback(() => {
        dispatch(setShowImportFeedback(false));
    }, [dispatch]);

    const submitFeedback = useCallback(
        async (
            feedbackRating: "positive" | "negative",
            feedbackComments: string,
        ) => {
            setIsSubmitting(true);

            try {
                trackClarityEvent(
                    CLARITY_EVENT_NAMES.IMPORT_FEEDBACK_SUBMITTED,
                );
                trackPostHogEvent("resume_import_feedback", {
                    feature_name: "new_resume",
                    feedback_rating: feedbackRating,
                    feedback_comments: feedbackComments,
                });
                setIsSuccess(true);

                await aiApi.createAiFeedback({
                    creditActionCode: CreditActionCode.IMPORT_RESUME,
                    userInput: "Imported resume",
                    aiResponse: activeResumeId ?? "Resume",
                    feedback:
                        feedbackRating === "positive"
                            ? "thumbs_up"
                            : "thumbs_down",
                    action: "thumb",
                    userEditedResponse: feedbackComments,
                });

                setTimeout(() => {
                    handleClose();
                }, 3000);
            } catch (error) {
                console.error("Error submitting import feedback:", error);
            } finally {
                setIsSubmitting(false);
            }
        },
        [activeResumeId, handleClose],
    );

    const handleRating = useCallback(
        (value: "positive" | "negative") => {
            setRating(value);
            trackClarityEvent(
                value === "positive"
                    ? CLARITY_EVENT_NAMES.IMPORT_FEEDBACK_POSITIVE
                    : CLARITY_EVENT_NAMES.IMPORT_FEEDBACK_NEGATIVE,
            );
            trackPostHogEvent(
                value === "positive"
                    ? CLARITY_EVENT_NAMES.IMPORT_FEEDBACK_POSITIVE
                    : CLARITY_EVENT_NAMES.IMPORT_FEEDBACK_NEGATIVE,
            );

            if (value === "positive") {
                submitFeedback(value, "");
            }
        },
        [submitFeedback],
    );

    const handleSubmit = useCallback(
        (e: React.FormEvent) => {
            e.preventDefault();
            if (rating === "negative") {
                submitFeedback(rating, comments);
            }
        },
        [comments, rating, submitFeedback],
    );

    // Position differently based on device
    const positionClass = isMobile
        ? "fixed bottom-4 left-4 right-4 z-50"
        : "fixed bottom-4 right-16 z-50 max-w-xs";

    return (
        <Transition
            show={showImportFeedback && showDelayed}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-4"
        >
            <div
                className={`${positionClass} rounded-lg bg-white shadow-xl ring-1 ring-neutral-200`}
            >
                <div className="rounded-lg p-3">
                    {isSuccess ? (
                        <div className="animate-in fade-in mx-auto text-center duration-700">
                            <SmileIcon className="mx-auto mb-2 h-8 w-8 animate-pulse text-primary-700" />
                            <AppText
                                variant="labelsbuttons"
                                className="mb-1 block !leading-tight text-neutral-900"
                            >
                                Thank you!
                            </AppText>
                            <AppText
                                variant="footnote"
                                className="block !leading-tight text-neutral-700"
                            >
                                We appreciate your feedback.
                            </AppText>
                        </div>
                    ) : (
                        <>
                            {!rating ? (
                                <div className="space-y-3">
                                    <div className="flex items-center justify-between">
                                        <AppText
                                            variant="footnote"
                                            className="block !leading-tight text-neutral-900"
                                        >
                                            How was your import experience?
                                        </AppText>
                                        <button
                                            onClick={handleClose}
                                            className="ml-2 text-neutral-400 hover:text-neutral-600"
                                            aria-label="Close feedback"
                                        >
                                            <X size={16} />
                                        </button>
                                    </div>

                                    <div className="flex justify-center gap-8">
                                        <button
                                            onClick={() =>
                                                handleRating("positive")
                                            }
                                            className="group flex flex-col items-center transition-all hover:opacity-80"
                                            aria-label="Positive feedback"
                                        >
                                            <div className="mb-1 text-neutral-500 transition-colors group-hover:text-green-600">
                                                <ThumbsUp
                                                    className={
                                                        isMobile
                                                            ? "h-6 w-6"
                                                            : "h-5 w-5"
                                                    }
                                                />
                                            </div>
                                            <AppText
                                                variant="footnote"
                                                className="text-xs text-neutral-500 group-hover:text-green-600"
                                            >
                                                Good
                                            </AppText>
                                        </button>

                                        <button
                                            onClick={() =>
                                                handleRating("negative")
                                            }
                                            className="group flex flex-col items-center transition-all hover:opacity-80"
                                            aria-label="Negative feedback"
                                        >
                                            <div className="mb-1 text-neutral-500 transition-colors group-hover:text-red-600">
                                                <ThumbsDown
                                                    className={
                                                        isMobile
                                                            ? "h-6 w-6"
                                                            : "h-5 w-5"
                                                    }
                                                />
                                            </div>
                                            <AppText
                                                variant="footnote"
                                                className="text-xs text-neutral-500 group-hover:text-red-600"
                                            >
                                                Needs work
                                            </AppText>
                                        </button>
                                    </div>
                                </div>
                            ) : rating === "negative" ? (
                                <form
                                    onSubmit={handleSubmit}
                                    className="space-y-3"
                                >
                                    <div className="flex items-center justify-between">
                                        <AppText
                                            variant="regular"
                                            className="block !font-medium !leading-tight text-neutral-900"
                                        >
                                            Help us improve
                                        </AppText>
                                        <button
                                            onClick={handleClose}
                                            className="ml-2 text-neutral-400 hover:text-neutral-600"
                                            aria-label="Close feedback"
                                        >
                                            <X size={16} />
                                        </button>
                                    </div>

                                    <div className="space-y-1">
                                        <AppText
                                            variant="footnote"
                                            className="block !leading-tight text-neutral-700"
                                        >
                                            Please tell us what went wrong:
                                        </AppText>

                                        <TextArea
                                            value={comments}
                                            onChange={e =>
                                                setComments(e.target.value)
                                            }
                                            rows={isMobile ? 2 : 3}
                                            name="comments"
                                            id="import-comments"
                                            required
                                            minLength={10}
                                            maxLength={300}
                                            placeholder="What issues did you encounter? (10-300 characters)"
                                            className="text-sm placeholder:py-1 placeholder:text-xs"
                                        />
                                    </div>

                                    <div className="flex justify-end gap-2">
                                        <Button
                                            variant="text"
                                            color="grey"
                                            size="small"
                                            onClick={handleClose}
                                            disabled={isSubmitting}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="submit"
                                            size="small"
                                            disabled={
                                                isSubmitting ||
                                                comments.length < 10
                                            }
                                        >
                                            {isSubmitting
                                                ? "Submitting..."
                                                : "Submit"}
                                        </Button>
                                    </div>
                                </form>
                            ) : null}
                        </>
                    )}
                </div>
            </div>
        </Transition>
    );
};
