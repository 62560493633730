import { AIModel } from "@/types/ai";

interface ToolHeader {
    title: string;
    description: string;
    recommendedTemperature: number;
    recommendedTool: AIModel;
    recommendedToolTwo?: AIModel;
}

interface AIToolHeaderProps {
    activeTab: string;
}

export const AIToolHeader = ({ activeTab }: AIToolHeaderProps) => {
    const getToolHeader = (activeTab: string): ToolHeader => {
        switch (activeTab) {
            case "ai-resume-formatter":
                return {
                    title: "Resume Formatter",
                    description:
                        "Format resume text to be in our resume document shape. Output should be a JSON object with specific sections. Use a lower temperature for this tool.",
                    recommendedTemperature: 0.3,
                    recommendedTool: "gemini-2.0-flash-lite",
                };
            case "ai-resume-rewriter":
                return {
                    title: "Resume Rewriter",
                    description:
                        "Rewrite your resume to be more readable and professional. Used as part of the importer",
                    recommendedTemperature: 0.7,
                    recommendedTool: "gemini-2.0-flash-lite",
                };
            case "ai-resume-importer":
                return {
                    title: "Resume Importer",
                    description:
                        "Rewrite and then Reformat a resume. This uses two tools in one. First, it rewrites the resume to be more readable and professional. Then, it reformats the resume to be in our resume document shape. Use a lower temperature for this tool.",
                    recommendedTemperature: 0.3,
                    recommendedTool: "gemini-2.0-flash-lite",
                    recommendedToolTwo: "gemini-2.0-flash-lite",
                };
            case "ai-smart-match":
                return {
                    title: "Smart Match",
                    description:
                        "Match your resume to a job description for scores and recommendations.",
                    recommendedTemperature: 0.5,
                    recommendedTool: "gemini-2.0-flash-lite",
                };
            case "ai-bullet-points":
                return {
                    title: "Bullet Points",
                    description:
                        "Generate professional bullet points for your resume using the context provided.",
                    recommendedTemperature: 0.7,
                    recommendedTool: "gpt-4o-mini",
                };
            case "ai-write-prompt":
                return {
                    title: "Write Prompt",
                    description: "Rewrite content based on the selected tool.",
                    recommendedTemperature: 0.7,
                    recommendedTool: "gpt-4o-mini",
                };
            default:
                return {
                    title: "AI Tool",
                    description: "Choose an AI tool to get started.",
                    recommendedTemperature: 0.5,
                    recommendedTool: "gpt-4o-mini",
                };
        }
    };

    const header = getToolHeader(activeTab);

    return (
        <div className="mb-2 grid grid-cols-12 items-center gap-6 border-b px-6 py-3">
            <div className="col-span-2">
                <h3 className="text-base font-semibold leading-tight">
                    {header.title}
                </h3>
            </div>
            <div className="col-span-6">
                <p className="text-xs leading-relaxed text-gray-600">
                    {header.description}
                </p>
            </div>
            <div className="col-span-4 flex flex-col gap-1 text-xs text-gray-500">
                <div className="flex items-center gap-6">
                    <span className="flex items-center gap-2">
                        <span className="font-medium">Temperature:</span>
                        {header.recommendedTemperature}
                    </span>
                    <span className="flex items-center gap-2">
                        <span className="font-medium">Model:</span>
                        {header.recommendedTool}
                    </span>
                </div>
                {header.recommendedToolTwo && (
                    <div className="flex items-center gap-2">
                        <span className="font-medium">Secondary Model:</span>
                        {header.recommendedToolTwo}
                    </div>
                )}
            </div>
        </div>
    );
};
