import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { getTokenHelper } from "@/helper/getTokens";
import { useAppSelector } from "@/hooks/types";
import { useDevice } from "@/hooks/useDevice";
import { getActiveResume } from "@/store/resume/selectors";
import { getUserDetails } from "@/store/user/selectors";
import { UsingReactChildren } from "@/types";
import { DeviceContextType } from "@/types/device";
import { AppRoutes } from "@/types/routes";
import { getIsAdminRole } from "@/utils/getIsRole";
import { getIsDesktopOnlyRoute, getIsResumePage } from "@/utils/routes";

export const ProtectedRoute = ({ children }: UsingReactChildren) => {
    const token = getTokenHelper("accessToken");
    const isDesktopOnlyRoute = getIsDesktopOnlyRoute();
    const { isDesktop } = useDevice() as DeviceContextType;
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const userDetails = useAppSelector(getUserDetails);
    const isResumePage = getIsResumePage();
    const activeResume = useAppSelector(getActiveResume);
    const isAdminUser = getIsAdminRole(userDetails?.role);

    useEffect(() => {
        if (isDesktopOnlyRoute && !isDesktop) {
            setShouldRedirect(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Purposefully leaving empty so only runs on mount of page.

    useEffect(() => {
        // If the user is not the owner of the resume, redirect to the dashboard
        if (
            isResumePage &&
            activeResume &&
            userDetails?.id !== activeResume?.user &&
            !isAdminUser
        ) {
            setShouldRedirect(true);
        }
    }, [userDetails, activeResume, isResumePage, isAdminUser]);

    if (!token?.token) {
        // user is not authenticated
        return <Navigate to={`/${AppRoutes.Login}`} />;
    } else if (shouldRedirect) {
        window.location.href = "/";
        return null;
    }

    return children;
};
