import { ThumbsDown, ThumbsUp } from "lucide-react";
import { useState } from "react";
import { AdminResumeFormatter } from "./AdminResumeFormatter";
import { AiHistory } from "./components/AiHistory";
import aiApi, { AiResponse } from "@/api/ai";
import { useAppSelector } from "@/hooks/types";
import { AIPreferencesConfig } from "@/pages/Admin/AI/AIPreferencesConfig";
import { AdminResumeImporter } from "@/pages/Admin/AI/AdminResumeImporter";
import { AdminResumeRewriter } from "@/pages/Admin/AI/AdminResumeRewriter";
import { AdminSmartMatch } from "@/pages/Admin/AI/AdminSmartMatch";
import { AIToolHeader } from "@/pages/Admin/AI/components/AIToolHeader";
import { AdminFormContainer } from "@/pages/Admin/AdminFormContainer";
import {
    generateBulletPointsPrompt,
    generateSummaryPrompt,
    generateWritePrompt,
} from "@/pages/Admin/utils/generateAiPrompt";
import { getUserId } from "@/store/user/selectors";
import { AdminNavigationItem } from "@/types/admin";
import { AIModel, AiHistoryEntry } from "@/types/ai";
import { CreditActionCode } from "@/types/creditAction";

const aiDashboardNavItems: AdminNavigationItem[] = [
    { id: "ai-summary", label: "Paragraph Pro" },
    { id: "ai-bullet-points", label: "Bullet Builder" },
    { id: "ai-quick-prompts", label: "AI Quick Prompts" },
    { id: "ai-resume-formatter", label: "AI Resume Formatter" },
    { id: "ai-resume-rewriter", label: "AI Resume Rewriter" },
    { id: "ai-resume-importer", label: "AI Resume Importer" },
    { id: "ai-smart-match", label: "AI Job Smart Match" },
];

export interface AIModelConfig {
    model: AIModel;
    temperature: number;
    overrideRewriteModel: AIModel;
    overrideFormatModel: AIModel;
    rewriteTemperature: number;
    formatTemperature: number;
}

export const AiDashboard = () => {
    const [activeTab, setActiveTab] = useState<string>("ai-summary");
    const [modelConfig, setModelConfig] = useState<AIModelConfig>({
        model: "gpt-4o-mini",
        temperature: 0.7,
        overrideRewriteModel: "gpt-4o-mini",
        overrideFormatModel: "gpt-4o-mini",
        rewriteTemperature: 0.7,
        formatTemperature: 0.3,
    });
    const userId = useAppSelector(getUserId);
    const [selectedJobRoles, setSelectedJobRoles] = useState<string[]>([]);
    const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
    const [selectedExperience, setSelectedExperience] = useState<string>("Any");
    const [additionalContext, setAdditionalContext] = useState<string>("");
    const [generatedOutput, setGeneratedOutput] = useState<string>("");
    const [modelUsed, setModelUsed] = useState<number | null>(null);
    const [sectionName, setSectionName] = useState<string>("Work Experience");
    const [writePrompt, setWritePrompt] = useState<CreditActionCode>(
        CreditActionCode.AI_PROMPT_REWRITE,
    );
    const [isGenerating, setIsGenerating] = useState(false);
    const [isEditingPrompt, setIsEditingPrompt] = useState(false);
    const [customPrompt, setCustomPrompt] = useState<string>("");
    const [hasRated, setHasRated] = useState(false);
    const [activeRating, setActiveRating] = useState<
        "Positive" | "Negative" | null
    >(null);
    const [history, setHistory] = useState<AiHistoryEntry[]>([]);

    const toggleJobRole = (role: string) => {
        setSelectedJobRoles(prev =>
            prev.includes(role)
                ? prev.filter(r => r !== role)
                : [...prev, role],
        );
    };

    const toggleIndustry = (industry: string) => {
        setSelectedIndustries(prev =>
            prev.includes(industry)
                ? prev.filter(i => i !== industry)
                : [...prev, industry],
        );
    };

    const handleClearHistory = () => {
        setHistory([]);
    };

    const handleSelectHistoryEntry = (entry: AiHistoryEntry) => {
        setActiveTab(entry.tool);
        setAdditionalContext(entry.input.context || "");
        setSelectedJobRoles(entry.input.jobRoles || []);
        setSelectedIndustries(entry.input.industries || []);
        setSelectedExperience(entry.input.experience || "Any");
        if (entry.input.sectionName) {
            setSectionName(entry.input.sectionName);
        }
        if (entry.input.writePrompt) {
            setWritePrompt(entry.input.writePrompt);
        }
        setGeneratedOutput(entry.output);
    };

    const handleGenerate = async () => {
        const startTime = Date.now();
        setHasRated(false);
        setActiveRating(null);
        // Check estimated tokens before generating
        const promptText = isEditingPrompt ? customPrompt : generatePrompt();
        const estimatedTokens = Math.ceil(promptText.length / 4);

        if (estimatedTokens > 500) {
            const shouldProceed = window.confirm(
                `Warning: Estimated prompt token count (${estimatedTokens}) exceeds recommended limit of 500 tokens. This may result in higher costs and potential truncation. Do you want to proceed?`,
            );

            if (!shouldProceed) {
                return;
            }
        }

        setIsGenerating(true);
        try {
            let response: AiResponse = {
                output: "",
            };

            if (activeTab === "ai-summary") {
                response = await aiApi.generateParagraphPro({
                    temperature: modelConfig.temperature,
                    overridePrompt: promptText ?? "",
                    adminId: userId,
                    model: modelConfig.model,
                });
            }

            if (activeTab === "ai-bullet-points") {
                response = await aiApi.generateBulletBuilder({
                    temperature: modelConfig.temperature,
                    overridePrompt: promptText ?? "",
                    sectionType: sectionName,
                    adminId: userId,
                    model: modelConfig.model,
                });
            }

            if (activeTab === "ai-quick-prompts") {
                response = await aiApi.generateQuickPrompt({
                    temperature: modelConfig.temperature,
                    content: additionalContext,
                    creditActionCode: writePrompt,
                    adminId: userId,
                    model: modelConfig.model,
                });
            }

            setGeneratedOutput(response.output);
            setModelUsed(response.modelUsed ?? null);

            // Add the new entry to history after successful generation
            const newEntry: AiHistoryEntry = {
                id: Date.now().toString(),
                timestamp: new Date().toISOString(),
                tool: activeTab,
                input: {
                    context: additionalContext,
                    jobRoles: selectedJobRoles,
                    industries: selectedIndustries,
                    experience: selectedExperience,
                    sectionName,
                    writePrompt,
                    temperature: modelConfig.temperature,
                },
                output: response.output,
                duration: (Date.now() - startTime) / 1000,
                modelUsed: response.modelUsed?.toString(),
            };
            setHistory(prev => [newEntry, ...prev]);
        } catch (error) {
            console.error("Error generating content:", error);
            setGeneratedOutput("Error: " + error);
        } finally {
            setIsGenerating(false);
        }
    };

    const handleClear = () => {
        setGeneratedOutput("");
    };

    const handleResetJobRoles = () => {
        setSelectedJobRoles([]);
    };

    const handleResetExperience = () => {
        setSelectedExperience("");
    };

    const handleResetIndustries = () => {
        setSelectedIndustries([]);
    };

    const generatePrompt = () => {
        if (activeTab === "ai-summary") {
            return generateSummaryPrompt(
                customPrompt,
                selectedJobRoles,
                selectedExperience,
                selectedIndustries,
                additionalContext,
            );
        }
        if (activeTab === "ai-bullet-points") {
            return generateBulletPointsPrompt(
                customPrompt,
                additionalContext,
                sectionName,
            );
        }
        if (activeTab === "ai-quick-prompts") {
            return generateWritePrompt(writePrompt, additionalContext);
        }
        return "Invalid feature";
    };

    const submitFeedback = async (isPositive: boolean) => {
        const activeCreditActionCode =
            activeTab === "ai-summary"
                ? CreditActionCode.AI_GENERATE_SUMMARY
                : activeTab === "ai-bullet-points"
                  ? CreditActionCode.AI_GENERATE_BULLET_POINTS
                  : writePrompt;

        await aiApi.createAiFeedback({
            creditActionCode: activeCreditActionCode!,
            userInput: additionalContext,
            aiResponse: generatedOutput,
            feedback: isPositive ? "thumbs_up" : "thumbs_down",
            action: "thumb",
            userEditedResponse: null,
            modelUsed: modelUsed ?? undefined,
        });
        setHasRated(true);
        setModelUsed(null);
        setActiveRating(isPositive ? "Positive" : "Negative");
    };

    return (
        <div className="flex min-h-screen flex-col">
            <div className="flex-1 pb-16">
                <AdminFormContainer
                    pageTitle="AI Dashboard"
                    navigationItems={aiDashboardNavItems}
                    onNavigationItemClick={setActiveTab}
                    activeItemId={activeTab}
                >
                    {activeTab === "ai-resume-rewriter" && (
                        <AdminResumeRewriter
                            modelConfig={modelConfig}
                            setModelConfig={setModelConfig}
                            addToHistory={(entry: AiHistoryEntry) =>
                                setHistory(prev => [entry, ...prev])
                            }
                        />
                    )}

                    {activeTab === "ai-smart-match" && (
                        <AdminSmartMatch
                            modelConfig={modelConfig}
                            setModelConfig={setModelConfig}
                            addToHistory={(entry: AiHistoryEntry) =>
                                setHistory(prev => [entry, ...prev])
                            }
                        />
                    )}
                    {activeTab === "ai-resume-formatter" && (
                        <AdminResumeFormatter
                            modelConfig={modelConfig}
                            setModelConfig={setModelConfig}
                            addToHistory={(entry: AiHistoryEntry) =>
                                setHistory(prev => [entry, ...prev])
                            }
                        />
                    )}
                    {activeTab === "ai-resume-importer" && (
                        <AdminResumeImporter
                            modelConfig={modelConfig}
                            setModelConfig={setModelConfig}
                            addToHistory={(entry: AiHistoryEntry) =>
                                setHistory(prev => [entry, ...prev])
                            }
                        />
                    )}
                    {(activeTab === "ai-summary" ||
                        activeTab === "ai-bullet-points" ||
                        activeTab === "ai-quick-prompts") && (
                        <div>
                            <AIToolHeader activeTab={activeTab} />
                            <div className="flex flex-col gap-8 px-8">
                                <div className="grid grid-cols-12 gap-8">
                                    <div className="col-span-4">
                                        <AIPreferencesConfig
                                            toggleJobRole={toggleJobRole}
                                            toggleIndustry={toggleIndustry}
                                            setSelectedExperience={
                                                setSelectedExperience
                                            }
                                            resetJobRoles={handleResetJobRoles}
                                            resetIndustries={
                                                handleResetIndustries
                                            }
                                            resetExperience={
                                                handleResetExperience
                                            }
                                            setContext={setAdditionalContext}
                                            selectedJobRoles={selectedJobRoles}
                                            selectedIndustries={
                                                selectedIndustries
                                            }
                                            selectedExperience={
                                                selectedExperience
                                            }
                                            additionalContext={
                                                additionalContext
                                            }
                                            modelConfig={modelConfig}
                                            setModelConfig={setModelConfig}
                                            activeTab={activeTab}
                                            sectionName={sectionName}
                                            setSectionName={setSectionName}
                                            setWritePrompt={setWritePrompt}
                                            writePrompt={writePrompt}
                                        />
                                    </div>

                                    <div className="col-span-8 rounded-lg border p-4">
                                        <div className="mb-4 flex items-center justify-between">
                                            <h2 className="text-xl font-semibold">
                                                AI Output
                                            </h2>
                                            <div className="flex items-center gap-2">
                                                <div className="flex items-center gap-1">
                                                    {activeRating ? (
                                                        <p className="text-xs text-neutral-600">
                                                            Rated {activeRating}
                                                        </p>
                                                    ) : (
                                                        <p className="text-xs text-neutral-600">
                                                            Rate the response
                                                        </p>
                                                    )}
                                                    <button
                                                        className={`rounded-md p-2 text-neutral-600 transition-colors ${
                                                            hasRated
                                                                ? "cursor-not-allowed opacity-50"
                                                                : "hover:bg-neutral-100 hover:text-primary-600 active:bg-neutral-200"
                                                        }`}
                                                        onClick={() =>
                                                            submitFeedback(true)
                                                        }
                                                        title="Thumbs Up"
                                                        disabled={hasRated}
                                                    >
                                                        <ThumbsUp className="h-5 w-5" />
                                                    </button>
                                                    <button
                                                        className={`rounded-md p-2 text-neutral-600 transition-colors ${
                                                            hasRated
                                                                ? "cursor-not-allowed opacity-50"
                                                                : "hover:bg-neutral-100 hover:text-red-600 active:bg-neutral-200"
                                                        }`}
                                                        onClick={() =>
                                                            submitFeedback(
                                                                false,
                                                            )
                                                        }
                                                        title="Thumbs Down"
                                                        disabled={hasRated}
                                                    >
                                                        <ThumbsDown className="h-5 w-5" />
                                                    </button>
                                                </div>
                                                <button
                                                    onClick={handleClear}
                                                    disabled={
                                                        isGenerating ||
                                                        !generatedOutput
                                                    }
                                                    className={`rounded-md px-4 py-2 text-sm font-medium text-white ${
                                                        isGenerating ||
                                                        !generatedOutput
                                                            ? "cursor-not-allowed bg-neutral-400"
                                                            : "bg-neutral-700 hover:bg-neutral-900"
                                                    }`}
                                                >
                                                    Clear
                                                </button>
                                                <button
                                                    onClick={handleGenerate}
                                                    disabled={
                                                        isGenerating ||
                                                        !additionalContext
                                                    }
                                                    className={`rounded-md px-4 py-2 text-sm font-medium text-white 
                                            ${
                                                isGenerating ||
                                                !additionalContext
                                                    ? "cursor-not-allowed bg-primary-400 opacity-50 hover:bg-primary-400"
                                                    : "bg-primary-700 hover:bg-primary-900 active:bg-primary-800"
                                            }`}
                                                >
                                                    {isGenerating
                                                        ? "Generating..."
                                                        : "Generate"}
                                                </button>
                                            </div>
                                        </div>

                                        <div className="space-y-4">
                                            <div className="min-h-[100px] rounded-lg border border-gray-200 bg-white p-4 text-xs">
                                                {isGenerating ? (
                                                    <div className="flex h-full items-center justify-center">
                                                        <div className="text-xs text-gray-500">
                                                            Generating...
                                                        </div>
                                                    </div>
                                                ) : generatedOutput ? (
                                                    <div className="prose max-w-none">
                                                        <div className="whitespace-pre-wrap text-xs">
                                                            {generatedOutput}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="flex h-full items-center justify-center">
                                                        <div className="text-xs text-gray-500">
                                                            AI response will
                                                            appear here
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </AdminFormContainer>
            </div>

            {history.length > 0 && (
                <AiHistory
                    history={history}
                    onSelectEntry={handleSelectHistoryEntry}
                    onClearHistory={handleClearHistory}
                />
            )}
        </div>
    );
};
