import {
    Document as ReactPdfDocument,
    Page as ReactPdfPage,
    View,
} from "@react-pdf/renderer";
import { PdfColumn } from "@/components/PdfDocument/PdfColumn";
import { PdfSection } from "@/components/PdfDocument/PdfSection";
import { PdfSectionsRow } from "@/components/PdfDocument/PdfSectionsRow";
import { pdfStyles } from "@/components/PdfDocument/styles/pdfStyles";
import { PageSections } from "@/components/PdfDocument/types/pdfTypes";
import { getPdfColumnsSections } from "@/components/PdfDocument/utils/getPdfColumnsSections";
import {
    getColumnWrapperStyle,
    getPageVerticalMarginStyle,
} from "@/components/PdfDocument/utils/getStyles";
import { GLOBAL_STYLE_KEY } from "@/constants/resume";
import { Page as PageType, Resume, Section } from "@/types";
import { ColumnLayout, ResumeStyles, SectionItemDetails } from "@/types/resume";

interface PdfDocumentViewerProps {
    resume?: Resume;
    sectionsConfig: Section[];
    pages: PageType[];
    imageSrc?: string;
}

export const PdfDocumentViewer = ({
    resume,
    sectionsConfig,
    pages,
    imageSrc,
}: PdfDocumentViewerProps) => {
    const resumeStyles: ResumeStyles = resume?.styles ?? {};
    const documentWrapperStyles = {
        fontFamily: resume?.styles[GLOBAL_STYLE_KEY]?.fontFamily,
    };
    const isTwoColumn = resume?.columnLayout === ColumnLayout.DOUBLE;

    if (!resume) return null;

    return (
        <ReactPdfDocument title={resume.name}>
            {pages.map(page => {
                if (!page) return null;

                const pageSections = page.sections.reduce<PageSections>(
                    (acc, pageSection) => {
                        const sectionDetails =
                            resume.sections[pageSection.sectionId] ?? {};

                        const pageSectionBodyItems = page.items.filter(
                            ps => ps.section === pageSection.sectionId,
                        );

                        // For now templates/examples are only a single page, so we can assume
                        // all items from the resume are on this page.
                        const sectionItems = resume.isTemplate
                            ? sectionDetails.body
                            : ((pageSectionBodyItems
                                  .map(ps => {
                                      const bodyItem = sectionDetails.body.find(
                                          bi => bi.__id === ps.itemId,
                                      );
                                      return bodyItem;
                                  })
                                  .filter(Boolean) as SectionItemDetails[]) ??
                              []);

                        const isSplitSection =
                            sectionItems[0] &&
                            sectionItems[0].__id !==
                                sectionDetails.body[0].__id;

                        const sectionConfig = sectionsConfig.find(
                            section =>
                                section.id === sectionDetails.sectionConfigId,
                        );

                        if (!sectionConfig) return acc;

                        acc[pageSection.sectionId] = {
                            details: {
                                ...sectionDetails,
                                body: sectionItems,
                            },
                            config: sectionConfig,
                            columnIndex: sectionDetails.columnIndex,
                            isStaticHeader: !!sectionConfig.isStaticHeader,
                            isSplitSection,
                        };

                        return acc;
                    },
                    {},
                );

                const { headerSection, columns } = getPdfColumnsSections(
                    isTwoColumn,
                    pageSections,
                );

                const pageVerticalMarginStyle = getPageVerticalMarginStyle(
                    page.pageNumber === 0,
                    resumeStyles[GLOBAL_STYLE_KEY]?.pageMarginSize,
                );

                const pageStyle = {
                    ...documentWrapperStyles,
                    ...pageVerticalMarginStyle,
                    ...pdfStyles.previewPageLayout,
                };

                const columnWrapperStyle = getColumnWrapperStyle(
                    isTwoColumn,
                    resumeStyles[GLOBAL_STYLE_KEY]?.pageMarginSize,
                );

                return (
                    <ReactPdfPage
                        size="A4"
                        dpi={96}
                        key={page.pageNumber}
                        style={pageStyle}
                        wrap={false} // Disable React PDF's default wrapping as we handle it with our own pagination
                    >
                        {headerSection && (
                            <PdfSection
                                resumeStyles={resumeStyles}
                                config={headerSection.config}
                                details={headerSection.details}
                                isStaticHeader
                                columnIndex={0}
                                isTwoColumn={false}
                                imageSrc={imageSrc}
                                isSplitSection={false}
                            />
                        )}
                        <View style={columnWrapperStyle}>
                            {Object.keys(columns).map(columnKey => {
                                const columnSectionGroupIds =
                                    columns[columnKey];
                                if (!columnSectionGroupIds) return null;
                                return (
                                    <PdfColumn
                                        key={columnKey}
                                        isTwoColumn={isTwoColumn}
                                        columnIndex={Number(columnKey)}
                                        sectionSpacingSize={
                                            resumeStyles[GLOBAL_STYLE_KEY]
                                                ?.sectionSpacingSize
                                        }
                                        pageMarginSize={
                                            resumeStyles[GLOBAL_STYLE_KEY]
                                                ?.pageMarginSize
                                        }
                                    >
                                        {columnSectionGroupIds.map(sections => {
                                            return (
                                                <PdfSectionsRow
                                                    key={sections.join("-")}
                                                    sectionIds={sections}
                                                    pageSections={pageSections}
                                                    resumeStyles={resumeStyles}
                                                    isTwoColumn={isTwoColumn}
                                                />
                                            );
                                        })}
                                    </PdfColumn>
                                );
                            })}
                        </View>
                    </ReactPdfPage>
                );
            })}
        </ReactPdfDocument>
    );
};
