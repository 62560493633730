import clsx from "clsx";
import { ArrowDownAZ, Eye, Hash, PencilLine, PlusIcon } from "lucide-react";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { CreateResumeButton } from "./CreateResumeButton/CreateResumeButton";
import { ResumePreview } from "./ResumePreview";
import { formatTemplateTimestamps, sortResumes } from "./utils";
import resumeApi from "@/api/resume";
import { AdminControlPanel } from "@/components/Admin/AdminControlPanel";
import { Button } from "@/components/Button/Button";
import { Dropdown } from "@/components/Dropdown/Dropdown";
import { FeedbackSurveyButton } from "@/components/Feedback/FeedbackSurveyButton";
import { GradientFilesIcon } from "@/components/Icons/Icons";
import { PageFooter } from "@/components/PageFooter/PageFooter";
import { PageHelmet } from "@/components/PageHelmet/PageHelmet";
import { SwitchToggle } from "@/components/SwitchToggle/SwitchToggle";
import { AppText } from "@/components/Text/AppText";
import { EditorContext } from "@/context/EditorContext";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useInvalidateResumesCache } from "@/hooks/useAppData";
import { EmptyDashboardView } from "@/pages/Dashboard/EmptyDashboardView";
import { resetResumeBuilderState } from "@/routes/utils/resetResumeBuilderState";
import { openModal } from "@/store/modal/slice";
import { getResumesWithDates } from "@/store/resume/selectors";
import {
    getUserDocumentLimit,
    getUserReachedResumeLimit,
} from "@/store/user/selectors";
import { Resume } from "@/types";
import { EditorContextType } from "@/types/editor";
import { ModalTypes } from "@/types/modal";
import { CLARITY_EVENT_NAMES } from "@/types/tracking";
import { trackClarityEvent, trackPostHogEvent } from "@/utils/tracking";

const sortOptions = [
    { id: "1", title: "Last modified", icon: <PencilLine size={20} /> },
    { id: "2", title: "Name", icon: <ArrowDownAZ size={20} /> },
];

const adminSortOptions = [
    { id: "1", title: "Last modified", icon: <PencilLine size={20} /> },
    { id: "2", title: "Name", icon: <ArrowDownAZ size={20} /> },
    { id: "3", title: "Usage Count", icon: <Hash size={20} /> },
    { id: "4", title: "Visibility", icon: <Eye size={20} /> },
];

export const Dashboard = () => {
    const resumes = useAppSelector(getResumesWithDates);
    const { destroyEditors } = useContext(EditorContext) as EditorContextType;
    const dispatch = useAppDispatch();
    const [sortBy, setSortBy] = useState(sortOptions[0].id);
    const [sortedResumes, setSortedResumes] = useState<Resume[]>([]);
    const [sortByOptions, setSortByOptions] = useState(sortOptions);
    const [showTemplates, setShowTemplates] = useState(false);
    const [templates, setTemplates] = useState<Resume[]>([]);
    const documentLimit = useAppSelector(getUserDocumentLimit);
    const reachedResumeLimit = useAppSelector(getUserReachedResumeLimit);
    const hasInvalidatedCache = useRef(false);
    const invalidateResumes = useInvalidateResumesCache();

    useEffect(() => {
        if (!hasInvalidatedCache.current) {
            invalidateResumes();
            hasInvalidatedCache.current = true;
        }
    }, [invalidateResumes]);

    useEffect(() => {
        if (showTemplates) {
            setTemplates(sortResumes(templates, sortBy));
        } else {
            setSortedResumes(sortResumes(resumes, sortBy));
        }
    }, [resumes, showTemplates, sortBy, templates]);

    const onShowTemplates = useCallback(() => {
        const newState = !showTemplates;
        setShowTemplates(newState);
        if (newState) {
            setSortByOptions(adminSortOptions);
            setSortBy(adminSortOptions[0].id);
        } else {
            setSortByOptions(sortOptions);
            setSortBy(sortOptions[0].id);
        }
    }, [showTemplates]);

    const openDocumentLimitModal = useCallback(() => {
        dispatch(openModal({ modalType: ModalTypes.DOCUMENT_LIMIT }));
    }, [dispatch]);

    const handleCreateNewResume = () => {
        trackClarityEvent(CLARITY_EVENT_NAMES.NEW_RESUME_BUTTON_CLICKED);
        trackPostHogEvent(CLARITY_EVENT_NAMES.NEW_RESUME_BUTTON_CLICKED, {
            feature_name: "new_resume",
        });
        if (reachedResumeLimit) {
            openDocumentLimitModal();
        } else {
            dispatch(openModal({ modalType: ModalTypes.NEW_RESUME }));
        }
    };

    const openDeleteResumeModal = useCallback(
        (resumeId: string) => {
            dispatch(
                openModal({
                    modalType: ModalTypes.DELETE_RESUME,
                    props: { resumeId },
                }),
            );
        },
        [dispatch],
    );

    const onFetchTemplates = useCallback(async () => {
        const res = await resumeApi.getAllResume(true);
        const formattedTemplates = formatTemplateTimestamps(res.results);
        // Sort templates by visibility status

        // Then sort by the sortBy variable
        const fullySortedTemplates = sortResumes(formattedTemplates, sortBy);

        setTemplates(fullySortedTemplates);
    }, [sortBy]);

    useEffect(() => {
        // Ensure we kill all editors and reset the builder when navigating between resumes
        destroyEditors();
        resetResumeBuilderState(dispatch);
    }, [destroyEditors, dispatch]);

    return (
        <>
            <PageHelmet title="Your Resumes" />
            <main className="mt-6 min-h-[calc(100vh-200px)] px-3 pb-16 xs:px-8 lg:mt-16">
                <div
                    className={clsx(
                        "mx-0 flex w-full max-w-6xl flex-col justify-between px-0 lg:mx-auto lg:flex-row lg:px-20 xl:w-2/3 xl:px-0",
                        resumes.length === 0
                            ? "mb-6 lg:mb-6 xl:mb-6"
                            : "mb-6 lg:mb-12 xl:mb-16",
                    )}
                >
                    <AppText
                        variant="headings"
                        className="hidden text-neutral-900 md:block"
                    >
                        Your Resumes
                    </AppText>
                    <div className="mt-0 flex flex-col-reverse items-center gap-3 md:mt-6 lg:my-0 lg:flex-row">
                        {resumes.length !== 0 && (
                            <Dropdown
                                title={
                                    sortByOptions.find(o => o.id === sortBy)
                                        ?.title
                                }
                                items={sortByOptions}
                                handleChange={id => setSortBy(id)}
                                activeId={sortBy}
                                className="!w-[240px]"
                                leftIcon={
                                    sortByOptions.find(o => o.id === sortBy)
                                        ?.icon
                                }
                            />
                        )}
                        <div className="flex flex-row-reverse items-center gap-2 lg:flex-row">
                            <Button
                                variant="outline"
                                color="gradient"
                                onClick={openDocumentLimitModal}
                                className="!min-w-0 !px-3"
                                leftIcon={
                                    <GradientFilesIcon className="h-4 w-4" />
                                }
                            >
                                {resumes.length}/{documentLimit}
                            </Button>
                            {resumes.length !== 0 && (
                                <Button
                                    leftIcon={<PlusIcon size={16} />}
                                    onClick={handleCreateNewResume}
                                >
                                    New Resume
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
                {resumes.length === 0 ? (
                    <EmptyDashboardView />
                ) : (
                    <ul
                        role="list"
                        className="dashboard-resume-grid mx-auto grid max-w-6xl justify-center gap-x-4 gap-y-4 sm:gap-x-6 sm:gap-y-4 md:gap-y-6 xl:gap-x-8 xl:gap-y-12"
                    >
                        <CreateResumeButton
                            onClick={handleCreateNewResume}
                            reachedResumeLimit={reachedResumeLimit}
                        />
                        {showTemplates
                            ? templates.map(template => (
                                  <ResumePreview
                                      key={template.id}
                                      resume={template}
                                      onDelete={() =>
                                          openDeleteResumeModal(template.id)
                                      }
                                  />
                              ))
                            : sortedResumes &&
                              sortedResumes.length > 0 &&
                              sortedResumes.map(resume => (
                                  <ResumePreview
                                      key={resume.id}
                                      resume={resume}
                                      onDelete={() =>
                                          openDeleteResumeModal(resume.id)
                                      }
                                  />
                              ))}
                    </ul>
                )}

                <AdminControlPanel topPosition={80}>
                    <div className="flex flex-col gap-2">
                        <div className="mb-2 flex flex-col gap-1 text-xs">
                            <AppText
                                variant="footnote"
                                className="!font-semibold text-primary-500"
                            >
                                Public
                            </AppText>
                            <AppText variant="footnote">
                                Visible to all, listed on examples page
                            </AppText>
                            <AppText
                                variant="footnote"
                                className="!font-semibold text-error-500"
                            >
                                Private
                            </AppText>
                            <AppText variant="footnote">
                                Visible to admins, listed on examples page
                            </AppText>
                            <AppText
                                variant="footnote"
                                className="!font-semibold text-neutral-500"
                            >
                                Unlisted
                            </AppText>
                            <AppText variant="footnote">
                                Visible to admins, not on examples page
                            </AppText>
                        </div>
                        <div className="mb-2 flex items-center gap-2">
                            <AppText variant="labelsbuttons">
                                Show Templates?
                            </AppText>
                            <SwitchToggle
                                enabled={showTemplates}
                                disabled={!templates.length}
                                onToggle={onShowTemplates}
                            />
                        </div>
                        <Button onClick={onFetchTemplates}>Fetch</Button>
                    </div>
                </AdminControlPanel>
                <FeedbackSurveyButton />
            </main>
            <PageFooter />
        </>
    );
};
