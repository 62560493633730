import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { filterXSS } from "xss";
import { AiInputOutput } from "../AiInputOutput";
import { AiToolboxFooter } from "../AiToolboxFooter";
import { AiToolboxHeader } from "../AiToolboxHeader";
import aiApi from "@/api/ai";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useContentEditor } from "@/hooks/useContentEditor";
import { useTransaction } from "@/hooks/useTransaction";
import { useUpdateTemplate } from "@/hooks/useUpdateTemplate";
import {
    getAiToolboxInitialPrompt,
    getAiToolboxPromptOutput,
} from "@/store/app/selectors";
import { resetAiToolbox, updateAiToolbox } from "@/store/app/slice";
import { getActiveSectionDetails } from "@/store/resume/selectors";
import { getActiveItemId, getActiveSection } from "@/store/template/selectors";
import { CreditActionCode } from "@/types/creditAction";
// import { setupDevAutoType } from "@/utils/devUtils";
// import { getIsDevelopmentEnv } from "@/utils/env";
import { convertBulletPointsToHtml } from "@/utils/string";

interface BaseAiFeatureProps {
    onGenerate: (input: string) => Promise<string | undefined>;
    title: string;
    creditActionCode: CreditActionCode;
    maxLength?: number;
    sectionType?: string;
    selection?: {
        from: number;
        to: number;
    };
    hasGenerateError?: boolean;
    handleCancel: () => void;
    modelUsed?: number | null;
}

export const BaseAiFeature = ({
    onGenerate,
    title,
    creditActionCode,
    maxLength = 1000,
    selection,
    sectionType,
    hasGenerateError,
    handleCancel,
    modelUsed,
}: BaseAiFeatureProps) => {
    const initialPrompt = useAppSelector(getAiToolboxInitialPrompt);
    const [isGenerating, setIsGenerating] = useState(false);
    const [userInput, setUserInput] = useState(initialPrompt);
    const { getCreditActionCostByCode } = useTransaction();
    const { setEditorContent, getEditor } = useContentEditor();
    const { updateField } = useUpdateTemplate();
    // const isDev = useMemo(() => getIsDevelopmentEnv(), []);
    const dispatch = useAppDispatch();
    const initialGenerationComplete = useRef(false);
    const activeSectionDetails = useAppSelector(getActiveSectionDetails);
    const activeItemId = useAppSelector(getActiveItemId);
    const generateActionCost = getCreditActionCostByCode(creditActionCode);
    const activeSection = useAppSelector(getActiveSection);

    useEffect(() => {
        setUserInput(initialPrompt);
    }, [initialPrompt]);

    useEffect(() => {
        if (initialPrompt && !initialGenerationComplete.current) {
            initialGenerationComplete.current = true;
            validateAndGenerate();
        }
    }, [initialPrompt]); // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    //     if (isDev) {
    //         const { cleanup } = setupDevAutoType(setUserInput);
    //         return cleanup;
    //     }
    // }, [isDev]);

    const validateAndGenerate = async () => {
        if (isGenerating) return;

        setIsGenerating(true);
        try {
            const trimmedInput = userInput.trim();
            const sanitizedInput = filterXSS(trimmedInput, {
                whiteList: {},
                stripIgnoreTag: true,
                stripIgnoreTagBody: ["script", "style"],
                css: false,
            });

            const output = await onGenerate(sanitizedInput);
            dispatch(updateAiToolbox({ promptOutput: output }));
        } catch (error) {
            dispatch(updateAiToolbox({ promptOutput: "" }));
        } finally {
            setIsGenerating(false);
        }
    };

    const handleBack = useCallback(() => {
        dispatch(updateAiToolbox({ stage: "input" }));
        dispatch(updateAiToolbox({ promptOutput: "" }));
    }, [dispatch]);

    const promptOutput = useAppSelector(getAiToolboxPromptOutput);

    const handleInsert = () => {
        if (selection) {
            setEditorContent(promptOutput, undefined, selection);
        } else {
            const bodyItem = activeSectionDetails?.body.find(
                item => item.__id === activeItemId,
            );
            if (!bodyItem) return;
            const hiddenFields = bodyItem?.hiddenFields ?? [];
            const aiFieldIsHidden = hiddenFields.some(field =>
                field.toLowerCase().includes("description"),
            );
            const isBulletBuilder =
                creditActionCode === CreditActionCode.AI_GENERATE_BULLET_POINTS;
            const formattedContent =
                aiFieldIsHidden || !isBulletBuilder
                    ? promptOutput
                    : convertBulletPointsToHtml(promptOutput);

            if (aiFieldIsHidden) {
                const newHiddenFields = hiddenFields.filter(
                    field => !field.toLowerCase().includes("description"),
                );
                // Unhide field and then select it
                updateField(
                    newHiddenFields,
                    "hiddenFields",
                    "body",
                    activeSection!,
                    activeItemId!,
                    () => {
                        const hasDescriptionEditor = getEditor(
                            `${activeSection}-description-${activeItemId}`,
                        );
                        let editorId = `${activeSection}-description-${activeItemId}`;

                        if (!hasDescriptionEditor) {
                            // Attempt to fallback to accomplishmentDescription
                            const hasAccomplishmentDescriptionEditor =
                                getEditor(
                                    `${activeSection}-accomplishmentDescription-${activeItemId}`,
                                );
                            if (hasAccomplishmentDescriptionEditor) {
                                editorId = `${activeSection}-accomplishmentDescription-${activeItemId}`;
                            }
                        }

                        setEditorContent(
                            formattedContent,
                            editorId,
                            undefined,
                            true,
                        );
                    },
                );
            } else {
                const hasDescriptionEditor = getEditor(
                    `${activeSection}-description-${activeItemId}`,
                );
                let editorId = `${activeSection}-description-${activeItemId}`;

                if (!hasDescriptionEditor) {
                    // Attempt to fallback to accomplishmentDescription
                    // TODO: This is a hack to get the description editor to work for accomplishment section
                    const hasAccomplishmentDescriptionEditor = getEditor(
                        `${activeSection}-accomplishmentDescription-${activeItemId}`,
                    );
                    if (hasAccomplishmentDescriptionEditor) {
                        editorId = `${activeSection}-accomplishmentDescription-${activeItemId}`;
                    }
                }

                setEditorContent(formattedContent, editorId, undefined, true);
            }
            if (userInput) {
                aiApi.createAiFeedback({
                    creditActionCode: creditActionCode,
                    userInput: userInput,
                    aiResponse: promptOutput,
                    feedback: "thumbs_up",
                    action: "insert",
                    userEditedResponse: null,
                    modelUsed: modelUsed ?? undefined,
                });
            }
        }
        dispatch(resetAiToolbox());
    };

    const handleAddBelow = () => {
        setEditorContent(promptOutput, undefined, selection, true);
    };

    const trackCopy = useCallback(() => {
        if (userInput) {
            aiApi.createAiFeedback({
                creditActionCode: creditActionCode,
                userInput: userInput,
                aiResponse: promptOutput,
                feedback: "thumbs_up",
                action: "copy",
                userEditedResponse: null,
                modelUsed: modelUsed ?? undefined,
            });
        }
    }, [userInput, creditActionCode, promptOutput, modelUsed]);

    const subtitle = useMemo(() => {
        if (initialPrompt) {
            return `${title} - "${initialPrompt}"`;
        } else if (promptOutput && userInput) {
            return `${title} - "${userInput}"`;
        }
        return "";
    }, [initialPrompt, promptOutput, title, userInput]);

    return (
        <div className="w-full rounded-lg bg-neutral-100 shadow-lg ring-2 ring-neutral-300">
            <AiToolboxHeader
                subtitle={subtitle}
                creditActionCode={creditActionCode}
            />
            <AiInputOutput
                inputValue={userInput}
                onInputChange={setUserInput}
                maxLength={maxLength}
                animateOutput
                creditActionCode={creditActionCode}
                sectionType={sectionType}
                hasGenerateError={hasGenerateError}
                modelUsed={modelUsed}
            />
            <AiToolboxFooter
                estimatedCredits={generateActionCost}
                onGenerate={validateAndGenerate}
                onInsert={activeItemId ? handleInsert : undefined}
                isGenerating={isGenerating}
                hasInput={!!userInput}
                onBack={handleBack}
                maxLength={maxLength}
                inputValue={userInput}
                trackCopy={trackCopy}
                hasGenerateError={hasGenerateError}
                handleCancel={handleCancel}
                insertAction={selection ? "replace" : "insert"}
                onAddBelow={
                    activeItemId && selection ? handleAddBelow : undefined
                }
            />
        </div>
    );
};
