import { GLOBAL_STYLE_KEY, resumeThemes } from "@/constants/resume";
import { LEFT_HAND_ONLY_COLUMN_SECTIONS } from "@/store/resume/constants";
import { Measurement, ResumeSections, Section } from "@/types";
import { ColumnLayout, Resume } from "@/types/resume";
import { getSectionConfigBySectionId } from "@/utils/section";

export const determineColumnIndex = (
    sectionConfig: Section | undefined,
    layout: ColumnLayout,
    sectionMeasurement: Measurement | undefined,
    columnSectionsCount: Record<number, number>
): number => {
    const isLeftHandSectionOnly = LEFT_HAND_ONLY_COLUMN_SECTIONS.includes(sectionConfig?.sectionType?.toLowerCase() ?? "");
    const isSingleColumnLayout = layout === ColumnLayout.SINGLE;
    const isTallSection = (sectionMeasurement?.height ?? 0) > 350;

    if (isLeftHandSectionOnly || isSingleColumnLayout || isTallSection) {
        return 0;
    } else {
        const numLeftColumn = columnSectionsCount[0];
        const numRightColumn = columnSectionsCount[1];
        return numLeftColumn <= numRightColumn ? 0 : 1;
    }
};

/**
 * Update the sections column and position index when changing the layout.
 * If the layout is single column, all sections will be in the first column.
 * If the layout is double column, the sections will be split between the two columns.
 * Static headers will not be considered in the column split.
 * The sections will be split based on the number of sections. The first half will be in the first column and the second half will be in the second column. If the number of sections is odd, the first column will have one more section.
 * Large sections will never be placed into the right hand column by default.
 * @param resumeSections 
 * @param layout 
 */
export const setResumeSectionsIndexes = (resumeSections: ResumeSections, layout: ColumnLayout, sections: Section[], measurements: Measurement[]) => {
    const resumeSectionsCopy: ResumeSections = JSON.parse(
        JSON.stringify(resumeSections),
    );
    const moveableResumeSectionsKeys = Object.keys(resumeSectionsCopy).filter(
        sectionId => {
            const resSec = resumeSectionsCopy[sectionId];
            const sectionConfig = getSectionConfigBySectionId(sections, resSec.sectionConfigId);
            return !sectionConfig?.isStaticHeader;
        }
    );
    const columnSectionsCount: Record<number, number> = {
        0: 0,
        1: 0,
    };

    moveableResumeSectionsKeys.forEach((sectionId) => {
        const resSec = resumeSectionsCopy[sectionId];
        const sectionConfig = getSectionConfigBySectionId(sections, resSec.sectionConfigId);
        const sectionMeasurement = measurements.find(measurement => measurement.section === sectionId);
        if (sectionConfig?.isStaticHeader) {
            return;
        }

        const addToColumnIndex = determineColumnIndex(sectionConfig, layout, sectionMeasurement, columnSectionsCount);

        resumeSectionsCopy[sectionId].columnIndex = addToColumnIndex;
        resumeSectionsCopy[sectionId].positionIndex =
            columnSectionsCount[addToColumnIndex];
        columnSectionsCount[addToColumnIndex]++;
    });

    return resumeSectionsCopy;
};

export const getSectionIdsWithNewColumnIndexes = (
    measurements: Measurement[],
    sections: ResumeSections,
    sectionConfigs: Section[],
) => {
    const sectionIdsWithNewColumnIndex: { sectionId: string, columnIndex: number; }[] = [];

    measurements
        .forEach(measurement => {
            const sectionConfig = getSectionConfigBySectionId(sectionConfigs, measurement.sectionConfigId);
            if (sectionConfig?.isStaticHeader) {
                return;
            }
            const section = sections[measurement.section];
            if (section.columnIndex !== measurement.columnIndex) {
                sectionIdsWithNewColumnIndex.push({
                    sectionId: measurement.section,
                    columnIndex: section.columnIndex,
                });
            }
        });

    return sectionIdsWithNewColumnIndex;
};

export const getIsDarkTheme = (themeColor: string | undefined) => {
    if (!themeColor) return false;
    return !!resumeThemes.find(theme => theme.profileBackgroundColor.toLowerCase() === themeColor.toLowerCase())?.isDark;
};

export const getIsResumeUsingDarkTheme = (resume: Resume | undefined) => {
    if (!resume) return false;

    const resumeGlobalStyles = resume?.styles?.[GLOBAL_STYLE_KEY];
    const isDarkTheme = getIsDarkTheme(resumeGlobalStyles?.themeColor ?? "");
    return isDarkTheme ?? false;
};
