import {
    ICreditTransaction,
    ICreditTransactionMetadata,
} from "@/types/creditTransaction";
import { apiRequest } from "./request";
import { ApiPaginationRequest, ApiPaginationResponse } from "@/types";
import { CreditActionCategory, CreditActionType } from "@/types/creditAction";
import { User } from "@/types/user";

export interface CreateTransactionRequest {
    userId: string;
    createdBy: string;
    actionCode: string;
    type: CreditActionType;
    amount: number;
    description: string;
    metadata?: ICreditTransactionMetadata;
}

interface GetTransactionsParams extends ApiPaginationRequest {
    type?: CreditActionType;
    startDate?: string;
    endDate?: string;
    category?: CreditActionCategory;
    id?: string;
    userId?: string;
}

export interface TransactionResponse {
    transaction: ICreditTransaction;
    userUpdates: Partial<User>;
    emailDetails?: {
        emailType: "referral-success";
        emailTo: string;
        name: string;
        creditsEarned: number;
        referralLimit: number;
        referredUserName: string;
    };
}

const creditTransactionApi = {
    createTransaction: async (
        data: CreateTransactionRequest,
    ): Promise<TransactionResponse> => apiRequest<TransactionResponse>("/credit-transaction", "POST", data),

    getTransactions: async (
        params?: GetTransactionsParams,
    ): Promise<ApiPaginationResponse<ICreditTransaction[]>> => {
        const requestParams = { page: params?.page, limit: params?.limit, sortBy: params?.sortBy, userId: params?.userId };
        if (params?.search) {
            requestParams.search = params.search;
        }

        return apiRequest<ApiPaginationResponse<ICreditTransaction[]>>(`/credit-transaction`, "GET", requestParams);
    },

    getTransactionById: async (id: string): Promise<ICreditTransaction> => apiRequest<ICreditTransaction>(`/credit-transaction/${id}`, "GET"),
};

export default creditTransactionApi;
