import { widthMap } from "@/components/Grid/constants";
import { PageContent } from "@/components/Page/PageContent";
import {
    getColumnStyle,
    getPageHorizontalMarginStyle,
} from "@/components/PdfDocument/utils/getStyles";
import { ReadOnlySection } from "@/components/Section/ReadOnlySection";
import { GLOBAL_STYLE_KEY, resumeThemes } from "@/constants/resume";
import { useAppSelector } from "@/hooks/types";
import { getSectionsByDocumentTypeId } from "@/store/resume/selectors";
import { ColumnLayout, Resume, ResumeSections } from "@/types/resume";
import { getSectionsByLayout } from "@/utils/section";

interface ResumeViewProps {
    resume: Resume;
}

export const ReadOnlyResumeView = ({ resume }: ResumeViewProps) => {
    const sections = useAppSelector(state =>
        getSectionsByDocumentTypeId(state, resume?.documentType),
    );

    const { headerSection, bodySections, headerSectionId } =
        getSectionsByLayout(resume.sections, sections);
    const columnLayout = resume.columnLayout;
    const columns: { [key: number]: ResumeSections } =
        columnLayout === ColumnLayout.SINGLE
            ? { 0: bodySections }
            : Object.keys(bodySections).reduce(
                  (acc, sectionId) => {
                      const sectionColumnIndex =
                          bodySections[sectionId].columnIndex;
                      acc[sectionColumnIndex][sectionId] =
                          bodySections[sectionId];
                      return acc;
                  },
                  { 0: {}, 1: {} },
              );

    const theme = resumeThemes.find(
        t =>
            t.profileBackgroundColor ===
            resume.styles?.[GLOBAL_STYLE_KEY]?.themeColor,
    );
    const appliedBgColour =
        theme?.profileBackgroundColor ||
        resume.styles?.[GLOBAL_STYLE_KEY]?.backgroundColor;

    return (
        <PageContent
            pageNumber={0}
            id={resume.id}
        >
            {headerSection && headerSectionId && (
                <div
                    key={headerSectionId}
                    className="relative rounded-t-lg"
                    style={{
                        backgroundColor: appliedBgColour,
                    }}
                >
                    <ReadOnlySection
                        sectionId={headerSectionId}
                        shouldMeasure={false}
                        overrideSectionStyles={{
                            backgroundColor: "transparent",
                            themeColor: "transparent",
                        }}
                        resumeId={resume.id}
                        overrideResumeStyles={resume.styles}
                        overrideColumnLayout={columnLayout}
                        overrideSectionDetails={headerSection}
                        pageNumber={0}
                    />
                </div>
            )}

            <div
                className="grid h-full grid-cols-2"
                style={{
                    ...getPageHorizontalMarginStyle(
                        resume.styles?.[GLOBAL_STYLE_KEY]?.pageMarginSize,
                    ),
                    gridTemplateColumns:
                        columnLayout === ColumnLayout.SINGLE
                            ? "1fr"
                            : "1fr 40%",
                }}
            >
                {Object.keys(columns).map((columnKey, index) => {
                    const columnBodySections: ResumeSections =
                        columns[columnKey];
                    const columnStyles = getColumnStyle(
                        columnLayout === ColumnLayout.DOUBLE,
                        index,
                    );
                    return (
                        <div
                            key={`${resume.id}-${columnKey}`}
                            className="col-span-1"
                            style={columnStyles}
                        >
                            {Object.keys(columnBodySections).map(
                                bodySectionId => {
                                    const sectionWidth =
                                        columnBodySections[bodySectionId]
                                            .activeWidth;

                                    const itemWidth =
                                        columnLayout === ColumnLayout.DOUBLE
                                            ? "full"
                                            : sectionWidth;

                                    const previewWidth = `span ${widthMap[itemWidth || "6"]}`;

                                    return (
                                        <ReadOnlySection
                                            key={bodySectionId}
                                            sectionId={bodySectionId}
                                            shouldMeasure={false}
                                            resumeId={resume.id}
                                            overrideResumeStyles={resume.styles}
                                            previewWidth={previewWidth}
                                            overrideColumnLayout={columnLayout}
                                            overrideSectionDetails={
                                                columnBodySections[
                                                    bodySectionId
                                                ]
                                            }
                                            pageNumber={0}
                                        />
                                    );
                                },
                            )}
                        </div>
                    );
                })}
            </div>
        </PageContent>
    );
};
