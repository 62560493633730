import { ExternalLink } from "lucide-react";
import { useState, useEffect } from "react";
import { AIToolHeader } from "./components/AIToolHeader";
import aiApi from "@/api/ai";
import { useAppSelector } from "@/hooks/types";
import { AIModelConfig } from "@/pages/Admin/AI/AIPreferencesConfig";
import { ModelConfigSettings } from "@/pages/Admin/AI/ModelConfigSettings";
import { JsonEditorComponent } from "@/pages/Admin/JsonEditorComponent/JsonEditorComponent";
import { getResumeExamples, getResumes } from "@/store/resume/selectors";
import { AiHistoryEntry } from "@/types/ai";
import { getIsProductionEnv } from "@/utils/env";
interface TextAreaStats {
    text: string;
    wordCount: number;
}

type AiResponse = Record<string, any>;

interface AdminSmartMatchProps {
    modelConfig: AIModelConfig;
    setModelConfig: (modelConfig: AIModelConfig) => void;
    addToHistory: (entry: AiHistoryEntry) => void;
}

export const AdminSmartMatch = ({
    modelConfig,
    setModelConfig,
    addToHistory,
}: AdminSmartMatchProps) => {
    const isProdEnv = getIsProductionEnv();
    const [jobDescription, setJobDescription] = useState<TextAreaStats>({
        text: "",
        wordCount: 0,
    });
    const [aiResponse, setAiResponse] = useState<AiResponse | null>(null);
    const [isGenerating, setIsGenerating] = useState(false);
    const [isJobDescriptionExpanded, setIsJobDescriptionExpanded] =
        useState(false);
    const [error, setError] = useState<string | null>(null);
    const [timer, setTimer] = useState<number>(0);
    const [timerActive, setTimerActive] = useState(false);
    const resumeExamples = useAppSelector(getResumeExamples);
    const userResumes = useAppSelector(getResumes);
    const [selectedResume, setSelectedResume] = useState<string | null>(null);

    const calculateWordCount = (text: string): number => {
        return text
            .trim()
            .split(/\s+/)
            .filter(word => word.length > 0).length;
    };

    const handleTextChange = (
        text: string,
        setter: (stats: TextAreaStats) => void,
    ) => {
        setter({
            text,
            wordCount: calculateWordCount(text),
        });
    };

    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (timerActive) {
            interval = setInterval(() => {
                setTimer(prev => prev + 0.1);
            }, 100);
        }
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [timerActive]);

    const handleClear = () => {
        setJobDescription({ text: "", wordCount: 0 });
        setAiResponse(null);
        setError(null);
        setTimer(0);
        setTimerActive(false);
    };

    const handleGenerate = async () => {
        if (isProdEnv) {
            setError(
                "This feature is not available for testing in production. Test on staging",
            );
            return;
        }
        setIsGenerating(true);
        setError(null);
        setTimer(0);
        setTimerActive(true);
        try {
            const response = await aiApi.generateSmartMatch({
                resume: selectedResume!,
                jobDescription: jobDescription.text,
                model: modelConfig.model,
                temperature: modelConfig.temperature,
            });
            setAiResponse(response.output);
            addToHistory({
                id: Date.now().toString(),
                timestamp: new Date().toISOString(),
                tool: "ai-smart-match",
                input: {
                    resume: selectedResume ?? "",
                    jobDescription: jobDescription.text,
                    temperature: modelConfig.temperature,
                },
                output: response.output,
                modelUsed: modelConfig.model,
                duration: timer,
            });
        } catch (error) {
            console.error("Error generating response:", error);
            setError(
                error instanceof Error
                    ? error.message
                    : "An unknown error occurred",
            );
        } finally {
            setIsGenerating(false);
            setTimerActive(false);
        }
    };

    const getExpandButtonText = (isExpanded: boolean) =>
        isExpanded ? "Collapse" : "Expand";

    return (
        <>
            <AIToolHeader activeTab="ai-smart-match" />
            <div className="grid grid-cols-12 gap-8 px-8">
                {/* Left Column - Inputs */}
                <div className="col-span-6 space-y-6">
                    <ModelConfigSettings
                        modelConfig={modelConfig}
                        setModelConfig={setModelConfig}
                    />
                    <div className="space-y-2">
                        <div className="flex items-center justify-between">
                            <label className="mr-3 block whitespace-nowrap text-sm font-medium text-gray-700">
                                Resume *
                            </label>
                            <select
                                value={selectedResume ?? ""}
                                onChange={e =>
                                    setSelectedResume(e.target.value)
                                }
                                className="w-full rounded-lg border border-gray-300 p-2 text-sm"
                            >
                                <option value="">Select a resume</option>
                                {[...resumeExamples, ...userResumes].map(
                                    resume => (
                                        <option
                                            key={resume.id}
                                            value={resume.id}
                                        >
                                            {resume.name} ({resume.id})
                                        </option>
                                    ),
                                )}
                            </select>
                        </div>
                        {selectedResume && (
                            <a
                                href={`/resume/${selectedResume}`}
                                target="_blank"
                                rel="noreferrer"
                                className="flex items-center text-xs text-primary-600 hover:text-primary-700"
                            >
                                View Resume
                                <ExternalLink className="ml-1 h-4 w-4" />
                            </a>
                        )}
                    </div>

                    <div className="space-y-2">
                        <div className="flex items-center justify-between">
                            <label className="block text-sm font-medium text-gray-700">
                                Job Description *
                            </label>
                            <button
                                onClick={() =>
                                    setIsJobDescriptionExpanded(
                                        !isJobDescriptionExpanded,
                                    )
                                }
                                className="text-xs text-primary-600 hover:text-primary-700"
                            >
                                {getExpandButtonText(isJobDescriptionExpanded)}
                            </button>
                        </div>
                        <div className="relative">
                            <textarea
                                className={`w-full rounded-lg border border-gray-300 p-4 text-xs leading-tight transition-all duration-200 ${
                                    isJobDescriptionExpanded
                                        ? "min-h-[500px]"
                                        : "min-h-[200px]"
                                }`}
                                placeholder="Paste job description here..."
                                value={jobDescription.text}
                                onChange={e =>
                                    handleTextChange(
                                        e.target.value,
                                        setJobDescription,
                                    )
                                }
                            />
                            <div className="absolute bottom-2 right-2 text-xs text-gray-500">
                                Words: {jobDescription.wordCount}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Right Column - AI Response */}
                <div className="col-span-6 rounded-lg border p-4">
                    <div className="mb-4 flex items-center justify-between space-x-4">
                        <h2 className="text-xl font-semibold">AI Output</h2>
                        <div className="flex items-center space-x-2">
                            <button
                                onClick={handleClear}
                                className="rounded-md bg-gray-100 px-4 py-2 text-sm font-medium text-gray-600 hover:bg-gray-200"
                            >
                                Clear
                            </button>
                            <button
                                onClick={handleGenerate}
                                disabled={
                                    !selectedResume ||
                                    !jobDescription.text ||
                                    isGenerating
                                }
                                className={`rounded-md px-4 py-2 text-sm font-medium text-white ${
                                    !selectedResume ||
                                    !jobDescription.text ||
                                    isGenerating
                                        ? "cursor-not-allowed bg-primary-400"
                                        : "bg-primary-600 hover:bg-primary-700"
                                }`}
                            >
                                {isGenerating ? "Generating..." : "Generate"}
                            </button>
                            <span className="text-xs text-gray-500">
                                {timer > 0 && `${timer.toFixed(1)}s`}
                            </span>
                        </div>
                    </div>

                    <div className="min-h-[450px] overflow-auto rounded-lg border border-gray-300 bg-white p-4">
                        {isGenerating ? (
                            <div className="flex h-full items-center justify-center">
                                <p className="text-sm text-gray-500">
                                    Generating response...
                                </p>
                            </div>
                        ) : aiResponse ? (
                            <JsonEditorComponent
                                jsonData={aiResponse}
                                onUpdate={() => {}}
                                id="resume"
                                viewOnly={true}
                                className="h-full"
                                rootFontSize="12px"
                                containerClassName="h-full"
                            />
                        ) : (
                            <div className="flex h-full items-center justify-center">
                                <p className="text-sm text-gray-500">
                                    AI response will appear here
                                </p>
                            </div>
                        )}
                        {error && (
                            <div className="mt-4 rounded-lg bg-red-50 p-4">
                                <p className="text-sm text-red-500">{error}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
