import { Edit3 } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { Alert } from "@/components/Alert/Alert";
import { Button } from "@/components/Button/Button";
import { FeedbackSurveyButton } from "@/components/Feedback/FeedbackSurveyButton";
import { ImportFeedback } from "@/components/Feedback/ImportFeedback";
import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner";
import { DownloadButton } from "@/components/Navigation/DownloadButton";
import { PageHelmet } from "@/components/PageHelmet/PageHelmet";
import { PdfDocumentViewer } from "@/components/PdfDocument/PdfDocumentViewer";
import { PdfDocumentPreview } from "@/components/PdfPreview/PdfDocumentPreview";
import { ResumeName } from "@/components/ResumeName/ResumeName";
import { TemplateLoader } from "@/components/TemplateLoader/TemplateLoader";
import { useAppSelector } from "@/hooks/types";
import { useDevice } from "@/hooks/useDevice";
import { getTemplateLoadingState } from "@/store/app/selectors";
import { A4_PAGE_WIDTH_PIXELS } from "@/store/pages/constants";
import { getPages } from "@/store/pages/selectors";
import {
    getActiveResume,
    getActiveResumeHeaderImage,
    getSectionsByDocumentTypeId,
} from "@/store/resume/selectors";
import { TemplateLoadingState } from "@/types/app";
import { DeviceContextType } from "@/types/device";

const LoadingComponent = () => {
    return (
        <div
            className="mt-10 flex items-center justify-center"
            style={{ maxWidth: "calc(100vw - 24px)" }}
        >
            <LoadingSpinner />
        </div>
    );
};

export const PreviewPage = () => {
    const activeResume = useAppSelector(getActiveResume);
    const { id, name } = activeResume ?? {};
    const [loading, setLoading] = useState(true);
    const [pdfLoaded, setPdfLoaded] = useState(false);
    const [canDownloadFile, setCanDownloadFile] = useState(false);
    const [loadPdf, setLoadPdf] = useState(false);
    const loadingState = useAppSelector(getTemplateLoadingState);
    const sectionsConfig = useAppSelector(state =>
        getSectionsByDocumentTypeId(state, activeResume?.documentType ?? ""),
    );
    const pages = useAppSelector(getPages);
    const activeResumeImageSignedUrl = useAppSelector(
        getActiveResumeHeaderImage,
    );
    const { isDesktop } = useDevice() as DeviceContextType;
    const numberOfPages = useMemo(() => pages?.length ?? 0, [pages]);

    useEffect(() => {
        if (
            activeResume &&
            loadingState === TemplateLoadingState.READY &&
            pages?.[0].sections.length > 0
        ) {
            setLoading(false);
            setLoadPdf(true);
        }
    }, [activeResume, loadingState, pages]);

    useEffect(() => {
        if (!loading && pdfLoaded) {
            setCanDownloadFile(true);
        }
    }, [loading, pdfLoaded]);

    return (
        <>
            <PageHelmet title="Preview" />
            <div>
                <div className="border-b-2 border-neutral-300 bg-white px-3 py-4">
                    <div
                        className="mx-auto flex items-center justify-between"
                        style={{ maxWidth: "calc(100vw - 24px)" }}
                    >
                        <ResumeName
                            resumeId={id}
                            resumeName={name}
                            showSaveButton={true}
                        />
                        <div className="flex items-center gap-2">
                            <Button
                                to={`/resume/${id}`}
                                color={"softIndigo"}
                                leftIcon={<Edit3 size={20} />}
                                className="hidden lg:flex"
                            >
                                Edit
                            </Button>
                            {canDownloadFile && <DownloadButton responsive />}
                        </div>
                    </div>
                </div>
            </div>
            <main className="flex flex-col md:items-center">
                {!isDesktop && (
                    <div className="mx-auto mt-2 max-w-[300px] md:max-w-[500px]">
                        <Alert
                            type="info"
                            message="This is a read only view. Please visit 1Template on a desktop device to edit."
                            dismissable
                        />
                    </div>
                )}
                <div className="mt-2 max-w-[100%] flex-grow overflow-x-scroll px-3">
                    <div
                        className="relative mb-3 lg:mb-8"
                        style={{
                            width: A4_PAGE_WIDTH_PIXELS,
                        }}
                        onContextMenu={e => e.preventDefault()}
                    >
                        {(loading || !activeResume) && (
                            <TemplateLoader overlay={<LoadingComponent />} />
                        )}
                        {loadPdf && (
                            <div className="mb-8">
                                <PdfDocumentPreview
                                    onLoadSuccess={() => {
                                        setPdfLoaded(true);
                                    }}
                                    numberOfPages={numberOfPages}
                                    scale={1}
                                    loadingComponent={<LoadingComponent />}
                                    resume={activeResume}
                                >
                                    <PdfDocumentViewer
                                        resume={activeResume}
                                        sectionsConfig={sectionsConfig}
                                        pages={pages}
                                        imageSrc={activeResumeImageSignedUrl}
                                    />
                                </PdfDocumentPreview>
                            </div>
                        )}
                    </div>
                </div>
                <FeedbackSurveyButton />
                <ImportFeedback />
            </main>
        </>
    );
};
