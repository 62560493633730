import { CreditSpendUserProfile, UserCategory } from "@/pages/Admin/Stats/types";
import { Resume, User } from "@/types";
import { ICreditTransaction } from "@/types/creditTransaction";
import { ColumnLayout } from "@/types/resume";

interface StatItem {
    label: string;
    value: string;
}

export const calculatePreferenceStats = (
    allUsers: User[],
    possibleValues: StatItem[],
    preferenceKey: "industries" | "jobRoles" | "experienceLevel" | "jobTypes",
) => {
    const stats = possibleValues.reduce(
        (acc, item) => ({
            ...acc,
            [item.value]: 0,
        }),
        {} as Record<string, number>
    );

    allUsers.forEach(user => {
        const userPreferences = user.preferences?.[preferenceKey] ?? [];
        if (Array.isArray(userPreferences)) {
            userPreferences.forEach(pref => {
                if (pref in stats) {
                    stats[pref] += 1;
                }
            });
        } else if (typeof userPreferences === "string") {
            if (userPreferences in stats) {
                stats[userPreferences] += 1;
            }
        }
    });

    const totalUsers = allUsers.length;
    return Object.entries(stats)
        .map(([key, count]) => ({
            [preferenceKey]: key,
            count,
            percentage: totalUsers ? (count / totalUsers) * 100 : 0,
        }))
        .sort((a, b) => b.count - a.count);
};

export const calculateResumeLayoutStats = (
    allResumes: Resume[],
) => {
    const validLayouts = Object.keys(ColumnLayout)
        .filter(key => isNaN(Number(key))) as (keyof typeof ColumnLayout)[];

    const stats = validLayouts.reduce(
        (acc, layout) => ({
            ...acc,
            [layout]: 0,
        }),
        {} as Record<string, number>
    );

    allResumes.forEach(resume => {
        let value: keyof typeof ColumnLayout;
        if (resume.columnLayout === 1) {
            value = "SINGLE";
        } else if (resume.columnLayout === 2) {
            value = "DOUBLE";
        } else {
            value = resume.columnLayout as keyof typeof ColumnLayout;
        }

        if (validLayouts.includes(value)) {
            stats[value] += 1;
        }
    });

    const totalResumes = allResumes.length;
    return Object.entries(stats)
        .map(([key, count]) => ({
            layout: key,
            count,
            percentage: totalResumes ? (count / totalResumes) * 100 : 0,
        }))
        .sort((a, b) => b.count - a.count);
};

/**
 * Calculate the credit balance stats for all users.
 * Use ranges 20+, 16-20, 11-15, 6-10, 2-5, 1, 0
 */
export const calculateCreditBalanceStats = (
    allUsers: User[],
) => {
    const balanceRanges = {
        "21+": { value: 0, order: 8 },
        "20": { value: 0, order: 7 },
        "16-20": { value: 0, order: 6 },
        "11-15": { value: 0, order: 5 },
        "10": { value: 0, order: 4 },
        "6-9": { value: 0, order: 3 },
        "2-5": { value: 0, order: 2 },
        "1": { value: 0, order: 1 },
        "0": { value: 0, order: 0 },
    };

    allUsers.forEach(user => {
        const balance = user.credits?.balance ?? 0;
        if (balance > 20) {
            balanceRanges["21+"].value += 1;
        } else if (balance === 20) {
            balanceRanges["20"].value += 1;
        } else if (balance >= 16) {
            balanceRanges["16-20"].value += 1;
        } else if (balance >= 11) {
            balanceRanges["11-15"].value += 1;
        } else if (balance === 10) {
            balanceRanges["10"].value += 1;
        } else if (balance >= 6) {
            balanceRanges["6-9"].value += 1;
        } else if (balance >= 2) {
            balanceRanges["2-5"].value += 1;
        } else if (balance === 1) {
            balanceRanges["1"].value += 1;
        } else {
            balanceRanges["0"].value += 1;
        }
    });

    return Object.entries(balanceRanges)
        .map(([key, { value, order }]) => ({
            balance: key,
            count: value,
            percentage: allUsers.length ? (value / allUsers.length) * 100 : 0,
            order,
        }))
        .sort((a, b) => b.order - a.order);
};


type UserSummary = {
    userId: string;
    totalSpent: number;
    totalEarned: number;
    totalPurchased: number;
    featuresUsed: Set<string>;
    lastActivity: Date;
};

export const formatCreditSpendUserProfile = (transactions: ICreditTransaction[]): CreditSpendUserProfile => {
    const userSummaries: Record<string, UserSummary> = {};

    transactions.forEach(({ userId, actionCode, type, _id }) => {
        const date = new Date(parseInt(_id.substring(0, 8), 16) * 1000);

        if (!userSummaries[userId]) {
            userSummaries[userId] = {
                totalSpent: 0,
                totalEarned: 0,
                totalPurchased: 0,
                featuresUsed: new Set(),
                lastActivity: date,
            };
        }

        const user = userSummaries[userId];

        if (type === 'SPEND') {
            user.totalSpent += 1;
            user.featuresUsed.add(actionCode);
        } else if (type === 'PURCHASE') {
            user.totalPurchased += 1;
        } else if (type === 'EARN') {
            user.totalEarned += 1;
        }

        if (date > user.lastActivity) {
            user.lastActivity = date;
        }
    });

    const userCategories: Record<UserCategory, number> = {
        'Power User': 0,
        'Happy User': 0,
        'Casual User': 0,
        'Feature Explorer': 0,
        'Dormant User': 0,
        'New User': 0,
        'Credit Hoarder': 0,
        'High Value User': 0,
        'Earner': 0,
        'Inactive User': 0,
        'Other': 0,
    };

    Object.values(userSummaries).forEach((user) => {
        const now = new Date();
        const daysSinceLastActivity = (now.getTime() - user.lastActivity.getTime()) / (1000 * 60 * 60 * 24);

        if (user.totalSpent >= 8 && user.featuresUsed.size >= 2) {
            userCategories['Power User']++;
        } else if (user.totalSpent >= 5 && user.featuresUsed.size >= 1) {
            userCategories['Happy User']++;
        } else if (user.totalSpent >= 1 && user.featuresUsed.size === 1) {
            userCategories['Casual User']++;
        } else if (user.featuresUsed.size >= 2 && user.totalSpent <= 4) {
            userCategories['Feature Explorer']++;
        } else if (daysSinceLastActivity >= 10 && user.totalSpent > 0) {
            userCategories['Dormant User']++;
        } else if (daysSinceLastActivity >= 10 && user.totalSpent === 0 && user.totalEarned === 0 && user.totalPurchased === 0) {
            userCategories['Inactive User']++;
        } else if (user.totalPurchased >= 10 && user.totalSpent >= 5) {
            userCategories['High Value User']++;
        } else if (user.totalEarned >= 5 && user.totalSpent < 5) {
            userCategories['Earner']++;
        } else if (user.totalSpent === 0 && daysSinceLastActivity < 7) {
            userCategories['New User']++;
        } else if (user.totalSpent < 2 && user.totalEarned >= 8) {
            userCategories['Credit Hoarder']++;
        } else {
            userCategories['Other']++;
        }
    });

    return userCategories;
};
