import { useEffect, useMemo, useState } from "react";
import useMeasure from "react-use-measure";
import { MemoReadOnlyMapper } from "@/components/EditorComponents/ReadOnlyMapper";
import { getSectionHeadingMarginYStyle } from "@/components/PdfDocument/utils/getStyles";
import { EditableSectionHeader } from "@/components/Section/EditableSectionHeader";
import { AppText } from "@/components/Text/AppText";
import { GLOBAL_STYLE_KEY } from "@/constants/resume";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { AdminPip } from "@/pages/Admin/components/AdminPip";
import { decodeHtml } from "@/pages/ResumeBuilder/utils";
import { getDebugBuilder } from "@/store/app/selectors";
import { setDefaultSectionHeight, setSectionHeight } from "@/store/pages/slice";
import { calculateSectionHeight } from "@/store/pages/utils";
import {
    getActiveResumeSectionById,
    getActiveResumeSectionIds,
    getActiveResumeStyles,
    getResumeById,
} from "@/store/resume/selectors";
import { getIsDarkTheme } from "@/store/resume/utils";
import { getSectionConfigById } from "@/store/sections/selectors";
import { Measurement } from "@/types/pages";
import {
    PageMarginSize,
    SectionDetails,
    SectionSpacingSize,
    ResumeStyles,
} from "@/types/resume";
import {
    getResumeStylesConfig,
    getSectionFieldsConfig,
    getVisibleProps,
    transformLayoutFieldsToProps,
} from "@/utils/section";

interface SectionHeaderProps {
    sectionId: string;
    isEditable: boolean;
    resumeId?: string;
    overrideSectionDetails?: SectionDetails;
    overrideResumeStyles?: ResumeStyles;
    shouldMeasure?: boolean;
    isDefaultMeasurement?: boolean;
}

export const SectionHeader = ({
    sectionId,
    isEditable,
    resumeId,
    overrideSectionDetails,
    overrideResumeStyles,
    shouldMeasure,
    isDefaultMeasurement,
}: SectionHeaderProps) => {
    const debugBuilder = useAppSelector(getDebugBuilder);
    const resume = useAppSelector(state => getResumeById(state, resumeId));
    const [headerRef, { height }] = useMeasure();
    const activeResumeSectionIds = useAppSelector(getActiveResumeSectionIds);
    const [currentHeight, setCurrentHeight] = useState(height);
    const resumeSection = useMemo(
        () => resume?.sections[sectionId],
        [resume?.sections, sectionId],
    );
    const activeResumeSection = useAppSelector(state =>
        getActiveResumeSectionById(state, sectionId),
    );
    const dispatch = useAppDispatch();
    // Override section details are used for template loader
    // Resume section is used for the resume preview in dashboard
    const sectionDetails = useMemo(
        () => overrideSectionDetails || resumeSection || activeResumeSection,
        [activeResumeSection, overrideSectionDetails, resumeSection],
    );
    const sectionConfigId = useMemo(
        () => sectionDetails?.sectionConfigId,
        [sectionDetails?.sectionConfigId],
    );
    const sectionConfig = useAppSelector(state =>
        getSectionConfigById(state, sectionConfigId),
    );
    const titleComp = useMemo(
        () => decodeHtml(sectionConfig?.titleComp ?? ""),
        [sectionConfig?.titleComp],
    );
    const visibleProps = getVisibleProps(
        sectionDetails?.header ?? {},
        sectionDetails?.hiddenFields ?? [],
    );
    const fieldsConfig = useMemo(
        () => getSectionFieldsConfig(sectionConfig?.headerFields, visibleProps),
        [sectionConfig?.headerFields, visibleProps],
    );
    const layoutProps = useMemo(
        () =>
            transformLayoutFieldsToProps(
                sectionConfig?.layoutFields ?? [],
                sectionDetails?.layout,
            ),
        [sectionConfig?.layoutFields, sectionDetails?.layout],
    );

    const activeResumeStyles = useAppSelector(getActiveResumeStyles);
    const stylesConfig = useMemo(
        () =>
            getResumeStylesConfig({
                overrideResumeStyles,
                resumeStyles: resume?.styles,
                activeResumeStyles,
                columnIndex: sectionDetails?.columnIndex ?? 0,
            }),
        [
            activeResumeStyles,
            overrideResumeStyles,
            resume?.styles,
            sectionDetails?.columnIndex,
        ],
    );

    const isDarkTheme = useMemo(
        () => getIsDarkTheme(stylesConfig[GLOBAL_STYLE_KEY].themeColor ?? ""),
        [stylesConfig],
    );

    const sectionSpacingSize =
        stylesConfig[GLOBAL_STYLE_KEY].sectionSpacingSize ??
        SectionSpacingSize.MD;
    const sectionHeadingMarginYStyle = getSectionHeadingMarginYStyle(
        sectionSpacingSize,
        sectionConfig?.isStaticHeader,
    );

    useEffect(() => {
        if (
            sectionConfig &&
            shouldMeasure &&
            height !== currentHeight &&
            !!height
        ) {
            const isStaticHeader = !!sectionConfig?.isStaticHeader;
            const marginYStyle = getSectionHeadingMarginYStyle(
                stylesConfig[GLOBAL_STYLE_KEY].sectionSpacingSize ??
                    SectionSpacingSize.MD,
                isStaticHeader,
            );
            const addToPage =
                activeResumeSectionIds.includes(sectionId) &&
                !isDefaultMeasurement;
            // TODO: Sections have a margin that is applied. Previously this was fine as we measured the full section. Now we are measuring the header and items separately. For now I am including the margin in the header height to ensure the total section height is correct. This may need changing in the future.
            const newHeight = calculateSectionHeight(
                height + marginYStyle.marginBottom,
                isStaticHeader,
                stylesConfig[GLOBAL_STYLE_KEY].sectionSpacingSize ??
                    SectionSpacingSize.MD,
                stylesConfig[GLOBAL_STYLE_KEY].pageMarginSize ??
                    PageMarginSize.MD,
            );

            const measurementDetails: Measurement = {
                type: "header",
                section: sectionId,
                sectionConfigId: sectionConfig.id,
                height: newHeight,
                isStaticHeader: isStaticHeader ?? false,
                columnIndex: isStaticHeader
                    ? null
                    : (sectionDetails?.columnIndex ?? 0),
                positionIndex: isStaticHeader
                    ? null
                    : (sectionDetails?.positionIndex ?? 0),
                itemIndex: null,
            };

            if (addToPage) {
                dispatch(setSectionHeight(measurementDetails));
                setCurrentHeight(newHeight);
            }

            if (isDefaultMeasurement) {
                dispatch(setDefaultSectionHeight(measurementDetails));
            }
        }
    }, [
        activeResumeSectionIds,
        dispatch,
        sectionDetails,
        sectionId,
        isDefaultMeasurement,
        shouldMeasure,
        stylesConfig,
        currentHeight,
        height,
        sectionConfig,
    ]);

    return (
        <div
            className={`section-header ${isDarkTheme ? "dark-theme" : "light-theme"}`}
            ref={shouldMeasure ? headerRef : undefined}
            style={sectionHeadingMarginYStyle}
        >
            {isEditable ? (
                <EditableSectionHeader
                    sectionId={sectionId}
                    fieldsConfig={fieldsConfig}
                    isStaticHeader={sectionConfig?.isStaticHeader}
                    layoutProps={layoutProps}
                    stylesConfig={stylesConfig}
                    sectionConfigId={sectionConfigId}
                />
            ) : (
                <MemoReadOnlyMapper
                    html={titleComp}
                    sectionId={sectionId}
                    visibleProps={visibleProps}
                    propType="header"
                    fieldsConfig={fieldsConfig}
                    isStaticHeader={sectionConfig?.isStaticHeader}
                    stylesConfig={stylesConfig}
                    layoutProps={layoutProps}
                />
            )}
            {debugBuilder && (
                <div className="absolute right-0 top-0">
                    <AdminPip colour="bg-primary-100">
                        <AppText
                            variant="footnote"
                            className="font-poppins text-xs"
                        >
                            pos: {sectionDetails?.positionIndex} | {height}px
                        </AppText>
                    </AdminPip>
                </div>
            )}
        </div>
    );
};
