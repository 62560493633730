import { resumeFontSizes, resumeFonts, resumeDateLocationPositions, resumeHeadingFontSizes, GLOBAL_STYLE_KEY, PROFILE_IMAGE_KEY, resumeDateLocationLayouts } from "@/constants/resume";
import { getDefaultSectionDetails } from "@/helper/getDefaultSectionDetails";
import { ResumeSections, Template, User } from "@/types";
import { ColumnLayout, DateFormat, PageMarginSize, ResumeStyles, SectionSpacingSize } from "@/types/resume";
import { Section } from "@/types/section";
import { generateUniqueId } from "@/utils/string";

export const getDefaultSectionsDataForTemplate = (
    sections: Section[],
    template: Template,
    styles: ResumeStyles,
    columnLayout: ColumnLayout = ColumnLayout.SINGLE,
    showImage: boolean = false,
    userDetails: User,
) => {
    const defaultSectionContent = {} as ResumeSections;
    const templateColumnLayout = template.columnLayout;
    const templateSections = [...template.sections].sort((a, b) => a.positionIndex - b.positionIndex);
    templateSections.forEach((templateSection, index) => {
        const sectionConfig = sections.find((section) => section.id === templateSection.id);
        const useTemplateColumnLayout = columnLayout === templateColumnLayout;
        const sectionId = generateUniqueId();
        const columnIndex = useTemplateColumnLayout
            ? templateSection.columnIndex - 1
            : columnLayout === ColumnLayout.DOUBLE
                ? index % 2
                : 0;
        if (sectionConfig) {
            defaultSectionContent[sectionId] = getDefaultSectionDetails(sectionConfig, index, columnIndex, !showImage, userDetails);
        }
    });

    const globalStyles = styles[GLOBAL_STYLE_KEY] ?? {};
    const imageStyles = styles[PROFILE_IMAGE_KEY] ?? {};

    const defaultStyles: ResumeStyles = {
        [GLOBAL_STYLE_KEY]: {
            "fontFamily": globalStyles.fontFamily || resumeFonts[0].value,
            "fontSize": globalStyles.fontSize || resumeFontSizes[0].value,
            "headingFontSize": globalStyles.headingFontSize || resumeHeadingFontSizes[0].value,
            "backgroundColor": globalStyles.backgroundColor || "#FFFFFF",
            "themeColor": globalStyles.themeColor || "#FFFFFF",
            "showIcons": globalStyles.showIcons || false,
            "underlineSectionHeadings": globalStyles.underlineSectionHeadings || false,
            dateLocationPosition: globalStyles.dateLocationPosition || resumeDateLocationPositions[0].value as "column",
            dateLocationLayout: globalStyles.dateLocationLayout || resumeDateLocationLayouts[0].value as "column",
            dateFormat: globalStyles.dateFormat || DateFormat.LRG,
            sectionSpacingSize: globalStyles.sectionSpacingSize || SectionSpacingSize.MD,
            pageMarginSize: globalStyles.pageMarginSize || PageMarginSize.MD,
        },
        [PROFILE_IMAGE_KEY]: {
            "borderRadius": imageStyles.borderRadius || "100%",
            "borderWidth": imageStyles.borderWidth || undefined,
            "borderStyle": imageStyles.borderStyle || undefined,
            "borderColor": imageStyles.borderColor || undefined,
        },
    };

    return { defaultSectionContent, defaultStyles };
};
