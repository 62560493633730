import { AppText } from "@/components/Text/AppText";
import { translate } from "@/helper/translate";
import { sortResumes } from "@/pages/Dashboard/utils";
import { ResumeExample } from "@/pages/Examples/ResumeExample";
import { Resume } from "@/types/resume";
interface ImportTemplateProps {
    selectedExample: string;
    resumeExamples: Resume[] | null;
    onExampleChange: (exampleId: string | null) => void;
    onNext: () => void;
    hasJobDescription: boolean;
}

export const ImportTemplate = ({
    selectedExample,
    resumeExamples,
    onExampleChange,
}: ImportTemplateProps) => {
    const sortedExamples = sortResumes(resumeExamples || [], "3"); // sort by most popular
    return (
        <div className="">
            <AppText
                variant="regular"
                className="mb-4 hidden text-neutral-700 lg:block"
            >
                The perfect resume deserves the perfect look! Choose a
                professionally designed template to make your resume stand out.
                Your content remains the same—just a fresh, polished layout. You
                can {translate("customise")} style settings later to fine-tune
                the look.
            </AppText>
            <AppText
                variant="regular"
                className="mb-4 block text-neutral-700 lg:hidden"
            >
                The perfect resume deserves the perfect look! Choose a
                professionally designed template to make your resume stand out.
            </AppText>

            <div className="flex h-full max-h-[100%] flex-col lg:h-[60vh] lg:max-h-[500px]">
                <div className="no-scrollbar h-full overflow-y-auto px-4">
                    <ul
                        role="list"
                        className="create-resume-grid grid justify-center gap-6 pb-6 pt-1"
                    >
                        {sortedExamples?.map(resume => (
                            <ResumeExample
                                key={resume.id}
                                resume={resume}
                                onSelect={() => {
                                    if (selectedExample === resume.id) {
                                        onExampleChange(null);
                                    } else {
                                        onExampleChange(resume.id);
                                    }
                                }}
                                isSelected={selectedExample === resume.id}
                            />
                        ))}
                        <li className="h-3"></li>
                        <li className="h-3"></li>
                        <li className="h-3"></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};
