import * as Yup from "yup";
import { CREDIT_ACTION_TYPES, CREDIT_ACTION_CATEGORIES, TIME_FRAMES, CREDIT_ACTION_AMOUNT_TYPES } from "@/types/creditAction";

export const creditActionSchema = Yup.object().shape({
    code: Yup.string()
        .required("Code is required")
        .matches(/^[A-Z0-9_]+$/, "Code must be uppercase letters, numbers and underscores only")
        .min(3, "Code must be at least 3 characters")
        .max(50, "Code must be less than 50 characters"),
    name: Yup.string()
        .required("Name is required")
        .min(3, "Name must be at least 3 characters")
        .max(50, "Name must be less than 50 characters"),
    description: Yup.string()
        .required("Description is required")
        .min(3, "Description must be at least 3 characters")
        .max(200, "Description must be less than 200 characters"),
    type: Yup.string()
        .required("Type is required")
        .oneOf(CREDIT_ACTION_TYPES, "Invalid type"),
    credits: Yup.number().when("amountType", {
        is: "FIXED",
        then: schema => schema
            .required("Credits is required for fixed amount actions"),
        otherwise: schema => schema.nullable(),
    }),
    amountType: Yup.string()
        .required("Amount Type is required")
        .oneOf(CREDIT_ACTION_AMOUNT_TYPES, "Invalid amount type"),
    category: Yup.string()
        .required("Category is required")
        .oneOf(CREDIT_ACTION_CATEGORIES, "Invalid category"),
    isActive: Yup.boolean(),
    usageLimit: Yup.object().shape({
        maxCount: Yup.number()
            .min(1, "Max count must be at least 1"),
        timeFrame: Yup.string()
            .oneOf(TIME_FRAMES, "Invalid time frame"),
        resetCount: Yup.number()
            .min(0, "Reset count must be 0 or greater")
    }).nullable(),
    cooldownPeriod: Yup.number()
        .min(0, "Cooldown period must be 0 or greater")
        .nullable(),
    metadata: Yup.mixed()
        .required("Metadata is required")
}); 
