import {
    AtSignPdfIcon,
    CalendarPdfIcon,
    LinkPdfIcon,
    MapPinPdfIcon,
    PhonePdfIcon,
    TagPdfIcon,
} from "@/components/PdfDocument/PdfIcons";

interface PdfIconProps {
    name: string;
    size: number;
    isDarkTheme: boolean;
}

export const PdfIcon = ({ name, size, isDarkTheme }: PdfIconProps) => {
    if (!name) return null;
    switch (name.toLowerCase()) {
        case "atsign":
            return (
                <AtSignPdfIcon
                    size={size}
                    isDarkTheme={isDarkTheme}
                />
            );
        case "calendar":
            return (
                <CalendarPdfIcon
                    size={size}
                    isDarkTheme={isDarkTheme}
                />
            );
        case "mappin":
            return (
                <MapPinPdfIcon
                    size={size}
                    isDarkTheme={isDarkTheme}
                />
            );
        case "phone":
            return (
                <PhonePdfIcon
                    size={size}
                    isDarkTheme={isDarkTheme}
                />
            );
        case "link":
            return (
                <LinkPdfIcon
                    size={size}
                    isDarkTheme={isDarkTheme}
                />
            );
        case "tag":
            return (
                <TagPdfIcon
                    size={size}
                    isDarkTheme={isDarkTheme}
                />
            );
        default:
            null;
    }
};
