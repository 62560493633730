import { TitleWrapper } from "@/components/EditorComponents/TitleWrapper";
import { FieldIcon } from "@/components/FieldIcon/FieldIcon";
import { TextElementType } from "@/components/PdfDocument/types/pdfTypes";
import { decodeHtml } from "@/pages/ResumeBuilder/utils";
import { ResumeStyles } from "@/types/resume";

interface ReadOnlyTitleProps {
    value: string;
    variant: TextElementType;
    icon?: string;
    isStaticHeader?: boolean;
    stylesConfig: ResumeStyles;
    id: string;
}

export const ReadOnlyTitle = ({
    value,
    variant,
    icon,
    isStaticHeader,
    stylesConfig,
    id,
}: ReadOnlyTitleProps) => {
    const getContent = (variant: string, value: string) => {
        const decodedValue = decodeHtml(value);
        if (decodedValue.startsWith("<h")) {
            return <div dangerouslySetInnerHTML={{ __html: decodedValue }} />;
        }

        switch (variant) {
            case "h1":
                return (
                    <h1
                        dangerouslySetInnerHTML={{ __html: decodeHtml(value) }}
                    />
                );
            case "h2":
                return (
                    <h2
                        dangerouslySetInnerHTML={{ __html: decodeHtml(value) }}
                    />
                );
            case "h3":
                return (
                    <h3
                        dangerouslySetInnerHTML={{ __html: decodeHtml(value) }}
                    />
                );
            case "h4":
                return (
                    <h4
                        dangerouslySetInnerHTML={{ __html: decodeHtml(value) }}
                    />
                );
            case "h5":
                return (
                    <h5
                        dangerouslySetInnerHTML={{ __html: decodeHtml(value) }}
                    />
                );
            default:
                return (
                    <h1
                        dangerouslySetInnerHTML={{ __html: decodeHtml(value) }}
                    />
                );
        }
    };

    return (
        <TitleWrapper
            isStaticHeader={isStaticHeader}
            stylesConfig={stylesConfig}
            variant={variant}
            id={id}
        >
            {icon && (
                <FieldIcon
                    name={icon}
                    stylesConfig={stylesConfig}
                    isStaticHeader={!!isStaticHeader}
                />
            )}
            {getContent(variant, value)}
        </TitleWrapper>
    );
};
