import { getUserCountryCode } from "@/i18n";
import { formatCasing } from "@/utils/string";
import enToUsTranslations from "@/constants/en-to-us-translations.json";

/**
 * Translates a word from British English to American English
 * @param gbSpelledWord - The word to translate in British English
 * @param casing - The casing of the word to return
 * @returns The translated word
 * 
 * Add more words to the en-to-us-translations.json file to support more words
 */
export const translate = (gbSpelledWord: string, casing: "lower" | "title" | "upper" = "lower") => {
    const countryCode = getUserCountryCode();
    if (countryCode?.toLowerCase() !== "us") {
        return formatCasing(gbSpelledWord, casing);
    }

    const lowerCaseWord = gbSpelledWord.toLowerCase();
    const usWord = enToUsTranslations[lowerCaseWord as keyof typeof enToUsTranslations];
    const translatedWord = usWord || gbSpelledWord;
    return formatCasing(translatedWord, casing);

};
