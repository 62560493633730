import { pdfStyles } from "@/components/PdfDocument/styles/pdfStyles";
import { TextElementType, TextAlignType } from "@/components/PdfDocument/types/pdfTypes";
import { resumeHeadingFontSizes, resumeFontSizes, pdfFontSizeMap, resumeThemes } from "@/constants/resume";
import { A4_PAGE_WIDTH_PIXELS } from "@/store/pages/constants";
import { PageMarginSize, SectionSpacingSize } from "@/types/resume";

export const getSectionStyle = ({
    isStaticHeader,
    backgroundColor,
    sectionSpacingSize,
    pageMarginSize,
    isLastSectionOnPage,
    themeColor,
}: {
    isStaticHeader: boolean,
    backgroundColor?: string,
    sectionSpacingSize: SectionSpacingSize,
    pageMarginSize?: PageMarginSize,
    isLastSectionOnPage?: boolean,
    themeColor?: string,
}) => {
    if (isStaticHeader) {
        const theme = resumeThemes.find(theme => {
            return theme.profileBackgroundColor === themeColor;
        });
        const sectionBackground = theme?.profileBackgroundColor ?? backgroundColor;
        return {
            ...getStaticHeaderSectionStyle(sectionSpacingSize, pageMarginSize),
            backgroundColor: sectionBackground,
        };
    }

    return {
        ...getSectionHorizontalPadding(sectionSpacingSize),
        ...getSectionYMargin(sectionSpacingSize),
        ...(isLastSectionOnPage && { marginBottom: 0 }), // Last sections on page should not have a margin bottom.
    };
};

// Get the horizontal style and flex direction for columns to fit
export const getColumnWrapperStyle = (isTwoColumn: boolean, pageMarginSize?: PageMarginSize) => ({
    ...getPageHorizontalMarginStyle(pageMarginSize),
    ...(isTwoColumn ? pdfStyles.twoColumn : pdfStyles.oneColumn)
});

export const getColumnStyle = (isTwoColumn: boolean, columnIndex: number, isPdf?: boolean, sectionSpacingSize?: SectionSpacingSize, pageMarginSize?: PageMarginSize) => {
    if (!isTwoColumn) {
        return pdfStyles.fullPage;
    }

    const columnMargin = getColumnGap(columnIndex, sectionSpacingSize);

    if (isPdf && sectionSpacingSize && pageMarginSize) {
        const columnWidth = getSplitPageColumnWidthForPdf(columnIndex, pageMarginSize, sectionSpacingSize);

        return {
            ...columnMargin,
            width: columnWidth,
        };
    } else {
        return columnMargin;
    }

};

const inheritTags = ["strong", "em", "u", "li"];

export const getTextStyle = (fontSizeValue: string, variant: TextElementType, isHeading?: boolean, textAlign?: TextAlignType) => {
    const fontSizeArray = isHeading ? resumeHeadingFontSizes : resumeFontSizes;
    const fontSizeMap = fontSizeArray.find(size => size.value === fontSizeValue);
    const fontConversionMap =
        pdfFontSizeMap[fontSizeMap?.value ?? "medium"];
    const shouldInheritSize = inheritTags.includes(variant);
    const variantFontValue = fontConversionMap[variant];
    const fontSizeInPx = shouldInheritSize ? null : variantFontValue.px;

    const style = {
        fontSize: fontSizeInPx,
        lineHeight: variant === "h1" ? 1.2 : 1.25,
    };

    if (textAlign) style.textAlign = textAlign;
    if (shouldInheritSize) delete style.fontSize;

    return style;
};

const defaultTheme = resumeThemes[0];

// Each of these correspond to a section field key.
const colouredTextKeys = ["certificationTitle", "position", "accomplishmentTitle", "fieldOfStudy", "licenseTitle", "projectTitle", "groupingTitle", "volunteerTitle", "header"];

export const getTextColourStyle = ({
    accentColor,
    isStaticHeader,
    fieldKey,
}: {
    accentColor: string,
    isStaticHeader?: boolean,
    fieldKey: string,
}) => {
    const theme = resumeThemes.find(t => t.profileBackgroundColor.toLowerCase() === accentColor.toLowerCase()) || defaultTheme;
    const isColouredField = colouredTextKeys.includes(fieldKey ?? "");

    let textColor = "#000000";

    if (isStaticHeader) {
        textColor = theme?.isDark ? "#FFFFFF" : "#000000";
    } else if (isColouredField) {
        textColor = theme?.titleColor ?? "#000000";
    }

    return { color: textColor };
};

export const getPageVerticalMarginStyle = (firstPage: boolean, pageMarginSize?: PageMarginSize) => {
    if (firstPage) {
        switch (pageMarginSize) {
            case PageMarginSize.XS:
                return pdfStyles.firstPageVerticalSpacingExtraSmall;
            case PageMarginSize.SM:
                return pdfStyles.firstPageVerticalSpacingSmall;
            case PageMarginSize.MD:
                return pdfStyles.firstPageVerticalSpacingMedium;
            case PageMarginSize.LG:
                return pdfStyles.firstPageVerticalSpacingLarge;
            case PageMarginSize.XL:
                return pdfStyles.firstPageVerticalSpacingExtraLarge;
            default:
                return pdfStyles.firstPageVerticalSpacingMedium;
        }
    }

    switch (pageMarginSize) {
        case PageMarginSize.XS:
            return pdfStyles.pageVerticalSpacingExtraSmall;
        case PageMarginSize.SM:
            return pdfStyles.pageVerticalSpacingSmall;
        case PageMarginSize.MD:
            return pdfStyles.pageVerticalSpacingMedium;
        case PageMarginSize.LG:
            return pdfStyles.pageVerticalSpacingLarge;
        case PageMarginSize.XL:
            return pdfStyles.pageVerticalSpacingExtraLarge;
        default:
            return pdfStyles.pageVerticalSpacingMedium;
    }
};

export const getPageHorizontalMarginStyle = (pageMarginSize?: PageMarginSize) => {
    switch (pageMarginSize) {
        case PageMarginSize.XS:
            return pdfStyles.pageHorizontalMarginsExtraSmall;
        case PageMarginSize.SM:
            return pdfStyles.pageHorizontalMarginsSmall;
        case PageMarginSize.MD:
            return pdfStyles.pageHorizontalMarginsMedium;
        case PageMarginSize.LG:
            return pdfStyles.pageHorizontalMarginsLarge;
        case PageMarginSize.XL:
            return pdfStyles.pageHorizontalMarginsExtraLarge;
        default:
            return pdfStyles.pageHorizontalMarginsMedium;
    }
};

export const getSectionHorizontalPadding = (sectionSpacing: SectionSpacingSize = SectionSpacingSize.MD) => {
    switch (sectionSpacing) {
        case SectionSpacingSize.XS:
            return pdfStyles.sectionHorizontalPaddingExtraSmall;
        case SectionSpacingSize.SM:
            return pdfStyles.sectionHorizontalPaddingSmall;
        case SectionSpacingSize.MD:
            return pdfStyles.sectionHorizontalPaddingMedium;
        case SectionSpacingSize.LG:
            return pdfStyles.sectionHorizontalPaddingLarge;
        case SectionSpacingSize.XL:
            return pdfStyles.sectionHorizontalPaddingExtraLarge;
        default:
            return pdfStyles.sectionHorizontalPaddingMedium;
    }
};

export const getSectionYMargin = (sectionSpacing: SectionSpacingSize = SectionSpacingSize.MD) => {
    switch (sectionSpacing) {
        case SectionSpacingSize.XS:
            return pdfStyles.sectionMarginYExtraSmall;
        case SectionSpacingSize.SM:
            return pdfStyles.sectionMarginYSmall;
        case SectionSpacingSize.MD:
            return pdfStyles.sectionMarginYMedium;
        case SectionSpacingSize.LG:
            return pdfStyles.sectionMarginYLarge;
        case SectionSpacingSize.XL:
            return pdfStyles.sectionMarginYExtraLarge;
        default:
            return pdfStyles.sectionMarginYMedium;
    }
};

/**
 * The static header has a different layout to other sections due to the accent colour background.
 * The padding applied is the margin + padding applied to the section. Rather than an individual padding and margin value.
 * @param sectionSpacing 
 * @param pageMarginSize 
 */
export const getStaticHeaderSectionStyle = (sectionSpacing: SectionSpacingSize = SectionSpacingSize.MD, pageMarginSize?: PageMarginSize) => {
    const pageXMarginStyle = getPageHorizontalMarginStyle(pageMarginSize);
    const sectionXPaddingStyle = getSectionHorizontalPadding(sectionSpacing);
    const sectionPaddingYStyle = getStaticHeaderSectionPaddingYStyle(sectionSpacing);

    return {
        paddingLeft: pageXMarginStyle.marginLeft + sectionXPaddingStyle.paddingLeft,
        paddingRight: pageXMarginStyle.marginRight + sectionXPaddingStyle.paddingRight,
        ...sectionPaddingYStyle,
    };
};

export const getStaticHeaderSectionPaddingYStyle = (sectionSpacing: SectionSpacingSize = SectionSpacingSize.MD) => {
    switch (sectionSpacing) {
        case SectionSpacingSize.XS:
            return pdfStyles.headerSectionSpacingYExtraSmall;
        case SectionSpacingSize.SM:
            return pdfStyles.headerSectionSpacingYSmall;
        case SectionSpacingSize.MD:
            return pdfStyles.headerSectionSpacingYMedium;
        case SectionSpacingSize.LG:
            return pdfStyles.headerSectionSpacingYLarge;
        case SectionSpacingSize.XL:
            return pdfStyles.headerSectionSpacingYExtraLarge;
        default:
            return pdfStyles.headerSectionSpacingYMedium;
    }
};

export const getSectionHeadingMarginYStyle = (sectionSpacing: SectionSpacingSize = SectionSpacingSize.MD, isStaticHeader: boolean) => {
    if (isStaticHeader) {
        return { marginBottom: 0 };
    }

    switch (sectionSpacing) {
        case SectionSpacingSize.XS:
            return pdfStyles.sectionHeadingMarginYExtraSmall;
        case SectionSpacingSize.SM:
            return pdfStyles.sectionHeadingMarginYSmall;
        case SectionSpacingSize.MD:
            return pdfStyles.sectionHeadingMarginYMedium;
        case SectionSpacingSize.LG:
            return pdfStyles.sectionHeadingMarginYLarge;
        case SectionSpacingSize.XL:
            return pdfStyles.sectionHeadingMarginYExtraLarge;
        default:
            return pdfStyles.sectionHeadingMarginYMedium;
    }
};

export const getSectionItemMarginYStyle = (sectionSpacing: SectionSpacingSize = SectionSpacingSize.MD, isLastItem: boolean) => {
    if (isLastItem) {
        return { marginBottom: 0 };
    }

    switch (sectionSpacing) {
        case SectionSpacingSize.XS:
            return pdfStyles.sectionItemMarginYExtraSmall;
        case SectionSpacingSize.SM:
            return pdfStyles.sectionItemMarginYSmall;
        case SectionSpacingSize.MD:
            return pdfStyles.sectionItemMarginYMedium;
        case SectionSpacingSize.LG:
            return pdfStyles.sectionItemMarginYLarge;
        case SectionSpacingSize.XL:
            return pdfStyles.sectionItemMarginYExtraLarge;
        default:
            return pdfStyles.sectionItemMarginYMedium;
    }
};

export const getSplitPageColumnWidthForPdf = (columnIndex: number, pageMarginSize: PageMarginSize = PageMarginSize.MD, sectionSpacing: SectionSpacingSize = SectionSpacingSize.MD) => {
    const pageXMargin = getPageHorizontalMarginStyle(pageMarginSize);
    const pageWidth = A4_PAGE_WIDTH_PIXELS;
    const columnGap = getColumnGap(0, sectionSpacing);
    const spaceWithoutMargins = pageWidth - pageXMargin.marginLeft - pageXMargin.marginRight - (columnGap.marginRight * 2);

    const columnWidth = columnIndex === 1
        ? Math.round(spaceWithoutMargins * 0.4)
        : Math.round(spaceWithoutMargins * 0.6);

    return columnWidth;
};

export const getColumnGap = (columnIndex: number, sectionSpacing: SectionSpacingSize = SectionSpacingSize.MD) => {
    switch (sectionSpacing) {
        case SectionSpacingSize.XS: {
            return normaliseColumnGap(columnIndex, pdfStyles.columnGapExtraSmall);
        }
        case SectionSpacingSize.SM:
            return normaliseColumnGap(columnIndex, pdfStyles.columnGapSmall);
        case SectionSpacingSize.MD:
            return normaliseColumnGap(columnIndex, pdfStyles.columnGapMedium);
        case SectionSpacingSize.LG:
            return normaliseColumnGap(columnIndex, pdfStyles.columnGapLarge);
        case SectionSpacingSize.XL:
            return normaliseColumnGap(columnIndex, pdfStyles.columnGapExtraLarge);
        default:
            return normaliseColumnGap(columnIndex, pdfStyles.columnGapMedium);
    }
};

const normaliseColumnGap = (columnIndex: number, columnGap: { marginRight: number, marginLeft: number; }) => {
    if (columnIndex === 0) {
        return {
            ...columnGap,
            marginLeft: 0,
        };
    }
    return {
        ...columnGap,
        marginRight: 0,
    };
};