import { Tokens } from "./token";

export interface RegisterRequest {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    preferences?: UserPreferences;
    acceptedTerms: boolean;
    marketingOptIns: MarketingOptIn[];
    recaptchaToken: string;
    signUpReferralCode?: string;
    referralSource?: ReferralSource;
}

export interface LoginRequest {
    email: string;
    password: string;
    recaptchaToken: string;
}

export interface SSOLoginRequest {
    type: "code" | "credential";
    value: string;
    method: "google" | "linkedin";
    referralSource?: ReferralSource;
    signUpReferralCode?: string;
}

export interface LoginAndRegisterResponse {
    tokens: Tokens;
    user: User;
    verifyCode: string;
}

export interface ChangePasswordRequest {
    oldPassword: string;
    newPassword: string;
}

export interface ChangeEmailRequest {
    currentEmail: string;
    newEmail: string;
    code: string;
}

export interface VerifyChangeEmailRequest {
    currentEmail: string;
    newEmail: string;
    password: string;
}

export interface ChangeNamesRequest {
    firstName: string;
    lastName: string;
}

export interface UpdatedOptInsRequest {
    channel: MarketingChannels;
    optedIn: boolean;
}

export type UserRole = "user" | "admin" | "superAdmin";

export interface MarketingOptIn {
    channel: MarketingChannels;
    optedIn: boolean;
    timestamp: Date;
    source: MarketingSources;
}

export const USER_FLAGS = {
    HAS_SEEN_RESUME_BUILDER_TOUR: "hasSeenResumeBuilderTour",
    DOWNLOAD_COUNT: "downloadCount",
    FEEDBACK_SURVEY_TIMESTAMP: "feedbackSurveyTimestamp",
} as const;

export type UserFlagKey = keyof typeof USER_FLAGS;

export interface UserFlags {
    [key: string]: boolean | number | string;
}


export interface ICredit {
    balance: number;
    lastUpdated: Date | null;
}

export enum ReferralSource {
    LINKEDIN = "linkedin",
    FACEBOOK = "facebook",
    WHATSAPP = "whatsapp",
    DIRECT = "direct",
}

export interface Referral {
    referredUserId: string;
    referredUserName: {
        firstName: string;
        lastName?: string;
    };
    status: "pending" | "successful";
    signupDate: Date;
    completedDate?: Date;
    creditsEarned: number;
    source: ReferralSource;
}

export interface User {
    firstName?: string; // SSO users may not have a first name
    lastName?: string; // SSO users may not have a last name
    email: string;
    password?: string;
    role: UserRole;
    isEmailVerified?: boolean;
    id: string;
    loginMethod: "email" | "google" | "linkedin";
    preferences?: UserPreferences;
    acceptedTerms: boolean;
    marketingOptIns: MarketingOptIn[];
    stripeCustomerId: string;
    accountDeletionRequestedAt: Date | null;
    scheduledDeletionDate: Date | null;
    createdAt: Date;
    flags: UserFlags;
    credits?: ICredit;
    lastLoginAt: Date | null;
    documentLimit: number;
    referralCode: string;
    referrals: Referral[];
    referredBy: string;
}

export interface UserPreferences {
    experienceLevel: string;
    industries: string[];
    jobTypes: string[];
    jobRoles: string[];
}

export enum MarketingChannels {
    EMAIL = "email",
}

export enum MarketingSources {
    SIGNUP = "signup",
    PROFILE = "profile",
}

export const REFERRAL_LIMIT = 5;