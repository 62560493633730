import { DetailedHTMLProps, HTMLAttributes, memo } from "react";
import HtmlMapper from "react-html-map";
import { MemoBlock } from "@/components/EditorComponents/Block";
import { Image } from "@/components/EditorComponents/Image";
import { ReadOnlyBodyText } from "@/components/EditorComponents/ReadOnlyBodyText";
import { ReadOnlyTitle } from "@/components/EditorComponents/ReadOnlyTitle";
import { ReadOnlyWidget } from "@/components/EditorComponents/ReadOnlyWidget";
import { ComponentPropsObject, ResumeStyles } from "@/types/resume";
import { SectionFieldsConfig } from "@/types/section";

interface ReadOnlyMapperProps {
    html: string;
    sectionId: string;
    visibleProps: ComponentPropsObject;
    propType: "header" | "body";
    itemId?: string;
    fieldsConfig?: SectionFieldsConfig;
    showUnderline?: boolean;
    isStaticHeader?: boolean;
    stylesConfig: ResumeStyles;
    layoutProps?: ComponentPropsObject;
}

const ReadOnlyMapper = ({
    html,
    sectionId,
    visibleProps,
    isStaticHeader,
    propType,
    fieldsConfig = {},
    stylesConfig,
    layoutProps,
}: ReadOnlyMapperProps) => {
    return (
        <HtmlMapper html={html}>
            {{
                p: (
                    props: DetailedHTMLProps<
                        HTMLAttributes<HTMLParagraphElement>,
                        HTMLParagraphElement
                    >,
                ) => {
                    if (!props.id || visibleProps?.[props.id] === undefined)
                        return null;

                    return (
                        <ReadOnlyBodyText
                            value={visibleProps?.[props.id]}
                            icon={fieldsConfig[props.id]?.icon}
                            stylesConfig={stylesConfig}
                            id={props.id}
                            isStaticHeader={!!isStaticHeader}
                        />
                    );
                },
                h1: (
                    props: DetailedHTMLProps<
                        HTMLAttributes<HTMLHeadingElement>,
                        HTMLHeadingElement
                    >,
                ) => {
                    if (!props.id || visibleProps?.[props.id] === undefined)
                        return null;

                    return (
                        <ReadOnlyTitle
                            variant="h1"
                            value={visibleProps?.[props.id]}
                            icon={fieldsConfig[props.id]?.icon}
                            isStaticHeader={!!isStaticHeader}
                            stylesConfig={stylesConfig}
                            id={props.id}
                        />
                    );
                },
                h2: (
                    props: DetailedHTMLProps<
                        HTMLAttributes<HTMLHeadingElement>,
                        HTMLHeadingElement
                    >,
                ) => {
                    if (!props.id || visibleProps?.[props.id] === undefined)
                        return null;

                    return (
                        <ReadOnlyTitle
                            variant="h2"
                            value={visibleProps?.[props.id]}
                            icon={fieldsConfig[props.id]?.icon}
                            stylesConfig={stylesConfig}
                            isStaticHeader={!!isStaticHeader}
                            id={props.id}
                        />
                    );
                },
                h3: (
                    props: DetailedHTMLProps<
                        HTMLAttributes<HTMLHeadingElement>,
                        HTMLHeadingElement
                    >,
                ) => {
                    if (!props.id || visibleProps?.[props.id] === undefined)
                        return null;

                    return (
                        <ReadOnlyTitle
                            variant="h3"
                            value={visibleProps?.[props.id]}
                            icon={fieldsConfig[props.id]?.icon}
                            stylesConfig={stylesConfig}
                            isStaticHeader={!!isStaticHeader}
                            id={props.id}
                        />
                    );
                },
                h4: (
                    props: DetailedHTMLProps<
                        HTMLAttributes<HTMLHeadingElement>,
                        HTMLHeadingElement
                    >,
                ) => {
                    if (!props.id || visibleProps?.[props.id] === undefined)
                        return null;

                    return (
                        <ReadOnlyTitle
                            variant="h4"
                            value={visibleProps?.[props.id]}
                            icon={fieldsConfig[props.id]?.icon}
                            stylesConfig={stylesConfig}
                            isStaticHeader={!!isStaticHeader}
                            id={props.id}
                        />
                    );
                },
                h5: (
                    props: DetailedHTMLProps<
                        HTMLAttributes<HTMLHeadingElement>,
                        HTMLHeadingElement
                    >,
                ) => {
                    if (!props.id || visibleProps?.[props.id] === undefined)
                        return null;

                    return (
                        <ReadOnlyTitle
                            variant="h5"
                            value={visibleProps?.[props.id]}
                            icon={fieldsConfig[props.id]?.icon}
                            stylesConfig={stylesConfig}
                            isStaticHeader={!!isStaticHeader}
                            id={props.id}
                        />
                    );
                },
                div: (
                    props: DetailedHTMLProps<
                        HTMLAttributes<HTMLDivElement>,
                        HTMLDivElement
                    >,
                ) => (
                    <MemoBlock
                        {...props}
                        className={props.class || props.className}
                        id={props.id}
                        value={layoutProps?.[props.id]}
                        stylesConfig={stylesConfig}
                        layoutProps={layoutProps}
                    />
                ),
                img: (
                    props: DetailedHTMLProps<
                        HTMLAttributes<HTMLImageElement>,
                        HTMLImageElement
                    >,
                ) => {
                    return visibleProps?.[props.id] ? (
                        <Image
                            key={visibleProps?.[props.id]}
                            src={visibleProps?.[props.id]}
                            sectionId={sectionId}
                            propId={props.id}
                            propType={propType}
                            editable={false}
                            inlineStyles={props.style}
                            showPreview
                            stylesConfig={stylesConfig}
                        />
                    ) : null;
                },
                span: (
                    props: DetailedHTMLProps<
                        HTMLAttributes<HTMLSpanElement>,
                        HTMLSpanElement
                    >,
                ) => {
                    if (!props.id || visibleProps?.[props.id] === undefined)
                        return null;

                    return (
                        <ReadOnlyWidget
                            value={visibleProps?.[props.id]}
                            icon={fieldsConfig[props.id]?.icon}
                            stylesConfig={stylesConfig}
                            type={fieldsConfig[props.id]?.type}
                        />
                    );
                },
            }}
        </HtmlMapper>
    );
};

export const MemoReadOnlyMapper = memo(ReadOnlyMapper);
