import clsx from "clsx";
import { ArrowLeft, ArrowRight, WrapText } from "lucide-react";
import { useEffect, useMemo, useState, useCallback } from "react";
import {
    smartScribeQuickPromptOptions,
    smartScribeToolsOptions,
} from "@/components/AiToolbox/constants";
import { Button } from "@/components/Button/Button";
import { CopyButton } from "@/components/Button/CopyButton";
import { CreditSpendIndicator } from "@/components/Credits/CreditSpendIndicator";
import { Dropdown } from "@/components/Dropdown/Dropdown";
import { InsertBelowIcon } from "@/components/Icons/Icons";
import { AppText } from "@/components/Text/AppText";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useTransaction } from "@/hooks/useTransaction";
import {
    getAiToolboxPromptActionCode,
    getAiToolboxPromptOutput,
    getAiToolboxStage,
} from "@/store/app/selectors";
import { updateAiToolbox } from "@/store/app/slice";
import { getUserCreditBalance } from "@/store/user/selectors";
import { CreditActionCode } from "@/types/creditAction";
import { pluralise } from "@/utils/string";

interface AiToolboxFooterProps {
    estimatedCredits: number;
    onGenerate: () => void;
    onInsert?: () => void;
    isGenerating: boolean;
    hasInput: boolean;
    onBack: () => void;
    maxLength?: number;
    inputValue?: string;
    trackCopy?: () => void;
    hasGenerateError?: boolean;
    handleCancel: () => void;
    insertAction: "replace" | "insert";
    onAddBelow?: () => void;
}

const footerBaseClasses =
    "flex items-center justify-between px-3 pb-3 transition-[height,opacity] duration-300";

export const AiToolboxFooter = ({
    estimatedCredits,
    onGenerate,
    onInsert,
    isGenerating,
    hasInput,
    onBack,
    maxLength = 1000,
    inputValue,
    trackCopy,
    hasGenerateError,
    handleCancel,
    insertAction,
    onAddBelow,
}: AiToolboxFooterProps) => {
    const userCreditBalance = useAppSelector(getUserCreditBalance);
    const stage = useAppSelector(getAiToolboxStage);
    const dispatch = useAppDispatch();
    const [localValue, setLocalValue] = useState(inputValue);
    const { getCanAffordCreditAction } = useTransaction();
    const promptOutput = useAppSelector(getAiToolboxPromptOutput);
    const promptActionCode = useAppSelector(getAiToolboxPromptActionCode);
    const onFeatureChange = useCallback(
        (promptActionCode: CreditActionCode) => {
            dispatch(updateAiToolbox({ promptActionCode, stage: "input" }));
        },
        [dispatch],
    );

    useEffect(() => {
        setLocalValue(inputValue);
    }, [inputValue]);

    const allOptions = useMemo(
        () => [...smartScribeToolsOptions, ...smartScribeQuickPromptOptions],
        [],
    );
    const activeOption = allOptions.find(
        option => option.id === promptActionCode,
    );

    const canAffordAiWritingPrompt = getCanAffordCreditAction(
        activeOption?.id ?? "",
    );

    if (stage === "output") {
        return (
            <div className="flex flex-col">
                <div className={footerBaseClasses}>
                    <div className="flex items-center gap-3">
                        {isGenerating ? (
                            <Button
                                onClick={handleCancel}
                                size="small"
                                variant="text"
                                color="grey"
                                leftIcon={<ArrowLeft size={16} />}
                            >
                                Cancel
                            </Button>
                        ) : (
                            <Button
                                onClick={onBack}
                                variant="solid"
                                color="softIndigo"
                                size="small"
                                disabled={isGenerating}
                                leftIcon={<ArrowLeft size={16} />}
                            >
                                Back
                            </Button>
                        )}
                    </div>
                    {!hasGenerateError && !isGenerating && (
                        <div className="flex items-center gap-3">
                            <CopyButton
                                content={promptOutput}
                                size="small"
                                variant="full"
                                disabled={isGenerating}
                                onCopy={trackCopy}
                            />
                            {insertAction === "replace" && onAddBelow && (
                                <Button
                                    onClick={onAddBelow}
                                    variant="outline"
                                    size="small"
                                    color="primary"
                                    leftIcon={
                                        <InsertBelowIcon className="h-4 w-4" />
                                    }
                                    disabled={isGenerating}
                                >
                                    Add below
                                </Button>
                            )}
                            {onInsert && (
                                <Button
                                    onClick={onInsert}
                                    variant="solid"
                                    size="small"
                                    color="primary"
                                    leftIcon={<WrapText size={16} />}
                                    disabled={isGenerating}
                                >
                                    {insertAction === "replace"
                                        ? "Replace"
                                        : "Insert"}
                                </Button>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className={footerBaseClasses}>
            <div className="flex items-center gap-3">
                <Dropdown
                    items={allOptions}
                    activeId={promptActionCode || ""}
                    title={activeOption?.title || ""}
                    handleChange={id => {
                        onFeatureChange?.(id as CreditActionCode);
                    }}
                    className="w-[200px]"
                    hideActiveId={!!promptActionCode}
                    leftIcon={activeOption?.icon}
                />
                <div className="flex items-center gap-2">
                    <CreditSpendIndicator
                        creditActionCode={activeOption?.id}
                        buttonSize="small"
                    />
                </div>
            </div>
            <div className="flex items-center gap-3">
                {canAffordAiWritingPrompt && (
                    <div
                        className={clsx(
                            "w-[80px] text-right",
                            (localValue?.length ?? 0) >= maxLength &&
                                "animate-shake",
                        )}
                    >
                        <AppText
                            variant="labelsbuttons"
                            className={clsx(
                                "text-neutral-700 transition-all",
                                (localValue?.length ?? 0) >= maxLength &&
                                    "text-red-500",
                            )}
                        >
                            {localValue?.length ?? 0}/{maxLength}
                        </AppText>
                    </div>
                )}
                <Button
                    onClick={onGenerate}
                    disabled={
                        canAffordAiWritingPrompt
                            ? isGenerating || !hasInput || !!promptOutput
                            : true
                    }
                    loading={isGenerating}
                    size="small"
                    rightIcon={<ArrowRight size={16} />}
                >
                    Generate
                </Button>
            </div>
        </div>
    );
};
