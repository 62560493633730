import { Form, Formik } from "formik";
import { FileText, LayoutPanelLeft, Paintbrush } from "lucide-react";
import { useMemo, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import {
    createSearchParams,
    useNavigate,
    useSearchParams,
} from "react-router-dom";
import { bool, object, ref, string } from "yup";
import { Button } from "../../components/Button/Button";
import userApi from "@/api/user";
import { Alert } from "@/components/Alert/Alert";
import { Logo, SmartScribe, WhiteLogo } from "@/components/Icons/Icons";
import { PageHelmet } from "@/components/PageHelmet/PageHelmet";
import { SSOLogin } from "@/components/SSO/SSOLogin";
import { AppText } from "@/components/Text/AppText";
import { Checkbox } from "@/components/form/Checkbox";
import { Input } from "@/components/form/Input";
import { InputError } from "@/components/form/InputError";
import {
    emailValidation,
    firstNameValidation,
    getNewPasswordSchemaField,
    lastNameValidation,
} from "@/constants/schema";
import { sendVerifyEmail } from "@/email/send";
import { getErrorMessage } from "@/helper/getErrorMessage";
import { translate } from "@/helper/translate";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import { PasswordValidator } from "@/pages/Signup/PasswordValidator";
import {
    setAccessToken,
    setRefreshToken,
    setUserDetails,
} from "@/store/user/slice";
import { getUserPreferenceWizard } from "@/store/wizard/selectors";
import { RegisterRequest, User } from "@/types";
import { AppRoutes, SiteRoutes } from "@/types/routes";
import { Tokens } from "@/types/token";
import { CLARITY_EVENT_NAMES, META_EVENT_ACTIONS } from "@/types/tracking";
import {
    MarketingChannels,
    MarketingSources,
    ReferralSource,
} from "@/types/user";
import {
    trackClarityEvent,
    trackMetaEvent,
    trackPostHogEvent,
} from "@/utils/tracking";

const signUpSchema = object({
    email: emailValidation,
    password: getNewPasswordSchemaField("Password is required"),
    confirmPassword: string()
        .required("Please confirm your password")
        .oneOf([ref("password")], "Entered passwords do not match"),
    firstName: firstNameValidation,
    lastName: lastNameValidation,
    acceptedTerms: bool().oneOf(
        [true],
        "You must accept the terms and conditions",
    ),
    marketingOptIn: bool(),
});

interface SignUpFormFields {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    confirmPassword: string;
    acceptedTerms: boolean;
    marketingOptIn: boolean;
}

export const SignUpPage = () => {
    const initialValues: SignUpFormFields = useMemo(
        () => ({
            email: "",
            password: "",
            confirmPassword: "",
            firstName: "",
            lastName: "",
            acceptedTerms: false,
            marketingOptIn: false,
        }),
        [],
    );
    const [error, setError] = useState<string | null>("");
    const [, setLocalAccessToken] = useLocalStorage("accessToken", null);
    const [, setLocalRefreshToken] = useLocalStorage("refreshToken", null);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const userPreferencesWizard = useAppSelector(getUserPreferenceWizard);
    const [loading, setLoading] = useState(false);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [searchParams] = useSearchParams();
    const referralCode = searchParams.get("referral_code");
    const referralSource = searchParams.get("utm_source");

    const onLoginSuccess = (user: User, tokens: Tokens) => {
        if (user.isEmailVerified) {
            dispatch(setAccessToken(tokens.access));
            dispatch(setRefreshToken(tokens.refresh));
            setLocalAccessToken(tokens.access);
            setLocalRefreshToken(tokens.refresh);
            dispatch(setUserDetails(user));
            const hasAPreferenceSet =
                (user.preferences?.jobTypes ?? []).length > 0 ||
                (user.preferences?.industries ?? []).length > 0 ||
                (user.preferences?.jobRoles ?? []).length > 0 ||
                !!user.preferences?.experienceLevel;
            if (!hasAPreferenceSet) {
                navigate(`/${AppRoutes.Preferences}`);
            } else {
                navigate(AppRoutes.Dashboard);
            }
        }
    };

    const handleSignUp = async (values: SignUpFormFields) => {
        setError("");
        setLoading(true);

        if (!executeRecaptcha) {
            setError(
                "ReCAPTCHA not available. Please refresh the page and try again.",
            );
            setLoading(false);
            return;
        }

        try {
            const recaptchaToken = await executeRecaptcha("signup");

            const data: RegisterRequest = {
                email: values.email,
                password: values.password,
                firstName: values.firstName,
                lastName: values.lastName,
                preferences: userPreferencesWizard,
                acceptedTerms: values.acceptedTerms,
                marketingOptIns: [],
                recaptchaToken,
                signUpReferralCode: referralCode ?? undefined,
                referralSource: (referralSource as ReferralSource) ?? undefined,
            };

            if (values.marketingOptIn) {
                data.marketingOptIns = [
                    {
                        channel: MarketingChannels.EMAIL,
                        optedIn: true,
                        timestamp: new Date(),
                        source: MarketingSources.SIGNUP,
                    },
                ];
            }

            try {
                const res = await userApi.registerApi(data);
                const { user, tokens, verifyCode } = res;
                if (verifyCode) {
                    sendVerifyEmail(user.email, verifyCode);
                    navigate({
                        pathname: `/${AppRoutes.Verify}`,
                        search: createSearchParams({
                            email: user.email,
                        }).toString(),
                    });
                    return;
                }

                trackPostHogEvent(CLARITY_EVENT_NAMES.AUTH_SIGNUP_SUCCESS);
                trackMetaEvent(META_EVENT_ACTIONS.COMPLETED_REGISTRATION, {
                    content_name: "Signup",
                    status: true,
                });
                trackClarityEvent(CLARITY_EVENT_NAMES.AUTH_SIGNUP_SUCCESS);

                const loginRecaptchaToken = await executeRecaptcha("login");

                await userApi
                    .loginApi({
                        email: values.email,
                        password: values.password,
                        recaptchaToken: loginRecaptchaToken,
                    })
                    .then(() => {
                        onLoginSuccess(user, tokens);
                    });
            } catch (e: unknown) {
                const errorMessage =
                    typeof e === "object" && e !== null && "message" in e
                        ? (e.message as string)
                        : e?.toString() || "Unknown error occurred";
                if (errorMessage.includes("Email already taken")) {
                    setError("An account with this email already exists");
                } else {
                    throw e; // Re-throw other errors to be caught by outer catch block
                }
                setLoading(false);
                return;
            }
        } catch (e) {
            setError(getErrorMessage(e, "Register"));
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-neutral-50">
            <div className="mx-auto h-full lg:flex">
                {/* Left column - Form */}
                <div className="flex flex-1 flex-col bg-white lg:min-w-[500px]">
                    <div className="mx-auto flex w-full flex-col items-center px-6 py-8 lg:px-16 lg:py-12">
                        {/* Mobile Logo */}
                        <div className="mb-6 flex justify-center lg:hidden">
                            <Logo className="h-10 w-auto" />
                        </div>

                        <PageHelmet
                            title="Sign up"
                            description="Create Account"
                            robotsOverride="noindex, nofollow"
                            url={`${window.location.origin}/${AppRoutes.Signup}`}
                        />

                        <div className="lg:hidden">
                            <AppText
                                variant="headings"
                                className="text-center text-neutral-900"
                            >
                                GET STARTED FOR FREE
                            </AppText>
                            <AppText
                                variant="regular"
                                className="mt-2 text-center !font-medium uppercase text-neutral-600"
                            >
                                Unlock the full potential of your resume today
                            </AppText>
                        </div>

                        <div className="hidden text-center lg:block">
                            <AppText
                                variant="headings"
                                className="text-neutral-900"
                            >
                                GET STARTED FOR FREE
                            </AppText>
                            <AppText
                                variant="regular"
                                className="mt-2 !font-medium uppercase text-neutral-600"
                            >
                                Unlock the full potential of your resume today
                            </AppText>
                        </div>

                        <Formik
                            initialValues={initialValues}
                            onSubmit={handleSignUp}
                            validationSchema={signUpSchema}
                            validateOnBlur={false}
                            validateOnChange={false}
                            validateOnMount={false}
                        >
                            {({ values, errors, handleChange }) => (
                                <Form className="mt-8">
                                    <div className="mx-auto max-w-[450px] lg:mx-0">
                                        {error && (
                                            <Alert
                                                type="error"
                                                message={error}
                                                className="mb-4"
                                            />
                                        )}

                                        {/* SSO Login Option at the top */}
                                        <div className="mb-3">
                                            <SSOLogin
                                                setError={setError}
                                                onSuccess={onLoginSuccess}
                                            />
                                            <div className="my-4 flex items-center">
                                                <div className="h-px flex-1 bg-neutral-200"></div>
                                                <span className="mx-4 text-sm text-neutral-500">
                                                    or continue with email
                                                </span>
                                                <div className="h-px flex-1 bg-neutral-200"></div>
                                            </div>
                                        </div>

                                        <div className="space-y-3">
                                            <div className="grid grid-cols-2 gap-4">
                                                <Input
                                                    type="text"
                                                    autoComplete="firstName"
                                                    label="First Name"
                                                    name="firstName"
                                                    formikChange={handleChange}
                                                    error={errors.firstName}
                                                    value={values.firstName}
                                                    containerClassName="col-span-1"
                                                />
                                                <Input
                                                    type="text"
                                                    autoComplete="lastName"
                                                    label="Last Name"
                                                    name="lastName"
                                                    formikChange={handleChange}
                                                    error={errors.lastName}
                                                    value={values.lastName}
                                                    containerClassName="col-span-1"
                                                />
                                            </div>

                                            <Input
                                                type="text"
                                                autoComplete="email"
                                                label="Email"
                                                name="email"
                                                formikChange={handleChange}
                                                error={errors.email}
                                                value={values.email}
                                            />

                                            <Input
                                                type="password"
                                                autoComplete="password"
                                                label="Password"
                                                name="password"
                                                formikChange={handleChange}
                                                error={errors.password}
                                                value={values.password}
                                            />

                                            <PasswordValidator
                                                password={values.password}
                                            />

                                            <Input
                                                type="password"
                                                label="Confirm password"
                                                name="confirmPassword"
                                                formikChange={handleChange}
                                                error={errors.confirmPassword}
                                                value={values.confirmPassword}
                                            />
                                        </div>

                                        <div className="mt-4 rounded-lg bg-neutral-50">
                                            <Checkbox
                                                label={
                                                    <AppText variant="labelsbuttons">
                                                        I agree to{" "}
                                                        <a
                                                            href={
                                                                SiteRoutes.Terms
                                                            }
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="text-primary-700"
                                                        >
                                                            Terms of Service
                                                        </a>{" "}
                                                        and{" "}
                                                        <a
                                                            href={
                                                                SiteRoutes.PrivacyPolicy
                                                            }
                                                            target="blank"
                                                            rel="noreferrer"
                                                            className="text-primary-700"
                                                        >
                                                            Privacy Policy
                                                        </a>
                                                    </AppText>
                                                }
                                                id={"acceptedTerms"}
                                                borderless
                                                className="px-0 py-1"
                                                checked={values.acceptedTerms}
                                                onChange={checked => {
                                                    handleChange({
                                                        target: {
                                                            name: "acceptedTerms",
                                                            value: checked,
                                                        },
                                                    });
                                                }}
                                            />
                                            <div className="pl-9">
                                                <InputError
                                                    error={errors.acceptedTerms}
                                                />
                                            </div>
                                            <Checkbox
                                                label="Sign me up for emails and updates from 1Template"
                                                id={"marketingOptIn"}
                                                borderless
                                                className="px-0 py-1"
                                                checked={values.marketingOptIn}
                                                onChange={checked => {
                                                    handleChange({
                                                        target: {
                                                            name: "marketingOptIn",
                                                            value: checked,
                                                        },
                                                    });
                                                }}
                                            />
                                        </div>

                                        <Button
                                            className="mt-4 w-full"
                                            type="submit"
                                            disabled={loading}
                                            loading={loading}
                                        >
                                            Create Account
                                        </Button>

                                        <div className="mt-3 text-center">
                                            <Button
                                                to={`/${AppRoutes.Login}`}
                                                color={"primary"}
                                                variant={"text"}
                                                className="text-sm"
                                            >
                                                Already have an account? Sign in
                                            </Button>
                                        </div>
                                        <div className="mt-4 flex justify-center">
                                            <AppText
                                                variant="footnote"
                                                className="w-[250px] text-center text-neutral-700"
                                            >
                                                By continuing, you agree to
                                                1Template&apos;s{" "}
                                                <a
                                                    href={SiteRoutes.Terms}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="text-primary-700"
                                                >
                                                    Terms of Service
                                                </a>
                                                . Read our{" "}
                                                <a
                                                    href={
                                                        SiteRoutes.PrivacyPolicy
                                                    }
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="text-primary-700"
                                                >
                                                    Privacy Policy
                                                </a>
                                                .
                                            </AppText>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>

                {/* Right column - Hero/Benefits */}
                <div className="relative hidden bg-gradient-to-bl from-primary-700 to-primary-900 lg:flex lg:w-[55%] xl:w-[60%]">
                    <div className="relative flex w-full flex-col p-8 text-white xl:p-12">
                        <WhiteLogo className="h-10 w-auto" />
                        <div className="mx-auto mt-12 flex max-w-2xl flex-1 flex-col">
                            <AppText
                                variant="headings"
                                className="mb-4 uppercase text-white"
                            >
                                Elevate Your Career with a Resume That Gets
                                Results
                            </AppText>
                            <AppText
                                variant="regular"
                                className="mb-8 text-white/90"
                            >
                                Build your resume in minutes with the most
                                advanced AI-powered builder.
                            </AppText>

                            <div className="mt-4 space-y-6">
                                <div className="flex items-start">
                                    <div className="mr-4 flex h-10 w-10 items-center justify-center">
                                        <SmartScribe className="h-6 w-6 text-white" />
                                    </div>
                                    <div className="flex-1">
                                        <AppText
                                            variant="contextheading"
                                            className="font-semibold text-white"
                                        >
                                            Smart Tools for Faster Results
                                        </AppText>
                                        <AppText
                                            variant="regular"
                                            className="text-white/80"
                                        >
                                            Build powerful resumes in seconds
                                            with Smart Scribe and Smart Match
                                            AI.
                                        </AppText>
                                    </div>
                                </div>
                                <div className="flex items-start">
                                    <div className="mr-4 flex h-10 w-10 items-center justify-center">
                                        <FileText className="h-5 w-5 text-white" />
                                    </div>
                                    <div className="flex-1">
                                        <AppText
                                            variant="contextheading"
                                            className="font-semibold text-white"
                                        >
                                            Expert-Designed Templates
                                        </AppText>
                                        <AppText
                                            variant="regular"
                                            className="text-white/80"
                                        >
                                            Stand out with templates built for
                                            impact — professional, polished, and
                                            ATS-friendly.
                                        </AppText>
                                    </div>
                                </div>

                                <div className="flex items-start">
                                    <div className="mr-4 flex h-10 w-10 items-center justify-center">
                                        <LayoutPanelLeft className="h-5 w-5 text-white" />
                                    </div>
                                    <div className="flex-1">
                                        <AppText
                                            variant="contextheading"
                                            className="font-semibold text-white"
                                        >
                                            Dynamic Formatting
                                        </AppText>
                                        <AppText
                                            variant="regular"
                                            className="text-white/80"
                                        >
                                            Drag, drop, and done — our system
                                            handles the layout for you.
                                        </AppText>
                                    </div>
                                </div>

                                <div className="flex items-start">
                                    <div className="mr-4 flex h-10 w-10 items-center justify-center">
                                        <Paintbrush className="h-5 w-5 text-white" />
                                    </div>
                                    <div className="flex-1">
                                        <AppText
                                            variant="contextheading"
                                            className="font-semibold text-white"
                                        >
                                            Instant Design
                                        </AppText>
                                        <AppText
                                            variant="regular"
                                            className="text-white/80"
                                        >
                                            One-click styling for{" "}
                                            {translate("colours")}, fonts, and
                                            layouts — no more fighting with
                                            editors.
                                        </AppText>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Testimonial */}
                        <div className="mx-auto mt-auto w-full max-w-2xl">
                            <div className="rounded-lg bg-white/10 p-6 backdrop-blur-sm">
                                <div className="flex items-center">
                                    <div className="mr-3 h-20 w-20 overflow-hidden rounded-full border-2 border-white/30">
                                        <img
                                            src="https://framerusercontent.com/images/0LRBZkUXkwuF2WcmgGx1YS4o.jpeg?scale-down-to=1024"
                                            alt="User testimonial"
                                            className="h-full w-full object-cover"
                                        />
                                    </div>
                                    <div className="flex items-baseline gap-2">
                                        <AppText
                                            variant="contextheading"
                                            className="font-semibold text-white"
                                        >
                                            Monica Candy
                                        </AppText>
                                        <AppText
                                            variant="footnote"
                                            className="text-white/80"
                                        >
                                            MBA Graduate
                                        </AppText>
                                    </div>
                                </div>
                                <AppText
                                    variant="regular"
                                    className="mt-3 italic text-white/90"
                                >
                                    &ldquo;1Template is an absolute
                                    game-changer! I quickly created multiple
                                    versions of my resume for each job I applied
                                    for. It&apos;s an essential tool for anyone
                                    looking to stand out in their
                                    applications.&rdquo;
                                </AppText>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile benefits section */}
            <div className="bg-gradient-to-bl from-primary-700 to-primary-900 px-6 py-8 lg:hidden">
                <div className="relative">
                    <img
                        src="/assets/pattern-bg.png"
                        alt=""
                        className="absolute inset-0 h-full w-full object-cover opacity-10"
                    />
                    <div className="relative mx-auto max-w-[450px]">
                        <AppText
                            variant="subheadings"
                            className="mb-6 text-center text-white"
                        >
                            Elevate Your Career with a Resume That Gets Results
                        </AppText>

                        <div className="space-y-6">
                            <div className="flex items-start">
                                <div className="mr-4 flex h-10 w-10 items-center justify-center">
                                    <SmartScribe className="h-6 w-6 text-white" />
                                </div>
                                <div className="flex-1">
                                    <AppText
                                        variant="contextheading"
                                        className="font-semibold text-white"
                                    >
                                        Smart Tools for Faster Results
                                    </AppText>
                                    <AppText
                                        variant="regular"
                                        className="text-white/80"
                                    >
                                        Build powerful resumes in seconds with
                                        Smart Scribe and Smart Match tools.
                                    </AppText>
                                </div>
                            </div>

                            <div className="flex items-start">
                                <div className="mr-4 flex h-10 w-10 items-center justify-center">
                                    <FileText className="h-5 w-5 text-white" />
                                </div>
                                <div className="flex-1">
                                    <AppText
                                        variant="contextheading"
                                        className="font-semibold text-white"
                                    >
                                        Expert-Designed Templates
                                    </AppText>
                                    <AppText
                                        variant="regular"
                                        className="text-white/80"
                                    >
                                        Stand out with templates built for
                                        impact — professional, polished, and
                                        ATS-friendly.
                                    </AppText>
                                </div>
                            </div>

                            <div className="flex items-start">
                                <div className="mr-4 flex h-10 w-10 items-center justify-center">
                                    <LayoutPanelLeft className="h-5 w-5 text-white" />
                                </div>
                                <div className="flex-1">
                                    <AppText
                                        variant="contextheading"
                                        className="font-semibold text-white"
                                    >
                                        Dynamic Formatting
                                    </AppText>
                                    <AppText
                                        variant="regular"
                                        className="text-white/80"
                                    >
                                        Drag, drop, and done — our system
                                        handles the layout for you.
                                    </AppText>
                                </div>
                            </div>

                            <div className="flex items-start">
                                <div className="mr-4 flex h-10 w-10 items-center justify-center">
                                    <Paintbrush className="h-5 w-5 text-white" />
                                </div>
                                <div className="flex-1">
                                    <AppText
                                        variant="contextheading"
                                        className="font-semibold text-white"
                                    >
                                        Instant Design
                                    </AppText>
                                    <AppText
                                        variant="regular"
                                        className="text-white/80"
                                    >
                                        One-click styling for{" "}
                                        {translate("colours")}, fonts, and
                                        layouts — no more fighting with editors.
                                    </AppText>
                                </div>
                            </div>
                        </div>

                        {/* Mobile Testimonial */}
                        <div className="mt-8">
                            <div className="rounded-lg bg-white/10 p-6 backdrop-blur-sm">
                                <div className="flex items-center">
                                    <div className="mr-3 h-16 w-16 overflow-hidden rounded-full border-2 border-white/30">
                                        <img
                                            src="https://framerusercontent.com/images/0LRBZkUXkwuF2WcmgGx1YS4o.jpeg?scale-down-to=1024"
                                            alt="User testimonial"
                                            className="h-full w-full object-cover"
                                        />
                                    </div>
                                    <div className="flex items-baseline gap-2">
                                        <AppText
                                            variant="contextheading"
                                            className="font-semibold text-white"
                                        >
                                            Monica Candy
                                        </AppText>
                                        <AppText
                                            variant="footnote"
                                            className="text-white/80"
                                        >
                                            MBA Graduate
                                        </AppText>
                                    </div>
                                </div>
                                <AppText
                                    variant="regular"
                                    className="mt-3 italic text-white/90"
                                >
                                    &ldquo;1Template is an absolute
                                    game-changer! I quickly created multiple
                                    versions of my resume for each job I applied
                                    for. It&apos;s an essential tool for anyone
                                    looking to stand out in their
                                    applications.&rdquo;
                                </AppText>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
