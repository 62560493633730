import { CLARITY_EVENT_NAMES, GA_EVENT_ACTIONS, GA_EVENT_CATEGORIES, META_CUSTOM_EVENT_ACTIONS, META_EVENT_ACTIONS } from '@/types/tracking';
import PostHogClient from 'posthog-js';
import ReactGA from 'react-ga4';
import { v4 as uuidv4 } from 'uuid';

const trackGAEvent = (
  category: GA_EVENT_CATEGORIES,
  action: GA_EVENT_ACTIONS,
  label?: string,
  value?: number,
): void => {
  ReactGA.event({
    category,
    action,
    label,
    value
  });
};

const trackMetaEvent = (event: META_EVENT_ACTIONS, params?: object) => {
  if (typeof window !== "undefined" && window.fbq) {
    window.fbq("track", event, params);
  }
};

function trackMetaCustomEvent(event: META_CUSTOM_EVENT_ACTIONS, params?: object) {
  if (typeof window !== "undefined" && window.fbq) {
    window.fbq("trackCustom", event, params);
  }
}

const trackClarityEvent = (eventName: CLARITY_EVENT_NAMES): void => {
  if (typeof window !== "undefined" && window.clarity) {
    window.clarity("event", eventName);
  }
};

const trackClarityIdentify = (email: string | null): void => {
  if (typeof window !== "undefined" && window.clarity) {
    window.clarity("identify", email);
  }
};

const trackClarityError = (scope: string): void => {
  if (typeof window !== "undefined" && window.clarity) {
    window.clarity("event", scope);
  }
};

interface UTMParams {
  source?: string;
  medium?: string;
  campaign?: string;
  term?: string;
  content?: string;
}

const addUTMParams = (baseUrl: string, params: UTMParams): string => {
  const url = new URL(baseUrl);

  if (params.source) url.searchParams.set("utm_source", params.source);
  if (params.medium) url.searchParams.set("utm_medium", params.medium);
  if (params.campaign) url.searchParams.set("utm_campaign", params.campaign);
  if (params.term) url.searchParams.set("utm_term", params.term);
  if (params.content) url.searchParams.set("utm_content", params.content);

  return url.toString();
};

/**
 * Common properties that can be sent with any PostHog event
 * Designed to be PII-compliant by avoiding personally identifiable information
 */

type FeatureName = "smart_scribe" | "smart_match" | "new_resume" | "checkout" | "feedback_survey";
export interface PostHogCommonProperties {
  // Anonymous user identifiers (non-PII)
  user_id_hash?: string; // Hashed user ID instead of actual ID
  anonymous_id?: string; // Random identifier for the session

  // Page/navigation context
  page?: string;
  path?: string;
  url_path?: string; // Only the path, not full URL with parameters
  referrer_domain?: string; // Only domain, not full referrer URL

  // UI interaction
  component?: string;
  element_id?: string;
  element_class?: string;
  button_text?: string;

  // Error tracking
  error_type?: string;
  error_code?: string | number;
  has_error?: boolean;

  // Feature usage
  feature_name?: FeatureName;
  action_taken?: string;
  success?: boolean;

  // Custom properties (non-PII)
  [key: string]: any;
}

/**
 * Tracks a custom event with PostHog while ensuring PII compliance
 * @param eventName The name of the event to track
 * @param properties Optional properties to include with the event
 */
const trackPostHogEvent = (eventName: string, properties?: PostHogCommonProperties): void => {
  if (typeof window !== "undefined") {
    try {
      const distinctId = PostHogClient.get_distinct_id() || uuidv4();
      const userAgent = navigator.userAgent;
      let deviceType = "desktop";
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
        deviceType = "tablet";
      } else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(userAgent)) {
        deviceType = "mobile";
      }

      PostHogClient.capture(eventName, {
        distinctId,
        deviceType,
        ...properties
      });
    } catch (_) {
      // Do nothing
    }
  }
};

export {
  trackGAEvent,
  trackMetaEvent,
  trackMetaCustomEvent,
  trackClarityEvent,
  trackClarityIdentify,
  trackClarityError,
  addUTMParams,
  trackPostHogEvent,
};
