import {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Transition,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { CircleCheckIcon } from "lucide-react";
import { Fragment, ReactNode, useRef, useEffect, useState } from "react";
import { AppText } from "@/components/Text/AppText";

export interface DropdownItemProps {
    title: string;
    id: string;
    icon?: ReactNode;
}

interface DropdownProps {
    items: DropdownItemProps[];
    activeId: string;
    handleChange: (id: string) => void;
    title?: string | ReactNode;
    className?: string;
    leftIcon?: ReactNode;
    disabled?: boolean;
    borderless?: boolean;
    size?: "small" | "default";
    hideActiveId?: boolean;
}

export const Dropdown = ({
    items,
    activeId,
    handleChange,
    title,
    className,
    leftIcon,
    disabled,
    size = "default",
    borderless = false,
    hideActiveId = false,
}: DropdownProps) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [buttonWidth, setButtonWidth] = useState<number>(0);

    useEffect(() => {
        if (buttonRef.current) {
            setButtonWidth(buttonRef.current.offsetWidth);
        }
    }, []);

    return (
        <Menu
            as="div"
            className={clsx(
                "relative inline-block min-w-[144px] max-w-[328px] text-left text-neutral-900",
                className,
            )}
        >
            {({ open }) => (
                <>
                    <MenuButton
                        ref={buttonRef}
                        className={clsx(
                            "relative flex w-full items-center justify-between gap-x-1.5 rounded-lg bg-neutral-50 px-3 py-2 ring-inset ring-neutral-300 hover:bg-neutral-100 disabled:opacity-40 disabled:hover:bg-neutral-50",
                            size === "small" ? "h-7" : "h-9",
                            borderless ? "ring-0" : "ring-2",
                        )}
                        disabled={disabled}
                    >
                        <div className="flex flex-row items-center gap-2 pr-6">
                            {leftIcon}
                            <AppText variant="labelsbuttons">{title}</AppText>
                        </div>
                        <ChevronDownIcon
                            className={clsx(
                                open && "rotate-180",
                                "absolute right-3 -mr-1 h-5 w-5 ",
                            )}
                            aria-hidden="true"
                        />
                    </MenuButton>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <MenuItems
                            className="absolute z-50 my-1 max-h-56 overflow-x-hidden rounded-lg bg-neutral-50 shadow-lg ring-2 ring-inset ring-neutral-300 focus:outline-none"
                            style={{ width: `${buttonWidth}px` }}
                            modal={false}
                            anchor="bottom"
                        >
                            {!!items.length &&
                                items.map(({ id, title, icon }, index) => {
                                    if (hideActiveId && id === activeId) {
                                        return null;
                                    }

                                    return (
                                        <MenuItem key={id}>
                                            <span
                                                className={clsx(
                                                    "flex cursor-pointer items-center justify-between border-neutral-300 bg-clip-padding px-3 hover:bg-neutral-100",
                                                    index === items.length - 1
                                                        ? "rounded-b-lg border-2"
                                                        : "border-l-2 border-r-2 border-t-2",
                                                    index === 0 &&
                                                        "rounded-t-lg",
                                                    size === "small"
                                                        ? "h-7"
                                                        : "h-10",
                                                )}
                                                onClick={() => handleChange(id)}
                                            >
                                                <div className="flex items-center">
                                                    {icon && (
                                                        <span className="mr-2">
                                                            {icon}
                                                        </span>
                                                    )}
                                                    <AppText variant="labelsbuttons">
                                                        {title}
                                                    </AppText>
                                                </div>
                                                {activeId === id && (
                                                    <CircleCheckIcon />
                                                )}
                                            </span>
                                        </MenuItem>
                                    );
                                })}
                        </MenuItems>
                    </Transition>
                </>
            )}
        </Menu>
    );
};
