import { View } from "@react-pdf/renderer";
import { getSectionStyle } from "@/components/PdfDocument/utils/getStyles";
import { GLOBAL_STYLE_KEY } from "@/constants/resume";
import {
    PageMarginSize,
    ResumeStyles,
    SectionSpacingSize,
} from "@/types/resume";
import { SectionWidth } from "@/types/section";

export interface PdfSectionContainerProps {
    children: React.ReactNode;
    resumeStyles: ResumeStyles;
    isStaticHeader: boolean;
    activeWidth: SectionWidth;
}

export const PdfSectionContainer = ({
    children,
    resumeStyles,
    isStaticHeader,
    activeWidth,
}: PdfSectionContainerProps) => {
    const globalStyles = resumeStyles?.[GLOBAL_STYLE_KEY];
    const sectionStyle = getSectionStyle({
        isStaticHeader,
        backgroundColor: globalStyles?.backgroundColor ?? "inherit",
        themeColor: globalStyles?.themeColor,
        sectionSpacingSize:
            globalStyles?.sectionSpacingSize ?? SectionSpacingSize.MD,
        pageMarginSize: globalStyles?.pageMarginSize ?? PageMarginSize.MD,
    });

    return (
        <View
            style={{
                ...sectionStyle,
                width: activeWidth === "half" ? "50%" : "100%",
            }}
        >
            {children}
        </View>
    );
};
