import { ArrowRightIcon } from "lucide-react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/Button/Button";
import { CopyButton } from "@/components/Button/CopyButton";
import { Modal } from "@/components/Modal/Modal";
import { ReferralSteps } from "@/components/ReferallSteps/ReferralSteps";
import { AppText } from "@/components/Text/AppText";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useTransaction } from "@/hooks/useTransaction";
import { getIsModalOpen } from "@/store/modal/selectors";
import { closeModal } from "@/store/modal/slice";
import { getUserDetails } from "@/store/user/selectors";
import { CreditActionCode } from "@/types/creditAction";
import { ModalTypes } from "@/types/modal";
import { AppRoutes } from "@/types/routes";

const ReferralModal = () => {
    const userDetails = useAppSelector(getUserDetails);
    const isOpen = useAppSelector(getIsModalOpen(ModalTypes.REFERRAL));
    const { getCreditActionByCode } = useTransaction();
    const referralAction = useMemo(
        () => getCreditActionByCode(CreditActionCode.USER_REFERRAL),
        [getCreditActionByCode],
    );
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const referralBaseLink = `${window.location.origin}/${AppRoutes.Signup}?referral_code=${userDetails?.referralCode}`;

    const getShareLink = (source: string) => {
        return `${referralBaseLink}&utm_source=${source}`;
    };

    const goToProfile = () => {
        dispatch(closeModal(ModalTypes.REFERRAL));
        navigate(`/${AppRoutes.Profile}`);
    };

    return (
        <Modal
            open={isOpen}
            modalType={ModalTypes.REFERRAL}
            width="4xl"
            title=""
            noHeader
            floatingCloseButton
            mobileFullScreen
        >
            <div className="flex flex-col gap-8 p-6 lg:flex-row lg:gap-16">
                <div className="flex w-full flex-col justify-center gap-8 lg:gap-10">
                    <div className="mt-6 text-center sm:mt-0 lg:text-left">
                        <AppText
                            variant="headings"
                            className="mb-3 block text-primary-900"
                        >
                            Invite friends and earn rewards
                        </AppText>
                        <AppText
                            variant="subheadings"
                            className="text-lg text-neutral-900"
                        >
                            Get{" "}
                            <AppText
                                variant="subheadings"
                                applyGradient
                                className="inline"
                            >
                                {referralAction?.credits} bonus credits
                            </AppText>{" "}
                            for each friend you refer
                        </AppText>
                    </div>

                    <div className="flex flex-col space-y-6">
                        <div className="w-full">
                            <AppText
                                variant="regular"
                                className="mb-2 font-medium text-neutral-900"
                            >
                                Your unique referral link
                            </AppText>
                            <div className="flex flex-row gap-2">
                                <input
                                    type="text"
                                    value={getShareLink("direct")}
                                    readOnly
                                    className="flex-1 rounded-lg border-2 border-neutral-300 bg-transparent p-2 text-app-regular-5-mobile text-neutral-900 focus:outline-none md:text-app-regular-5-desktop"
                                />
                                <CopyButton content={getShareLink("direct")} />
                            </div>
                        </div>

                        <div className="flex w-full items-center justify-center sm:justify-start">
                            <Button
                                variant="solid"
                                color="primary"
                                rightIcon={
                                    <ArrowRightIcon className="h-4 w-4" />
                                }
                                onClick={goToProfile}
                                className="w-full sm:w-auto"
                            >
                                Track Referrals
                            </Button>
                        </div>
                    </div>
                </div>
                <ReferralSteps className="hidden lg:block" />
            </div>
            <ReferralSteps className="block border-t border-neutral-200 p-6 lg:hidden" />
        </Modal>
    );
};

export default ReferralModal;
