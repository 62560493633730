import { useEffect, useState } from "react";
import { AnimatedUploadAnalysis } from "@/components/Icons/AnimatedUploadAnalysis";
import { LoadingSteps } from "@/components/LoadingSpinner/LoadingSteps";
import { AppText } from "@/components/Text/AppText";
import { translate } from "@/helper/translate";

export const ImportValidateDetailsLoading = () => {
    const [showDelayMessage, setShowDelayMessage] = useState(false);

    const loadingSteps = [
        {
            label: `${translate("Analysing", "title")} your resume...`,
        },
        {
            label: "Extracting your experience...",
        },
        {
            label: "Identifying key skills...",
        },
        {
            label: "Processing your accomplishments...",
        },
        {
            label: "Parsing education details...",
        },
        {
            label: "Mapping your career path...",
        },
        {
            label: `${translate("Organising", "title")} your information...`,
        },
        {
            label: `Preparing for ${translate("optimisation")}...`,
        },
        {
            label: `${translate("Finalising", "title")}...`,
        },
    ];

    useEffect(() => {
        const delayTimer = setTimeout(() => {
            setShowDelayMessage(true);
        }, 15000);

        return () => clearTimeout(delayTimer);
    }, []);

    return (
        <div className="flex min-h-[200px] flex-1 flex-col items-center justify-center gap-2">
            <div className="mb-2 text-center">
                <AppText
                    variant="contextheading"
                    className="mt-2 text-neutral-800"
                >
                    Sit tight... we&apos;re reviewing your resume
                </AppText>
            </div>

            <div className="mb-4 text-center">
                <AppText
                    variant="regular"
                    className="text-neutral-700"
                >
                    Your resume is being {translate("analysed", "lower")} and
                    improved for structure, clarity, and readability.
                </AppText>
            </div>

            <LoadingSteps
                steps={loadingSteps}
                autoProgress={true}
                stepDuration={4000}
                loop={true}
                icon={<AnimatedUploadAnalysis className="h-10 w-10" />}
            />

            <div className="mt-3 text-center">
                <AppText
                    variant="footnote"
                    className="text-neutral-600"
                >
                    {showDelayMessage
                        ? "Still working on it! Great resumes take time—yours will be ready soon."
                        : "This may take a few moments."}
                </AppText>
            </div>
        </div>
    );
};
