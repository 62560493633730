import { CSSProperties } from "react";
import { v4 as uuidv4 } from "uuid";
import { decode } from "html-entities";
import { User } from "@/types";

export const formatCapitalisedTitle = (title: string, prefix?: string) => {
  const words = title.replace(/([A-Z])/g, "$1").trim();

  const capitalizedTitle = words.charAt(0).toUpperCase() + words.slice(1).toLowerCase();
  return prefix ? `${prefix} ${capitalizedTitle}` : capitalizedTitle;
};

export const generateUniqueId = () => {
  return uuidv4();
};

export const kebabToCamelCase = (str: string) => {
  return str.replace(/-./g, match => match.charAt(1).toUpperCase());
};

export const objectToCssString = (styles: CSSProperties) => {
  return Object.entries(styles)
    .map(([key, value]) => {
      // Convert camelCase to kebab-case for CSS properties
      const kebabKey = key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
      return `${kebabKey}: ${value};`;
    })
    .join(' ');
};

export const styleStringToObject = (styleString: string) => {
  return styleString.split(";")
    .filter(style => style.trim())
    .reduce((acc, style) => {
      const [property, value] = style.split(":").map(str => str.trim());
      return { ...acc, [property]: value };
    }, {} as Record<string, string>);
};

// Insert a space wherever there are empty tags to ensure a new line is shown
export const insertBlankSpaceToEmptyTags = (html: string) => {
  return html.replace(/<(\w+)(\s[^>]*)?><\/\1>/g, '<$1$2>&nbsp;</$1>');
};

export const pluralise = (count: number, singular: string, plural: string) => {
  return count === 1 || count === -1 ? singular : plural;
};

export const formatCasing = (word: string, casing: "lower" | "title" | "upper" = "lower") => {
  switch (casing) {
    case "lower":
      return word.toLowerCase();
    case "title":
      return formatCapitalisedTitle(word);
    case "upper":
      return word.toUpperCase();
    default:
      return word;
  }
};

export const decodeHtmlEntities = (str: string): string => {
  return decode(str);
};

export const formatUserName = (user?: User) => {
  if (!user) return "";
  return user.lastName ? `${user.firstName} ${user.lastName}` : user.firstName;
};

export const convertBulletPointsToHtml = (text: string): string => {
  // Split by newlines and filter out empty lines
  const lines = text.split("\n").filter(line => line.trim());

  // Convert to HTML list
  const listItems = lines.map(line => {
    // Remove any existing bullet characters (•, *, -, etc.) from the start of the line
    const cleanLine = line.replace(/^[•\-\*]\s*/, "").trim();
    return `<li>${cleanLine}</li>`;
  });

  return `<ul>${listItems.join("")}</ul>`;
};

export const formatCreditAmount = (amount: number, capitalise = true, forcePositive = false) => {
  const cost = forcePositive ? Math.abs(amount) : amount;
  const word = pluralise(cost, "credit", "credits");
  return `${cost} ${capitalise ? formatCapitalisedTitle(word) : word}`;
};

export const validateUrl = (url: string): boolean => {
  // Add protocol if missing
  const urlToCheck = url.startsWith("http://") || url.startsWith("https://")
    ? url
    : `https://${url}`;

  try {
    new URL(urlToCheck);
    // Additional check for minimum domain requirements
    const hostname = new URL(urlToCheck).hostname;
    const hasValidDomain = hostname.includes(".") &&
      hostname.split(".").every(part => part.length > 0);
    return hasValidDomain;
  } catch {
    return false;
  }
};
