import {
    Popover,
    PopoverButton,
    PopoverPanel,
    Button as HeadlessButton,
} from "@headlessui/react";
import { BubbleMenu, Editor } from "@tiptap/react";
import clsx from "clsx";
import { CircleCheck, Edit2, Link2, Mic2 } from "lucide-react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Button } from "@/components/Button/Button";
import { IconButton } from "@/components/Button/IconButton";
import { CreditSpendIndicator } from "@/components/Credits/CreditSpendIndicator";
import { getButtonCommand } from "@/components/Editor/utils/getButtonCommand";
import { ShortenIcon, SmartScribe } from "@/components/Icons/Icons";
import { AppText } from "@/components/Text/AppText";
import { Tooltip } from "@/components/Tooltip/Tooltip";
import { TooltipContent } from "@/components/Tooltip/TooltipContent";
import { TooltipTrigger } from "@/components/Tooltip/TooltipTrigger";
import { TransactionContext } from "@/context/TransactionContext";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getAiToolboxState } from "@/store/app/selectors";
import { resetAiToolbox, updateAiToolbox } from "@/store/app/slice";
import { openModal } from "@/store/modal/slice";
import { getUserCreditBalance } from "@/store/user/selectors";
import { CreditActionCode } from "@/types/creditAction";
import { TransactionContextType } from "@/types/creditTransaction";
import { ModalTypes } from "@/types/modal";
import { isMacPlatform } from "@/utils/env";
import { formatCreditAmount } from "@/utils/string";

interface EditorBubbleMenuProps {
    editor: Editor | null;
}

export const EditorBubbleMenu = ({ editor }: EditorBubbleMenuProps) => {
    const dispatch = useAppDispatch();
    const {
        validateCreditAction,
        getCanAffordCreditAction,
        getCreditActionByCode,
    } = useContext(TransactionContext) as TransactionContextType;
    const [canShowMenu, setCanShowMenu] = useState(true);
    const { isVisible: isAiToolboxVisible } = useAppSelector(getAiToolboxState);
    const userCreditBalance = useAppSelector(getUserCreditBalance);
    const aiPromptAction = useMemo(
        () => getCreditActionByCode(CreditActionCode.AI_PROMPT_REWRITE),
        [getCreditActionByCode],
    );
    const canAffordAiWritingPrompt = getCanAffordCreditAction(
        CreditActionCode.AI_PROMPT_REWRITE,
    );
    const isMac = useMemo(() => isMacPlatform(), []);
    const [isLinkActive, setIsLinkActive] = useState(false);

    useEffect(() => {
        if (isAiToolboxVisible) {
            setCanShowMenu(false);
        }
    }, [isAiToolboxVisible]);

    const openLinkModal = useCallback(() => {
        let url = "";
        if (editor) {
            url = editor.getAttributes("link").href;
        }

        dispatch(
            openModal({
                modalType: ModalTypes.ADD_LINK,
                props: { url },
            }),
        );
        setCanShowMenu(false);
    }, [dispatch, editor]);

    const onPromptClick = useCallback(
        async (promptCode: CreditActionCode) => {
            if (!editor) return;
            setCanShowMenu(false);
            // Clear selection to avoid showing the AI prompt menu again
            editor.chain().focus().unsetAllMarks().run();

            const { creditActionError, hasInsufficientFunds } =
                await validateCreditAction({
                    actionCode: promptCode,
                });

            if (hasInsufficientFunds || creditActionError) {
                return;
            }

            const selectionTo = editor.state.selection.to;
            const selectionFrom = editor.state.selection.from;
            const selectedText = editor.view.state.doc.textBetween(
                selectionFrom,
                selectionTo,
                "\n",
                " ", // Default block separator
            );

            dispatch(
                updateAiToolbox({
                    promptInput: selectedText,
                    promptActionCode: promptCode,
                    selection: {
                        from: selectionFrom,
                        to: selectionTo,
                    },
                    stage: "output", // auto submitting quick prompts
                    source: "bubble-menu",
                    isVisible: true,
                }),
            );
        },
        [editor, validateCreditAction, dispatch],
    );

    useEffect(() => {
        const handleSelectionChange = () => {
            if (isAiToolboxVisible) {
                dispatch(resetAiToolbox());
            }
            setCanShowMenu(true);
            const url = editor?.getAttributes("link").href;
            setIsLinkActive(!!url);
        };

        editor?.on("selectionUpdate", handleSelectionChange);

        return () => {
            editor?.off("selectionUpdate", handleSelectionChange);
        };
    }, [editor, dispatch, isAiToolboxVisible]);

    return (
        <div id="editor-bubble-menu">
            <BubbleMenu
                editor={editor}
                tippyOptions={{
                    placement: "bottom",
                    animation: "fade",
                    onHide: () => {
                        setCanShowMenu(true);
                    },
                    zIndex: 333,
                }}
                shouldShow={({ editor }) => {
                    const shouldShow =
                        editor.isEditable &&
                        canShowMenu &&
                        !editor.state.selection.empty &&
                        !isAiToolboxVisible;
                    return shouldShow;
                }}
                className="flex h-9 items-center gap-3 rounded-lg bg-white p-1 font-body  shadow-md"
            >
                <Popover className="relative">
                    {({ open, close }) => (
                        <>
                            <PopoverButton
                                as="div"
                                className="h-7 outline-none"
                                role="button"
                                aria-haspopup="true"
                                aria-label="AI writing prompts"
                            >
                                <Button
                                    color="gradient"
                                    leftIcon={<SmartScribe gradient={!open} />}
                                    variant={open ? "solid" : "outline"}
                                    size="small"
                                >
                                    Smart Scribe
                                </Button>
                            </PopoverButton>
                            <PopoverPanel className="absolute left-1/2 z-50 mt-3 flex w-48 -translate-x-1/2 flex-col rounded-lg bg-white shadow-md ring-2 ring-neutral-100">
                                <HeadlessButton
                                    onClick={() => {
                                        close();
                                        onPromptClick(
                                            CreditActionCode.AI_PROMPT_REWRITE,
                                        );
                                    }}
                                    disabled={!canAffordAiWritingPrompt}
                                    className="flex justify-center gap-2 border-b border-neutral-100 px-3 py-2 text-left text-primary-700 hover:bg-neutral-100/50 disabled:pointer-events-none disabled:opacity-25"
                                >
                                    <Edit2 className="h-4 w-4" />
                                    <AppText variant="labelsbuttons">
                                        Rewrite
                                    </AppText>
                                </HeadlessButton>
                                <HeadlessButton
                                    onClick={() => {
                                        close();
                                        onPromptClick(
                                            CreditActionCode.AI_PROMPT_SHORTEN,
                                        );
                                    }}
                                    disabled={!canAffordAiWritingPrompt}
                                    className="flex justify-center gap-2 border-b border-neutral-100 px-3 py-2 text-primary-700 hover:bg-neutral-100/50 disabled:pointer-events-none disabled:opacity-25"
                                >
                                    <ShortenIcon
                                        className="h-4 w-4"
                                        stroke="#0901FF"
                                    />
                                    <AppText variant="labelsbuttons">
                                        Shorten
                                    </AppText>
                                </HeadlessButton>

                                <HeadlessButton
                                    onClick={() => {
                                        close();
                                        onPromptClick(
                                            CreditActionCode.AI_PROMPT_GRAMMAR,
                                        );
                                    }}
                                    disabled={!canAffordAiWritingPrompt}
                                    className="flex justify-center gap-2 border-b border-neutral-100 px-3 py-2 text-left text-primary-700 hover:bg-neutral-100/50 disabled:pointer-events-none disabled:opacity-25"
                                >
                                    <CircleCheck className="h-4 w-4" />
                                    <AppText variant="labelsbuttons">
                                        Fix grammar
                                    </AppText>
                                </HeadlessButton>
                                <HeadlessButton
                                    onClick={() => {
                                        close();
                                        onPromptClick(
                                            CreditActionCode.AI_PROMPT_FORMAL,
                                        );
                                    }}
                                    className="flex justify-center gap-2 border-b border-neutral-100 px-3 py-2 text-left text-primary-700 hover:bg-neutral-100/50 disabled:pointer-events-none disabled:opacity-25"
                                    disabled={!canAffordAiWritingPrompt}
                                >
                                    <Mic2 className="h-4 w-4" />
                                    <AppText variant="labelsbuttons">
                                        More formal
                                    </AppText>
                                </HeadlessButton>
                                <HeadlessButton
                                    onClick={() => {
                                        close();
                                    }}
                                    className="flex items-center justify-center gap-3 px-3 py-2 hover:bg-neutral-100/50 disabled:pointer-events-none disabled:opacity-25"
                                >
                                    <div className="flex items-center gap-3">
                                        <CreditSpendIndicator
                                            creditAction={aiPromptAction}
                                            className="flex-col"
                                            buttonSize="small"
                                            buttonClassName="w-full"
                                        />
                                    </div>
                                </HeadlessButton>
                            </PopoverPanel>
                        </>
                    )}
                </Popover>
                <Tooltip>
                    <TooltipTrigger>
                        <IconButton
                            size="28px"
                            color="white"
                            onClick={openLinkModal}
                            className={clsx("", {
                                "!bg-neutral-200": isLinkActive,
                            })}
                        >
                            <Link2 className="h-4 w-4" />
                        </IconButton>
                    </TooltipTrigger>
                    <TooltipContent>
                        <AppText variant="footnote">
                            Insert link ({getButtonCommand("link", isMac)})
                        </AppText>
                    </TooltipContent>
                </Tooltip>
            </BubbleMenu>
        </div>
    );
};
