import clsx from "clsx";
import { useCallback, useMemo } from "react";
import { CopyButton } from "@/components/Button/CopyButton";
import { ReferralSteps } from "@/components/ReferallSteps/ReferralSteps";
import { AppText } from "@/components/Text/AppText";
import { useAppSelector } from "@/hooks/types";
import { useTransaction } from "@/hooks/useTransaction";
import { ReferralLineItem } from "@/pages/Profile/ReferralLineItem";
import { getUserDetails, getUserReferrals } from "@/store/user/selectors";
import { CreditActionCode } from "@/types/creditAction";
import { AppRoutes } from "@/types/routes";
import { CLARITY_EVENT_NAMES } from "@/types/tracking";
import { REFERRAL_LIMIT } from "@/types/user";
import { trackClarityEvent, trackPostHogEvent } from "@/utils/tracking";

export const ProfileReferral = () => {
    const { getAndFormatCreditActionCostByCode } = useTransaction();
    const referralActionCost = useMemo(
        () =>
            getAndFormatCreditActionCostByCode(CreditActionCode.USER_REFERRAL),
        [getAndFormatCreditActionCostByCode],
    );
    const userDetails = useAppSelector(getUserDetails);
    const userReferrals = useAppSelector(getUserReferrals);

    const referralBaseLink = `${window.location.origin}/${AppRoutes.Signup}?referral_code=${userDetails?.referralCode}`;
    const getShareLink = (source: string) => {
        return `${referralBaseLink}&utm_source=${source}`;
    };
    const trackCopy = useCallback(() => {
        trackClarityEvent(CLARITY_EVENT_NAMES.REFERRAL_LINK_COPIED);
        trackPostHogEvent(CLARITY_EVENT_NAMES.REFERRAL_LINK_COPIED);
    }, []);

    return (
        <div className="flex flex-col">
            <AppText
                variant="headings"
                className="mb-4 hidden text-neutral-900 md:block"
            >
                Invite Friends
            </AppText>
            <AppText
                variant="regular"
                className="mb-8 inline-block text-neutral-900"
            >
                Have friends who want to build their own resumes? Refer them to
                1Template, and receive{" "}
                <AppText
                    variant="regular"
                    applyGradient
                    className="inline whitespace-nowrap !font-semibold"
                >
                    {referralActionCost}
                </AppText>{" "}
                for each friend that signs up.
            </AppText>
            <div className="mb-8 md:mb-0">
                <AppText
                    variant="subheadings"
                    className="mb-4 text-neutral-900"
                >
                    Earn when you invite friends
                </AppText>
                <div className="flex flex-col gap-4 rounded-lg p-5 ring-2 ring-neutral-300">
                    <div className="flex flex-col gap-4">
                        <div className="w-full space-y-2">
                            <ReferralSteps showNote={false} />
                            <AppText
                                variant="contextheading"
                                className="mb-2 mt-4 block font-medium text-neutral-900"
                            >
                                Your unique referral link
                            </AppText>
                            <div className="flex flex-row flex-wrap gap-2">
                                <input
                                    type="text"
                                    value={getShareLink("direct")}
                                    readOnly
                                    className="h-9 flex-1 rounded-lg border-2 border-neutral-300 bg-transparent p-2 text-app-regular-5-mobile text-neutral-900 focus:outline-none md:text-app-regular-5-desktop"
                                />
                                <CopyButton
                                    variant="full"
                                    content={getShareLink("direct")}
                                    onCopy={trackCopy}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="my-8 flex flex-col gap-2 md:mb-0">
                <div className="mb-2 flex items-center justify-between">
                    <AppText
                        variant="subheadings"
                        className="pr-3 text-neutral-900"
                    >
                        Your Referrals
                    </AppText>
                    <div className="flex items-center gap-2">
                        <AppText
                            variant="labelsbuttons"
                            className="text-neutral-900"
                        >
                            {userReferrals?.length ?? 0}/{REFERRAL_LIMIT}
                        </AppText>
                        <div className="h-2 w-[130px] rounded-full bg-neutral-200">
                            <div
                                className={clsx(
                                    "h-full rounded-l-full bg-gradient-primary transition-all duration-300",
                                    {
                                        "rounded-r-full":
                                            (userReferrals?.length ?? 0) ===
                                            REFERRAL_LIMIT,
                                    },
                                )}
                                style={{
                                    width: `${
                                        Math.min(
                                            (userReferrals?.length ?? 0 ?? 0) /
                                                REFERRAL_LIMIT,
                                            1,
                                        ) * 100
                                    }%`,
                                }}
                            />
                        </div>
                    </div>
                </div>
                {userReferrals && userReferrals.length > 0 ? (
                    userReferrals.map(referral => (
                        <ReferralLineItem
                            key={referral.referredUserId}
                            referral={referral}
                        />
                    ))
                ) : (
                    <AppText
                        variant="regular"
                        className="p-2 text-center text-neutral-600"
                    >
                        No referrals yet
                    </AppText>
                )}
            </div>
        </div>
    );
};
