import { applyResumeLevelDateFormat } from "@/components/DatePeriodPicker/utils";
import { getTextColourStyle } from "@/components/PdfDocument/utils/getStyles";
import { DETAILS_POSITION_KEY, GLOBAL_STYLE_KEY, PROFILE_ALIGNMENT_KEY } from "@/constants/resume";
import { ComponentPropsObject, DateFormat, ResumeStyles, StyleConfig } from "@/types/resume";
import { SectionFieldsConfig } from "@/types/section";
import { decodeHtmlEntities, insertBlankSpaceToEmptyTags } from "@/utils/string";

const isHTMLString = (str: string) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = str;
    return tempDiv.childElementCount > 0;
};

export const isEmptyValueOrEmptyHtmlTag = (value: string) => {
    if (!value) return true;
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = value.trim();
    return tempDiv.innerHTML === '' || tempDiv.innerText.trim() === '';
};

// Function to replace the placeholders in style attributes
const replaceHtmlPlaceholders = (html: string, replacements: ComponentPropsObject) => {
    return html.replace(/style="([^"]*)"/g, (match, styleContent) => {
        let updatedStyle = styleContent;
        for (const key in replacements) {

            // If editing the details position, and the user has set the profile alignment to center,
            // then we need to set the justify content to center for the details position
            if (key === DETAILS_POSITION_KEY && replacements[PROFILE_ALIGNMENT_KEY] === "center" && updatedStyle.includes(DETAILS_POSITION_KEY)) {
                updatedStyle = `${updatedStyle} justify-content: center;`;
            }

            if (Object.prototype.hasOwnProperty.call(replacements, key)) {
                const regex = new RegExp(key, 'g');
                updatedStyle = updatedStyle.replace(regex, replacements[key]);
            }
        }
        return `style="${updatedStyle}"`;
    });
};

/**
 * If a field has an icon we need to handle the layout differently.
 * We need to insert an icon element before the content and wrap the content in a div with a class of "next-to-icon".
 * This is done to ensure that the icon is displayed next to the content and that the content is displayed correctly. 
 * This is done by moving all existing children to the wrapper div and then adding the icon and wrapper to the main element.
 * This will look like this:
 * <div>
 *   <i></i>
 *   <div class="next-to-icon">
 *     <p>Content</p>
 *     <p>Content</p>
 *   </div>
 * </div>
 * Visually this could look like this:
 * [Icon] Content goes here
 *        Content
 *        More Content
 */
const insertIcon = (stylesConfig: StyleConfig, fieldsConfig: SectionFieldsConfig, element: HTMLDivElement, key: string) => {
    const icon = fieldsConfig[key]?.icon;
    if (icon && stylesConfig.showIcons) {
        // Create icon element
        const iconElement = document.createElement("i");
        iconElement.id = icon;

        // Create wrapper div for content
        const contentWrapper = document.createElement("div");
        contentWrapper.className = "next-to-icon";

        // Move all existing children to the wrapper
        while (element.firstChild) {
            contentWrapper.appendChild(element.firstChild);
        }

        // Add icon and wrapper to main element
        element.appendChild(iconElement);
        element.appendChild(contentWrapper);
        element.dataset.icon = icon;
    }

    return element;
};

const applyTextColourStyle = (value: string, textColour: string) => {
    // Value could be HTML or not. If it it HTML, apply style="color: ${textColourStyle}" to the element
    if (isHTMLString(value)) {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = value;
        const firstElement = tempDiv.firstElementChild;
        if (firstElement) {
            firstElement.setAttribute("style", `color: ${textColour}`);
            return tempDiv.innerHTML;
        }
    }
    return value;
};

export const getHtmlStringWithValues = (
    origHtml: string,
    values: ComponentPropsObject,
    layoutFields: ComponentPropsObject,
    hiddenFields: string[] = [],
    fieldsConfig: SectionFieldsConfig,
    resumeStyles: ResumeStyles,
): string => {
    const globalStyles = resumeStyles?.[GLOBAL_STYLE_KEY];
    // Create a temporary DOM element to manipulate the HTML string
    const tempDiv = document.createElement("div");
    // Replace all layout keys with their values in html
    const updatedHtml = replaceHtmlPlaceholders(origHtml, layoutFields);
    tempDiv.innerHTML = updatedHtml;

    const valueKeys = Object.keys(values);
    // Get all IDs from the HTML. IDs are on the elements.
    const idsInHtml = Array.from(tempDiv.querySelectorAll("[id]")).map((element) => element.id);
    const idsWithoutValues = idsInHtml.filter((id) => ![...valueKeys, ...Object.keys(layoutFields)].includes(id));

    // Remove the ids without values from the HTML. This prevents {this.field} from being displayed if the field is not present in the values object.
    idsWithoutValues.forEach((id) => {
        const element = tempDiv.querySelector(`#${id}`);
        if (element) {
            element.remove();
        }
    });

    // Loop through the keys in the values object
    valueKeys.forEach((key) => {
        const textColourStyle = getTextColourStyle({
            accentColor: globalStyles?.themeColor ?? "",
            isStaticHeader: false,
            fieldKey: key,
        }).color;
        const element = tempDiv.querySelector(`#${key}`);
        if (element) {
            if (hiddenFields.includes(key)) {
                element.remove();
            } else if (values[key]) {

                // Decode the value from HTML entities
                const decodedValue = applyTextColourStyle(decodeHtmlEntities(values[key]), textColourStyle);
                if (isEmptyValueOrEmptyHtmlTag(decodedValue)) {
                    // Insert the placeholder value or remove it if no placeholder is defined
                    const placeholderValue = fieldsConfig[key]?.placeholder;
                    if (placeholderValue) {
                        element.innerHTML = placeholderValue;
                    } else {
                        element.remove();
                    }
                } else if (isHTMLString(decodedValue)) {
                    // For HTML content, replace the element with a div containing the HTML
                    const newElement = document.createElement("div");
                    newElement.id = key;
                    const elementHtml = decodedValue;
                    newElement.innerHTML = insertBlankSpaceToEmptyTags(elementHtml);
                    insertIcon(globalStyles, fieldsConfig, newElement, key);
                    element.parentNode?.replaceChild(newElement, element);
                } else if (element.tagName.toLowerCase() === "img") {
                    // For img tags, update the src attribute
                    (element as HTMLImageElement).src = decodedValue;
                } else {
                    // For text content, update the innerHTML to have a div wrapping the appropriate element tag so we can insert icons
                    const valueType = fieldsConfig[key]?.type;
                    let formattedValue = decodedValue;
                    if (valueType === "date") {
                        // Apply the resume level date format to the value
                        // Note: Date type values will always be a "text" type value and not contain HTML
                        formattedValue = applyResumeLevelDateFormat(values[key], globalStyles?.dateFormat || DateFormat.LRG);
                    }

                    const newElement = document.createElement("div");
                    newElement.id = key;
                    newElement.innerHTML = `<${element.tagName} style="color: ${textColourStyle}">${formattedValue}</${element.tagName}>`;
                    insertIcon(globalStyles, fieldsConfig, newElement, key);
                    element.parentNode?.replaceChild(newElement, element);
                }
            } else {
                // Value is not hidden but is empty. Display the placeholder value or remove it if no placeholder is defined
                const placeholderValue = fieldsConfig[key]?.placeholder;
                if (placeholderValue) {
                    element.innerHTML = placeholderValue;

                } else {
                    element.remove();
                }
            }
        }
    });

    /**
     * Convert the HTML into a flat string without new lines and spaces between div elements.
     * This is done to ensure that the PDF is generated correctly as the PDF expects all elements
     * to be inside a <Text> element. We can only remove spaces between divs as all text elements
     * must preserve their whitespace.
     */
    const result = tempDiv.innerHTML
        .replace(/\n\s*/g, "")
        .replace(/(<\/div>)\s+(<div)/g, "$1$2")
        .trim();

    return result;
};