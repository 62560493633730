import { Form, Formik } from "formik";
import { useMemo, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { NavLink, useNavigate, createSearchParams } from "react-router-dom";
import { object } from "yup";
import userApi from "@/api/user";
import { Alert } from "@/components/Alert/Alert";
import { Button } from "@/components/Button/Button";
import { Logo } from "@/components/Icons/Icons";
import { PageHelmet } from "@/components/PageHelmet/PageHelmet";
import { SSOLogin } from "@/components/SSO/SSOLogin";
import { AppText } from "@/components/Text/AppText";
import { Input } from "@/components/form/Input";
import { emailValidation, passwordValidation } from "@/constants/schema";
import { sendVerifyEmail } from "@/email/send";
import { getErrorMessage } from "@/helper/getErrorMessage";
import { useAppDispatch } from "@/hooks/types";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import {
    setAccessToken,
    setRefreshToken,
    setUserDetails,
} from "@/store/user/slice";
import { setWizardUserPreferences } from "@/store/wizard/slice";
import { User } from "@/types";
import { AppRoutes, SiteRoutes } from "@/types/routes";
import { Tokens } from "@/types/token";
import { CLARITY_EVENT_NAMES } from "@/types/tracking";
import { UserPreferences } from "@/types/user";
import {
    trackClarityEvent,
    trackClarityIdentify,
    trackPostHogEvent,
} from "@/utils/tracking";

const loginSchema = object({
    email: emailValidation,
    password: passwordValidation,
});

interface LoginFormFields {
    email: string;
    password: string;
}

export const LoginPage = () => {
    const initialValues: LoginFormFields = useMemo(
        () => ({
            email: "",
            password: "",
        }),
        [],
    );
    const [, setLocalAccessToken] = useLocalStorage("accessToken", null);
    const [, setLocalRefreshToken] = useLocalStorage("refreshToken", null);
    const [loading, setLoading] = useState(false);

    const dispatch = useAppDispatch();
    const [error, setError] = useState<string | null>("");

    const navigate = useNavigate();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const onLoginSuccess = async (
        user: User,
        tokens: Tokens,
        verifyCode?: string,
    ) => {
        dispatch(setAccessToken(tokens.access));
        dispatch(setRefreshToken(tokens.refresh));
        setLocalAccessToken(tokens.access);
        setLocalRefreshToken(tokens.refresh);
        dispatch(setUserDetails(user));
        dispatch(setWizardUserPreferences(user.preferences || null));
        trackClarityIdentify(user.email);

        trackClarityEvent(CLARITY_EVENT_NAMES.AUTH_LOGIN_SUCCESS);
        trackPostHogEvent(CLARITY_EVENT_NAMES.AUTH_LOGIN_SUCCESS);

        if (user.isEmailVerified) {
            const preferences: UserPreferences | null =
                user.preferences ?? null;
            if (!preferences) {
                navigate(`/${AppRoutes.Preferences}`, { replace: true });
                return;
            }

            const hasAPreferenceSet =
                (preferences.jobTypes ?? []).length > 0 ||
                (preferences.industries ?? []).length > 0 ||
                (preferences.jobRoles ?? []).length > 0 ||
                !!preferences.experienceLevel;

            if (!hasAPreferenceSet) {
                navigate(`/${AppRoutes.Preferences}`, { replace: true });
            } else {
                navigate(AppRoutes.Dashboard, { replace: true });
            }
        } else {
            if (verifyCode) {
                sendVerifyEmail(user.email, verifyCode);
            }
            navigate({
                pathname: `/${AppRoutes.Verify}`,
                search: createSearchParams({ email: user.email }).toString(),
            });
        }
    };

    const handleLogin = async (values: LoginFormFields) => {
        setLoading(true);
        if (!executeRecaptcha) {
            setError(
                "ReCAPTCHA not available. Please refresh the page and try again.",
            );
            setLoading(false);
            return;
        }

        try {
            const recaptchaToken = await executeRecaptcha("login");

            const response = await userApi.loginApi({
                email: values.email,
                password: values.password,
                recaptchaToken,
            });
            const { user, tokens, verifyCode } = response;
            onLoginSuccess(user, tokens, verifyCode);
        } catch (e) {
            setError(getErrorMessage(e, "Login"));
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-bl from-primary-700 to-primary-900">
            <div className="relative z-10 mx-auto flex min-h-screen max-w-md items-center justify-center px-6">
                <div className="w-full rounded-xl bg-white p-8 shadow-xl">
                    {/* Logo */}
                    <div className="mb-8 flex justify-center">
                        <Logo className="h-10 w-auto" />
                    </div>

                    <PageHelmet
                        title="Sign in"
                        description="Sign in to 1Template to create and manage your resumes"
                        robotsOverride="noindex, nofollow"
                    />

                    <div className="text-center">
                        <AppText
                            variant="headings"
                            className="text-neutral-900"
                        >
                            WELCOME BACK
                        </AppText>
                    </div>

                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleLogin}
                        validationSchema={loginSchema}
                        validateOnBlur={false}
                        validateOnChange={false}
                        validateOnMount={false}
                    >
                        {({ values, errors, handleChange }) => (
                            <Form className="mt-8">
                                {error && (
                                    <Alert
                                        type="error"
                                        message={error}
                                        className="mb-4"
                                    />
                                )}

                                <div className="mb-3">
                                    <SSOLogin
                                        setError={setError}
                                        onSuccess={onLoginSuccess}
                                    />
                                    <div className="my-4 flex items-center">
                                        <div className="h-px flex-1 bg-neutral-200"></div>
                                        <span className="mx-4 text-sm text-neutral-500">
                                            or continue with email
                                        </span>
                                        <div className="h-px flex-1 bg-neutral-200"></div>
                                    </div>
                                </div>

                                <div className="space-y-3">
                                    <Input
                                        type="text"
                                        autoComplete="email"
                                        label="Email"
                                        name="email"
                                        value={values.email}
                                        formikChange={handleChange}
                                        error={errors.email}
                                    />
                                    <Input
                                        type="password"
                                        autoComplete="password"
                                        label="Password"
                                        name="password"
                                        formikChange={handleChange}
                                        value={values.password}
                                        error={errors.password}
                                    />
                                    <div className="flex justify-end">
                                        <NavLink
                                            to={`/${AppRoutes.ForgotPassword}`}
                                            className="text-primary-700 hover:text-primary-600"
                                        >
                                            <AppText variant="labelsbuttons">
                                                Forgot password?
                                            </AppText>
                                        </NavLink>
                                    </div>
                                </div>

                                <Button
                                    className="mt-4 w-full"
                                    type="submit"
                                    disabled={loading}
                                    loading={loading}
                                >
                                    Sign in
                                </Button>

                                <div className="mt-3 text-center">
                                    <Button
                                        to={`/${AppRoutes.Signup}`}
                                        color={"primary"}
                                        variant={"text"}
                                        className="text-sm"
                                    >
                                        New to 1Template? Create account
                                    </Button>
                                </div>
                                <div className="mt-4 flex justify-center">
                                    <AppText
                                        variant="footnote"
                                        className="w-[250px] text-center text-neutral-700"
                                    >
                                        By continuing, you agree to
                                        1Template&apos;s{" "}
                                        <a
                                            href={SiteRoutes.Terms}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="text-primary-700"
                                        >
                                            Terms of Service
                                        </a>
                                        . Read our{" "}
                                        <a
                                            href={SiteRoutes.PrivacyPolicy}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="text-primary-700"
                                        >
                                            Privacy Policy
                                        </a>
                                        .
                                    </AppText>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};
