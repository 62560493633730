import { CalendarPlus, PlusIcon, ThumbsUp } from "lucide-react";
import { useCallback, useContext, useEffect, useState } from "react";
import { Button } from "@/components/Button/Button";
import { Dropdown } from "@/components/Dropdown/Dropdown";
import { FeedbackSurveyButton } from "@/components/Feedback/FeedbackSurveyButton";
import { GradientFilesIcon } from "@/components/Icons/Icons";
import { PageFooter } from "@/components/PageFooter/PageFooter";
import { PageHelmet } from "@/components/PageHelmet/PageHelmet";
import { AppText } from "@/components/Text/AppText";
import { EditorContext } from "@/context/EditorContext";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { sortResumes } from "@/pages/Dashboard/utils";
import { ResumeExample } from "@/pages/Examples/ResumeExample";
import { resetResumeBuilderState } from "@/routes/utils/resetResumeBuilderState";
import { openModal } from "@/store/modal/slice";
import {
    getResumeExamples,
    getResumesWithDates,
} from "@/store/resume/selectors";
import {
    getUserDocumentLimit,
    getUserReachedResumeLimit,
} from "@/store/user/selectors";
import { Resume } from "@/types";
import { EditorContextType } from "@/types/editor";
import { ModalTypes } from "@/types/modal";
import { CLARITY_EVENT_NAMES } from "@/types/tracking";
import { trackClarityEvent, trackPostHogEvent } from "@/utils/tracking";

const sortOptions = [
    { id: "5", title: "Newest", icon: <CalendarPlus size={20} /> },
    { id: "3", title: "Most Popular", icon: <ThumbsUp size={20} /> },
];

export const ExamplesPage = () => {
    const examples = useAppSelector(getResumeExamples);
    const [sortBy, setSortBy] = useState(sortOptions[1].id);
    const { destroyEditors } = useContext(EditorContext) as EditorContextType;
    const [sortedExamples, setSortedExamples] = useState<Resume[]>(
        sortResumes([...examples] || [], sortBy),
    );
    const resumes = useAppSelector(getResumesWithDates);
    const reachedResumeLimit = useAppSelector(getUserReachedResumeLimit);
    const dispatch = useAppDispatch();
    const documentLimit = useAppSelector(getUserDocumentLimit);

    useEffect(() => {
        setSortedExamples(sortResumes([...examples] || [], sortBy));
    }, [examples, sortBy]);

    const handleCreateNewResume = () => {
        trackClarityEvent(CLARITY_EVENT_NAMES.NEW_RESUME_BUTTON_CLICKED);
        trackPostHogEvent(CLARITY_EVENT_NAMES.NEW_RESUME_BUTTON_CLICKED, {
            feature_name: "new_resume",
        });
        if (reachedResumeLimit) {
            dispatch(openModal({ modalType: ModalTypes.DOCUMENT_LIMIT }));
        } else {
            dispatch(openModal({ modalType: ModalTypes.NEW_RESUME }));
        }
    };

    const openDocumentLimitModal = useCallback(() => {
        dispatch(openModal({ modalType: ModalTypes.DOCUMENT_LIMIT }));
    }, [dispatch]);

    useEffect(() => {
        // Ensure we kill all editors and reset the builder when navigating between resumes
        destroyEditors();
        resetResumeBuilderState(dispatch);
    }, [destroyEditors, dispatch]);

    return (
        <>
            <PageHelmet title="Resume Examples" />
            <main className="mt-6 min-h-[calc(100vh-300px)] px-8 pb-16 lg:mt-16">
                <div className="mx-0 mb-6 flex w-full max-w-6xl flex-col justify-between px-0 lg:mx-auto lg:mb-12 lg:flex-row lg:px-20 xl:mb-16 xl:w-2/3 xl:px-0">
                    <AppText
                        variant="headings"
                        className="hidden text-neutral-900 md:block"
                    >
                        Resume Examples
                    </AppText>
                    <div className="mt-0 flex flex-col-reverse items-center gap-3 md:mt-6 lg:my-0 lg:flex-row">
                        <Dropdown
                            title={
                                sortOptions.find(o => o.id === sortBy)?.title
                            }
                            items={sortOptions}
                            handleChange={id => setSortBy(id)}
                            activeId={sortBy}
                            className="!w-[240px]"
                            leftIcon={
                                sortOptions.find(o => o.id === sortBy)?.icon
                            }
                        />
                        <div className="flex flex-row-reverse items-center gap-2 lg:flex-row">
                            <Button
                                variant="outline"
                                color="gradient"
                                onClick={openDocumentLimitModal}
                                className="!min-w-0 !px-3"
                                leftIcon={
                                    <GradientFilesIcon className="h-4 w-4" />
                                }
                            >
                                {resumes.length}/{documentLimit}
                            </Button>
                            <Button
                                leftIcon={<PlusIcon size={16} />}
                                onClick={handleCreateNewResume}
                            >
                                New Resume
                            </Button>
                        </div>
                    </div>
                </div>
                <ul
                    role="list"
                    className="dashboard-resume-grid mx-auto grid max-w-6xl justify-center gap-x-4 gap-y-12 sm:gap-x-6 xl:gap-x-8"
                >
                    {sortedExamples.map(resume => (
                        <ResumeExample
                            key={resume.id}
                            resume={resume}
                        />
                    ))}
                </ul>
                <FeedbackSurveyButton />
            </main>
            <PageFooter />
        </>
    );
};
