import clsx from "clsx";
import { ArrowRight, CircleCheckBig, LucideIcon } from "lucide-react";
import { AppText } from "@/components/Text/AppText";

interface ResumeOptionFeature {
    text: React.ReactNode;
}

interface ResumeOptionCardProps {
    icon: LucideIcon;
    title: string;
    features: ResumeOptionFeature[];
    ctaText: string;
    onClick: () => void;
    iconBgColor?: string;
    gradientFrom?: string;
    className?: string;
}

export const ResumeOptionCard = ({
    icon: Icon,
    title,
    features,
    ctaText,
    onClick,
    iconBgColor = "bg-primary-600",
    gradientFrom = "from-primary-50/30",
    className,
}: ResumeOptionCardProps) => {
    return (
        <button
            onClick={onClick}
            className={clsx(
                "group relative flex h-full overflow-hidden rounded-xl border-2 border-neutral-200 bg-white transition-all hover:border-primary-500 hover:shadow-lg",
                className,
            )}
        >
            <div className="relative z-10 flex w-full flex-col p-4 sm:p-4">
                <div className="mb-4">
                    <div className="flex items-center gap-3">
                        <div
                            className={`flex-shrink-0 rounded-full ${iconBgColor} hidden p-2.5 xs:flex sm:p-3`}
                        >
                            <Icon className="h-5 w-5 text-white sm:h-6 sm:w-6" />
                        </div>
                        <AppText
                            variant="subheadings"
                            className="text-neutral-900"
                        >
                            {title}
                        </AppText>
                    </div>
                </div>

                <div className="mt-2 flex-1 space-y-2.5 sm:space-y-3">
                    {features.map((feature, index) => {
                        return (
                            <div
                                key={`resume-option-card-feature-${index}`}
                                className="flex items-start gap-2 sm:items-center"
                            >
                                <div className="flex-shrink-0">
                                    <CircleCheckBig className="mt-1 h-3 w-3 text-primary-600 sm:mt-0 sm:h-4 sm:w-4" />
                                </div>
                                <AppText
                                    variant="regular"
                                    className="flex-grow gap-1 text-left text-neutral-600"
                                >
                                    {feature.text}
                                </AppText>
                            </div>
                        );
                    })}
                </div>

                <div className="mt-6 flex items-center justify-end">
                    <div className="flex items-center gap-1.5 text-primary-700 transition-transform group-hover:translate-x-1 sm:gap-2">
                        <AppText variant="labelsbuttons">{ctaText}</AppText>
                        <ArrowRight className="h-4 w-4 flex-shrink-0" />
                    </div>
                </div>
            </div>
            <div
                className={`absolute inset-0 bg-gradient-to-br ${gradientFrom} to-transparent opacity-0 transition-opacity group-hover:opacity-100`}
            />
        </button>
    );
};
