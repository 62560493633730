import { Download, ExternalLink, Files, Import } from "lucide-react";
import { useMemo } from "react";
import { CreditsIcon, SmartScribe } from "@/components/Icons/Icons";
import { Modal } from "@/components/Modal/Modal";
import { AppText } from "@/components/Text/AppText";
import { useAppSelector } from "@/hooks/types";
import { useTransaction } from "@/hooks/useTransaction";
import { getIsModalOpen } from "@/store/modal/selectors";
import { CreditActionCode } from "@/types/creditAction";
import { ModalTypes } from "@/types/modal";
import { SiteRoutes } from "@/types/routes";
import { formatCreditAmount } from "@/utils/string";
import { addUTMParams } from "@/utils/tracking";

const CreditsInfoModal = () => {
    const isOpen = useAppSelector(getIsModalOpen(ModalTypes.CREDITS_INFO));
    const { getCreditActionByCode } = useTransaction();
    const additionalResumeAction = useMemo(
        () => getCreditActionByCode(CreditActionCode.INCREASE_DOCUMENT_LIMIT),
        [getCreditActionByCode],
    );
    const rewriteAction = useMemo(
        () => getCreditActionByCode(CreditActionCode.AI_PROMPT_REWRITE),
        [getCreditActionByCode],
    );
    const downloadAction = useMemo(
        () => getCreditActionByCode(CreditActionCode.DOWNLOAD_DOCUMENT),
        [getCreditActionByCode],
    );
    const importAction = useMemo(
        () => getCreditActionByCode(CreditActionCode.IMPORT_RESUME),
        [getCreditActionByCode],
    );

    return (
        <Modal
            open={isOpen}
            modalType={ModalTypes.CREDITS_INFO}
            noHeader
            width="4xl"
            gradientBackground
            title=""
            floatingCloseButton
        >
            <div className="flex flex-col items-center">
                <div className="mb-4 text-primary-700">
                    <CreditsIcon className="h-16 w-16" />
                </div>

                <AppText
                    variant="headings"
                    className="mb-4 w-full text-center text-2xl font-bold text-primary-900 lg:w-3/4"
                >
                    WHAT ARE CREDITS?
                </AppText>

                <AppText
                    variant="labelsbuttons"
                    className="mb-6 w-full text-center text-neutral-700 lg:w-3/4"
                >
                    Credits give you flexible access to premium features in
                    1Template—no subscription required. Pay only for what you
                    need, when you need it.
                </AppText>

                <a
                    href={addUTMParams(SiteRoutes.Pricing, {
                        source: "app",
                        medium: "internal_link",
                        campaign: "credits_info_modal",
                        content: "learn_more_about_credits",
                    })}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary mb-8 flex justify-center gap-2 text-primary-700 hover:underline"
                >
                    <ExternalLink
                        size={16}
                        strokeWidth={3}
                    />
                    <AppText
                        variant="labelsbuttons"
                        className="underline"
                    >
                        Learn more about Credits
                    </AppText>
                </a>

                <div className="w-full px-4 lg:px-10">
                    <div className="grid grid-cols-1 gap-6 md:grid-cols-2 md:gap-8">
                        {importAction && (
                            <FeatureCard
                                icon={<Import size={24} />}
                                title="IMPORT RESUME"
                                description="Import your resume and let AI refine it with Smart Scribe for impactful content and Smart Match for tailored precision."
                                creditLine="Per Import"
                                creditCost={importAction?.credits ?? 0}
                            />
                        )}
                        {rewriteAction && (
                            <FeatureCard
                                icon={
                                    <SmartScribe
                                        gradient
                                        className="h-6 w-6"
                                    />
                                }
                                title="SMART SCRIBE"
                                description="AI-powered tools to create, rewrite, shorten, and refine resumes with standout summaries and bullet points."
                                creditLine="Per Use"
                                creditCost={rewriteAction?.credits ?? 0}
                            />
                        )}
                        {additionalResumeAction && (
                            <FeatureCard
                                icon={<Files size={24} />}
                                title="MULTIPLE RESUMES"
                                description="Create more specialised resumes for different roles and industries."
                                creditLine="Per Resume"
                                creditCost={
                                    additionalResumeAction?.credits ?? 0
                                }
                            />
                        )}
                        {downloadAction && (
                            <FeatureCard
                                icon={<Download size={24} />}
                                title="PDF DOWNLOAD"
                                description="Download your resume as a PDF or have it sent directly to your inbox as an email attachment."
                                creditLine="Per Download"
                                creditCost={downloadAction?.credits ?? 0}
                            />
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

interface FeatureCardProps {
    icon: React.ReactNode;
    title: string;
    description: string;
    creditLine: string;
    creditCost: number;
    className?: string;
}

const FeatureCard = ({
    icon,
    title,
    description,
    creditLine,
    creditCost,
    className,
}: FeatureCardProps) => {
    return (
        <div
            className={`group relative flex flex-col rounded-xl border border-neutral-100 bg-white p-4 shadow-md transition-all duration-200 ${className || ""}`}
        >
            <div className="mb-2 flex flex-row items-center justify-between">
                <AppText
                    variant="contextheading"
                    className="text-lg font-semibold text-neutral-900"
                >
                    {title}
                </AppText>
                <div className="flex items-center justify-center rounded-lg bg-neutral-50 text-primary-700">
                    {icon}
                </div>
            </div>
            <AppText
                variant="regular"
                className="mb-1 text-neutral-700"
            >
                {description}
            </AppText>
            <div className="mt-auto flex flex-row items-center gap-1 rounded-lg bg-neutral-50 py-2">
                <AppText
                    variant="regular"
                    applyGradient
                    className="flex items-center gap-2 !font-semibold"
                >
                    <CreditsIcon className="h-5 w-5" />
                    {formatCreditAmount(creditCost, true, true)}
                </AppText>
                <AppText
                    variant="regular"
                    className="!font-semibold text-neutral-700"
                >
                    {creditLine}
                </AppText>
            </div>
        </div>
    );
};

export default CreditsInfoModal;
