import { ImportIcon, FileText, ArrowRight } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/Button/Button";
import { ResumeOptionCard } from "@/components/Cards/ResumeOptionCard";
import { ResumeStep } from "@/components/Modals/NewResumeModal/NewResumeModal";
import { AppText } from "@/components/Text/AppText";
import { createNewResume } from "@/helper/createNewResume";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useDevice } from "@/hooks/useDevice";
import { closeModal, openModal } from "@/store/modal/slice";
import { getUserDetails } from "@/store/user/selectors";
import { ModalTypes } from "@/types/modal";
import { trackPostHogEvent } from "@/utils/tracking";

export const EmptyDashboardView = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const userDetails = useAppSelector(getUserDetails);
    const { isDesktop } = useDevice();

    const onOptionSelect = (modalStep?: ResumeStep) => {
        if (!modalStep) {
            dispatch(closeModal(ModalTypes.NEW_RESUME));
            trackPostHogEvent("new_resume_option_selected", {
                feature_name: "new_resume",
                option_selected: "create_from_blank",
                fromDashboard: true,
            });
            createNewResume(navigate, {
                userDetails,
                isDesktop,
            });
        } else {
            trackPostHogEvent("new_resume_option_selected", {
                feature_name: "new_resume",
                option_selected:
                    modalStep === ResumeStep.RESUME_DETAILS
                        ? "import_resume"
                        : "create_from_template",
                fromDashboard: true,
            });
            dispatch(
                openModal({
                    modalType: ModalTypes.NEW_RESUME,
                    props: { startStep: modalStep },
                }),
            );
        }
    };

    return (
        <div className="flex min-h-[calc(100vh-theme(space.16))] flex-col items-center pb-4 sm:px-3 sm:pb-12">
            <div className="mx-auto w-full max-w-5xl rounded-xl border border-neutral-200 bg-white p-4 shadow-sm sm:p-8">
                <div className="mx-auto max-w-4xl">
                    <div className="mb-6 text-center sm:mb-10">
                        <AppText
                            variant="headings"
                            className="mb-2 text-neutral-900 "
                        >
                            Create Your First Resume
                        </AppText>
                        <AppText
                            variant="regular"
                            className="text-neutral-600"
                        >
                            Craft a job-winning resume in minutes
                        </AppText>
                    </div>

                    <div className="mb-8 flex flex-col items-center justify-center gap-4 lg:mb-8 lg:grid lg:grid-cols-2 lg:gap-6">
                        <div className="ml-0 space-y-2 lg:ml-auto">
                            <div className="hidden items-center rounded-full border border-neutral-200 bg-neutral-50 px-2.5 py-0.5 sm:px-3 sm:py-1 lg:inline-flex">
                                <AppText
                                    variant="labelsbuttons"
                                    className=" text-neutral-600 "
                                >
                                    Have a resume already?
                                </AppText>
                            </div>
                            <ResumeOptionCard
                                icon={ImportIcon}
                                title="Import Resume"
                                features={[
                                    {
                                        text: (
                                            <>
                                                Transform your resume with{" "}
                                                <AppText
                                                    variant="labelsbuttons"
                                                    applyGradient
                                                    className="whitespace-nowrap"
                                                >
                                                    Smart Scribe
                                                </AppText>
                                            </>
                                        ),
                                    },
                                    {
                                        text: (
                                            <>
                                                Tailored to your roles with{" "}
                                                <AppText
                                                    variant="labelsbuttons"
                                                    applyGradient
                                                    className="whitespace-nowrap"
                                                >
                                                    Smart Match
                                                </AppText>
                                            </>
                                        ),
                                    },
                                    {
                                        text: "Get a polished, ATS-friendly resume in minutes",
                                    },
                                ]}
                                ctaText="Import & Enhance"
                                onClick={() =>
                                    onOptionSelect(ResumeStep.RESUME_DETAILS)
                                }
                                iconBgColor="bg-primary-600"
                                gradientFrom="from-primary-50"
                                className="border-primary-100 bg-primary-50/10 hover:border-primary-200 hover:bg-primary-50/20"
                            />
                        </div>

                        <div className="space-y-2">
                            <div className="hidden items-center rounded-full border border-neutral-200 bg-neutral-50 px-2.5 py-0.5 sm:px-3 sm:py-1 lg:inline-flex">
                                <AppText
                                    variant="labelsbuttons"
                                    className=" text-neutral-600"
                                >
                                    Starting fresh?
                                </AppText>
                            </div>
                            <ResumeOptionCard
                                icon={FileText}
                                title="Use a Template"
                                features={[
                                    {
                                        text: "Start with proven, recruiter-approved designs",
                                    },
                                    {
                                        text: (
                                            <>
                                                Create impactful content with{" "}
                                                <AppText
                                                    variant="labelsbuttons"
                                                    applyGradient
                                                    className="whitespace-nowrap"
                                                >
                                                    Smart Scribe
                                                </AppText>
                                            </>
                                        ),
                                    },
                                    {
                                        text: "Dynamic layouts for standout resumes",
                                    },
                                ]}
                                ctaText="Choose Template"
                                onClick={() =>
                                    onOptionSelect(ResumeStep.TEMPLATE)
                                }
                                iconBgColor="bg-primary-600"
                                gradientFrom="from-primary-50"
                                className="border-primary-100 bg-primary-50/10 hover:border-primary-200 hover:bg-primary-50/20"
                            />
                        </div>
                    </div>

                    <div className="mt-4 text-center">
                        <Button
                            variant="text"
                            color="grey"
                            onClick={() => onOptionSelect()}
                            rightIcon={<ArrowRight size={16} />}
                            className="text-sm sm:text-base"
                        >
                            Start with a blank resume instead
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
